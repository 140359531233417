import {
  ArrowLeftOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  InboxOutlined,
  RocketOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { StepType, useTour } from '@reactour/tour'
import {
  Button,
  Card,
  Empty,
  notification,
  Popconfirm,
  Row,
  Space,
  Tag,
} from 'antd'
import { useContext, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { PathQuery } from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../auth/components/Can'
import { LoadScreen } from '../../core/components/LoadScreen'
import { getParentRoute } from '../../core/routes/router'
import { HoverMenu } from '../../courses/pages/course-viewer/CourseViewer.style'
import { PageProps } from '../../shared/interfaces/page.interface'
import { Container } from '../../shared/layout/Layout.style'
import { usePathTourSteps } from '../../tour/use-tour-steps'
import { LearningPathActions } from '../components/LearningPathActions'
import { LearningPathTabs } from '../components/LearningPathTabs'
import { PathStatusAlerts } from '../components/PathStatus'
import { UserPathStatus } from '../components/UserPathStatus'
import { usePath } from '../contexts/PathContext'

import ARCHIVE_LEARNING_PATH_MUTATION from './../mutations/archive-path.graphql'
import DELETE_LEARNING_PATH_MUTATION from './../mutations/delete-path.graphql'
import DUPLICATE_LEARNING_PATH_MUTATION from './../mutations/duplicate-path.graphql'
import UNARCHIVE_LEARNING_PATH_MUTATION from './../mutations/unarchive-path.graphql'
import UPDATE_LEARNING_PATH_MUTATION from './../mutations/update-path.graphql'
import LEARNING_PATH_QUERY from './../queries/path.graphql'
import { LearningPathTimeline } from './LearningPathTimeline'
import { SpecialCol, Title, Wrapper } from './LearningPathViewer.style'

export const LearningPathViewer = ({ setPageTitle, route }: PageProps) => {
  const { setPath } = usePath()
  const params = useParams()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const parent = getParentRoute(route, params)

  const tour = useTour()
  const { setIsOpen: setIsTourOpen, setSteps } = tour
  const steps = usePathTourSteps(ability, tour)

  const { data, loading, error, refetch } = useQuery<PathQuery>(
    LEARNING_PATH_QUERY,
    {
      variables: { id: params.id },
      onCompleted: (data) => {
        if (data?.fetchLearningPathById.title && setPageTitle)
          setPageTitle(data?.fetchLearningPathById.title)

        if (data?.fetchLearningPathById) setPath(data?.fetchLearningPathById)
      },
    }
  )

  const [duplicate] = useMutation(DUPLICATE_LEARNING_PATH_MUTATION, {
    variables: {
      id: params.id,
    },
    onCompleted: (data) => {
      notification.success({
        message: t({
          id: 'paths.duplicate.success',
          message: 'Leerpad succesvol gedupliceerd',
        }),
      })

      navigate(`/paths/${data?.duplicateLearningPath._id}`)
    },
  })

  const [publishPath, { loading: publishing }] = useMutation(
    UPDATE_LEARNING_PATH_MUTATION,
    {
      variables: {
        id: params.id,
        input: {
          published: new Date(),
        },
      },
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'paths.publish.success',
            message: 'Leerpad succesvol gepubliceerd',
          }),
        })
      },
      refetchQueries: ['path'],
    }
  )

  const [archive] = useMutation(ARCHIVE_LEARNING_PATH_MUTATION, {
    variables: {
      id: params.id,
    },
    onCompleted: () => {
      notification.success({
        message: t({
          id: 'paths.archivation.success',
          message: 'Leerpad succesvol gearchiveerd',
        }),
      })
    },
    refetchQueries: ['paths'],
  })

  const [unarchive] = useMutation(UNARCHIVE_LEARNING_PATH_MUTATION, {
    variables: {
      id: params.id,
    },
    onCompleted: () => {
      notification.success({
        message: t({
          id: 'paths.unarchivation.success',
          message: 'Leerpad succesvol teruggeplaatst',
        }),
      })
    },
    refetchQueries: ['paths'],
  })

  const [deletePath, { loading: deleting }] = useMutation(
    DELETE_LEARNING_PATH_MUTATION,
    {
      variables: { id: params.id },
      onCompleted: () => {
        navigate(parent)
        notification.success({
          message: 'Leerpad succesvol verwijderd',
        })
      },
      refetchQueries: ['paths'],
    }
  )

  const path = useMemo(() => {
    return data?.fetchLearningPathById
  }, [data])

  // Set tour steps
  useEffect(() => {
    setSteps?.(steps as StepType[])
  }, [setSteps, steps])

  // Open tour if not shown before
  useEffect(() => {
    if (
      localStorage.getItem('aa_lms_paths_tour') !== 'true' &&
      path?.modules?.length === 0
    ) {
      setIsTourOpen(true)
    }
  }, [setIsTourOpen, path])

  if (!path && !error) {
    return <LoadScreen />
  }

  const canArchive = ability.can(
    PermissionAction.ARCHIVE,
    PermissionObjectType.BRANCH_LEARNING_PATH
  )

  const canUpdate = ability.can(
    PermissionAction.UPDATE,
    PermissionObjectType.BRANCH_LEARNING_PATH
  )

  const canDelete = ability.can(
    PermissionAction.DELETE,
    PermissionObjectType.BRANCH_LEARNING_PATH
  )

  if (!path) {
    return (
      <Container style={{ padding: '36px 0' }}>
        <Empty
          description={
            <p>
              <Trans id="paths.detail.not_found">
                Leerpad niet gevonden. Ben je zeker dat je over de nodige
                rechten beschikt om deze te bekijken?
              </Trans>
            </p>
          }
        >
          <Button type="primary" onClick={() => navigate(parent)}>
            <Trans id="courses.detail.back_to_overview">
              Terug naar overzicht
            </Trans>
          </Button>
        </Empty>
      </Container>
    )
  }

  return (
    <Wrapper>
      <Row align={'stretch'} style={{ height: 'calc(100vh - 72px)' }}>
        <SpecialCol
          xs={{ span: 24 }}
          lg={{ span: 10 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <PathStatusAlerts path={path} />
          <UserPathStatus path={path} />
          <Card style={{ paddingBottom: 16, flex: 1 }}>
            <Title>
              <Space size="small">
                <Button
                  icon={<ArrowLeftOutlined />}
                  size="middle"
                  onClick={() => navigate(parent)}
                  type="text"
                  shape="circle"
                />
                {path.title}
                {!path.published && (
                  <Tag style={{ verticalAlign: 'middle' }}>
                    <Trans id="path.tag.draft">Concept</Trans>
                  </Tag>
                )}
                {path.my_activity?.completed && (
                  <Tag color="success" style={{ verticalAlign: 'middle' }}>
                    <Trans id="path.tag.completed">Voltooid</Trans>
                  </Tag>
                )}
              </Space>
            </Title>
            <HoverMenu className="path-hover-menu" inline="true">
              <Button
                className="path-hover-menu__edit"
                type="text"
                onClick={() => navigate(`/paths/${path._id}/edit`)}
                hidden={!canUpdate}
              >
                <Space>
                  <EditOutlined />
                  <Trans id="paths.detail.action.edit">Bewerken</Trans>
                </Space>
              </Button>
              <Popconfirm
                title={
                  <Trans id="paths.detail.action.publish.title">
                    Ben je zeker dat je dit leerpad wilt publiceren?
                  </Trans>
                }
                onConfirm={() => publishPath()}
                okText={<Trans id="action.publish">Publiceren</Trans>}
                cancelText={<Trans id="action.cancel">Annuleren</Trans>}
                okButtonProps={{ loading: publishing }}
              >
                <Button
                  className="path-hover-menu__publish"
                  type="text"
                  hidden={!canUpdate || !!path.published}
                >
                  <Space>
                    <RocketOutlined />
                    <Trans id="action.publish">Publiceren</Trans>
                  </Space>
                </Button>
              </Popconfirm>
              <Popconfirm
                title={
                  path.archived ? (
                    <Trans id="paths.detail.action.archive.activate.title">
                      Ben je zeker dat je dit leerpad opnieuw wil activeren?
                    </Trans>
                  ) : (
                    <Trans id="paths.detail.action.archive.title">
                      Ben je zeker dat je dit leerpad wilt archiveren?
                    </Trans>
                  )
                }
                onConfirm={path.archived ? () => unarchive() : () => archive()}
                okText={
                  path.archived ? (
                    <Trans id="action.archive.activate">
                      Heractiveren uit archief
                    </Trans>
                  ) : (
                    <Trans id="action.archive">Archiveren</Trans>
                  )
                }
                cancelText={<Trans id="action.cancel">Annuleren</Trans>}
                okButtonProps={{ loading }}
              >
                <Button
                  className="path-hover-menu__publish"
                  type="text"
                  hidden={!canArchive || !path.published}
                >
                  <Space>
                    <InboxOutlined />
                    {path.archived ? (
                      <Trans id="action.archive.activate">
                        Heractiveren uit archief
                      </Trans>
                    ) : (
                      <Trans id="action.archive">Archiveren</Trans>
                    )}
                  </Space>
                </Button>
              </Popconfirm>
              {canUpdate && (
                <Button type="text" onClick={() => duplicate()}>
                  <Space>
                    <CopyOutlined />
                    <Trans id="path.detail.action.duplicate">Dupliceren</Trans>
                  </Space>
                </Button>
              )}
              <Popconfirm
                title={
                  <Trans id="paths.detail.action.delete.title">
                    Ben je zeker dat je dit leerpad wilt verwijderen?
                  </Trans>
                }
                onConfirm={() => deletePath()}
                okText={<Trans id="action.delete">Verwijderen</Trans>}
                cancelText={<Trans id="action.cancel">Annuleren</Trans>}
                okButtonProps={{ loading: deleting }}
              >
                <Button
                  className="path-hover-menu__delete"
                  type="text"
                  hidden={!canDelete}
                >
                  <Space>
                    <DeleteOutlined />
                    <Trans id="path.detail.action.delete">Verwijderen</Trans>
                  </Space>
                </Button>
              </Popconfirm>
            </HoverMenu>
            <LearningPathActions path={path} />
            <LearningPathTabs
              path={path}
              loading={loading}
              refetch={() => refetch}
            />
          </Card>
        </SpecialCol>
        <SpecialCol
          xs={{ span: 24 }}
          lg={{ span: 14 }}
          style={{
            padding: '0 1rem',
          }}
        >
          <LearningPathTimeline path={path} />
        </SpecialCol>
      </Row>
    </Wrapper>
  )
}
