import {
  DownOutlined,
  FundProjectionScreenOutlined,
  GlobalOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Button, Dropdown, Space } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { EventLocationType } from '@lms-shared-patterns/enums/event.enums'
import {
  CancelLiveEventRegistrationMutation,
  LiveEventsQuery,
  RegisterForLiveEventMutation,
} from 'apps/lms-front/src/generated/graphql'

import { QuestionInputModal } from '../../shared/components/question-input-modal/question-input-modal'
import { useEventStatus } from '../hooks/use-event-status.hook'
import { useUserEventRegistrationStatus } from '../hooks/use-user-event-registration-status.hook'
import CANCEL_LIVE_EVENT_REGISTRATION_MUTATION from '../mutations/cancel-live-event-registration.graphql'
import REGISTER_FOR_LIVE_EVENT_MUTATION from '../mutations/register-for-live-event.graphql'
import SUBMIT_QUESTION_FOR_EVENT_MUTATION from '../mutations/submit-question-for-event.graphql'
import LIVE_EVENT_PARTICIPANTS_QUERY from '../queries/live-event-participants.graphql'

export const EventActions = ({
  event,
}: {
  event: LiveEventsQuery['fetchLiveEvents']['results'][0]
}) => {
  const navigate = useNavigate()
  const { openForRegistration, hasRegistrationDeadline, hasReachedCapacity } =
    useEventStatus(event)
  const { canJoinCall, canRegister, canCancel } =
    useUserEventRegistrationStatus(event)

  const [questionModalOpen, setQuestionModalOpen] = useState(false)

  const [registerForEvent, { loading: registering }] =
    useMutation<RegisterForLiveEventMutation>(
      REGISTER_FOR_LIVE_EVENT_MUTATION,
      {
        variables: {
          id: event._id,
        },
        refetchQueries: [
          {
            query: LIVE_EVENT_PARTICIPANTS_QUERY,
            variables: {
              id: event._id,
            },
          },
        ],
      }
    )

  const [cancelRegistration, { loading: cancelling }] =
    useMutation<CancelLiveEventRegistrationMutation>(
      CANCEL_LIVE_EVENT_REGISTRATION_MUTATION,
      {
        variables: {
          id: event._id,
        },
        refetchQueries: [
          {
            query: LIVE_EVENT_PARTICIPANTS_QUERY,
            variables: {
              id: event._id,
            },
          },
        ],
      }
    )

  return (
    <Space direction="vertical">
      <Space>
        {canRegister &&
          (event.location_type.length === 1 ? (
            <Button
              type={'primary'}
              loading={registering}
              onClick={() =>
                registerForEvent({
                  variables: {
                    type: event.location_type[0],
                  },
                })
              }
            >
              <Trans id="events.card.register">Inschrijven</Trans>
            </Button>
          ) : (
            <Dropdown
              menu={{
                items: [
                  {
                    disabled: hasReachedCapacity,
                    key: EventLocationType.Physical,
                    label: (
                      <Space>
                        <FundProjectionScreenOutlined />
                        <span>
                          <Trans id="events.card.register.physical">
                            Fysiek
                          </Trans>{' '}
                          {hasReachedCapacity && (
                            <>
                              (
                              <Trans id="events.card.register.full">
                                volzet
                              </Trans>
                              )
                            </>
                          )}
                        </span>
                      </Space>
                    ),
                    onClick: () =>
                      registerForEvent({
                        variables: {
                          type: EventLocationType.Physical,
                        },
                      }),
                  },
                  {
                    key: EventLocationType.Online,
                    label: (
                      <Space>
                        <GlobalOutlined />
                        <Trans id="events.card.register.online">Online</Trans>
                      </Space>
                    ),
                    onClick: () =>
                      registerForEvent({
                        variables: {
                          type: EventLocationType.Online,
                        },
                      }),
                  },
                ].filter(Boolean) as ItemType[],
              }}
            >
              <Button
                type={'primary'}
                loading={registering}
                icon={<DownOutlined />}
              >
                Inschrijven
              </Button>
            </Dropdown>
          ))}
        {canJoinCall && (
          <Button
            type={'primary'}
            onClick={() => navigate(`/meet/${event.call_id}`)}
          >
            <Trans id="events.card.join_event">
              Inloggen op de online sessie
            </Trans>
          </Button>
        )}
        {canCancel && (
          <Button onClick={() => cancelRegistration()} loading={cancelling}>
            <Trans id="events.card.cancel_registration">
              Inschrijving annuleren
            </Trans>
          </Button>
        )}
        {canCancel && (
          <Button onClick={() => setQuestionModalOpen(true)}>
            <Trans id="events.card.ask_question">Stel een vraag</Trans>
          </Button>
        )}
      </Space>
      {openForRegistration && hasRegistrationDeadline && (
        <em>
          <Trans id="events.card.registration_deadline">
            De deadline voor het inschrijven voor deze sessie:
          </Trans>{' '}
          {dayjs(event.registration_deadline).format('DD MMMM YYYY HH:mm')}
        </em>
      )}

      <QuestionInputModal
        open={questionModalOpen}
        onClose={() => setQuestionModalOpen(false)}
        id={event._id}
        mutation={SUBMIT_QUESTION_FOR_EVENT_MUTATION}
        title={t({
          id: 'event.question_modal.title',
          message: 'Stel een vraag over dit event',
        })}
        placeholder={t({
          id: 'event_question_modal.placeholder',
          message: 'Je vraag',
        })}
        successMessage={t({
          id: 'event_question_modal.success',
          message: 'Je vraag is succesvol verzonden!',
        })}
        errorMessage={t({
          id: 'event_question_modal.failed',
          message: 'Er ging iets mis. Probeer het later opnieuw.',
        })}
      />
    </Space>
  )
}
