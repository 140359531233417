import {
  AudioMutedOutlined,
  AudioOutlined,
  DesktopOutlined,
  MessageOutlined,
  PoweroffOutlined,
  TrademarkCircleOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
  QuestionOutlined,
  BarChartOutlined,
  MoreOutlined,
  EyeOutlined,
  BulbOutlined,
} from '@ant-design/icons'
import { t } from '@lingui/macro'
import {
  useLocalParticipant,
  useParticipants,
  useRoomInfo,
} from '@livekit/components-react'
import '@livekit/components-styles' // Add default styles for LiveKit components
import { Badge, Dropdown, Statistic, Tooltip, notification } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useMediaQuery } from 'apps/lms-front/src/modules/shared/hooks/use-media-query'

import { useStream } from '../../../../contexts/StreamContext'
import BackgroundController from '../background-controller/BackgroundController'
import {
  startRecordingApiCall,
  stopRecordingApiCall,
} from '../utils/recording-handler'

import {
  ControlButton,
  ControlPanelContainer,
  LightProgress,
} from './ControlPanel.style'

export const ControlPanel = ({
  onToggleChat,
  isChatVisible,
  unreadChatCount,
  virtualBackground,
  onVirtualBackgroundChange,
}: {
  onToggleChat: () => void
  isChatVisible: boolean
  unreadChatCount: number
  virtualBackground: string
  onVirtualBackgroundChange: (background: string) => void
}) => {
  const { localParticipant } = useLocalParticipant()
  const { call_id } = useParams()
  const participants = useParticipants()
  const {
    leaveRoom,
    emit,
    canEnableCamera,
    canEnableMicrophone,
    isSurveyModalVisible,
    surveyCompletionArr,
    surveyActiveTimer,
    isMeetingHost,
    survey,
    handRaisedParticipants,
  } = useStream()

  const isMobile = useMediaQuery('(max-width: 768px)')
  const roomContext = useRoomInfo()

  const isUserControlAvailable = useMemo(() => {
    return canEnableCamera && canEnableMicrophone
  }, [canEnableCamera, canEnableMicrophone])

  const [isRecording, setIsRecording] = useState(false)
  const [surveyStartTime, setSurveyStartTime] = useState<number | null>(null)
  const [virtualBackgroundDrawerOpened, setVirtualBackgroundDrawerOpened] =
    useState(false)

  const [isHandRaised, raiseHand] = useState(false)

  useEffect(() => {
    if (handRaisedParticipants.includes(localParticipant.identity)) {
      raiseHand(true)
    } else {
      raiseHand(false)
    }
  }, [handRaisedParticipants])

  const totalNonHostParticipants = useMemo(() => {
    return participants
      ? participants.filter((p) => {
          const userMetadata = p?.metadata
            ? JSON.parse(p?.metadata as string)
            : {}

          return !userMetadata['isHost']
        }).length
      : 0
  }, [participants])

  const completionPercentage = useMemo(() => {
    if (
      !surveyCompletionArr ||
      surveyCompletionArr.length === 0 ||
      totalNonHostParticipants === 0
    ) {
      return 0
    }
    const completedCount = surveyCompletionArr.filter(Boolean).length
    return Math.round((completedCount / totalNonHostParticipants) * 100)
  }, [surveyCompletionArr, totalNonHostParticipants])

  useEffect(() => {
    if (roomContext?.metadata) {
      const isRecording = JSON.parse(
        roomContext?.metadata as string
      ).isRecording
      setIsRecording(isRecording)
    }
  }, [roomContext])

  const toggleAudio = async () => {
    if (canEnableMicrophone) {
      await localParticipant.setMicrophoneEnabled(
        !localParticipant.isMicrophoneEnabled
      )
    }
  }

  const toggleVideo = async () => {
    if (canEnableCamera) {
      await localParticipant.setCameraEnabled(!localParticipant.isCameraEnabled)
    }
  }

  const toggleScreenShare = async () => {
    try {
      await (localParticipant.isScreenShareEnabled
        ? localParticipant.setScreenShareEnabled(false)
        : localParticipant.setScreenShareEnabled(true))
    } catch (error) {
      notification.error({
        message: t({
          id: 'stream.control_panel.error.screen_share',
          message: 'Failed to toggle screen sharing',
        }),
      })
      console.error('Failed to toggle screen sharing', error)
    }
  }

  const isRemoteParticipantSharingScreen = useMemo(() => {
    return participants.some(
      (participant) =>
        participant !== localParticipant && participant.isScreenShareEnabled
    )
  }, [participants, localParticipant])

  const handleSurveyAction = useCallback(() => {
    emit('toggleSurvey', {
      call_id,
      is_survey_visible: !isSurveyModalVisible,
    })

    if (isSurveyModalVisible) {
      setSurveyStartTime(null)
    } else {
      const startTime = Date.now()
      setSurveyStartTime(startTime)
    }
  }, [isSurveyModalVisible, emit, call_id])

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null

    if (surveyStartTime) {
      timer = setTimeout(() => {
        handleSurveyAction()
      }, surveyActiveTimer)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [surveyStartTime, handleSurveyAction])

  const startRecording = async () => {
    try {
      await startRecordingApiCall(call_id)
      setIsRecording(true)
    } catch (error) {
      console.error('Failed to start recording', error)
    }
  }

  const stopRecording = async () => {
    try {
      await stopRecordingApiCall(call_id)
      setIsRecording(false)
    } catch (error) {
      console.error('Failed to stop recording', error)
    }
  }

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording()
    } else {
      startRecording()
    }
  }
  return (
    <ControlPanelContainer>
      <>
        {isUserControlAvailable && (
          <>
            <ControlButton
              onClick={toggleAudio}
              disabled={!canEnableMicrophone}
            >
              {localParticipant.isMicrophoneEnabled ? (
                <AudioOutlined />
              ) : (
                <AudioMutedOutlined />
              )}
              <span>
                {localParticipant.isMicrophoneEnabled
                  ? t({
                      id: 'stream.control_panel.action.mute',
                      message: 'Geluid dempen',
                    })
                  : t({
                      id: 'stream.control_panel.action.unmute',
                      message: 'Geluid hervatten',
                    })}
              </span>
            </ControlButton>
            <ControlButton onClick={toggleVideo} disabled={!canEnableCamera}>
              {localParticipant.isCameraEnabled ? (
                <VideoCameraOutlined />
              ) : (
                <VideoCameraAddOutlined />
              )}
              <span>
                {localParticipant.isCameraEnabled
                  ? t({
                      id: 'stream.control_panel.action.stop_video',
                      message: 'Video stoppen',
                    })
                  : t({
                      id: 'stream.control_panel.action.start_video',
                      message: 'Video starten',
                    })}
              </span>
            </ControlButton>
            {!isMobile && (
              <Tooltip
                placement="top"
                title={
                  isRemoteParticipantSharingScreen
                    ? t({
                        id: 'stream.control_panel.action.only_one_screen_share',
                        message:
                          'Only one screen could be shared at the same time',
                      })
                    : ''
                }
              >
                <span>
                  <ControlButton
                    onClick={toggleScreenShare}
                    disabled={isRemoteParticipantSharingScreen}
                  >
                    <DesktopOutlined />
                    <span>
                      {localParticipant.isScreenShareEnabled
                        ? t({
                            id: 'stream.control_panel.action.stop_sharing_screen',
                            message: 'Scherm delen stoppen',
                          })
                        : isRemoteParticipantSharingScreen
                        ? t({
                            id: 'stream.control_panel.action.screen_already_shared',
                            message: 'Scherm wordt al gedeeld',
                          })
                        : t({
                            id: 'stream.control_panel.action.share_screen',
                            message: 'Scherm delen',
                          })}
                    </span>
                  </ControlButton>
                </span>
              </Tooltip>
            )}
          </>
        )}
      </>
      {!isUserControlAvailable && !isMeetingHost && (
        <ControlButton
          onClick={() => {
            emit('raiseHand', {
              call_id,
              participantId: localParticipant.identity,
              isHandRaised: !isHandRaised,
            })
            raiseHand(!isHandRaised)
          }}
        >
          <BulbOutlined
            style={{ color: isHandRaised ? 'yellow' : 'inherit' }}
          />
          <span style={{ color: isHandRaised ? 'yellow' : 'inherit' }}>
            {!isHandRaised
              ? t({
                  id: 'stream.control_panel.action.raise_hand',
                  message: 'Raise hand',
                })
              : t({
                  id: 'stream.control_panel.action.put_hand_down',
                  message: 'Put hand down',
                })}
          </span>
        </ControlButton>
      )}
      {!isMobile && (
        <ControlButton onClick={() => leaveRoom()}>
          <PoweroffOutlined style={{ color: 'red' }} />
          <span>
            {t({
              id: 'stream.control_panel.action.leave',
              message: 'Verlaten',
            })}
          </span>
        </ControlButton>
      )}
      {!isMobile && (
        <ControlButton onClick={onToggleChat}>
          <Badge dot={unreadChatCount > 0} offset={[-5, 5]}>
            <MessageOutlined
              style={{ color: isChatVisible ? '#1890ff' : 'white' }}
            />
          </Badge>
          <span>
            {isChatVisible
              ? t({
                  id: 'stream.control_panel.action.hide_chat',
                  message: 'Chat verbergen',
                })
              : t({
                  id: 'stream.control_panel.action.show_chat',
                  message: 'Chat tonen',
                })}
          </span>
        </ControlButton>
      )}
      {isMeetingHost && (
        <>
          <ControlButton onClick={toggleRecording}>
            <TrademarkCircleOutlined
              style={{ color: isRecording ? 'red' : 'inherit' }}
            />
            <span>
              {isRecording
                ? t({
                    id: 'stream.control_panel.action.stop_recording',
                    message: 'Opname stoppen',
                  })
                : t({
                    id: 'stream.control_panel.action.record',
                    message: 'Opname starten',
                  })}
            </span>
          </ControlButton>
          {!isMobile && !!survey?.length && (
            <>
              <ControlButton onClick={handleSurveyAction}>
                <QuestionOutlined
                  style={{ color: isSurveyModalVisible ? 'red' : 'inherit' }}
                />
                <span>
                  {isSurveyModalVisible
                    ? t({
                        id: 'stream.control_panel.survey.stop',
                        message: 'Stop survey',
                      })
                    : t({
                        id: 'stream.control_panel.survey.start',
                        message: 'Start survey',
                      })}
                </span>
              </ControlButton>
              {isSurveyModalVisible && (
                <Tooltip
                  style={{ backgroundColor: 'white' }}
                  title={
                    <LightProgress
                      type="dashboard"
                      percent={completionPercentage}
                      size="small"
                      format={() => (
                        <Statistic
                          valueStyle={{ textAlign: 'center' }}
                          value={surveyCompletionArr.length}
                          suffix={`/ ${totalNonHostParticipants}`}
                        />
                      )}
                    />
                  }
                  placement="top"
                >
                  <ControlButton>
                    <BarChartOutlined />
                    <span>
                      {t({
                        id: 'stream.control_panel.survey.title',
                        message: 'Survey status',
                      })}
                    </span>
                  </ControlButton>
                </Tooltip>
              )}
            </>
          )}
          <Dropdown
            placement="top"
            menu={{
              items: [
                {
                  label: t({
                    id: 'stream.background-fx',
                    message: 'Achtergrondeffecten',
                  }),
                  key: 'virtual-background',
                  icon: <EyeOutlined />,
                  onClick: () => setVirtualBackgroundDrawerOpened(true),
                },
              ],
            }}
          >
            <ControlButton>
              <MoreOutlined />
              <span>
                {t({
                  id: 'stream.control_panel.more',
                  message: 'Meer',
                })}
              </span>
            </ControlButton>
          </Dropdown>
          <BackgroundController
            virtualBackgroundDrawerOpened={virtualBackgroundDrawerOpened}
            setVirtualBackgroundDrawerOpened={setVirtualBackgroundDrawerOpened}
            virtualBackground={virtualBackground}
            onVirtualBackgroundChange={onVirtualBackgroundChange}
          />
        </>
      )}
    </ControlPanelContainer>
  )
}
