/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  FolderOutlined,
  UserOutlined,
  DeleteOutlined,
  ClockCircleOutlined,
  UserAddOutlined,
  EditOutlined,
  ShareAltOutlined,
  BarChartOutlined,
  CalendarOutlined,
  TeamOutlined,
  HeartFilled,
  HeartOutlined,
  SafetyCertificateOutlined,
  UploadOutlined,
  FileOutlined,
  TranslationOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  List,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Tabs,
  Tooltip,
  Upload,
} from 'antd'
import axios from 'axios'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'
import download from 'js-file-download'
import {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useNavigate, useParams } from 'react-router-dom'
import styled, { AnyStyledComponent } from 'styled-components'

import {
  PermissionAction,
  PermissionObjectType,
  subject,
} from '@lms-shared-patterns/models'
import { formatDuration } from '@lms-shared-patterns/utils'
import {
  ArchiveCourseMutation,
  AssignCourseMutation,
  AssignedCoursesQuery,
  CourseBySlugQuery,
  CreateVodEventMutation,
  DeleteCourseAttachmentMutation,
  DeleteCourseMutation,
  DuplicateCurrentCourseMutation,
  LikeCourseMutation,
  UnarchiveCourseMutation,
  UnitUnion,
} from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { LoadScreen } from '../../../core/components/LoadScreen'
import { getParentRoute } from '../../../core/routes/router'
import {
  TranslateableRecord,
  TranslateButton,
} from '../../../shared/components/translate-button/TranslateButton'
import { useLanguages } from '../../../shared/hooks/use-languages'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { AddToCalendarModal } from '../../components/add-to-calendar-modal'
import { CourseAssignmentModal } from '../../components/course-assignment-modal/CourseAssignmentModal'
import { CourseSharingModal } from '../../components/course-sharing-modal'
import { CourseViewerButton } from '../../components/course-viewer-button/CourseViewerButton'
import { SectionUnitTimeline } from '../../components/section-unit-timeline'

import ARCHIVE_COURSE from './../../mutations/archive-course.graphql'
import ASSIGN_COURSE_MUTATION from './../../mutations/assign-course.graphql'
import CREATE_VOD_EVENT_MUTATION from './../../mutations/create-vod-event.graphql'
import DELETE_COURSE_ATTACHMENT_MUTATION from './../../mutations/delete-course-attachment.graphql'
import DELETE_COURSE_MUTATION from './../../mutations/delete-course.graphql'
import DUPLICATE_COURSE_MUTATION from './../../mutations/duplicate-course.graphql'
import LIKE_COURSE_MUTATION from './../../mutations/like-course.graphql'
import UNARCHIVE_COURSE from './../../mutations/unarchive-course.graphql'
import ASSIGNED_COURSES_QUERY from './../../queries/assigned-courses.graphql'
import COURSE_BY_SLUG_QUERY from './../../queries/course-by-slug.graphql'
import {
  AutoTranslated,
  Container,
  CourseImage,
  CourseMeta,
  CourseTitle,
  DarkContainer,
  HoverMenu,
  LessonGroupContent,
  LessonGroupTitle,
  TabContent,
  TransparentButton,
} from './CourseViewer.style'

const LikeSpacer = styled(Space as unknown as AnyStyledComponent)`
  position: absolute;
  right: calc(15% + 12px);
  top: 12px;
  z-index: 5;

  @media screen and (max-width: 767px) {
    right: 12px;
  }
`

export const AttachmentName = styled.span`
  display: inline-block;
  max-width: 65vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  margin-right: -48px;

  @media screen and (min-width: 768px) {
    max-width: 35vw;
    margin-right: -48px;
  }

  @media screen and (min-width: 1280px) {
    max-width: 500px;
    margin-right: -48px;
  }
`

export const CourseViewer = ({ setPageTitle, route }: PageProps) => {
  const params = useParams()
  const { slug } = params
  const parent = getParentRoute(route, params)

  const { user } = useAuth()
  const { languages } = useLanguages()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const branch = useBranch()

  const filesRef = useRef<HTMLSpanElement>(null)
  const [activeKey, setActiveKey] = useState<string>('content')

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
  const [showSharingOptions, setShowSharingOptions] = useState<boolean>(false)
  const [showAddToCalendar, setShowAddToCalendar] = useState<boolean>(false)

  const {
    data: course,
    error,
    refetch,
  } = useQuery<CourseBySlugQuery>(COURSE_BY_SLUG_QUERY, {
    variables: { slug },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.fetchCourseBySlug.translation.name && setPageTitle)
        setPageTitle(data?.fetchCourseBySlug.translation.name)
    },
  })

  const { data: assignedCoursesData } = useQuery<AssignedCoursesQuery>(
    ASSIGNED_COURSES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const [duplicateCourse] = useMutation<DuplicateCurrentCourseMutation>(
    DUPLICATE_COURSE_MUTATION,
    {
      variables: {
        id: course?.fetchCourseBySlug._id,
      },
    }
  )

  const [likeCourse] = useMutation<LikeCourseMutation>(LIKE_COURSE_MUTATION, {
    variables: {
      id: course?.fetchCourseBySlug._id,
    },
  })

  const [archiveCourse] = useMutation<ArchiveCourseMutation>(ARCHIVE_COURSE, {
    variables: {
      id: course?.fetchCourseBySlug._id,
    },
  })

  const [unarchiveCourse] = useMutation<UnarchiveCourseMutation>(
    UNARCHIVE_COURSE,
    {
      variables: {
        id: course?.fetchCourseBySlug._id,
      },
    }
  )

  const [startWatchParty] = useMutation<CreateVodEventMutation>(
    CREATE_VOD_EVENT_MUTATION,
    {
      variables: {
        course_id: course?.fetchCourseBySlug._id,
      },
    }
  )

  const [assignCourse, { loading: assigning }] =
    useMutation<AssignCourseMutation>(ASSIGN_COURSE_MUTATION)

  const attachments = useMemo(() => {
    if (course?.fetchCourseBySlug?.attachments)
      return [...course.fetchCourseBySlug.attachments].sort((a, b) =>
        a.originalName.localeCompare(b.originalName)
      )
    return []
  }, [course?.fetchCourseBySlug?.attachments])

  const courseIsAssigned = useMemo(() => {
    if (assignedCoursesData?.assignedToMe) {
      return assignedCoursesData.assignedToMe.find((assignedCourse) =>
        new ObjectId(assignedCourse._id).equals(course?.fetchCourseBySlug._id)
      )?.assignment
    }
    return undefined
  }, [assignedCoursesData, course?.fetchCourseBySlug._id])

  const [deleteCourse] = useMutation<DeleteCourseMutation>(
    DELETE_COURSE_MUTATION
  )

  const [deleteAttachment] = useMutation<DeleteCourseAttachmentMutation>(
    DELETE_COURSE_ATTACHMENT_MUTATION
  )

  const [assignModalVisible, setAssignModalVisible] = useState<boolean>(false)

  useEffect(() => {
    if (course && course.fetchCourseBySlug.slug !== slug) {
      navigate(`${parent}/${course.fetchCourseBySlug.slug}`, { replace: true })
    }
  }, [course, navigate, slug])

  if (!course && !error) {
    return <LoadScreen />
  }

  if (!course) {
    return (
      <Container style={{ padding: '36px 0' }}>
        <Empty
          description={
            <p>
              <Trans id="courses.detail.not_found">
                Opleiding niet gevonden. Ben je zeker dat je over de nodige
                rechten beschikt om deze te bekijken?
              </Trans>
            </p>
          }
        >
          <Button type="primary" onClick={() => navigate(parent)}>
            <Trans id="courses.detail.back_to_overview">
              Terug naar overzicht
            </Trans>
          </Button>
        </Empty>
      </Container>
    )
  }

  const canShare =
    ability.can(PermissionAction.SHARE, PermissionObjectType.COURSE) ||
    ability.can(
      PermissionAction.SHARE,
      subject(PermissionObjectType.BRANCH_COURSE, course.fetchCourseBySlug)
    ) ||
    ability.can(
      PermissionAction.SHARE,
      subject(PermissionObjectType.OWN_COURSE, course.fetchCourseBySlug)
    )

  const canUpdate =
    ability.can(PermissionAction.UPDATE, PermissionObjectType.COURSE) ||
    (ability.can(
      PermissionAction.UPDATE,
      subject(PermissionObjectType.BRANCH_COURSE, course.fetchCourseBySlug)
    ) &&
      !course.fetchCourseBySlug.readonly) ||
    (ability.can(
      PermissionAction.UPDATE,
      subject(PermissionObjectType.OWN_COURSE, course.fetchCourseBySlug)
    ) &&
      !course.fetchCourseBySlug.readonly)

  const canDuplicate =
    ability.can(PermissionAction.DUPLICATE, PermissionObjectType.COURSE) ||
    ability.can(
      PermissionAction.DUPLICATE,
      subject(PermissionObjectType.BRANCH_COURSE, {
        ...course.fetchCourseBySlug,
      })
    ) ||
    ability.can(
      PermissionAction.DUPLICATE,
      subject(PermissionObjectType.OWN_COURSE, {
        ...course.fetchCourseBySlug,
      })
    )

  const canTranslate =
    ability.can(PermissionAction.TRANSLATE, PermissionObjectType.COURSE) ||
    (ability.can(
      PermissionAction.TRANSLATE,
      subject(PermissionObjectType.BRANCH_COURSE, course.fetchCourseBySlug)
    ) &&
      !course.fetchCourseBySlug.readonly) ||
    (ability.can(
      PermissionAction.TRANSLATE,
      subject(PermissionObjectType.OWN_COURSE, course.fetchCourseBySlug)
    ) &&
      !course.fetchCourseBySlug.readonly)

  const canArchiveOwnCourse = ability.can(
    PermissionAction.ARCHIVE,
    subject(PermissionObjectType.OWN_COURSE, course.fetchCourseBySlug)
  )

  const canDelete =
    ability.can(PermissionAction.DELETE, PermissionObjectType.COURSE) ||
    ability.can(
      PermissionAction.DELETE,
      subject(PermissionObjectType.BRANCH_COURSE, course.fetchCourseBySlug)
    ) ||
    ability.can(
      PermissionAction.DELETE,
      subject(PermissionObjectType.OWN_COURSE, course.fetchCourseBySlug)
    )

  const description = ReactHtmlParser(
    course.fetchCourseBySlug.translation.description || ''
  )

  const courseHeaderBG = branch?.courseHeaderImage?.url
    ? `url(${branch?.courseHeaderImage?.url}) center / cover`
    : `linear-gradient(145deg, ${branch?.theme.headerColor}, ${branch?.theme.primaryColor} 125%)`

  return (
    <>
      <div
        className="course-viewer-header"
        style={{
          background: courseHeaderBG,
          position: 'relative',
          paddingBottom: 18,
          marginBottom: 24,
        }}
      >
        <DarkContainer>
          <Row>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 6, offset: 1 }}
              xl={{ span: 8, offset: 2 }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ position: 'relative' }}>
                {ability.can(
                  PermissionAction.READ,
                  PermissionObjectType.LIKE
                ) && (
                  <LikeSpacer>
                    <Tooltip
                      mouseEnterDelay={0.2}
                      overlay={
                        course.fetchCourseBySlug.liked
                          ? t({
                              id: 'course.card.action.remove_from_likes',
                              message: 'Verwijderen uit favorieten',
                            })
                          : t({
                              id: 'course.card.action.add_to_likes',
                              message: 'Toevoegen aan favorieten',
                            })
                      }
                    >
                      <Button
                        style={{
                          backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        }}
                        shape="circle"
                        type="text"
                        icon={
                          course.fetchCourseBySlug.liked ? (
                            <HeartFilled
                              style={{ color: 'var(--like-color)' }}
                            />
                          ) : (
                            <HeartOutlined
                              style={{ color: 'var(--like-color)' }}
                            />
                          )
                        }
                        onClick={(e) => {
                          e.stopPropagation()
                          likeCourse({
                            refetchQueries: ['likedCourses'],
                          })
                          return false
                        }}
                      />
                    </Tooltip>
                  </LikeSpacer>
                )}
                <CourseImage
                  src={
                    course.fetchCourseBySlug.image?.url ||
                    '/course-image-placeholder.svg'
                  }
                  width={course.fetchCourseBySlug.image?.width || 720}
                  height={course.fetchCourseBySlug.image?.height || 480}
                  loading="lazy"
                  alt={course.fetchCourseBySlug.image?.alt || ''}
                />
              </div>
            </Col>
            <Col
              md={{ span: 12 }}
              lg={{ span: 16, offset: 1 }}
              xl={{ span: 11, offset: 1 }}
            >
              <Space style={{ marginBottom: 16 }}>
                <TransparentButton
                  type={'text'}
                  onClick={() =>
                    navigate(
                      `${
                        parent.startsWith('/courses') ? '/library' : ''
                      }${parent}${sessionStorage.getItem('aa_filters') || ''}`
                    )
                  }
                  icon={
                    <svg
                      width="6"
                      height="9"
                      viewBox="0 0 6 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: 8, color: '#FFF' }}
                    >
                      <path
                        d="M4.6665 0.833252L0.999837 4.49992L4.6665 8.16658"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                >
                  <Trans id="courses.detail.go_to_overview">
                    Naar overzicht
                  </Trans>
                </TransparentButton>
              </Space>
              <CourseTitle>
                {course.fetchCourseBySlug.archived && (
                  <span>
                    [<Trans id="course.status.archived">In archief</Trans>]:{' '}
                  </span>
                )}
                {course.fetchCourseBySlug.translation.name}
              </CourseTitle>
              {course.fetchCourseBySlug.my_activity?.completed && (
                <CourseMeta>
                  <li>
                    <Trans id="courses.detail.completed">
                      Je hebt deze opleiding afgerond op
                      {dayjs(
                        course.fetchCourseBySlug.my_activity.completed
                      ).format('DD/MM/YYYY')}
                      .
                    </Trans>
                  </li>
                </CourseMeta>
              )}
              {(course.fetchCourseBySlug.lecturer ||
                course.fetchCourseBySlug.duration ||
                course.fetchCourseBySlug.meta?.publication_date ||
                course.fetchCourseBySlug.meta?.revision_date) && (
                <CourseMeta>
                  {course.fetchCourseBySlug.lecturer && (
                    <li>
                      <UserOutlined />
                      {course.fetchCourseBySlug.lecturer}
                    </li>
                  )}
                  {course.fetchCourseBySlug.duration && (
                    <li>
                      <ClockCircleOutlined />
                      {formatDuration(course.fetchCourseBySlug.duration * 60)}
                    </li>
                  )}
                  {(course.fetchCourseBySlug.meta?.publication_date ||
                    course.fetchCourseBySlug.meta?.revision_date) && (
                    <li>
                      <CalendarOutlined />
                      {dayjs(
                        course.fetchCourseBySlug.meta.revision_date ||
                          course.fetchCourseBySlug.meta.publication_date
                      ).format('DD/MM/YYYY')}
                    </li>
                  )}
                  {course.fetchCourseBySlug.certificationType?.length ? (
                    <Space style={{ marginTop: 8, marginBottom: 8 }}>
                      {course.fetchCourseBySlug.certificationType
                        ?.filter(
                          (c) =>
                            user?.certificationType?.find((ct) =>
                              new ObjectId(ct._id).equals(c._id)
                            )
                        )
                        .map((c) => (
                          <li key={c._id}>
                            <SafetyCertificateOutlined />
                            {c.translation?.name || c.name}
                          </li>
                        ))}
                    </Space>
                  ) : null}
                </CourseMeta>
              )}
              <div>
                {course.fetchCourseBySlug?.translation.short_description ||
                  (description.slice(0, 1) as ReactNode)}
              </div>
              <div style={{ marginTop: 24 }}>
                <Space size={'middle'}>
                  <CourseViewerButton
                    course={course.fetchCourseBySlug}
                    parent={parent}
                  />
                  {course.fetchCourseBySlug.attachments?.length > 0 && (
                    <Button
                      type="primary"
                      onClick={() => {
                        if (filesRef.current) {
                          setActiveKey('downloads')
                          filesRef.current.scrollIntoView({
                            behavior: 'smooth',
                          })
                        }
                      }}
                    >
                      <Trans id="courses.detail.go_to_downloads">
                        Bekijk downloads
                      </Trans>
                    </Button>
                  )}
                  {ability.can(
                    PermissionAction.CREATE,
                    PermissionObjectType.VOD_EVENT
                  ) && (
                    <Tooltip
                      title={
                        <Trans id="course.detail.start_group_watch">
                          In groep bekijken
                        </Trans>
                      }
                    >
                      <Button
                        icon={<TeamOutlined />}
                        type="primary"
                        ghost
                        onClick={() => {
                          if (course.fetchCourseBySlug.event_id) {
                            navigate(
                              `/vod-events/${encodeURIComponent(
                                course.fetchCourseBySlug.event_id
                              )}`
                            )
                          } else
                            startWatchParty().then(({ data }) => {
                              if (data?.createVODEvent) {
                                navigate(
                                  `/vod-events/${encodeURIComponent(
                                    data.createVODEvent._id
                                  )}`
                                )
                                notification.success({
                                  message: t({
                                    id: 'course.detail.start_group_watch.success',
                                    message: 'Evenement aangemaakt',
                                  }),
                                  description: t({
                                    id: 'course.detail.start_group_watch.success.description',
                                    message:
                                      'Deel je scherm met anderen en laat hen de QR-code scannen om deel te nemen.',
                                  }),
                                  duration: 5,
                                })
                              }
                            })
                        }}
                      />
                    </Tooltip>
                  )}
                </Space>
              </div>
            </Col>
          </Row>
        </DarkContainer>
        <HoverMenu>
          <Can I={PermissionAction.CREATE} a={PermissionObjectType.ASSIGNMENT}>
            <Button
              hidden={course.fetchCourseBySlug.archived}
              onClick={() => setAssignModalVisible(true)}
              type="text"
            >
              <UserAddOutlined />
              &nbsp;
              <Trans id="courses.detail.action.assign">Toewijzen</Trans>
            </Button>
            <CourseAssignmentModal
              course={course.fetchCourseBySlug._id}
              assigning={assigning}
              visible={assignModalVisible}
              onVisibilityChange={(state) => setAssignModalVisible(state)}
              onAssign={(variables) => {
                return assignCourse({
                  variables,
                })
              }}
            />
          </Can>
          <Can I={PermissionAction.READ} a={PermissionObjectType.BRANCH_REPORT}>
            <Button
              type="text"
              onClick={() =>
                navigate(
                  `/reports/courses/?query=${encodeURIComponent(
                    course.fetchCourseBySlug.name
                  )}&expand=${encodeURIComponent(course.fetchCourseBySlug._id)}`
                )
              }
            >
              <BarChartOutlined />
              &nbsp;
              <Trans id="courses.detail.action.reports">Rapporten</Trans>
            </Button>
          </Can>
          <Button
            type="text"
            hidden={!canUpdate || course.fetchCourseBySlug.archived}
            onClick={() =>
              navigate(`${parent}/${course.fetchCourseBySlug._id}/edit`)
            }
          >
            <EditOutlined />
            &nbsp;
            <Trans id="courses.detail.action.edit">Bewerken</Trans>
          </Button>
          {canTranslate && !course.fetchCourseBySlug.archived && (
            <TranslateButton
              languages={languages}
              record={
                {
                  key: course.fetchCourseBySlug._id,
                  ...course.fetchCourseBySlug,
                } as unknown as TranslateableRecord
              }
              onClick={(key, lang) => {
                navigate('/settings/courses/translate/' + key + '/' + lang)
              }}
            >
              <Button type="text">
                <TranslationOutlined />
                &nbsp;
                <Trans id="actions.translate">Vertalen</Trans>
              </Button>
            </TranslateButton>
          )}
          {canDuplicate && (
            <Button
              type="text"
              onClick={() =>
                duplicateCourse()
                  .then(({ data }) => {
                    notification.success({
                      message: t({
                        id: 'courses.detail.duplicated.success',
                        message: 'Opleiding succesvol gedupliceerd.',
                      }),
                    })
                    navigate(`/courses/${data?.duplicateCourse.slug}`)
                  })
                  .catch((error) => {
                    notification.error({
                      message: t({
                        id: 'courses.detail.duplicated.error',

                        message: 'Dupliceren niet geslaagd.',
                      }),

                      description: error.message,
                    })
                  })
              }
            >
              <CopyOutlined />
              &nbsp;
              <Trans id="courses.detail.action.duplicate">Dupliceren</Trans>
            </Button>
          )}
          <Button
            type="text"
            hidden={
              !canArchiveOwnCourse ||
              course.fetchCourseBySlug.published ||
              course.fetchCourseBySlug.archived
            }
            onClick={() => archiveCourse()}
          >
            <FolderOutlined />
            &nbsp;
            <Trans id="courses.detail.action.archive">Archiveren</Trans>
          </Button>
          <Button
            type="text"
            hidden={
              !canArchiveOwnCourse ||
              course.fetchCourseBySlug.published ||
              !course.fetchCourseBySlug.archived
            }
            onClick={() => unarchiveCourse()}
          >
            <FolderOutlined />
            &nbsp;
            <Trans id="courses.detail.action.unarchive">
              Terughalen uit archief
            </Trans>
          </Button>
          <Button
            type="text"
            hidden={!canShare || course.fetchCourseBySlug.archived}
            onClick={() => setShowSharingOptions(true)}
          >
            <ShareAltOutlined />
            &nbsp;
            <Trans id="courses.detail.action.share">Delen</Trans>
          </Button>
          <Button
            type="text"
            hidden={!canDelete}
            onClick={() => setDeleteModalVisible(true)}
          >
            <DeleteOutlined />
            &nbsp;
            <Trans id="courses.detail.action.delete">Verwijderen</Trans>
          </Button>
          {canDelete && (
            <Modal
              forceRender
              title={t({
                id: 'courses.detail.action.delete.title',
                message: 'Opleiding verwijderen',
              })}
              open={deleteModalVisible}
              cancelText={t({
                id: 'action.cancel',
                message: 'Annuleren',
              })}
              okText={t({
                id: 'action.delete',
                message: 'Verwijderen',
              })}
              onOk={() =>
                deleteCourse({
                  variables: {
                    id: course?.fetchCourseBySlug._id,
                  },
                  update(cache) {
                    cache.evict({
                      id: 'ROOT_QUERY',
                      fieldName: 'fetchCourses',
                    })
                    cache.gc()
                  },
                }).then(() => {
                  navigate(parent)
                  notification.success({
                    message: t({
                      id: 'courses.detail.delete.success',
                      message: 'Opleiding succesvol verwijderd',
                    }),
                  })
                })
              }
              onCancel={() => setDeleteModalVisible(false)}
            >
              <Trans id="courses.detail.delete.title">
                Ben je zeker dat je deze opleiding permanent wil verwijderen?
              </Trans>
            </Modal>
          )}
          <Button
            hidden={course.fetchCourseBySlug.archived}
            type="text"
            onClick={() => setShowAddToCalendar(true)}
          >
            <CalendarOutlined />
            &nbsp;
            <Trans id="courses.detail.action.schedule">
              Inplannen in agenda
            </Trans>
          </Button>
          <AddToCalendarModal
            open={showAddToCalendar}
            onClose={() => setShowAddToCalendar(false)}
            description={course.fetchCourseBySlug.translation.description}
            name={course.fetchCourseBySlug.translation.name}
            duration={course.fetchCourseBySlug.duration}
            deadline={courseIsAssigned?.deadline}
            url={`https://${window.location.host}/courses/${course.fetchCourseBySlug.slug}`}
          />
        </HoverMenu>
      </div>
      <div>
        <Container>
          <Row gutter={24}>
            <Col md={{ span: 12 }} style={{ paddingTop: 32 }}>
              <Card
                style={{
                  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.05)',
                  border: '1px solid rgba(0, 0, 0, 0.06)',
                }}
              >
                <h2 style={{ textTransform: 'uppercase', fontWeight: 700 }}>
                  <Trans id="courses.detail.about">Over deze opleiding</Trans>
                </h2>
                {description as ReactNode}
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              style={{
                paddingTop: 32,
                zIndex: 1,
                position: 'relative',
                minHeight: '75vh',
              }}
            >
              <span ref={filesRef} />
              <Tabs
                activeKey={activeKey}
                onTabClick={(key) => setActiveKey(key)}
                type="card"
                size={'small'}
                tabBarExtraContent={
                  <>
                    {canUpdate && !course.fetchCourseBySlug.archived && (
                      <Button
                        type="text"
                        onClick={() =>
                          navigate(
                            `${parent}/${course.fetchCourseBySlug._id}/edit-content`
                          )
                        }
                      >
                        <Space>
                          <EditOutlined />
                          <Trans id="courses.detail.edit_content">
                            Inhoud bewerken
                          </Trans>
                        </Space>
                      </Button>
                    )}
                  </>
                }
                items={[
                  {
                    label: <Trans id="courses.detail.content">Inhoud</Trans>,
                    key: 'content',
                    children: (
                      <TabContent>
                        {course.fetchCourseBySlug.translation.contents
                          .length === 0 && (
                          <Empty
                            description={t({
                              id: 'courses.detail.content.empty',
                              message: 'Deze opleiding bevat geen inhoud',
                            })}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          />
                        )}
                        {[...course.fetchCourseBySlug.translation.contents]
                          .sort((a, b) => a.order - b.order)
                          .map((section) => {
                            const timeline = (
                              <LessonGroupContent key={section._id}>
                                <SectionUnitTimeline
                                  units={
                                    section.units.map((u) => {
                                      if (u.__typename === 'ContentUnit')
                                        return {
                                          ...u,
                                          translation: u.contentUnitTranslation,
                                        }
                                      if (u.__typename === 'PDFUnit')
                                        return {
                                          ...u,
                                          translation: u.pdfUnitTranslation,
                                        }
                                      if (u.__typename === 'VideoUnit')
                                        return {
                                          ...u,
                                          translation: u.videoUnitTranslation,
                                        }
                                      if (u.__typename === 'QuizUnit')
                                        return {
                                          ...u,
                                          translation: u.quizUnitTranslation,
                                        }
                                      if (u.__typename === 'SurveyUnit')
                                        return {
                                          ...u,
                                          translation: u.surveyUnitTranslation,
                                        }
                                      return u
                                    }) as UnitUnion[]
                                  }
                                  course={course.fetchCourseBySlug}
                                  parent={parent}
                                />
                              </LessonGroupContent>
                            )

                            return (
                              <Fragment key={`full${section._id}`}>
                                <LessonGroupTitle>
                                  {section.title}
                                </LessonGroupTitle>
                                {timeline}
                              </Fragment>
                            )
                          })}
                      </TabContent>
                    ),
                  },
                  {
                    label: (
                      <Trans id="courses.detail.downloads.title">
                        Downloads
                      </Trans>
                    ),
                    key: 'downloads',
                    children: (
                      <TabContent>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <List
                            style={{
                              margin: -16,
                              border: 0,
                            }}
                            size="small"
                            bordered
                            locale={{
                              emptyText: t({
                                id: 'courses.detail.downloads.empty',
                                message: 'Geen bestanden beschikbaar',
                              }),
                            }}
                            //@ts-ignore
                            dataSource={attachments}
                            renderItem={(item) => (
                              <List.Item
                                actions={[
                                  <Popconfirm
                                    key={0}
                                    title={t({
                                      id: 'courses.detail.downloads.delete.title',
                                      message:
                                        'Ben je zeker dat je dit bestand wil verwijderen?',
                                    })}
                                    onConfirm={() =>
                                      deleteAttachment({
                                        variables: {
                                          course_id:
                                            course.fetchCourseBySlug._id,
                                          attachment_id: item._id,
                                        },
                                      })
                                    }
                                    okText={t({
                                      id: 'action.delete',
                                      message: 'Verwijderen',
                                    })}
                                    cancelText={t({
                                      id: 'action.cancel',
                                      message: 'Annuleren',
                                    })}
                                  >
                                    <Button
                                      hidden={!canUpdate}
                                      type="text"
                                      icon={
                                        <DeleteOutlined
                                          style={{ fontSize: 14 }}
                                        />
                                      }
                                    ></Button>
                                  </Popconfirm>,
                                ]}
                              >
                                <Button
                                  onClick={async () => {
                                    const file = await axios.get(
                                      item.translation.url || item.url,
                                      {
                                        responseType: 'blob',
                                      }
                                    )
                                    download(
                                      file.data,
                                      item.translation.originalName ||
                                        item.originalName,
                                      item.mimetype
                                    )
                                  }}
                                  type="text"
                                  style={{ padding: 0, height: 'auto' }}
                                >
                                  <FileOutlined
                                    style={{
                                      marginRight: 8,
                                      display: 'inline-block',
                                      verticalAlign: 'sub',
                                    }}
                                  />
                                  <Tooltip
                                    title={`${item.translation.originalName}${
                                      item.translation.autoTranslated
                                        ? ` (${t({
                                            id: 'courses.detail.downloads.auto_translated',
                                            message: 'Automatisch vertaald',
                                          })})`
                                        : ''
                                    }`}
                                    mouseEnterDelay={2}
                                  >
                                    <AttachmentName>
                                      {item.translation.originalName}
                                      {item.translation.autoTranslated && (
                                        <AutoTranslated>
                                          (
                                          <Trans id="courses.detail.downloads.auto_translated">
                                            Automatisch vertaald
                                          </Trans>
                                          )
                                        </AutoTranslated>
                                      )}
                                    </AttachmentName>
                                  </Tooltip>
                                </Button>
                              </List.Item>
                            )}
                          />
                          {canUpdate && !course.fetchCourseBySlug.archived && (
                            <Form.Item
                              style={{ marginTop: 24, marginBottom: 0 }}
                            >
                              <Upload
                                accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/epub+zip, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/rtf, application/zip"
                                multiple
                                type="drag"
                                action={`${
                                  import.meta.env.NX_BACKEND_URL
                                }/api/files/uploadCourseAttachment/${
                                  course.fetchCourseBySlug._id
                                }`}
                                headers={{
                                  Authorization: `Bearer ${localStorage.getItem(
                                    'aa_lms_at'
                                  )}`,
                                  'x-academy-host': window.location.hostname,
                                }}
                                onChange={(info) => {
                                  if (info.file.status === 'done') refetch()
                                }}
                              >
                                <Button>
                                  <Space>
                                    <UploadOutlined />
                                    <Trans id="courses.detail.downloads.select_file">
                                      Bestand selecteren
                                    </Trans>
                                  </Space>
                                </Button>
                              </Upload>
                            </Form.Item>
                          )}
                        </Space>
                      </TabContent>
                    ),
                  },
                ]}
              />
            </Col>
            {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Collapse
                style={{
                  marginTop: 32,
                  width: '100%',
                  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.05)',
                }}
                activeKey={collapseActiveKey}
                onChange={(key) => setCollapseActiveKey(key)}
              >
                {(course.fetchCourseBySlug.contents.length > 0 ||
                  canUpdate) && (
                  <CollapsePanel
                    header={<Trans id="courses.detail.content">Inhoud</Trans>}
                    // collapsible={'disabled'}
                    extra={
                      canUpdate &&
                      !course.fetchCourseBySlug.archived && (
                        <Link
                          key={0}
                          to={`${parent}/${course.fetchCourseBySlug._id}/edit-content`}
                        >
                          <Trans id="courses.detail.action.edit_content">
                            Bewerken
                          </Trans>
                        </Link>
                      )
                    }
                    key="1"
                  >
                    {course.fetchCourseBySlug.contents.length === 0 && (
                      <Empty
                        description={t({
                          id: 'courses.detail.content.empty',
                          message: 'Deze opleiding bevat geen inhoud',
                        })}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    )}
                    {[...course.fetchCourseBySlug.contents]
                      .sort((a, b) => a.order - b.order)
                      .map((section) => {
                        const timeline = (
                          <LessonGroupContent key={section._id}>
                            <SectionUnitTimeline
                              units={section.units as UnitUnion[]}
                              course={course.fetchCourseBySlug}
                              parent={parent}
                            />
                          </LessonGroupContent>
                        )

                        return (
                          <Fragment key={`full${section._id}`}>
                            <LessonGroupTitle>{section.title}</LessonGroupTitle>
                            {timeline}
                          </Fragment>
                        )
                      })}
                  </CollapsePanel>
                )}
                <CollapsePanel
                  header={
                    t({
                      id: 'courses.detail.downloads.title',
                      message: 'Bestanden',
                    }) +
                    (course.fetchCourseBySlug.attachments?.length
                      ? ` (${course.fetchCourseBySlug.attachments?.length})`
                      : '')
                  }
                  key="2"
                >
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <List
                      style={{
                        margin: -16,
                        border: 0,
                      }}
                      size="small"
                      bordered
                      locale={{
                        emptyText: t({
                          id: 'courses.detail.downloads.empty',
                          message: 'Geen bestanden beschikbaar',
                        }),
                      }}
                      //@ts-ignore
                      dataSource={attachments}
                      renderItem={(item) => (
                        <List.Item
                          actions={[
                            <Popconfirm
                              key={0}
                              title={t({
                                id: 'courses.detail.downloads.delete.title',
                                message:
                                  'Ben je zeker dat je dit bestand wil verwijderen?',
                              })}
                              onConfirm={() =>
                                deleteAttachment({
                                  variables: {
                                    course_id: course.fetchCourseBySlug._id,
                                    attachment_id: item._id,
                                  },
                                })
                              }
                              okText={t({
                                id: 'action.delete',
                                message: 'Verwijderen',
                              })}
                              cancelText={t({
                                id: 'action.cancel',
                                message: 'Annuleren',
                              })}
                            >
                              <Button
                                hidden={!canUpdate}
                                type="text"
                                icon={
                                  <DeleteOutlined style={{ fontSize: 14 }} />
                                }
                              ></Button>
                            </Popconfirm>,
                          ]}
                        >
                          <Button
                            onClick={async () => {
                              const file = await axios.get(item.url, {
                                responseType: 'blob',
                              })
                              download(
                                file.data,
                                item.originalName,
                                item.mimetype
                              )
                            }}
                            type="text"
                            style={{ padding: 0, height: 'auto' }}
                          >
                            <FileOutlined
                              style={{
                                marginRight: 8,
                                display: 'inline-block',
                                verticalAlign: 'sub',
                              }}
                            />
                            <Tooltip
                              title={item.originalName}
                              mouseEnterDelay={2}
                            >
                              <AttachmentName>
                                {item.originalName}
                              </AttachmentName>
                            </Tooltip>
                          </Button>
                        </List.Item>
                      )}
                    />
                    {canUpdate && !course.fetchCourseBySlug.archived && (
                      <Form.Item style={{ marginTop: 24, marginBottom: 0 }}>
                        <Upload
                          accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/epub+zip, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/rtf, application/zip"
                          multiple
                          type="drag"
                          action={`${
                            import.meta.env.NX_BACKEND_URL
                          }/api/files/uploadCourseAttachment/${
                            course.fetchCourseBySlug._id
                          }`}
                          headers={{
                            Authorization: `Bearer ${localStorage.getItem(
                              'aa_lms_at'
                            )}`,
                            'x-academy-host': window.location.hostname,
                          }}
                          onChange={(info) => {
                            if (info.file.status === 'done') refetch()
                          }}
                        >
                          <Button>
                            <Space>
                              <UploadOutlined />
                              <Trans id="courses.detail.downloads.select_file">
                                Bestand selecteren
                              </Trans>
                            </Space>
                          </Button>
                        </Upload>
                      </Form.Item>
                    )}
                  </Space>
                </CollapsePanel>
              </Collapse>
            </Col> */}
          </Row>
          {showSharingOptions && (
            <CourseSharingModal
              id={course.fetchCourseBySlug._id}
              options={course.fetchCourseBySlug.sharing}
              visible={showSharingOptions}
              setVisible={setShowSharingOptions}
            />
          )}
        </Container>
      </div>
    </>
  )
}
