import { CheckOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import dayjs from 'dayjs'

import { StreamActionEnum } from '@lms-shared-patterns/enums/event.enums'

import { LoadSection } from '../../../core/components/LoadScreen'
import { useAxios } from '../../../shared/hooks/use-axios'
import { dateRenderer } from '../../helpers/renderers'
import { Table } from '../Table'
import { UserEventSurveyReport } from '../UserEventSurveyReport'

export const renderLocationType = (type: string) => {
  if (type === 'Physical') return t({ id: 'reports.fysiek', message: 'Fysiek' })
  if (type === 'Online') return t({ id: 'reports.online', message: 'Online' })
  return type
}

export const renderLogType = (type: string) => {
  if (type === StreamActionEnum.JOIN)
    return t({
      id: 'reports.users_report.table.log.joined',
      message: 'Heeft zich bij het online evenement ingelogd',
    })
  if (type === StreamActionEnum.LEAVE)
    return t({
      id: 'reports.users_report.table.log.left',
      message: 'Heeft het online evenement verlaten',
    })
  if (type === StreamActionEnum.PASSED_ATTENTION_CHECK)
    return t({
      id: 'reports.users_report.table.log.attention_check_passed',
      message: 'Heeft de aandachtstest met succes doorstaan',
    })
  if (type === StreamActionEnum.FAILED_ATTENTION_CHECK)
    return t({
      id: 'reports.users_report.table.log.attention_check_failed',
      message: 'Heeft de aandachtstest niet doorstaan',
    })
  if (type === StreamActionEnum.ANSWERED_SURVEY)
    return t({
      id: 'reports.users_report.table.log.answered_survey',
      message: 'Heeft de vragenlijst beantwoord',
    })
  if (type === StreamActionEnum.COMPLETED)
    return t({
      id: 'reports.users_report.table.log.completed',
      message: 'Heeft het evenement succesvol voltooid',
    })
  if (type === StreamActionEnum.ADMIN_MARKED_ATTENDANCE)
    return t({
      id: 'reports.users_report.table.log.admin_marked_attendance',
      message: 'Een admin bevestigde de aanwezigheid',
    })
  if (type === StreamActionEnum.ADMIN_CLEARED_ATTENDANCE)
    return t({
      id: 'reports.users_report.table.log.admin_cleared_attendance',
      message: 'Een admin heeft de gemarkeerde aanwezigheid ingetrokken',
    })

  return type
}

export const LiveEventUsersReport = ({
  eventId,
  sectionId,
}: {
  eventId: string
  sectionId: string | null | undefined
}) => {
  const [{ data }] = useAxios({
    url: `/api/activity/live-events/${eventId}`,
    params: {
      section_id: sectionId ?? undefined,
    },
  })

  if (!data) return <LoadSection />

  return (
    <Table
      expandable={{
        expandedRowRender: (record: any) => (
          <Table
            size="small"
            dataSource={record.log || []}
            pagination={false}
            expandable={{
              expandedRowRender: () => (
                <UserEventSurveyReport
                  questions={record.questions || []}
                  answers={record.activity?.survey_answers || []}
                />
              ),
              expandRowByClick: true,
              rowExpandable: (logEntry: any) => {
                const isAnsweredSurvey =
                  logEntry.type === StreamActionEnum.ANSWERED_SURVEY
                const hasSurveyAnswers =
                  record.activity?.survey_answers?.length > 0

                return isAnsweredSurvey && hasSurveyAnswers
              },
            }}
            columns={[
              {
                title: t({
                  id: 'reports.users_report.table.log.time',
                  message: 'Tijd',
                }),
                dataIndex: 'date',
                key: 'date',
                render: (date) => dayjs(date).format('DD/MM/YYYY - HH:mm:ss'),
              },
              {
                title: t({
                  id: 'reports.users_report.table.log',
                  message: 'Log',
                }),
                dataIndex: 'type',
                key: 'type',
                render: (type) => renderLogType(type),
              },
            ]}
          />
        ),
        expandRowByClick: true,
        rowExpandable: (record: any) => record.log?.length > 0,
      }}
      columns={[
        {
          title: t({
            id: 'reports.users_report.table.user',
            message: 'Gebruiker',
          }),
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: t({
            id: 'reports.users_report.table.location_type',
            message: 'Type',
          }),
          dataIndex: 'location_type',
          key: 'location_type',
          render: (type) => renderLocationType(type),
        },
        {
          title: t({
            id: 'reports.users_report.table.attended',
            message: 'Aanwezig',
          }),
          dataIndex: 'joined',
          key: 'joined',
          render: (date) => (date ? <CheckOutlined /> : '-'),
          align: 'center' as const,
        },
        {
          title: t({
            id: 'reports.users_report.table.completed',
            message: 'Voltooid op',
          }),
          dataIndex: 'completed',
          key: 'completed',
          render: dateRenderer,
          align: 'center' as const,
        },
      ]}
      bordered
      size="small"
      dataSource={data}
      rowKey="_id"
      pagination={false}
    />
  )
}
