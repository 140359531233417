import { useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import { Form, FormInstance, Select } from 'antd'
import { test } from 'fuzzyjs'
import { useEffect, useMemo, useState } from 'react'

import {
  AssignmentStep,
  BranchAccessibleCoursesQuery,
  CourseUnitsQuery,
  SelfStudyStep,
  StepCategory,
  StepUnitKind,
  UnitCourseQuery,
} from 'apps/lms-front/src/generated/graphql'
import BRANCH_COURSES from 'apps/lms-front/src/modules/branch/queries/branch-accessible-courses.graphql'
import COURSE_UNITS_QUERY from 'apps/lms-front/src/modules/paths/queries/course-units.graphql'
import UNIT_COURSE_QUERY from 'apps/lms-front/src/modules/paths/queries/unit-course.graphql'

function getUnitName(unit): string {
  if ('contentUnitTranslation' in unit) {
    return unit.contentUnitTranslation.name
  }
  if ('pdfUnitTranslation' in unit) {
    return unit.pdfUnitTranslation.name
  }
  if ('videoUnitTranslation' in unit) {
    return unit.videoUnitTranslation.name
  }
  if ('quizUnitTranslation' in unit) {
    return unit.quizUnitTranslation.name
  }
  if ('surveyUnitTranslation' in unit) {
    return unit.surveyUnitTranslation.name
  }
  return unit.name
}

export const LinkedUnitPicker = (props: {
  form: FormInstance
  step: SelfStudyStep | AssignmentStep
}) => {
  const [course, setCourse] = useState<string | null>()
  const linkedUnitId = Form.useWatch('linked_unit_id', props.form)
  const { data: courses, loading: coursesLoading } =
    useQuery<BranchAccessibleCoursesQuery>(BRANCH_COURSES, {
      fetchPolicy: 'cache-and-network',
    })

  // Remove __typename from unit data
  const filterTypename = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(filterTypename)
    } else if (obj !== null && typeof obj === 'object') {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([key]) => key !== '__typename')
          .map(([key, value]) => [key, filterTypename(value)])
      )
    }
    return obj
  }

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      props.form.setFieldsValue({
        linked_unit_id: undefined,
        unit_kind: undefined,
        custom_quiz_unit: undefined,
        custom_survey_unit: undefined,
      })
    }
  }, [])

  const handleUnitChange = (value: string) => {
    props.form.setFieldValue('linked_unit_id', value)

    const selectedUnit = units?.find(
      (unit) => '_id' in unit && unit._id === value
    )
    props.form.setFieldValue('unit_kind', selectedUnit?.__typename)
  }

  const { data, loading: unitsLoading } = useQuery<CourseUnitsQuery>(
    COURSE_UNITS_QUERY,
    {
      variables: {
        id: course,
      },
      skip: !course,
    }
  )

  const { loading: loadingCourse } = useQuery<UnitCourseQuery>(
    UNIT_COURSE_QUERY,
    {
      variables: {
        id: props?.step?.linked_unit_id,
      },
      skip: !props?.step?.linked_unit_id || !!course,
      onCompleted: (data) => {
        if (data && 'course_id' in data.fetchUnitById)
          setCourse(data?.fetchUnitById.course_id)
      },
    }
  )

  useEffect(() => {
    if (!linkedUnitId) return

    const findUnit = (contents) => {
      return contents
        ?.flatMap((content) => content.units)
        .find((unit) => unit._id === linkedUnitId)
    }

    const unit = findUnit(data?.fetchCourseById?.translation?.contents)

    if (!unit) return

    const duration =
      unit.__typename === 'VideoUnit' && 'cf_stream' in unit
        ? unit.cf_stream?.duration
        : undefined

    props.form.setFieldValue('linked_unit_duration', duration)
  }, [linkedUnitId, props.form, data?.fetchCourseById])

  const units = useMemo(
    () =>
      data?.fetchCourseById.translation.contents
        .reduce<
          CourseUnitsQuery['fetchCourseById']['translation']['contents'][0]['units']
        >((prev, cur) => {
          prev.push(...cur.units)
          return prev
        }, [])
        .filter((unit) => {
          if (
            props.form.getFieldValue('category') ===
              StepCategory.SelfStudyStep &&
            [
              StepUnitKind.ContentUnit,
              StepUnitKind.PdfUnit,
              StepUnitKind.VideoUnit,
            ].includes(unit.__typename as StepUnitKind)
          )
            return true

          return !!(
            props.form.getFieldValue('category') ===
              StepCategory.AssignmentStep &&
            [StepUnitKind.QuizUnit, StepUnitKind.SurveyUnit].includes(
              unit.__typename as StepUnitKind
            )
          )
        }),
    [data, props.form]
  )

  return (
    <div style={{ marginTop: 16 }}>
      <Form.Item name="linked_unit_duration" hidden>
        <input />
      </Form.Item>
      <Form.Item
        label={t({
          id: 'step.linked_unit.field.course',
          message: 'Selecteer een opleiding',
        })}
        labelCol={{ span: 8 }}
        rules={[
          {
            required: true,
            message: t({
              id: 'step.linked_unit.field.validation.course',
              message: 'Selecteer een opleiding',
            }),
          },
        ]}
      >
        <Select
          disabled={coursesLoading || loadingCourse}
          style={{ width: '100%' }}
          placeholder={t({
            id: 'step.linked_unit.field.placeholder.course',
            message: 'Selecteer een opleiding',
          })}
          options={
            courses?.fetchBranchAccessibleCourses.results.map((course) => ({
              label: course.translation.name,
              value: course._id,
            })) || []
          }
          filterOption={(inputValue: string, option) =>
            test(inputValue, option?.label || '', {
              caseSensitive: false,
            })
          }
          showSearch
          value={course}
          onChange={(value) => {
            setCourse(value)
            props.form.setFieldValue('linked_unit_id', undefined)
            props.form.setFieldValue('linked_unit_duration', undefined)
          }}
        />
      </Form.Item>
      <Form.Item
        hidden={!course}
        name="linked_unit_id"
        label={t({
          id: 'step.linked_unit.field.unit',
          message: 'Selecteer een onderdeel',
        })}
        labelCol={{ span: 8 }}
        rules={[
          {
            required: true,
            message: t({
              id: 'step.linked_unit.field.validation.unit',
              message: 'Selecteer een onderdeel',
            }),
          },
        ]}
      >
        <Select
          disabled={unitsLoading}
          allowClear
          style={{ width: '100%' }}
          placeholder={t({
            id: 'step.linked_unit.field.placeholder.unit',
            message: 'Selecteer een onderdeel',
          })}
          options={
            units?.map((unit) => ({
              label: getUnitName(unit),
              value: '_id' in unit ? unit._id : '',
            })) || []
          }
          filterOption={(inputValue: string, option) =>
            test(inputValue, option?.label || '', {
              caseSensitive: false,
            })
          }
          showSearch
          onChange={handleUnitChange}
        />
      </Form.Item>
    </div>
  )
}
