import { Input, List, ListProps } from 'antd'
import { forwardRef } from 'react'
import styled, { AnyStyledComponent } from 'styled-components'

import { ChatMessage } from './Chat'

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f0f2f5;
  border-radius: 8px;
  overflow: hidden;
`

export const MessageListWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  background-color: white;
  position: relative;
  overflow-y: auto;
  height: 100%;
`

export const MessageListBase = forwardRef<
  HTMLDivElement,
  ListProps<ChatMessage>
>(({ ...props }, ref) => (
  <MessageListWrapper ref={ref}>
    <List<ChatMessage> {...props} />
  </MessageListWrapper>
))
MessageListBase.displayName = 'MessageListBase'

export const MessageList = styled(MessageListBase)`
  flex: 1;
  padding: 16px;

  .ant-list {
    height: 100%;
  }
`

export const MessageItem = styled(List.Item as unknown as AnyStyledComponent)`
  padding: 8px 0;
  border-bottom: none !important;
`

export const MessageContent = styled.div`
  background-color: #e6f7ff;
  border-radius: 12px;
  padding: 8px 12px;
  max-width: 80%;
`

export const SenderName = styled.span`
  font-weight: bold;
  margin-right: 8px;
`

export const Time = styled.span`
  font-size: 0.75rem;
  color: #9e9e9e;
  margin-left: 8px;
`

export const InputArea = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: white;
  border-top: 1px solid #e8e8e8;
  flex-shrink: 0;
`

export const StyledInput = styled(Input as unknown as AnyStyledComponent)`
  flex: 1;
  margin-right: 8px;
`

export const DrawerWrapper = styled.div`
  width: 300px;
  height: 100%;
  overflow: hidden;
`
