import { DocumentNode, useMutation } from '@apollo/client'
import { t } from '@lingui/macro'
import { Input, Modal, message } from 'antd'
import { ObjectId } from 'bson'
import { useState } from 'react'

type QuestionInputModalProps = {
  open: boolean
  onClose: () => void
  id: ObjectId
  mutation: DocumentNode
  title: string
  placeholder: string
  successMessage: string
  errorMessage: string
  submitButtonText?: string
  cancelButtonText?: string
}

export const QuestionInputModal = ({
  open,
  onClose,
  id,
  mutation,
  title,
  placeholder,
  successMessage,
  errorMessage,
  submitButtonText = t({ id: 'action.submit', message: 'Submit' }),
  cancelButtonText = t({ id: 'action.cancel', message: 'Annuleren' }),
}: QuestionInputModalProps) => {
  const [question, setQuestion] = useState<string>('')
  const [submitMutation, { loading: submittingQuestion }] = useMutation(
    mutation,
    {
      notifyOnNetworkStatusChange: true,
    }
  )

  const handleSubmit = () => {
    submitMutation({
      variables: {
        input: { contextId: id, question },
      },
    })
      .then(() => {
        message.info(successMessage)
        setQuestion('')
        onClose()
      })
      .catch((error) => {
        console.error(error)
        message.error(errorMessage)
      })
  }

  return (
    <Modal
      title={title}
      centered
      open={open}
      onOk={handleSubmit}
      okText={submitButtonText}
      onCancel={onClose}
      okButtonProps={{
        disabled: !question.trim(),
        loading: submittingQuestion,
      }}
      cancelText={cancelButtonText}
    >
      <Input.TextArea
        autoSize
        placeholder={placeholder}
        onChange={(evt) => setQuestion(evt.target.value)}
        value={question}
        style={{ minHeight: 100 }}
      />
    </Modal>
  )
}
