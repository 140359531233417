// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/display-name */
import { t, Trans } from '@lingui/macro'
import { Alert, Button, Form, Input, Radio, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { forwardRef, useImperativeHandle, useMemo } from 'react'
import styled from 'styled-components'

import { SurveyQuestionType } from 'apps/lms-front/src/generated/graphql'

import { useStream } from '../../../../contexts/StreamContext'

export const Question = styled.p`
  font-weight: 500;
  font-size: 1.125rem;
`

export type SurveyViewerRef = {
  submit: () => void
}

type SurveyViewerProps = {
  questionIndex: number
  onQuestionIndexChange: (index: number) => void
  onLoadingStateChange?: (loading: boolean) => void
  finishButton?: React.ReactNode
}

export const SurveyViewer = forwardRef<SurveyViewerRef, SurveyViewerProps>(
  (
    { questionIndex, onQuestionIndexChange, finishButton }: SurveyViewerProps,
    ref
  ) => {
    const { survey } = useStream()

    const { setSurveyModalVisibility, emit, callId } = useStream()

    const question =
      survey && survey.length > questionIndex ? survey[questionIndex] : null

    const [form] = useForm()

    const goToNextQuestion = () => {
      onQuestionIndexChange(questionIndex + 1)
    }

    useImperativeHandle(ref, () => ({
      submit: form.submit,
    }))

    const quizComplete = useMemo(() => {
      return !!(survey && questionIndex === survey.length)
    }, [survey, questionIndex])

    if (quizComplete) {
      return (
        <Space
          direction="vertical"
          size="large"
          style={{ width: '100%', alignItems: 'center' }}
        >
          <Alert
            type="info"
            message={
              <Trans id="unit.viewer.survey.already_finished">
                Je hebt deze enquête succesvol ingediend.
              </Trans>
            }
          />
          <Button
            type="primary"
            onClick={() => {
              emit('surveyCompleted', { call_id: callId })
              setSurveyModalVisibility(false)
            }}
          >
            <Trans id="unit.viewer.survey.close">Enquête sluiten</Trans>
          </Button>
        </Space>
      )
    }
    if (!question) {
      return null
    }

    return (
      <article className="quiz-unit">
        {survey && questionIndex < survey.length && (
          <div className="steps-content" style={{ padding: '1.5rem' }}>
            <Question>{question?.question}</Question>
            <Form
              form={form}
              onFinish={(result) => {
                const currentAnswer = result[`userAnswers[${questionIndex}]`]
                const answer = {
                  question_id: question._id,
                  user_answers: Array.isArray(currentAnswer)
                    ? currentAnswer
                    : currentAnswer
                    ? [currentAnswer]
                    : null,
                }

                emit('surveyAnswer', { call_id: callId, survey_answer: answer })

                if (questionIndex < survey.length - 1) {
                  goToNextQuestion()
                  form.resetFields()
                } else {
                  emit('surveyCompleted', { call_id: callId })
                  setSurveyModalVisibility(false)
                }
              }}
            >
              {question.answer_type === SurveyQuestionType.OpenEnded && (
                <Form.Item
                  name={`userAnswers[${questionIndex}]`}
                  required
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'unit.viewer.survey.validation.open_ended_answer',
                        message: 'Gelieve een antwoord in te vullen',
                      }),
                    },
                  ]}
                >
                  <Input.TextArea
                    style={{
                      marginLeft: 24,
                      marginRight: 24,
                      width: 'calc(100% - 48px)',
                      height: 100,
                    }}
                  />
                </Form.Item>
              )}
              {question.answer_type === SurveyQuestionType.MultipleChoice && (
                <Form.Item
                  name={`userAnswers[${questionIndex}]`}
                  required
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'unit.viewer.survey.validation.multiple_choice_answer',
                        message: 'Gelieve een antwoord te selecteren',
                      }),
                    },
                  ]}
                >
                  <Radio.Group style={{ textAlign: 'left', fontSize: 18 }}>
                    <Space direction="vertical">
                      {question.answers.map((answer) => (
                        <Radio
                          key={questionIndex + answer.answer}
                          value={answer.answer}
                        >
                          {answer.answer}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              )}

              <div
                className="steps-action"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {finishButton}
              </div>
            </Form>
          </div>
        )}
      </article>
    )
  }
)
