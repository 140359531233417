/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { t } from '@lingui/macro'

import { LoadSection } from '../../../core/components/LoadScreen'
import { useAxios } from '../../../shared/hooks/use-axios'
import { dateRenderer, percentageRenderer } from '../../helpers/renderers'
import { Table } from '../Table'
import { UserPathsModuleStepsReport } from '../UserPathModuleStepsReport'

export const UserPathsModuleReport = ({
  pathId,
  userId,
  branchId,
}: {
  pathId: string
  userId: string
  branchId: string | undefined
}) => {
  const [{ data }] = useAxios({
    url: `/api/activity/paths/${pathId}/user/${userId}`,
    params: {
      section_id: branchId,
    },
  })

  if (!data) return <LoadSection />
  return (
    <>
      <Table
        expandable={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          expandedRowRender: (record: any) => (
            <UserPathsModuleStepsReport
              pathId={pathId}
              userId={userId}
              branchId={branchId}
              moduleId={record.module_id}
            />
          ),
          expandRowByClick: true,
          rowExpandable: () => true,
        }}
        columns={[
          {
            title: t({
              id: 'reports.learning_path.module_activity_report.table.name',
              message: 'Module',
            }),
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: t({
              id: 'reports.learning_path.module_activity_report.table.progress',
              message: 'Vooruitgang',
            }),
            dataIndex: 'completion_percentage',
            key: 'completion_percentage',
            render: percentageRenderer,
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.learning_path.module_activity_report.table.last_activity',
              message: 'Laatst actief op',
            }),
            dataIndex: 'last_activity',
            key: 'last_activity',
            render: dateRenderer,
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.learning_path.module_activity_report.table.completed',
              message: 'Voltooid op',
            }),
            dataIndex: 'completed',
            key: 'completed',
            render: dateRenderer,
            align: 'center' as const,
          },
        ]}
        bordered
        size="small"
        dataSource={data}
        rowKey="module_id"
        pagination={false}
      />
    </>
  )
}
