/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios'
import { makeUseAxios } from 'axios-hooks'

const networkErrorInterceptor = (error) => {
  if (!error.response) {
    console.error('Network error:', error.message)
    return Promise.resolve({
      data: null,
      status: 0,
      isNetworkError: true,
      originalError: error,
      message: 'A network error occurred',
    })
  }
  return Promise.reject(error)
}

const isValidToken = (token: unknown): token is string => {
  return typeof token === 'string' && token.length > 0
}

export const authenticatedAxiosClient = () => {
  const baseURL = import.meta.env.NX_BACKEND_URL
  if (!baseURL) throw new Error('Backend URL is not configured')

  const token = localStorage.getItem('aa_lms_at')

  const axiosClient = axios.create({
    baseURL,
    headers: {
      'x-academy-host': window.location.hostname,
      Authorization: isValidToken(token) ? `Bearer ${token}` : '',
    },
  })

  axiosClient.interceptors.response.use(
    (response) => response,
    networkErrorInterceptor
  )

  return axiosClient
}

export const axiosClient = axios.create({
  baseURL: `${import.meta.env.NX_BACKEND_URL}`,
  headers: {
    'x-academy-host': window.location.hostname,
  },
})

axiosClient.interceptors.response.use(
  (response) => response,
  networkErrorInterceptor
)

const useAxiosHook = makeUseAxios({
  axios: axiosClient,
})

export const useAxios = <T = any>(config: AxiosRequestConfig) => {
  const token = localStorage.getItem('aa_lms_at')
  return useAxiosHook<T>({
    ...config,
    headers: {
      ...config.headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  })
}
