import { useMutation } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { Result, Space, Spin } from 'antd'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { AttendLiveEventMutation } from 'apps/lms-front/src/generated/graphql'

import ATTEND_LIVE_EVENT from '../../mutations/attend-live-event.graphql'

export const AttendEvent = () => {
  const { token } = useParams<{ token: string }>()

  const [attendLiveEvent, { loading, error, data }] =
    useMutation<AttendLiveEventMutation>(ATTEND_LIVE_EVENT)

  useEffect(() => {
    if (token && token.length > 0) {
      attendLiveEvent({ variables: { token } })
    }
  }, [token, attendLiveEvent])

  if (loading)
    return (
      <Space align="center" direction="vertical" style={{ width: '100%' }}>
        <Spin size="large" />
        <h2>
          <Trans id="events.attendance.registering">
            Aanmelding bevestigen...
          </Trans>
        </h2>
        <p>
          <Trans id="events.attendance.subtitle">Even geduld...</Trans>
        </p>
      </Space>
    )

  if (error)
    return (
      <Result
        status={'error'}
        title={<Trans id="events.attendance.failed">Aanmelden mislukt</Trans>}
        subTitle={error.message}
      />
    )

  if (data?.attendLiveEvent) {
    return (
      <Result
        status={'success'}
        title={<Trans id="events.attendance.success">Aanmelden gelukt</Trans>}
        subTitle={
          <Trans id="events.attendance.success.subtitle">
            Je aanmelding is successvol geregistreerd
          </Trans>
        }
      />
    )
  }

  return null
}
