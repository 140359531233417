import { AlertOutlined, LockOutlined, StopOutlined } from '@ant-design/icons'
import { Plural, t } from '@lingui/macro'
import { Alert } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'

import {
  EventLocationType,
  EventMode,
} from '@lms-shared-patterns/enums/event.enums'
import {
  LiveEventQuery,
  LiveEventsQuery,
} from 'apps/lms-front/src/generated/graphql'

type EventData =
  | LiveEventsQuery['fetchLiveEvents']['results'][0]
  | LiveEventQuery['fetchLiveEventById']

type EventStatus = {
  openForRegistration: boolean
  openForAdminRegistration: boolean
  hasRegistrationDeadline: boolean
  hasReachedCapacity: boolean
  hasExceededCapacity: boolean
  registrationStop: boolean
  closed: boolean
  started: boolean
  ended: boolean
  draft: boolean
  remaining?: number
  alert: React.ReactNode[] | null
  onlyOnline: boolean
  onlyPhysical: boolean
  cancelled: boolean
  daysUntilEvent: number
}

// Helper functions to improve readability and reusability
const hasReachedCapacity = (
  location_type: EventData['location_type'],
  capacity: EventData['capacity'],
  participant_count: EventData['physical_participant_count']
): boolean => {
  return (
    location_type.includes(EventLocationType.Physical) &&
    !!capacity &&
    participant_count >= capacity
  )
}

const hasExceededCapacity = (
  location_type: EventData['location_type'],
  capacity: EventData['capacity'],
  participant_count: EventData['physical_participant_count']
): boolean => {
  return (
    location_type.includes(EventLocationType.Physical) &&
    !!capacity &&
    participant_count > capacity
  )
}

const calculateRemaining = (
  capacity: number | null | undefined,
  participant_count: number
): number | undefined => {
  return capacity ? capacity - participant_count : undefined
}

const isRegistrationDeadlinePassed = (
  deadline: string | null | undefined
): boolean => {
  return deadline ? dayjs(deadline).isBefore(dayjs()) : false
}

const hasEventStarted = (start: string): boolean => {
  return dayjs(start).isBefore(dayjs())
}

const hasEventEnded = (end: string): boolean => {
  return dayjs(end).isBefore(dayjs())
}

const getStatusAlerts = (
  full: boolean,
  onlyPhysical: boolean,
  remaining?: number,
  cancelled?: boolean
): React.ReactNode[] => {
  const alerts: React.ReactNode[] = []

  if (cancelled) {
    return [
      <Alert
        key="cancelled"
        showIcon
        icon={<StopOutlined />}
        banner
        type="error"
        message={t({
          id: 'events.card.cancelled',
          message: 'Deze sessie is geannuleerd',
        })}
      />,
    ]
  }

  if (full && onlyPhysical) {
    alerts.push(
      <Alert
        key="full"
        showIcon
        icon={<LockOutlined />}
        banner
        type="info"
        message={t({
          id: 'events.card.full',
          message: 'Deze sessie is volledig volzet',
        })}
      />
    )
  } else if (remaining && remaining > 0) {
    alerts.push(
      <Alert
        key="remaining"
        showIcon
        icon={<AlertOutlined />}
        banner
        type="warning"
        message={
          <Plural
            id="events.participants.remaining"
            value={remaining}
            one="Er is nog # vrije plek beschikbaar voor de fysieke sessie."
            other="Er zijn nog # vrije plekken beschikbaar voor de fysieke sessie."
          />
        }
      />
    )
  }

  return alerts
}

export const useEventStatus = (eventData: EventData): EventStatus => {
  return useMemo(() => {
    // Calculate core status flags
    const remaining = calculateRemaining(
      eventData.capacity,
      eventData.physical_participant_count
    )

    const published = !!eventData.published

    const cancelled = !!eventData.cancelled

    const started = hasEventStarted(eventData.start)
    const ended = hasEventEnded(eventData.end)
    const closed =
      isRegistrationDeadlinePassed(eventData.registration_deadline) || ended

    const full = hasReachedCapacity(
      eventData.location_type,
      eventData.capacity,
      eventData.physical_participant_count
    )

    const onlyOnline =
      eventData.location_type.includes(EventLocationType.Online) &&
      eventData.location_type.length === 1

    const onlyPhysical =
      eventData.location_type.includes(EventLocationType.Physical) &&
      eventData.location_type.length === 1

    // Generate alerts based on status
    const alerts = getStatusAlerts(full, onlyPhysical, remaining, cancelled)

    const registrationStop = full && eventData.location_type.length === 1

    return {
      openForRegistration:
        published &&
        !closed &&
        !registrationStop &&
        eventData.mode === EventMode.Open,
      openForAdminRegistration: !registrationStop,
      hasRegistrationDeadline: !!eventData.registration_deadline,
      hasReachedCapacity: full,
      hasExceededCapacity: hasExceededCapacity(
        eventData.location_type,
        eventData.capacity,
        eventData.physical_participant_count
      ),
      registrationStop,
      closed,
      started,
      ended,
      draft: !published,
      remaining,
      alert: alerts.length > 0 ? alerts : null,
      onlyOnline,
      onlyPhysical,
      cancelled,
      daysUntilEvent: Math.min(0, dayjs(eventData.start).diff(dayjs(), 'day')),
    }
  }, [
    eventData.capacity,
    eventData.physical_participant_count,
    eventData.published,
    eventData.start,
    eventData.end,
    eventData.registration_deadline,
    eventData.location_type,
    eventData.cancelled,
    eventData.mode,
  ])
}

// Export types for consumers
export type { EventStatus, EventData }
