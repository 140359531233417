import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { LiveKitRoom, RoomAudioRenderer } from '@livekit/components-react'
import { Button, Col, Form, Layout, notification, Row, Spin } from 'antd'
import { Room, RoomOptions, VideoPresets } from 'livekit-client'
import { useCallback, useEffect, useState } from 'react'

import { FetchMeetingDetailsQuery } from 'apps/lms-front/src/generated/graphql'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { useStream } from '../../contexts/StreamContext'

import { PreJoin } from './components/prejoin/Prejoin'
import { VideoConference } from './components/video/VideoConference'

interface JoinStreamContentProps {
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<FetchMeetingDetailsQuery>>
}

export function JoinStreamContent({ refetch }: JoinStreamContentProps) {
  const [joining, setJoining] = useState(false)

  const {
    room,
    setRoom,
    token,
    setToken,
    callId,
    eventId,
    isRoomActive,
    setIsRoomActive,
    isMeetingHost,
    isParticipant,
    participantName,
    emit,
    connected,
    getToken,
  } = useStream()

  const roomOptions: RoomOptions = {
    adaptiveStream: true,
    dynacast: true,
    publishDefaults: {
      simulcast: true,
    },
    videoCaptureDefaults: {
      resolution: VideoPresets.h720.resolution,
    },
    audioCaptureDefaults: {
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: true,
    },
  }

  useEffect(() => {
    if (connected && room && callId && eventId) {
      emit('joinStream', {
        call_id: callId,
        event_id: eventId,
      })
    }
  }, [connected, room, callId, eventId, emit])

  const joinRoom = async () => {
    const room = new Room()
    setRoom(room)

    try {
      const token = await getToken()
      await room.connect(import.meta.env.NX_LIVEKIT_URL, token)

      if (isMeetingHost) {
        emit('activateMeetingRoom', {
          call_id: callId,
        })
        setIsRoomActive(true)
      }

      if (!isRoomActive && !isMeetingHost) {
        refetch({})
          .then(({ data }) => {
            if (data?.fetchLiveEventByCallId.stream_details?.is_active)
              setIsRoomActive(true)
          })
          .catch((error) =>
            notification.error({
              message: t({
                id: 'stream.join.error',
                message: `Er is een fout opgetreden: ${
                  (error as Error).message
                }`,
              }),
            })
          )
      }

      setToken(token)
    } catch (error) {
      notification.error({
        message: t({
          id: 'stream.join.error',
          message: `Er is een fout opgetreden: ${(error as Error).message}`,
        }),
      })
      setRoom(undefined)
    }
  }

  const handleError = useCallback((error) => console.error(error), [])

  if (!(isParticipant || isMeetingHost)) {
    return (
      <Row justify="center" style={{ marginTop: '50px' }}>
        <Col sm={12} lg={8}>
          <div
            style={{
              textAlign: 'center',
              background: '#fff',
              padding: '24px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Trans id="stream.join.not_allowed">
              Je hebt niet de juiste rechten om deel te nemen aan deze meeting.
            </Trans>
          </div>
        </Col>
      </Row>
    )
  }

  if (joining) return <LoadScreen />

  return (
    <div className="app">
      {token ? (
        isMeetingHost || isRoomActive ? (
          <LiveKitRoom
            token={token}
            serverUrl={import.meta.env.NX_LIVEKIT_URL}
            connect={true}
            options={roomOptions}
            audio={isMeetingHost}
            video={isMeetingHost}
          >
            <Layout>
              <Row
                justify="center"
                style={{ marginBottom: '20px', padding: '20px' }}
              >
                <Col span={24}>
                  <div className="livekit-container">
                    <VideoConference />
                  </div>
                </Col>
              </Row>
            </Layout>
            <RoomAudioRenderer />
          </LiveKitRoom>
        ) : (
          <Row justify="center" style={{ marginTop: '50px' }}>
            <Col sm={12} lg={8}>
              <div
                style={{
                  textAlign: 'center',
                  background: '#fff',
                  padding: '24px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Spin spinning />
                <p style={{ margin: 0, marginTop: '1.5rem' }}>
                  <Trans id="stream.join.not_started">
                    Deze meeting is nog niet gestart.
                  </Trans>
                </p>
                <p style={{ margin: 0, marginTop: '1rem' }}>
                  <Trans id="stream.join.auto_login">
                    Je zal automatisch ingelogd worden wanneer de meeting
                    begint.
                  </Trans>
                </p>
              </div>
            </Col>
          </Row>
        )
      ) : (
        <Row justify="center" style={{ marginTop: '50px' }}>
          <Col sm={12} lg={8}>
            <div id="join">
              <Form
                layout="vertical"
                style={{
                  background: '#fff',
                  padding: '24px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                {isMeetingHost && (
                  <PreJoin
                    micLabel={t({
                      id: 'stream.join.mic',
                      message: 'Microfoon',
                    })}
                    camLabel={t({ id: 'stream.join.cam', message: 'Camera' })}
                    onError={handleError}
                  />
                )}

                {!isMeetingHost && (
                  <>
                    <h2>
                      <Trans id="stream.join.welcome">
                        Welkom, {participantName}
                      </Trans>
                    </h2>
                    <p>
                      <Trans id="stream.join.info">
                        Klik op de knop hieronder om in de meeting te gaan.
                      </Trans>
                    </p>
                  </>
                )}

                <Form.Item noStyle>
                  <Button
                    type="primary"
                    onClick={() => {
                      setJoining(true)
                      joinRoom()
                        .catch((error) =>
                          notification.error({
                            message: t({
                              id: 'stream.join.error',
                              message: `Er is een fout opgetreden: ${
                                (error as Error).message
                              }`,
                            }),
                          })
                        )
                        .finally(() => {
                          setJoining(false)
                        })
                    }}
                    block
                  >
                    <Trans id="stream.join.join">Nu deelnemen</Trans>
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
