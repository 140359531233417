import { useQuery } from '@apollo/client'
import '@livekit/components-styles'
import { Col, Row } from 'antd'
import dayjs from 'dayjs'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { FetchMeetingDetailsQuery } from 'apps/lms-front/src/generated/graphql'

import { useAuth } from '../../../auth/hooks/use-auth'
import { LoadScreen } from '../../../core/components/LoadScreen'
import { Header } from '../../../units/components/header'
import { ChatParticipant, StreamProvider } from '../../contexts/StreamContext'
import MEETING_DETAILS_QUERY from '../../queries/fetch-event-by-call-id.graphql'

import { JoinStreamContent } from './JoinStreamContent'

export const JoinStream = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { call_id } = useParams()
  const { data, loading, refetch } = useQuery<FetchMeetingDetailsQuery>(
    MEETING_DETAILS_QUERY,
    {
      variables: {
        call_id,
      },
      onError: (error) => {
        console.error('Failed to fetch meeting details:', error)
      },
    }
  )

  if (!call_id) return

  if (!data) {
    return (
      <Row justify="center" style={{ marginTop: '50px' }}>
        <Col span={8}>
          <div
            style={{
              textAlign: 'center',
              background: '#fff',
              padding: '24px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Trans id="stream.join.invalid_link">
              Dit is geen geldige meeting link
            </Trans>
          </div>
        </Col>
      </Row>
    )
  }

  const {
    _id: eventId,
    title,
    participants,
    stream_details,
    is_survey_available,
    survey,
    start,
    end,
  } = data.fetchLiveEventByCallId

  const duration = dayjs(end).diff(dayjs(start), 'milliseconds')

  return (
    <StreamProvider
      chatParticipants={(participants as ChatParticipant[]) ?? []}
      eventId={eventId}
      isRoomActive={stream_details?.is_active ?? false}
      callId={call_id}
      isMeetingHost={stream_details?.host_ids.includes(user?._id) ?? false}
      isParticipant={
        participants?.some((participant) => participant._id === user?._id) ??
        false
      }
      survey={is_survey_available ? survey : []}
      duration={duration}
    >
      <Header
        title={title}
        onBack={() => navigate(`/library/calendar?event=${eventId}`)}
      />
      {loading ? <LoadScreen /> : <JoinStreamContent refetch={refetch} />}
    </StreamProvider>
  )
}
