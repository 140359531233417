import {
  AlertOutlined,
  CheckOutlined,
  DeleteOutlined,
  FileOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Plural, t, Trans } from '@lingui/macro'
import {
  Alert,
  Button,
  Empty,
  Form,
  List,
  Popconfirm,
  Space,
  Tabs,
  TabsProps,
  Tooltip,
  Upload,
} from 'antd'
import axios from 'axios'
import download from 'js-file-download'
import { useContext, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  DeleteLearningPathAttachmentMutation,
  LearningPathParticipant,
  PathQuery,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../auth/components/Can'
import { LoadSection } from '../../core/components/LoadScreen'
import { AttachmentName } from '../../courses/pages/course-viewer/CourseViewer'
import { useLearningPathStatus } from '../hooks/use-path-status.hook'
import { AddLearningPathParticipantsModal } from '../modals/AddLearningPathParticipantsModal'

import DELETE_LEARNING_PATH_ATTACHMENT_MUTATION from './../mutations/delete-path-attachment.graphql'
import { ParticipantListItem } from './ParticipantListItem'

export const LearningPathTabs = ({
  path,
  refetch,
  loading,
}: {
  path: PathQuery['fetchLearningPathById']
  loading: boolean
  refetch: () => void
}) => {
  const [addParticipantsModalVisible, setAddParticipantsModalVisible] =
    useState<boolean>(false)

  const ability = useContext(AbilityContext)

  const { remaining, hasReachedCapacity, hasExceededCapacity } =
    useLearningPathStatus(path)

  const [deleteAttachment, { loading: deletingAttachment }] =
    useMutation<DeleteLearningPathAttachmentMutation>(
      DELETE_LEARNING_PATH_ATTACHMENT_MUTATION
    )

  const canReadRegistrations = ability.can(
    PermissionAction.READ,
    PermissionObjectType.BRANCH_LEARNING_PATH_PARTICIPANTS
  )

  const canAdminRegister = ability.can(
    PermissionAction.UPDATE,
    PermissionObjectType.BRANCH_LEARNING_PATH_PARTICIPANTS
  )

  const canUpdate = ability.can(
    PermissionAction.UPDATE,
    PermissionObjectType.BRANCH_LEARNING_PATH
  )

  return (
    <>
      <AddLearningPathParticipantsModal
        participants={path.participants
          ?.filter((p) => p.activity?.created)
          ?.map((participant) => participant._id)}
        open={addParticipantsModalVisible}
        onClose={() => setAddParticipantsModalVisible(false)}
        path={path}
      />
      <Tabs
        type="card"
        size={'small'}
        style={{ marginTop: 8 }}
        items={
          [
            {
              key: 'description',
              className: 'path-tab-description',
              label: <Trans id="events.description">Beschrijving</Trans>,
              children: (
                <div className="event-description-wysiwyg">
                  {ReactHtmlParser(path.description || '') as React.ReactNode}
                </div>
              ),
            },
            canReadRegistrations && {
              key: 'participants',
              className: 'path-tab-participants',
              label: (
                <>
                  <Trans id="paths.participants">Deelnemers</Trans>{' '}
                  {remaining || hasReachedCapacity ? (
                    <>
                      ({path.participant_count}/{path.capacity})
                    </>
                  ) : (
                    <>({path.participant_count})</>
                  )}
                </>
              ),
              children: (
                <div style={{ maxHeight: '45vh', overflow: 'auto' }}>
                  {!!remaining && !hasReachedCapacity && (
                    <Alert
                      type="info"
                      showIcon={true}
                      icon={<InfoCircleOutlined />}
                      message={
                        <Plural
                          id="paths.participants.remaining"
                          value={remaining}
                          one="Er is nog # vrije plek beschikbaar in dit leerpad."
                          other="Er zijn nog # vrije plekken beschikbaar in dit leerpad."
                        />
                      }
                    />
                  )}
                  {hasReachedCapacity && !hasExceededCapacity && (
                    <Alert
                      type="info"
                      icon={<CheckOutlined />}
                      showIcon={true}
                      message={
                        <Trans id="paths.participants.full">
                          Met {path.participant_count} deelnemers zijn de
                          plaatsen voor dit leerpad volzet.
                        </Trans>
                      }
                    />
                  )}
                  {!!remaining && hasExceededCapacity && (
                    <Alert
                      type="error"
                      icon={<AlertOutlined />}
                      showIcon={true}
                      message={
                        <Plural
                          id="paths.participants.exceeded"
                          value={-remaining}
                          one="Er is # plek teveel gevuld voor dit leerpad."
                          other="Er zijn # plekken teveel gevuld voor dit leerpad."
                        />
                      }
                    />
                  )}
                  {loading ? (
                    <LoadSection />
                  ) : !path.participants || path.participants.length === 0 ? (
                    <Empty
                      description={
                        <Space
                          direction="vertical"
                          style={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Trans id="events.participants.empty">
                            Er zijn nog geen deelnemers ingeschreven.
                          </Trans>
                          {canAdminRegister && (
                            <Button
                              onClick={() =>
                                setAddParticipantsModalVisible(true)
                              }
                            >
                              <Space>
                                <PlusOutlined />
                                <Trans id="events.participants.add">
                                  Deelnemers toevoegen
                                </Trans>
                              </Space>
                            </Button>
                          )}
                        </Space>
                      }
                    />
                  ) : (
                    <List
                      footer={
                        canAdminRegister && (
                          <Space
                            direction="vertical"
                            style={{
                              width: '100%',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              onClick={() =>
                                setAddParticipantsModalVisible(true)
                              }
                            >
                              <Space>
                                <PlusOutlined />
                                <Trans id="events.participants.add">
                                  Deelnemers toevoegen
                                </Trans>
                              </Space>
                            </Button>
                          </Space>
                        )
                      }
                    >
                      {path.participants
                        .filter((p) => (path.approval ? true : !!p.activity))
                        .map((participant) => (
                          <List.Item key={participant._id}>
                            <ParticipantListItem
                              participant={
                                participant as LearningPathParticipant
                              }
                              path={path}
                            />
                          </List.Item>
                        ))}
                    </List>
                  )}
                </div>
              ),
            },
            {
              key: 'downloads',
              className: 'path-tab-downloads',
              label: <Trans id="events.attachments">Bijlagen</Trans>,
              children: (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <List
                    style={{
                      margin: -16,
                      border: 0,
                    }}
                    size="small"
                    bordered
                    locale={{
                      emptyText: t({
                        id: 'events.detail.downloads.empty',
                        message: 'Geen bijlagen beschikbaar',
                      }),
                    }}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    dataSource={path.attachments}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <Popconfirm
                            key={0}
                            title={t({
                              id: 'paths.detail.downloads.delete.title',
                              message:
                                'Ben je zeker dat je deze bijlage wil verwijderen?',
                            })}
                            onConfirm={() =>
                              deleteAttachment({
                                variables: {
                                  path_id: path._id,
                                  attachment_id: item._id,
                                },
                              })
                            }
                            okText={t({
                              id: 'action.delete',
                              message: 'Verwijderen',
                            })}
                            okButtonProps={{
                              loading: deletingAttachment,
                            }}
                            cancelText={t({
                              id: 'action.cancel',
                              message: 'Annuleren',
                            })}
                          >
                            <Button
                              hidden={!canUpdate}
                              type="text"
                              icon={<DeleteOutlined style={{ fontSize: 14 }} />}
                            ></Button>
                          </Popconfirm>,
                        ]}
                      >
                        <Button
                          onClick={async () => {
                            const file = await axios.get(item.url, {
                              responseType: 'blob',
                            })
                            download(
                              file.data,
                              item.originalName,
                              item.mimetype
                            )
                          }}
                          type="text"
                          style={{ padding: 0, height: 'auto' }}
                        >
                          <FileOutlined
                            style={{
                              marginRight: 8,
                              display: 'inline-block',
                              verticalAlign: 'sub',
                            }}
                          />
                          <Tooltip
                            title={item.originalName}
                            mouseEnterDelay={2}
                          >
                            <AttachmentName>{item.originalName}</AttachmentName>
                          </Tooltip>
                        </Button>
                      </List.Item>
                    )}
                  />
                  {canUpdate && (
                    <Form.Item style={{ marginTop: 24, marginBottom: 0 }}>
                      <Upload
                        accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/epub+zip, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/rtf, application/zip"
                        multiple
                        type="drag"
                        action={`${
                          import.meta.env.NX_BACKEND_URL
                        }/api/files/uploadLearningPathAttachment/${path._id}`}
                        headers={{
                          Authorization: `Bearer ${localStorage.getItem(
                            'aa_lms_at'
                          )}`,
                          'x-academy-host': window.location.hostname,
                        }}
                        onChange={(info) => {
                          if (info.file.status === 'done') refetch()
                        }}
                      >
                        <Button>
                          <Space>
                            <UploadOutlined />
                            <Trans id="event.attachments.select_file">
                              Bestand selecteren
                            </Trans>
                          </Space>
                        </Button>
                      </Upload>
                    </Form.Item>
                  )}
                </Space>
              ),
            },
          ].filter(Boolean) as TabsProps['items']
        }
      />
    </>
  )
}
