import { log, defaultUserChoices } from '@livekit/components-core'
import {
  ParticipantPlaceholder,
  TrackToggle,
  usePersistentUserChoices,
} from '@livekit/components-react'
import {
  facingModeFromLocalTrack,
  Track,
  Mutex,
  createLocalAudioTrack,
  createLocalVideoTrack,
} from 'livekit-client'
import * as React from 'react'

import { useBackgroundProcessor } from '../../backgrounds/BackgroundProcessor'

import { MediaDeviceMenu } from './MediaDeviceMenu'

import type { LocalUserChoices } from '@livekit/components-core'
import type {
  AudioCaptureOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  VideoCaptureOptions,
} from 'livekit-client'

export function roomOptionsStringifyReplacer(key: string, val: unknown) {
  if (key === 'processor' && val && typeof val === 'object' && 'name' in val) {
    return (val as { name: string }).name
  }
  if (key === 'e2ee' && val) {
    return 'e2ee-enabled'
  }

  return val
}

/**
 * Props for the PreJoin component.
 * @public
 */
export interface PreJoinProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onValidate' | 'onError'> {
  onValidate?: (values: LocalUserChoices) => boolean
  onError?: (error: Error) => void
  /** Prefill the input form with initial values. */
  defaults?: Partial<LocalUserChoices>
  /** Display a debug window for your convenience. */
  debug?: boolean
  joinLabel?: string
  micLabel?: string
  camLabel?: string
  /**
   * If true, user choices are persisted across sessions.
   * @defaultValue true
   * @alpha
   */
  persistUserChoices?: boolean
}

export function usePreviewTracks(
  audioOptions: AudioCaptureOptions | null,
  videoOptions: VideoCaptureOptions | null,
  onError?: (err: Error) => void
): {
  audioTrack?: LocalAudioTrack
  videoTrack?: LocalVideoTrack
} {
  const [audioTrack, setAudioTrack] = React.useState<LocalAudioTrack>()
  const [videoTrack, setVideoTrack] = React.useState<LocalVideoTrack>()
  const trackLock = React.useMemo(() => new Mutex(), [])

  // Handle audio track
  React.useEffect(() => {
    let localTrack: LocalAudioTrack | undefined

    if (audioOptions) {
      trackLock.lock().then(async (unlock) => {
        try {
          if (audioTrack) {
            await audioTrack.stop()
            audioTrack.detach()
          }
          localTrack = await createLocalAudioTrack(audioOptions)
          setAudioTrack(localTrack)
        } catch (error: unknown) {
          if (onError && error instanceof Error) {
            onError(error)
          } else {
            log.error(error)
          }
        } finally {
          unlock()
        }
      })
    } else {
      audioTrack?.stop()
      setAudioTrack(undefined)
    }

    return () => {
      if (localTrack) localTrack.stop()
    }
  }, [
    JSON.stringify(audioOptions, roomOptionsStringifyReplacer),
    onError,
    trackLock,
  ])

  // Handle video track
  React.useEffect(() => {
    let localTrack: LocalVideoTrack | undefined

    if (videoOptions) {
      trackLock.lock().then(async (unlock) => {
        try {
          if (videoTrack) {
            await videoTrack.stopProcessor?.()
            videoTrack.stop()
            videoTrack.detach()
          }
          localTrack = await createLocalVideoTrack(videoOptions)
          setVideoTrack(localTrack as LocalVideoTrack)
        } catch (error: unknown) {
          if (onError && error instanceof Error) {
            onError(error)
          } else {
            log.error(error)
          }
        } finally {
          unlock()
        }
      })
    } else {
      videoTrack?.stopProcessor?.()
      videoTrack?.stop()
      videoTrack?.detach()
      setVideoTrack(undefined)
    }

    return () => {
      if (localTrack) {
        localTrack.stopProcessor?.()
        localTrack.stop()
      }
    }
  }, [
    JSON.stringify(videoOptions, roomOptionsStringifyReplacer),
    onError,
    trackLock,
  ])

  return {
    audioTrack: React.useMemo(() => audioTrack, [audioTrack]),
    videoTrack: React.useMemo(() => videoTrack, [videoTrack]),
  }
}

export function PreJoin({
  onError,
  debug,
  micLabel = 'Microphone',
  camLabel = 'Camera',
  persistUserChoices = true,
  ...htmlProps
}: PreJoinProps) {
  const [userChoices, setUserChoices] = React.useState(defaultUserChoices)
  const { backgroundProcessor } = useBackgroundProcessor()
  const {
    userChoices: initialUserChoices,
    saveAudioInputDeviceId,
    saveAudioInputEnabled,
    saveVideoInputDeviceId,
    saveVideoInputEnabled,
  } = usePersistentUserChoices({
    preventSave: !persistUserChoices,
    preventLoad: !persistUserChoices,
  })

  // Initialize device settings
  const [audioEnabled, setAudioEnabled] = React.useState<boolean>(
    initialUserChoices.audioEnabled
  )
  const [videoEnabled, setVideoEnabled] = React.useState<boolean>(
    initialUserChoices.videoEnabled
  )
  const [audioDeviceId, setAudioDeviceId] = React.useState<string>(
    initialUserChoices.audioDeviceId
  )
  const [videoDeviceId, setVideoDeviceId] = React.useState<string>(
    initialUserChoices.videoDeviceId
  )

  // Save user choices to persistent storage.
  React.useEffect(() => {
    saveAudioInputEnabled(audioEnabled)
  }, [audioEnabled, saveAudioInputEnabled])
  React.useEffect(() => {
    saveVideoInputEnabled(videoEnabled)
  }, [videoEnabled, saveVideoInputEnabled])
  React.useEffect(() => {
    saveAudioInputDeviceId(audioDeviceId)
  }, [audioDeviceId, saveAudioInputDeviceId])
  React.useEffect(() => {
    saveVideoInputDeviceId(videoDeviceId)
  }, [videoDeviceId, saveVideoInputDeviceId])

  const { audioTrack, videoTrack } = usePreviewTracks(
    audioEnabled ? { deviceId: audioDeviceId || '' } : null,
    videoEnabled
      ? {
          deviceId: videoDeviceId || '',
          processor: backgroundProcessor as never,
        }
      : null,
    onError
  )

  const videoEl = React.useRef<HTMLVideoElement>(null)

  React.useEffect(() => {
    const videoElement = videoEl.current
    if (!videoTrack || !videoElement) return

    const setup = async () => {
      videoTrack.unmute()
      videoTrack.attach(videoElement)
      if (backgroundProcessor && !videoTrack.getProcessor()) {
        await videoTrack.setProcessor(backgroundProcessor)
      } else if (!backgroundProcessor) {
        await videoTrack.stopProcessor()
      }
    }
    setup()

    return () => {
      if (videoTrack) {
        videoTrack.stopProcessor()
        if (videoTrack.mediaStreamTrack) {
          videoTrack.mediaStreamTrack.enabled = false
          videoTrack.mediaStreamTrack.stop()
        }
        videoTrack.stop()
        videoTrack.detach()
      }
    }
  }, [videoTrack, backgroundProcessor])

  const facingMode = React.useMemo(() => {
    if (videoTrack) {
      const { facingMode } = facingModeFromLocalTrack(videoTrack)
      return facingMode
    } else {
      return 'undefined'
    }
  }, [videoTrack])

  React.useEffect(() => {
    const newUserChoices = {
      username: '',
      videoEnabled,
      videoDeviceId,
      audioEnabled,
      audioDeviceId,
    }
    setUserChoices(newUserChoices)
  }, [videoEnabled, audioEnabled, audioDeviceId, videoDeviceId])

  return (
    <div className="lk-prejoin" {...htmlProps}>
      <div
        className="lk-video-container"
        style={{ borderRadius: '0.25rem', overflow: 'hidden' }}
      >
        {videoTrack && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video
            ref={videoEl}
            width="1280"
            height="720"
            data-lk-facing-mode={facingMode}
          />
        )}
        {(!videoTrack || !videoEnabled) && (
          <div className="lk-camera-off-note">
            <ParticipantPlaceholder />
          </div>
        )}
      </div>
      <div className="lk-button-group-container">
        <div className="lk-button-group audio">
          <TrackToggle
            initialState={audioEnabled}
            source={Track.Source.Microphone}
            onChange={(enabled) => setAudioEnabled(enabled)}
          >
            {micLabel}
          </TrackToggle>
          <div className="lk-button-group-menu">
            <MediaDeviceMenu
              initialSelection={audioDeviceId}
              kind="audioinput"
              disabled={!audioTrack}
              tracks={{ audioinput: audioTrack }}
              onActiveDeviceChange={(_, id) => setAudioDeviceId(id)}
            />
          </div>
        </div>
        <div className="lk-button-group video">
          <TrackToggle
            initialState={videoEnabled}
            source={Track.Source.Camera}
            onChange={(enabled) => setVideoEnabled(enabled)}
          >
            {camLabel}
          </TrackToggle>
          <div className="lk-button-group-menu">
            <MediaDeviceMenu
              initialSelection={videoDeviceId}
              kind="videoinput"
              disabled={!videoTrack}
              tracks={{ videoinput: videoTrack }}
              onActiveDeviceChange={(_, id) => setVideoDeviceId(id)}
            />
          </div>
        </div>
      </div>

      {debug && (
        <>
          <strong>User Choices:</strong>
          <ul
            className="lk-list"
            style={{ overflow: 'hidden', maxWidth: '15rem' }}
          >
            <li>Username: {`${userChoices.username}`}</li>
            <li>Video Enabled: {`${userChoices.videoEnabled}`}</li>
            <li>Audio Enabled: {`${userChoices.audioEnabled}`}</li>
            <li>Video Device: {`${userChoices.videoDeviceId}`}</li>
            <li>Audio Device: {`${userChoices.audioDeviceId}`}</li>
          </ul>
        </>
      )}
    </div>
  )
}
