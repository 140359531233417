/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/no-identical-functions */
import {
  ApolloClient,
  ApolloError,
  useApolloClient,
  useMutation,
  NormalizedCacheObject,
  useQuery,
} from '@apollo/client'
import {
  AbilityBuilder,
  AnyMongoAbility,
  createMongoAbility,
} from '@casl/ability'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { notification } from 'antd'
import { usePostHog } from 'posthog-js/react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { createAbility } from '@lms-shared-patterns/models'
import { isValidLocalPath } from '@lms-shared-patterns/utils'
import {
  BranchQuery,
  LoginInput,
  LoginMutation,
  LoginWithMagicLinkTokenMutation,
  MeQuery,
  VerifyMagicCodeMutation,
} from 'apps/lms-front/src/generated/graphql'

import { ApolloCacheContext } from '../../core/context/ApolloCache.context'
import { AbilityContext } from '../components/Can'
import { AuthContext } from '../context/auth.context'

import LOGIN_MAGIC_LINK_MUTATION from './../mutations/login-with-magic-link-token.graphql'
import LOGIN_MUTATION from './../mutations/login.graphql'
import VERIFY_MAGIC_CODE_MUTATION from './../mutations/verify-magic-code.graphql'
import BRANCH_QUERY from './../queries/branch.graphql'
import ME_QUERY from './../queries/me.graphql'
import { BranchProvider } from './branch.provider'

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('aa_lms_at')
  )
  const [ability, setAbility] = useState<AnyMongoAbility>(
    new AbilityBuilder(createMongoAbility).build()
  )

  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const persistor = useContext(ApolloCacheContext)

  const { i18n } = useLingui()

  const posthog = usePostHog()

  const logout = useCallback(
    async (callback?: VoidFunction) => {
      await client.stop()
      localStorage.removeItem('aa_lms_at')
      sessionStorage.removeItem('aa_report_filter')
      setToken(null)
      setAbility(new AbilityBuilder(createMongoAbility).build())
      await persistor.cachePersistor?.purge()
      await client.cache.reset()
      posthog.reset()
      return client
        .resetStore()
        .then(callback)
        .catch(() => {
          return
        })
    },
    [client, persistor.cachePersistor, posthog]
  )

  const handleTokenExpired = useCallback(() => {
    setToken(null)
    if (!sessionStorage.getItem('aa_session_end'))
      sessionStorage.setItem('aa_session_end', location.pathname)
    logout()
    notification.info({
      message: t({
        id: 'error.token_expired',
        message: 'Je sessie is verlopen. Log opnieuw in om verder te gaan.',
      }),
      key: 'token_expired',
    })
  }, [logout])

  useEffect(() => {
    const tokenExpiredHandler = () => handleTokenExpired()
    document.addEventListener('token_expired', tokenExpiredHandler)
    return () => {
      document.removeEventListener('token_expired', tokenExpiredHandler)
    }
  }, [handleTokenExpired])

  const {
    data: me,
    loading: meLoading,
    refetch,
  } = useQuery<MeQuery>(ME_QUERY, {
    fetchPolicy: 'network-only',
    pollInterval: 10000,
    skip: !token,
    onCompleted: (data: MeQuery) => {
      if (data.me) {
        posthog.identify(data.me?._id, {
          name: `${data.me.firstName} ${data.me.lastName}`,
          firstName: data.me.firstName,
          lastName: data.me.lastName,
          email: data.me.email,
          role: data.me.roles.map((role) => role.role_name),
          branchId: data.branch?._id,
          branch: data.branch?.name,
          certificationType: data.me.certificationType?.map(
            (type) => type.name
          ),
        })
      }
      if (data.me?.lang && i18n.locale !== me?.me?.lang)
        window.LMS.setLanguage(data.me?.lang)
    },
    onError: (error: ApolloError) => {
      if (error.graphQLErrors[0]?.extensions?.code === 'UNAUTHENTICATED') {
        handleTokenExpired()
      }
    },
  })

  const { data, loading } = useQuery<BranchQuery>(BRANCH_QUERY, {
    fetchPolicy: 'network-only',
    pollInterval: 60000,
  })

  const [mutateLogin] = useMutation<LoginMutation>(LOGIN_MUTATION)
  const [mutateLoginWithMagicLinkToken] =
    useMutation<LoginWithMagicLinkTokenMutation>(LOGIN_MAGIC_LINK_MUTATION)

  const login = (
    loginInput: LoginInput,
    catchFn?: (reason: { message?: string }) => unknown
  ) => {
    return mutateLogin({
      variables: loginInput,
    })
      .then(({ data }) => {
        setToken(data?.login?.access_token || null)
        localStorage.setItem('aa_lms_at', data?.login?.access_token || '')
        window.dispatchEvent(new Event('storage'))

        const redirectTo = sessionStorage.getItem('aa_session_end') || '/'
        sessionStorage.removeItem('aa_session_end')

        data?.login.me.picture?.url &&
          localStorage.setItem('aa_avatar', data?.login.me.picture.url)
        data?.login.me.email &&
          localStorage.setItem('aa_email', data?.login.me.email)
        data?.login.me.firstName &&
          localStorage.setItem('aa_first_name', data?.login.me.firstName)

        if (redirectTo && isValidLocalPath(redirectTo) && redirectTo !== '/') {
          window.location.href = redirectTo
        }
      })
      .then(async () => refetch())
      .catch((error: ApolloError) => {
        if (!error.graphQLErrors[0]) return (catchFn || console.error)(error)
        const exception: { response: { suggestedHost: string } } = error
          ?.graphQLErrors[0].extensions?.exception as {
          response: { suggestedHost: string }
        }
        if (exception?.response?.suggestedHost) {
          window.location.href = `http://${exception.response.suggestedHost}/login`
        } else {
          throw error
        }
      })
  }

  const loginWithMicrosoftAzureAd = async () => {
    window.location.assign(
      `${
        import.meta.env.NX_BACKEND_URL
      }/api/auth/microsoft?hostname=${encodeURIComponent(
        window.location.hostname
      )}`
    )
  }

  const loginWithMagicLinkToken = (
    token: string,
    callback: VoidFunction,
    catchFn?: (reason: { message?: string }) => unknown
  ) => {
    return mutateLoginWithMagicLinkToken({
      variables: {
        token,
      },
    })
      .then(({ data }) => {
        const redirectTo = sessionStorage.getItem('aa_session_end') || '/'

        setToken(data?.loginWithMagicLinkToken?.access_token || null)
        localStorage.setItem(
          'aa_lms_at',
          data?.loginWithMagicLinkToken?.access_token || ''
        )
        window.dispatchEvent(new Event('storage'))
        data?.loginWithMagicLinkToken.me.picture?.url &&
          localStorage.setItem(
            'aa_avatar',
            data?.loginWithMagicLinkToken.me.picture.url
          )
        data?.loginWithMagicLinkToken.me.firstName &&
          localStorage.setItem(
            'aa_first_name',
            data?.loginWithMagicLinkToken.me.firstName
          )

        if (redirectTo && isValidLocalPath(redirectTo) && redirectTo !== '/') {
          window.location.href = redirectTo
        }
      })
      .then(() => refetch())
      .then(callback)
      .catch(catchFn)
  }

  const [mutateVerifyMagicCode] = useMutation<VerifyMagicCodeMutation>(
    VERIFY_MAGIC_CODE_MUTATION
  )

  const verifyMagicCode = (
    email: string,
    code: string,
    callback: VoidFunction,
    catchFn?: (reason: { message?: string }) => unknown
  ) => {
    return mutateVerifyMagicCode({
      variables: {
        email,
        code,
      },
    })
      .then(({ data }) => {
        setToken(data?.exchangeMagicCodeForToken?.access_token || null)
        localStorage.setItem(
          'aa_lms_at',
          data?.exchangeMagicCodeForToken?.access_token || ''
        )
        window.dispatchEvent(new Event('storage'))
        data?.exchangeMagicCodeForToken.me.picture?.url &&
          localStorage.setItem(
            'aa_avatar',
            data?.exchangeMagicCodeForToken.me.picture.url
          )
        data?.exchangeMagicCodeForToken.me.firstName &&
          localStorage.setItem(
            'aa_first_name',
            data?.exchangeMagicCodeForToken.me.firstName
          )

        const redirectTo = sessionStorage.getItem('aa_session_end') || '/'
        sessionStorage.removeItem('aa_session_end')

        if (redirectTo && isValidLocalPath(redirectTo) && redirectTo !== '/') {
          window.location.href = redirectTo
        }
      })
      .then(() => refetch())
      .then(callback)
      .catch(catchFn)
  }

  const value = {
    user: token ? (me?.me as MeQuery['me']) : null,
    login,
    loginWithMicrosoftAzureAd,
    loginWithMagicLinkToken,
    verifyMagicCode,
    logout,
    loading: meLoading,
    token: token || '',
  }

  useEffect(() => {
    if (me?.me?.roles)
      setAbility(
        createAbility(
          me?.me?.roles.map((role) => ({
            ...role,
            permissions: role.permissions.map((permission) => ({
              action: permission.action,
              subject: permission.subject,
            })),
          })) || [],
          me?.me._id
        )
      )
  }, [me])

  if (data?.branch) {
    return (
      <BranchProvider
        branch={data?.branch}
        loading={loading}
        hostname={window.location.hostname}
      >
        {data?.branch.favicon && (
          <Helmet>
            <link
              rel="icon"
              type="image/x-icon"
              href={data?.branch.favicon.url}
            />
          </Helmet>
        )}
        <AuthContext.Provider value={value}>
          <AbilityContext.Provider value={ability}>
            {children}
          </AbilityContext.Provider>
        </AuthContext.Provider>
      </BranchProvider>
    )
  }

  return null
}
