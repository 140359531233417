import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Form,
  Input,
  InputNumber,
  Modal,
  Button,
  Popconfirm,
  Row,
  Col,
  Switch,
  notification,
} from 'antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'

import { PathQuery } from 'apps/lms-front/src/generated/graphql'

import DatePicker from '../../shared/components/date-picker/DatePicker'
import { RichEditor } from '../../shared/components/rich-editor/RichEditor'
import { errorNotifierFn } from '../../shared/helpers/error-notifier'
import { usePath } from '../contexts/PathContext'
import DELETE_PATH_MODULE_MUTATION from '../mutations/delete-path-module.graphql'
import UPDATE_PATH_MODULE_MUTATION from '../mutations/update-path-module.graphql'

type Module = NonNullable<PathQuery['fetchLearningPathById']['modules']>[0]

export const EditModuleModal = ({
  open,
  onClose,
  onSubmit,
  module,
}: {
  open: boolean
  onClose: () => void
  onSubmit?: (values: Partial<Module>) => void
  module?: Module
}) => {
  const { isDateBeforeTodayOrStartDate, isDateWithinAvailableRange } = usePath()

  const [form] = Form.useForm()
  const optional = Form.useWatch('optional', form)

  const [updateLearningPathModule] = useMutation(UPDATE_PATH_MODULE_MUTATION, {
    refetchQueries: ['path'],
  })

  const [deleteLearningPathModule] = useMutation(DELETE_PATH_MODULE_MUTATION, {
    refetchQueries: ['path'],
  })

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        optional: false,
        ...module,
        deadline: module?.deadline ? dayjs(module?.deadline) : null,
        available_from: module?.available_from
          ? dayjs(module?.available_from)
          : null,
      })
    } else {
      form.resetFields()
    }
  }, [open, module, form])

  const handleSubmit = async () => {
    const values = await form.validateFields()
    const input = {
      ...values,
      deadline: values.optional ? null : values.deadline,
    }

    if (module?._id) {
      await updateLearningPathModule({
        variables: {
          module_id: module._id,
          input,
        },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'paths.detail.timeline.edit_module.success',
              message: 'Module succesvol bijgewerkt',
            }),
          })
        })
        .catch(errorNotifierFn)
    } else if (onSubmit) {
      onSubmit(input)
    }

    form.resetFields()
    onClose()
  }

  const handleDelete = async () => {
    if (module?._id) {
      await deleteLearningPathModule({
        variables: {
          module_id: module._id,
        },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'paths.detail.timeline.delete_module.success',
              message: 'Module succesvol verwijderd',
            }),
          })
          onClose()
        })
        .catch(errorNotifierFn)
    }
  }

  return (
    <Modal
      title={
        module ? (
          <Trans id="path.module.edit.title">Module bewerken</Trans>
        ) : (
          <Trans id="path.module.add.title">Module toevoegen</Trans>
        )
      }
      maskClosable={false}
      width={800}
      open={open}
      onCancel={onClose}
      footer={[
        !!module && (
          <Popconfirm
            key="delete"
            title={
              <Trans id="path.module.delete.confirm">
                Weet je zeker dat je deze module wilt verwijderen? Deze actie
                kan niet ongedaan worden gemaakt.
              </Trans>
            }
            okText={<Trans id="action.yes">Ja</Trans>}
            cancelText={<Trans id="action.no">Nee</Trans>}
            onConfirm={handleDelete}
          >
            <Button type="dashed" danger>
              <Trans id="action.delete">Verwijderen</Trans>
            </Button>
          </Popconfirm>
        ),
        !module && (
          <Button key="cancel" onClick={onClose}>
            <Trans id="action.cancel">Annuleren</Trans>
          </Button>
        ),
        <Button key="submit" type="primary" onClick={handleSubmit}>
          <Trans id="action.save">Opslaan</Trans>
        </Button>,
      ].filter(Boolean)}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Row gutter={16}>
          <Col span={20}>
            <Form.Item
              name="name"
              label={<Trans id="path.module.field.name">Naam</Trans>}
              rules={[{ required: true, message: 'Naam is verplicht' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="optional"
              valuePropName="checked"
              label={<Trans id="path.module.field.optional">Optioneel</Trans>}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="description"
          label={<Trans id="path.module.field.description">Beschrijving</Trans>}
        >
          <RichEditor disableTextStyles />
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="duration"
              label={<Trans id="path.module.field.duration">Duurtijd</Trans>}
            >
              <InputNumber
                min={1}
                style={{ width: '100%' }}
                addonAfter={<Trans id="time.minutes">minuten</Trans>}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="available_from"
              label={
                <Trans id="path.module.field.available_from">
                  Beschikbaar vanaf
                </Trans>
              }
            >
              <DatePicker
                disabledDate={(currentDate) => {
                  if (!dayjs.isDayjs(currentDate)) return true

                  return !isDateWithinAvailableRange(
                    currentDate,
                    form.getFieldValue('deadline')
                  )
                }}
                showTime
                showSecond={false}
                format="D MMMM YYYY - HH:mm"
                minuteStep={15}
                secondStep={60}
                style={{ width: '100%' }}
                onSelect={(value) => {
                  form.setFieldValue('available_from', value)
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              hidden={optional}
              name="deadline"
              label={<Trans id="path.module.field.deadline">Deadline</Trans>}
            >
              <DatePicker
                disabledDate={isDateBeforeTodayOrStartDate}
                showTime
                showSecond={false}
                format="D MMMM YYYY - HH:mm"
                minuteStep={15}
                secondStep={60}
                style={{ width: '100%' }}
                onSelect={(value) => {
                  form.setFieldValue('deadline', value)
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
