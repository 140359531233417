import { useQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { UnitKind } from '@lms-shared-patterns/models'
import {
  ContentUnit,
  PdfUnit,
  PublicCustomUnitQuery,
  PublicUnitQuery,
} from 'apps/lms-front/src/generated/graphql'
import { Container } from 'apps/lms-front/src/modules/shared/layout/Layout.style'

import { LoadScreen, LoadSection } from '../../core/components/LoadScreen'
import { getParentRoute } from '../../core/routes/router'
import { logActivity } from '../../shared/helpers/log-activity'
import { PageProps } from '../../shared/interfaces/page.interface'
import { Header } from '../../units/components/header'
import { ContentUnitViewer } from '../../units/pages/unit-viewer/content-unit-viewer/ContentUnitViewer'
import { PDFUnitViewer } from '../../units/pages/unit-viewer/pdf-unit-viewer/PdfUnitViewer'
import { QuizUnitViewer } from '../../units/pages/unit-viewer/quiz-unit-viewer/QuizUnitViewer'
import { SurveyUnitViewer } from '../../units/pages/unit-viewer/survey-unit-viewer/SurveyUnitViewer'
import {
  Content,
  UnitFocusLayout,
} from '../../units/pages/unit-viewer/UnitViewer.style'
import { VideoUnitViewer } from '../../units/pages/unit-viewer/video-unit-viewer/VideoUnitViewer'
import PUBLIC_CUSTOM_UNIT_QUERY from '../../units/queries/public-custom-unit.graphql'
import PUBLIC_UNIT_QUERY from '../../units/queries/public-unit.graphql'

export const LearningPathUnitViewer = ({ route }: PageProps) => {
  const navigate = useNavigate()
  const params = useParams()

  const { unit: id, certification: certification_id } = params
  const parent = getParentRoute(route, params, certification_id ? 3 : 2)

  const [loading, setLoading] = useState(false)

  const { data, error, refetch } = useQuery<PublicUnitQuery>(
    PUBLIC_UNIT_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: { id },
    }
  )

  const {
    data: customData,
    error: customError,
    refetch: customRefetch,
  } = useQuery<PublicCustomUnitQuery>(PUBLIC_CUSTOM_UNIT_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id },
  })

  const custom = useMemo(() => {
    return !!customData?.fetchCustomUnitById
  }, [customData])

  const unit = useMemo(() => {
    if (!custom)
      return data?.fetchUnitById as PublicUnitQuery['fetchUnitById'] & {
        __typename:
          | 'ContentUnit'
          | 'VideoUnit'
          | 'PDFUnit'
          | 'QuizUnit'
          | 'SurveyUnit'
      }

    return customData?.fetchCustomUnitById as PublicCustomUnitQuery['fetchCustomUnitById'] & {
      __typename:
        | 'ContentUnit'
        | 'VideoUnit'
        | 'PDFUnit'
        | 'QuizUnit'
        | 'SurveyUnit'
    }
  }, [custom, data, customData])

  const course = useMemo(() => {
    return data?.fetchUnitById.__typename === 'Unit'
      ? null
      : data?.fetchUnitById.course
  }, [data])

  const navigateToLearningPath = useCallback(() => {
    setLoading(true)
    navigate(parent)
  }, [navigate, parent])

  const renderedUnit = useMemo(() => {
    if (!unit) return null

    switch (unit.__typename) {
      case UnitKind.CONTENT: {
        return (
          <ContentUnitViewer
            unit={unit as ContentUnit}
            handleUnitCompletion={navigateToLearningPath}
          />
        )
      }
      case UnitKind.VIDEO: {
        const allowChangingPlaybackRate =
          !course?.my_activity?.certification_type?.disable_playbackrate ||
          !!unit.my_activity?.completed
        return (
          <VideoUnitViewer
            unit={
              unit as PublicUnitQuery['fetchUnitById'] & {
                __typename: 'VideoUnit'
              }
            }
            allowChangingPlaybackRate={allowChangingPlaybackRate}
            refetchUnit={async () => await (custom ? customRefetch : refetch)()}
            handleUnitCompletion={navigateToLearningPath}
          />
        )
      }
      case UnitKind.PDF: {
        return (
          <PDFUnitViewer
            unit={unit as PdfUnit}
            handleUnitCompletion={navigateToLearningPath}
          />
        )
      }
      case UnitKind.QUIZ: {
        return (
          <QuizUnitViewer
            unit={
              unit as PublicUnitQuery['fetchUnitById'] & {
                __typename: 'QuizUnit'
              }
            }
            refetchUnit={async () => await (custom ? customRefetch : refetch)()}
            handleUnitCompletion={navigateToLearningPath}
          />
        )
      }
      case UnitKind.SURVEY: {
        return (
          <SurveyUnitViewer
            unit={
              unit as PublicUnitQuery['fetchUnitById'] & {
                __typename: 'SurveyUnit'
              }
            }
            refetchUnit={async () => await (custom ? customRefetch : refetch)()}
            handleUnitCompletion={navigateToLearningPath}
          />
        )
      }
      default: {
        return null
      }
    }
  }, [custom, unit, navigateToLearningPath, refetch, customRefetch])

  /**
   * If certification_id is present,
   * the unit activity is logged as such
   * and we redirect to plain viewer.
   **/
  useEffect(() => {
    if (unit)
      logActivity({ unit_id: unit?._id, certification_id }).then(() => {
        if (certification_id) {
          navigate(parent)
        } else {
          refetch()
        }
      })
  }, [
    course,
    unit,
    certification_id,
    parent,
    navigateToLearningPath,
    navigate,
    refetch,
  ])

  if (error && customError) return <Navigate to={parent} />
  if (!unit) return <LoadScreen />

  return (
    <>
      <Helmet>
        <title>{course?.translation.name}</title>
      </Helmet>
      <Header
        title={course?.translation.name}
        onBack={() => navigate(parent)}
      />
      <Container>
        <UnitFocusLayout>
          <Content style={{ flex: 1, backgroundColor: '#FFF' }}>
            <Row justify="center" style={{ flex: 1 }} gutter={24}>
              {loading ? (
                <Col md={20} flex={1}>
                  <LoadSection />
                </Col>
              ) : (
                renderedUnit
              )}
            </Row>
          </Content>
        </UnitFocusLayout>
      </Container>
    </>
  )
}
