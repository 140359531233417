/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PlusOutlined, LinkOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Col,
  Dropdown,
  Form,
  Menu,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
} from 'antd'
import { ObjectId } from 'bson'
import dayjs, { Dayjs } from 'dayjs'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { formatDuration } from '@lms-shared-patterns/utils'
import {
  CourseCertificationTypesQuery,
  CertificationTypesQuery,
  CompleteCourseMutation,
  ResetCourseMutation,
  ModifyCourseActivityMutation,
  BranchAccessibleCoursesQuery,
  AddCourseActivityMutation,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { LoadSection } from '../../../core/components/LoadScreen'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { ExportButton } from '../../../shared/components/export-button/ExportButton'
import { useAxios } from '../../../shared/hooks/use-axios'
import { useTranslatedData } from '../../../shared/hooks/use-translated-data'
import { dateRenderer, percentageRenderer } from '../../helpers/renderers'
import { Table } from '../Table'
import { TableHeader } from '../TableHeader/TableHeader.style'
import { UserCourseActivityReport } from '../UserCourseActivityReport'

import BRANCH_ACCESSIBLE_COURSES_QUERY from './../../../branch/queries/branch-accessible-courses.graphql'
import CERTIFICATION_TYPES_QUERY from './../../../settings/queries/certification-types.graphql'
import ADD_COURSE_ACTIVITY from './../../mutations/add-course-activity.graphql'
import COMPLETE_COURSE from './../../mutations/complete-course.graphql'
import MODIFY_COURSE_ACTIVITY from './../../mutations/modify-course-activity.graphql'
import RESET_COURSE from './../../mutations/reset-course.graphql'
import COURSE_CERTIFICATION_TYPES_QUERY from './../../queries/course-certification-types.graphql'

export const UserCoursesReport = ({
  header,
  userId,
  branchId,
  courseId,
  exportable,
  start,
  end,
}: {
  header?: string
  userId: string
  branchId: string
  courseId?: string
  exportable?: string
  start?: string
  end?: string
}) => {
  const ability = useContext(AbilityContext)

  const [selectedAction, setSelectedAction] = useState<string>()
  const [selectedTarget, setSelectedTarget] = useState<{
    certification_id: string
    _id: string
    completed: string
  }>()
  const [showActionModal, setShowActionModal] = useState<boolean>(false)
  const [showAddActivityModal, setShowAddActivityModal] =
    useState<boolean>(false)
  const [addActivitySelectedCourse, setAddActivitySelectedCourse] =
    useState<string>()

  const params = { branch_id: branchId, course_id: courseId }

  const [{ data, loading }, refetch] = useAxios({
    url: `/api/activity/users/${userId}`,
    params: start && end ? { ...params, start, end } : params,
  })

  const transformedCourseData = useTranslatedData(data?.courses, 'name')

  const [fetchCourses, { data: courses, loading: coursesLoading }] =
    useLazyQuery<BranchAccessibleCoursesQuery>(BRANCH_ACCESSIBLE_COURSES_QUERY)

  const { data: certificationTypes } = useQuery<CertificationTypesQuery>(
    CERTIFICATION_TYPES_QUERY
  )

  const { data: selectedTargetCourse } =
    useQuery<CourseCertificationTypesQuery>(COURSE_CERTIFICATION_TYPES_QUERY, {
      skip: !selectedTarget?._id,
      variables: {
        id: selectedTarget?._id,
      },
    })

  const [complete, { loading: completing }] =
    useMutation<CompleteCourseMutation>(COMPLETE_COURSE)

  const [reset, { loading: resetting }] =
    useMutation<ResetCourseMutation>(RESET_COURSE)

  const [addCourseActivity, { loading: addingCourseActivity }] =
    useMutation<AddCourseActivityMutation>(ADD_COURSE_ACTIVITY)

  const [modify, { loading: modifying }] =
    useMutation<ModifyCourseActivityMutation>(MODIFY_COURSE_ACTIVITY)

  const [modifyForm] = Form.useForm()

  if (!data)
    return (
      <>
        <div className="ant-table-wrapper">
          <div className="ant-table ant-table-small ant-table-bordered">
            <div className="ant-table-title">
              {header && <TableHeader>{header}</TableHeader>}
            </div>
            <div className="ant-table-footer">
              <LoadSection />
            </div>
          </div>
        </div>
      </>
    )

  return (
    <>
      <Table
        title={header ? () => <TableHeader>{header}</TableHeader> : undefined}
        expandable={{
          expandedRowRender: (record) => (
            <UserCourseActivityReport
              // @ts-ignore
              courseId={record._id}
              // @ts-ignore
              userId={userId}
              branchId={branchId}
            />
          ),
          expandRowByClick: true,
          // @ts-ignore
          rowExpandable: () => true,
        }}
        bordered
        size="small"
        dataSource={transformedCourseData}
        rowKey="_id"
        columns={[
          {
            title: t({
              id: 'reports.courses_report.table.course',
              message: 'Opleiding',
            }),
            dataIndex: 'name',
            key: 'name',
            // @ts-ignore
            render: (
              value: string,
              {
                certification_id,
                _id,
                completed,
              }: { certification_id: string; _id: string; completed: string }
            ) => {
              const certificationType =
                certificationTypes?.fetchCertificationTypes.find((type) =>
                  new ObjectId(type._id).equals(certification_id)
                )?.name
              return (
                <Dropdown
                  disabled={
                    !ability.can(
                      PermissionAction.UPDATE,
                      PermissionObjectType.ACTIVITY
                    )
                  }
                  overlay={
                    <Menu
                      onClick={(info) => {
                        info.domEvent.stopPropagation()
                        modifyForm.setFieldsValue({
                          certificationType: certification_id,
                          completed: completed ? dayjs(completed) : undefined,
                        })
                        setSelectedTarget({
                          certification_id,
                          _id,
                          completed,
                        })
                        setSelectedAction(info.key)
                        setShowActionModal(true)
                      }}
                      items={[
                        {
                          label: t({
                            id: 'reports.courses_report.action.reset',
                            message: 'Vooruitgang verwijderen',
                          }),
                          key: 'reset',
                        },
                        {
                          label: t({
                            id: 'reports.courses_report.action.complete',
                            message: 'Markeer als voltooid',
                          }),
                          key: 'complete',
                        },
                        {
                          label: t({
                            id: 'reports.courses_report.action.edit',
                            message: 'Wijzigen',
                          }),
                          key: 'edit',
                        },
                      ]}
                    />
                  }
                  trigger={['contextMenu']}
                >
                  <Space>
                    {value}
                    {!!certificationType && (
                      <Tag color="blue">{certificationType}</Tag>
                    )}
                    <Tooltip
                      title={t({
                        id: 'reports.courses_report.table.course.link',
                        message: 'Naar opleiding navigeren',
                      })}
                    >
                      <Link
                        to={`/courses/${_id}`}
                        className={'show-on-row-hover'}
                      >
                        <LinkOutlined />
                      </Link>
                    </Tooltip>
                  </Space>
                </Dropdown>
              )
            },
            // @ts-ignore
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
          {
            title: t({
              id: 'reports.courses_report.table.progress',
              message: 'Vooruitgang',
            }),
            dataIndex: 'progress',
            key: 'progress',
            render: percentageRenderer,
            // @ts-ignore
            sorter: (a, b) => a.progress - b.progress,
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.courses_report.table.last_activity',
              message: 'Laatst actief op',
            }),
            dataIndex: 'last_activity',
            key: 'last_activity',
            render: dateRenderer,
            sorter: (a, b) =>
              // @ts-ignore
              new Date(a.last_activity) - new Date(b.last_activity),
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.courses_report.table.completed',
              message: 'Voltooid op',
            }),
            dataIndex: 'completed',
            key: 'completed',
            render: dateRenderer,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sorter: (a: any, b: any) => {
              if (!a.completed && !b.completed) return 0
              if (!a.completed) return -1
              if (!b.completed) return 1
              //@ts-ignore
              return new Date(a.completed) - new Date(b.completed)
            },
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.courses_report.table.score',
              message: 'Score',
            }),
            dataIndex: 'score',
            key: 'score',
            render: percentageRenderer,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sorter: (a: any, b: any) => {
              if (!a.score && !b.score) return 0
              if (!a.score) return -1
              if (!b.score) return 1
              return a.score - b.score
            },
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.courses_report.table.training_time',
              message: 'Trainingstijd',
            }),
            dataIndex: 'training_time',
            key: 'training_time',
            render: (val: number) => (val > 60 ? formatDuration(val) : '-'),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sorter: (a: any, b: any) => {
              if (!a.training_time && !b.training_time) return 0
              if (!a.training_time) return -1
              if (!b.training_time) return 1
              return a.training_time - b.training_time
            },
            align: 'center' as const,
          },
        ]}
        pagination={false}
        footer={() => {
          if (!exportable) {
            return (
              <Row align={'middle'}>
                <Col xs={24} sm={8}></Col>
                <Col xs={24} sm={8}>
                  {ability.can(
                    PermissionAction.UPDATE,
                    PermissionObjectType.ACTIVITY
                  ) ? (
                    <div style={{ textAlign: 'center' }}>
                      <Modal
                        title={t({
                          id: 'reports.courses_report.action.add_activity',
                          message:
                            'Opleiding aan gebruikersactiviteit toevoegen',
                        })}
                        open={showAddActivityModal}
                        okText={t({
                          id: 'actions.add',
                          message: 'Toevoegen',
                        })}
                        okButtonProps={{
                          disabled: !addActivitySelectedCourse,
                          loading: addingCourseActivity,
                        }}
                        onOk={() => {
                          addCourseActivity({
                            variables: {
                              user_id: userId,
                              branch_id: branchId,
                              course_id: addActivitySelectedCourse,
                            },
                          }).then(() => {
                            refetch()
                            setShowAddActivityModal(false)
                            setAddActivitySelectedCourse(undefined)
                          })
                        }}
                        onCancel={() => setShowAddActivityModal(false)}
                      >
                        <p>
                          <Trans id="reports.courses_report.action.add_activity.description">
                            Kies hieronder de opleiding die je bij de
                            activiteiten van de gebruiker wilt toevoegen.
                          </Trans>
                        </p>
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          loading={coursesLoading}
                          options={courses?.fetchBranchAccessibleCourses.results
                            .map((course) => ({
                              label: course.name,
                              value: course._id,
                            }))
                            .filter(
                              (course) =>
                                !data?.courses.some(
                                  (activity: { _id: string }) =>
                                    new ObjectId(activity._id).equals(
                                      course.value
                                    )
                                )
                            )}
                          value={addActivitySelectedCourse}
                          style={{ width: '100%' }}
                          onSelect={(value) =>
                            setAddActivitySelectedCourse(value)
                          }
                        />
                      </Modal>
                      <Button
                        type={'dashed'}
                        onClick={() => {
                          setShowAddActivityModal(true)
                          fetchCourses()
                        }}
                      >
                        <Space>
                          <PlusOutlined />
                          <Trans id="reports.courses_report.action.add_activity.button">
                            Opleidingsactiviteit toevoegen
                          </Trans>
                        </Space>
                      </Button>
                    </div>
                  ) : null}
                </Col>
                <Col xs={24} sm={8}>
                  <div style={{ textAlign: 'right' }}>
                    <Space>
                      <span>
                        <Trans id="reports.courses_report.action.export">
                          Exporteer naar:
                        </Trans>{' '}
                      </span>
                      {['xlsx', 'csv', 'json'].map((type, index) => (
                        <ExportButton
                          key={index}
                          url={`/api/export/user/${userId}/courses`}
                          params={
                            start && end
                              ? {
                                  branch_id:
                                    branchId ||
                                    sessionStorage.getItem('aa_report_filter'),
                                  start,
                                  end,
                                }
                              : {
                                  branch_id:
                                    branchId ||
                                    sessionStorage.getItem('aa_report_filter'),
                                }
                          }
                          type={type}
                          name={
                            data?.user?.name.replaceAll(/\s/g, '_') ||
                            `user_${userId}`
                          }
                          dates={['last_activity', 'completed']}
                          total={[
                            {
                              [t({
                                id: 'reports.export.names.user',
                                message: 'Gebruiker',
                              })]: data?.user?.name,
                              [t({
                                id: 'reports.export.names.courses.in_progress',
                                message: 'Opleidingen - In uitvoering',
                              })]: data?.user?.courses_in_progress,
                              [t({
                                id: 'reports.export.names.courses.completed',
                                message: 'Opleidingen - Voltooid',
                              })]: data?.user?.courses_completed,
                              [t({
                                id: 'reports.export.names.courses.total',
                                message: 'Opleidingen - Totaal',
                              })]: data?.user?.courses_total,
                              [t({
                                id: 'reports.export.names.total_training_duration',
                                message: 'Totale trainingstijd',
                              })]: data?.user?.courses_total_duration,
                            },
                          ]}
                        />
                      ))}
                    </Space>
                  </div>
                </Col>
              </Row>
            )
          }
          return (
            <div style={{ textAlign: 'right' }}>
              <Space>
                <span>
                  <Trans id="reports.courses_report.action.export">
                    Exporteer naar:
                  </Trans>{' '}
                </span>
                {['xlsx', 'csv', 'json'].map((type, index) => (
                  <ExportButton
                    key={index}
                    url={exportable || '/api/export/users'}
                    params={
                      start && end
                        ? {
                            start,
                            end,
                          }
                        : {}
                    }
                    type={type}
                    name="personal"
                    dates={['last_activity', 'completed']}
                  />
                ))}
              </Space>
            </div>
          )
        }}
        loading={loading}
      />
      <Modal
        forceRender
        title={t({
          id: 'reports.courses_report.action.reset.title',
          message:
            'Ben je zeker dat je de vooruitgang van de opleiding voor deze gebruiker wil resetten?',
        })}
        open={showActionModal && selectedAction === 'reset'}
        onCancel={() => setShowActionModal(false)}
        okText={t({
          id: 'actions.continue',
          message: 'Doorgaan',
        })}
        cancelText={t({
          id: 'actions.cancel',
          message: 'Annuleren',
        })}
        okButtonProps={{ loading: resetting }}
        onOk={() => {
          reset({
            variables: {
              user_id: userId,
              branch_id: branchId,
              course_id: selectedTarget?._id,
            },
          }).then(() => {
            setShowActionModal(false)
            refetch()
          })
        }}
      >
        <Trans id="reports.courses_report.action.reset.description">
          Deze actie kan niet ongedaan gemaakt worden.
        </Trans>
      </Modal>
      <Modal
        forceRender
        title={t({
          id: 'reports.courses_report.action.complete.title',
          message:
            'Ben je zeker dat je de opleiding voor deze gebruiker wil voltooien?',
        })}
        open={showActionModal && selectedAction === 'complete'}
        onCancel={() => setShowActionModal(false)}
        okText={t({
          id: 'actions.continue',
          message: 'Doorgaan',
        })}
        cancelText={t({
          id: 'actions.cancel',
          message: 'Annuleren',
        })}
        okButtonProps={{ loading: completing }}
        onOk={() => {
          complete({
            variables: {
              user_id: userId,
              branch_id: branchId,
              course_id: selectedTarget?._id,
            },
          }).then(() => {
            setShowActionModal(false)
            refetch()
          })
        }}
      >
        <Trans id="reports.courses_report.action.complete.description">
          Deze actie kan niet ongedaan gemaakt worden.
        </Trans>
      </Modal>
      <Modal
        forceRender
        title={t({
          id: 'reports.courses_report.action.activity_edit',
          message: 'Activiteit voor opleiding wijzigen',
        })}
        open={showActionModal && selectedAction === 'edit'}
        onCancel={() => setShowActionModal(false)}
        okText={t({
          id: 'actions.update',
          message: 'Wijzigen',
        })}
        cancelText={t({
          id: 'actions.cancel',
          message: 'Annuleren',
        })}
        okButtonProps={{ loading: modifying }}
        confirmLoading={modifying}
        onOk={() => modifyForm.submit()}
      >
        <Form
          key={selectedTarget?._id}
          form={modifyForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={({
            certificationType,
            completed,
          }: {
            certificationType?: string
            completed?: Dayjs
          }) => {
            modify({
              variables: {
                user_id: userId,
                branch_id: branchId,
                course_id: selectedTarget?._id,
                certification_id: certificationType,
                completed: completed?.toISOString(),
              },
            }).then(() => {
              setShowActionModal(false)
              modifyForm.resetFields()
              refetch()
            })
          }}
        >
          <Form.Item
            label={t({
              id: 'reports.courses_report.activity_edit.form.label.certification_type',
              message: 'Certificeringstype',
            })}
            name={'certificationType'}
          >
            <Select
              defaultValue={selectedTarget?.certification_id || null}
              options={[
                {
                  label: t({
                    id: 'reports.courses_report.activity_edit.form.certification_type.none',
                    message: '(geen)',
                  }),
                  value: null,
                },
                ...(selectedTargetCourse?.fetchCourseById.certificationType?.map(
                  (type) => ({
                    label: type.name,
                    value: type._id,
                  })
                ) || []),
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'reports.courses_report.activity_edit.form.label.completed',
              message: 'Voltooid op',
            })}
            name={'completed'}
            hidden={!selectedTarget?.completed}
          >
            <DatePicker
              style={{ width: '100%' }}
              defaultValue={dayjs(selectedTarget?.completed)}
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
