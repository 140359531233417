import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 24px;
`
export const LPPaginationWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
`
