import { t } from '@lingui/macro'
import { Statistic, Row, Col } from 'antd'

import { UserEventsReport } from '../../../components/UserEventsReport'

interface EventData {
  events_attended?: number
  events_completed?: number
  events_total?: number
}

interface EventsTabProps {
  data: EventData
  loading: boolean
  userId: string
  branchId: string
  start?: string
  end?: string
}

export const EventsTab = ({
  data,
  loading,
  userId,
  branchId,
  start,
  end,
}: EventsTabProps) => (
  <>
    <Row gutter={16} style={{ textAlign: 'center', marginBottom: 16 }}>
      <Col span={6}>
        <Statistic
          title={t({
            id: 'reports.personal.events_attended',
            message: 'Aanwezig',
          })}
          value={data?.events_attended || 0}
          loading={loading}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title={t({
            id: 'reports.personal.events_completed',
            message: 'Voltooide evenementen',
          })}
          value={data?.events_completed || 0}
          loading={loading}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title={t({
            id: 'reports.personal.events_total',
            message: 'Totaal aantal evenementen',
          })}
          value={data?.events_total || 0}
          loading={loading}
        />
      </Col>
    </Row>
    {userId && (
      <UserEventsReport
        userId={userId}
        branchId={branchId}
        sectionId={null}
        start={start}
        end={end}
        exportable="/api/export/personal/live-events"
      />
    )}
  </>
)
