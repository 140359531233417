export const shortenLanguageCode = (code: string): string => {
  switch (code.toLowerCase()) {
    case 'nl-be': {
      return 'NL'
    }
    case 'fr': {
      return 'FR'
    }
    case 'en': {
      return 'EN'
    }
    default: {
      return code.toUpperCase()
    }
  }
}
