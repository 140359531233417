import { DownOutlined, CalendarOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { DatesSetArg, LocaleSingularArg } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { t, Trans } from '@lingui/macro'
import {
  Button,
  Col,
  Empty,
  Modal,
  Row,
  Segmented,
  Select,
  Space,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(quarterOfYear)
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  EventLocationType,
  LanguagesQuery,
  LiveEventsQuery,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { useAuth } from '../../../auth/hooks/use-auth'
import { LoadScreen, LoadSection } from '../../../core/components/LoadScreen'
import { shortenLanguageCode } from '../../../paths/helpers/shorten-lang-code'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { EventTitle } from '../../components/EventHeader'
import { EventModal } from '../../components/EventModal'
import { isRegistrationActive } from '../../hooks/use-user-event-registration-status.hook'
import EVENTS_QUERY from '../../queries/live-events.graphql'

import LANGUAGES_QUERY from './../../../settings/queries/languages.graphql'
import { FullCalendarWrapper, LoadingOverlay } from './Calendar.style'

export const Calendar = () => {
  const { search } = useLocation()
  const [viewStartDate, setViewStartDate] = useState<Date>()
  const [viewEndDate, setViewEndDate] = useState<Date>()
  const [searchTerm, setSearchTerm] = useQueryParam('q', StringParam)
  const [segment, setSegment] = useQueryParam('segment', StringParam)
  const [selectedEvent, setSelectedEvent] = useQueryParam('event', StringParam)
  const [pageSize] = useState<number>(9999)
  const [currentView, setCurrentView] = useQueryParam('view', StringParam)
  const [params, setParams] = useSearchParams()

  const fullCalenderRef = useRef<FullCalendar>(null)

  const { data: languages } = useQuery<LanguagesQuery>(LANGUAGES_QUERY)

  const { user } = useAuth()
  const ability = useContext(AbilityContext)
  const navigate = useNavigate()

  const { data: events, loading } = useQuery<LiveEventsQuery>(EVENTS_QUERY, {
    variables: {
      query: searchTerm,
      limit: pageSize,
      start: viewStartDate,
      end: viewEndDate,
    },
    skip: !viewStartDate || !viewEndDate,
  })

  const handleDatesSet = useCallback(
    (dateInfo: DatesSetArg) => {
      // Compare with previous values to prevent unnecessary updates
      if (
        !dayjs(viewStartDate).isSame(dateInfo.start) ||
        !dayjs(viewEndDate).isSame(dateInfo.end)
      ) {
        setViewStartDate(dateInfo.start)
        setViewEndDate(dateInfo.end)
      }
    },
    [viewStartDate, viewEndDate, setViewStartDate, setViewEndDate]
  )

  useEffect(() => {
    if (!currentView && localStorage.getItem('aa_calendar_view')) {
      setCurrentView(localStorage.getItem('aa_calendar_view'))
    }
  }, [currentView, setCurrentView])

  const certificationTypes = useMemo(() => {
    const set = new Set(
      events?.fetchLiveEvents?.results
        ?.flatMap(
          (event) =>
            event.certificationType?.map(
              (type) => type.translation?.name || type.name
            )
        )
        .filter((type) => {
          return user?.certificationType?.find(
            (ct) => (ct.translation?.name || ct.name) === type
          )
        })
    )
    return [...set]
  }, [events?.fetchLiveEvents?.results, user?.certificationType])
  const [filter, setFilter] = useState<{
    show: string
    locationType: string | null
    certificationType: string[]
    languages: Array<string>
  }>({
    show: 'all',
    locationType: null,
    certificationType: [],
    languages: user?.lang ? [user.lang] : ['nl-BE'],
  })

  const handleSegmentedChange = useCallback(
    (value: string | number) => {
      if (segment !== value) return
      navigate(`${value === 'all' ? '/library' : ''}/calendar${search}`, {
        replace: true,
      })
      setFilter((prev) => ({ ...prev, show: value as string }))
    },
    [navigate, segment, search]
  )

  const handleLocationChange = (value: string | null) => {
    setFilter((prev) => ({ ...prev, locationType: value }))
  }

  const handleCertificationChange = (values: string[]) => {
    setFilter((prev) => ({ ...prev, certificationType: values }))
  }

  const handleLanguageChange = (values: Array<string>) => {
    setFilter((prev) => ({ ...prev, languages: values }))
  }

  useEffect(() => {
    if (params.get('date')) {
      // navigate to date
      fullCalenderRef.current
        ?.getApi()
        .gotoDate(dayjs(params.get('date')).toDate())
      // remove date from url
      params.delete('date')
      setParams(params)
    }
  }, [params, setParams])

  useEffect(() => {
    if (segment) handleSegmentedChange(segment as string)
  }, [segment, handleSegmentedChange])

  const filteredEvents = useMemo(() => {
    if (!events?.fetchLiveEvents?.results) {
      return []
    }

    const primaryFiltered = events.fetchLiveEvents.results.filter((event) => {
      if (filter.show === 'all') {
        return true // Include all events
      }

      if (filter.show === 'my_registered_events') {
        const active = isRegistrationActive(event.my_registration)
        return active && (!event.approval || event.my_registration?.approved)
      }

      return false
    })

    return primaryFiltered.filter((event) => {
      if (
        filter.locationType &&
        !event.location_type?.includes(filter.locationType as EventLocationType)
      ) {
        return false
      }

      if (filter.certificationType.length > 0) {
        const matchesCertification = filter.certificationType.some(
          (certType) =>
            event.certificationType?.some((type) => type.name === certType)
        )
        if (!matchesCertification) {
          return false
        }
      }

      return !(
        filter.languages?.length > 0 &&
        !filter.languages?.includes(event.language)
      )
    })
  }, [events?.fetchLiveEvents?.results, filter])

  useEffect(() => {
    try {
      const savedFilters = localStorage.getItem('aa_calendar_filters')
      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters)
        setFilter(parsedFilters)
      }
    } catch (error) {
      console.error('Failed to parse filters from localStorage:', error)

      setFilter({
        show: 'all',
        locationType: null,
        certificationType: [],
        languages: user?.lang ? [user?.lang] : ['nl-BE'],
      })
    }
  }, [user?.lang])

  useEffect(() => {
    localStorage.setItem('aa_calendar_filters', JSON.stringify(filter))
  }, [filter])

  function getEventClassNames(event, duration) {
    const classes: Array<string> = []

    // Add class based on location_type
    if (event.location_type?.length === 1) {
      if (event.location_type[0] === EventLocationType.Online) {
        classes.push('event-webinar')
      } else {
        classes.push('event-physical')
      }
    } else {
      classes.push('event-hybrid')
    }

    // Add classes based on duration
    if (duration < 30) classes.push('event-xs')
    if (duration < 40) classes.push('event-sm')

    // Add class for cancelled events
    if (event.cancelled) {
      classes.push('ant-alert-error')
    }

    // Add class for past events
    if (dayjs(event.end).isBefore(dayjs())) {
      classes.push('past-event')
    }

    return classes
  }

  return (
    <>
      <Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 12,
            width: '100%',
          }}
        >
          <Space>
            <Segmented
              options={[
                {
                  label: t({ id: 'events.overview.filter.show_all' }),
                  value: 'all',
                },
                {
                  label: t({
                    id: 'events.overview.filter.my_registered_events',
                  }),
                  value: 'my_registered_events',
                },
              ]}
              value={segment || 'all'}
              onChange={(e) => {
                setSegment(e as string)
                handleSegmentedChange(e)
              }}
            />

            <Select
              placeholder={t({
                id: 'events.overview.filter.select_location_type',
                message: 'Locatietype',
              })}
              style={{ width: 150 }}
              value={filter.locationType}
              onChange={handleLocationChange}
              allowClear
            >
              <Select.Option value={EventLocationType.Physical}>
                {t({
                  id: 'events.overview.filter.physical_events',
                  message: 'Fysiek',
                })}
              </Select.Option>
              <Select.Option value={EventLocationType.Online}>
                {t({
                  id: 'events.overview.filter.online_events',
                  message: 'Online',
                })}
              </Select.Option>
            </Select>

            <Select
              placeholder={t({
                id: 'events.overview.filter.select_certification_type',
                message: 'Certificeringstype',
              })}
              style={{ width: 200 }}
              value={filter.certificationType}
              onChange={handleCertificationChange}
              mode="multiple"
              allowClear
            >
              {certificationTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>

            <Select
              placeholder={t({
                id: 'events.overview.filter.languages',
                message: 'Taal',
              })}
              style={{ width: 150 }}
              value={filter.languages}
              onChange={handleLanguageChange}
              defaultValue={user?.lang ? [user.lang] : ['nl-BE']}
              mode="multiple"
            >
              {languages?.fetchLanguages.map((language) => (
                <Select.Option key={language.code} value={language.code}>
                  {shortenLanguageCode(language.code)}
                </Select.Option>
              ))}
            </Select>
          </Space>

          <Space>
            <InputSearch
              hidden
              key="1"
              placeholder={t({
                id: 'events.search',
                message: 'Zoeken',
              })}
              onSearch={(value) => {
                setSearchTerm(value)
              }}
              style={{ width: 200 }}
            />
            <Can
              I={PermissionAction.CREATE}
              a={PermissionObjectType.LIVE_EVENT}
              key="2"
            >
              <DropdownButton icon={<DownOutlined />} type="primary">
                <Link className={'btn-link'} to={'/events/new'}>
                  <Trans id="events.overview.action.create">
                    Sessie aanmaken
                  </Trans>
                </Link>
              </DropdownButton>
            </Can>
          </Space>
        </div>
      </Row>
      <Row>
        <Col span={24}>
          <FullCalendarWrapper>
            <LoadingOverlay style={{ opacity: events ? 0 : 1 }}>
              <LoadSection />
            </LoadingOverlay>
            <FullCalendar
              ref={fullCalenderRef}
              selectable={ability.can(
                PermissionAction.CREATE,
                PermissionObjectType.LIVE_EVENT
              )}
              selectAllow={(info) => {
                return (
                  dayjs(info.start).isAfter(dayjs()) &&
                  dayjs(info.end)
                    .subtract(1, 'minute')
                    .isSame(dayjs(info.start), 'day')
                )
              }}
              select={(info) => {
                if (
                  !(
                    dayjs(info.start).isAfter(dayjs()) &&
                    dayjs(info.end)
                      .subtract(1, 'minute')
                      .isSame(dayjs(info.start), 'day')
                  )
                )
                  return

                if (info.start.valueOf() === info.end.valueOf()) return

                const timesSelected =
                  dayjs(info.end).diff(dayjs(info.start), 'hour') !== 24

                Modal.confirm({
                  icon: <CalendarOutlined />,
                  title: t({
                    id: 'events.overview.action.create_day',
                    message: 'Sessie aanmaken',
                  }),
                  maskClosable: true,
                  content: timesSelected ? (
                    <>
                      {t({
                        id: 'events.overview.action.create_day_confirm',
                        message: 'Wil je een sessie aanmaken op',
                      })}{' '}
                      {dayjs(info.start).format('D/MM/YYYY')}
                      <br />
                      {t({
                        id: 'events.overview.action.create_day_confirm_from',
                        message: 'van',
                      })}{' '}
                      {dayjs(info.start).format('HH:mm')}
                      {` ${t({
                        id: 'events.overview.action.create_day_confirm_to',
                        message: 'tot',
                      })} `}
                      {dayjs(info.end).format('HH:mm')}?
                    </>
                  ) : (
                    <>
                      {t({
                        id: 'events.overview.action.create_day_confirm',
                        message: 'Wil je een sessie aanmaken op',
                      })}{' '}
                      {dayjs(info.start).format('D/MM/YYYY')}?
                    </>
                  ),
                  onOk: () =>
                    navigate('/events/new', {
                      state: timesSelected
                        ? {
                            date: dayjs(info.start).startOf('day').toDate(),
                            start: dayjs(info.start).toDate(),
                            end: dayjs(info.end).toDate(),
                          }
                        : {
                            date: dayjs(info.start).startOf('day').toDate(),
                          },
                    }),
                })
              }}
              scrollTime={'08:00'}
              allDaySlot={false}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              viewDidMount={({ view }) => {
                localStorage.setItem('aa_calendar_view', view.type)
                setCurrentView(view.type)
              }}
              datesSet={handleDatesSet}
              initialView={
                currentView ??
                localStorage.getItem('aa_calendar_view') ??
                'upcomingList'
              }
              height="calc(100vh - 10rem)"
              headerToolbar={{
                left: 'upcomingList,dayGridMonth,timeGridWeek',
                center: 'title',
                right: 'prev,next today',
              }}
              views={{
                upcomingList: {
                  type: 'list',
                  buttonText: t({
                    id: 'events.overview.quarter',
                    message: 'Lijst',
                  }),
                  visibleRange: (currentDate) => {
                    const date = dayjs(currentDate.valueOf())
                    return {
                      start: date.startOf('month').toDate(),
                      end: date.add(3, 'months').toDate(),
                    }
                  },
                  titleFormat: ({ start, end }) => {
                    const title = dayjs(end?.marker).isSameOrAfter(
                      dayjs(),
                      'hour'
                    )
                      ? t({
                          id: 'events.overview.upcoming_events',
                          message: 'Toekomstige sessies',
                        })
                      : t({
                          id: 'events.overview.past_events',
                          message: 'Afgelopen sessies',
                        })

                    return `${title} (${dayjs(start.marker).format(
                      'MMM YYYY'
                    )} - ${dayjs(end?.marker).format('MMM YYYY')})`
                  },
                  dateIncrement: { months: 3 },
                },
                timeGridWeek: {
                  type: 'timeGridWeek',
                  titleFormat: ({ start, end }) => {
                    if (
                      dayjs(start.marker).isSame(dayjs(end?.marker), 'month')
                    ) {
                      return `${dayjs(start.marker).format('D')} - ${dayjs(
                        end?.marker
                      ).format('D MMMM YYYY')}`
                    }
                    return `${dayjs(start.marker).format(
                      'D MMMM YYYY'
                    )} - ${dayjs(end?.marker).format('D MMMM YYYY')}`
                  },
                },
              }}
              progressiveEventRendering={true}
              events={filteredEvents?.map((event) => {
                const duration = dayjs(event.end).diff(
                  dayjs(event.start),
                  'minutes'
                )
                return {
                  id: event._id,
                  title: event.title,
                  start: event.start,
                  end: event.end,
                  classNames: getEventClassNames(event, duration),
                  finished: dayjs(event.end).isBefore(dayjs()) ? 1 : 0,
                  extendedProps: {
                    location: event.location,
                    lecturer: event.lecturer,
                    catering: event.catering,
                    description: event.description,
                    certificationType: event.certificationType,
                  },
                }
              })}
              eventClick={(event) => {
                setSelectedEvent(event.event.id)
              }}
              eventContent={(eventInfo) => {
                const event = events?.fetchLiveEvents.results?.find(
                  (event) => event._id === eventInfo.event.id
                )
                if (eventInfo.view.type === 'upcomingList') {
                  return (
                    <EventTitle
                      event={
                        event as LiveEventsQuery['fetchLiveEvents']['results'][0]
                      }
                    />
                  )
                }
                return (
                  <Tooltip
                    mouseEnterDelay={1}
                    overlayInnerStyle={{
                      fontSize: 14,
                    }}
                    style={{ width: '100%' }}
                    getPopupContainer={(trigger) =>
                      trigger.parentElement || document.body
                    }
                    overlayStyle={{
                      zIndex: 1100,
                    }}
                    title={
                      eventInfo.view.type === 'dayGridMonth'
                        ? eventInfo.event.title
                        : null
                    }
                  >
                    <div
                      style={{
                        padding:
                          eventInfo.view.type === 'dayGridMonth'
                            ? '0 0.5rem'
                            : '0.25rem 0.5rem',
                        maxWidth: '100%',
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        ...(eventInfo.view.type === 'dayGridMonth' && {
                          backgroundColor: 'var(--fc-event-bg-color)',
                          color: 'var(--fc-event-text-color)',
                          borderRadius: '5px',
                        }),
                      }}
                    >
                      {eventInfo.view.type === 'dayGridMonth' && (
                        <div
                          style={{
                            display: 'inline',
                            marginRight: '0.5rem',
                          }}
                        >
                          {dayjs(eventInfo.event.start).format('HH:mm')}
                        </div>
                      )}
                      <EventTitle
                        event={
                          event as LiveEventsQuery['fetchLiveEvents']['results'][0]
                        }
                        draftIcon={true}
                        hideMode={true}
                        hideLocation={true}
                        hideCertificationType={true}
                      />
                    </div>
                  </Tooltip>
                )
              }}
              noEventsContent={(eventInfo) =>
                loading || !events ? (
                  <LoadScreen />
                ) : segment === 'my_registered_events' ? (
                  <Empty
                    description={
                      <Space direction="vertical">
                        {eventInfo.view.type === 'upcomingList'
                          ? t({
                              id: 'events.registered.empty.quarter',
                              message:
                                'Je bent nog niet ingeschreven voor sessies in deze periode.',
                            })
                          : t({
                              id: 'events.registered.empty',
                              message:
                                'Je bent nog niet ingeschreven voor sessies.',
                            })}
                        <Button onClick={() => setSegment('all')}>
                          Bekijk alle sessies
                        </Button>
                      </Space>
                    }
                  />
                ) : (
                  <Empty
                    description={
                      eventInfo.view.type === 'upcomingList'
                        ? t({
                            id: 'events.overview.empty.quarter',
                            message: 'Geen sessies gevonden in deze periode.',
                          })
                        : t({
                            id: 'events.overview.empty',
                            message: 'Geen sessies gevonden.',
                          })
                    }
                  />
                )
              }
              moreLinkText={(n) =>
                `${n} ${t({
                  id: 'events.overview.more_events',
                  message: 'meer',
                })}`
              }
              dayMaxEvents={2}
              buttonIcons={false}
              buttonText={{
                today: t({
                  id: 'events.overview.today',
                  message: 'Vandaag',
                }),
                month: t({
                  id: 'events.overview.month',
                  message: 'Maand',
                }),
                week: t({
                  id: 'events.overview.week',
                  message: 'Week',
                }),
                day: t({
                  id: 'events.overview.day',
                  message: 'Dag',
                }),
                next: t({
                  id: 'events.overview.next',
                  message: 'Volgende',
                }),
                prev: t({
                  id: 'events.overview.previous',
                  message: 'Vorige',
                }),
                nextYear: t({
                  id: 'events.overview.next_year',
                  message: 'Volgend jaar',
                }),
                prevYear: t({
                  id: 'events.overview.previous_year',
                  message: 'Vorig jaar',
                }),
                list: t({
                  id: 'events.overview.list',
                  message: 'Lijst',
                }),
              }}
              locale={user?.lang as LocaleSingularArg}
              firstDay={1}
            />
          </FullCalendarWrapper>
          {events?.fetchLiveEvents.results.map((event) => (
            <EventModal
              key={event._id}
              event={event}
              modalOpen={event._id === selectedEvent}
              onModalOpen={(event) => setSelectedEvent(event._id)}
              onModalClose={() => setSelectedEvent(undefined)}
            />
          ))}
        </Col>
      </Row>
    </>
  )
}
