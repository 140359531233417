import { t } from '@lingui/macro'
import { Statistic, Row, Col } from 'antd'
import React from 'react'

import { formatDuration } from '@lms-shared-patterns/utils'

import { UserCoursesReport } from '../../../components/UserCoursesReport'

interface CourseData {
  courses_in_progress?: number
  courses_completed?: number
  courses_total?: number
  courses_total_duration?: number
}

interface CourseTabProps {
  data: CourseData
  loading: boolean
  userId: string
  branchId: string
  start?: string
  end?: string
}

export const CoursesTab: React.FC<CourseTabProps> = ({
  data,
  loading,
  userId,
  branchId,
  start,
  end,
}) => (
  <>
    <Row gutter={16} style={{ textAlign: 'center', marginBottom: 16 }}>
      <Col span={6}>
        <Statistic
          title={t({
            id: 'reports.personal.courses_in_progress',
            message: 'Actieve opleidingen',
          })}
          value={data?.courses_in_progress || 0}
          loading={loading}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title={t({
            id: 'reports.personal.courses_completed',
            message: 'Voltooide opleidingen',
          })}
          value={data?.courses_completed || 0}
          loading={loading}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title={t({
            id: 'reports.personal.courses_total',
            message: 'Totaal aantal opleidingen',
          })}
          value={data?.courses_total || 0}
          loading={loading}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title={t({
            id: 'reports.personal.total_training_duration',
            message: 'Totale trainingstijd',
          })}
          value={
            data?.courses_total_duration
              ? formatDuration(data?.courses_total_duration)
              : 0
          }
          loading={loading}
        />
      </Col>
    </Row>
    {userId && (
      <UserCoursesReport
        userId={userId}
        branchId={branchId}
        start={start}
        end={end}
        exportable="/api/export/personal/courses"
      />
    )}
  </>
)
