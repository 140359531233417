import {
  CalendarOutlined,
  ClockCircleOutlined,
  CoffeeOutlined,
  EnvironmentOutlined,
  FundProjectionScreenOutlined,
  LockOutlined,
  UserOutlined,
  EditOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { Space, Tag } from 'antd'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'

import {
  EventLocationType,
  EventMode,
} from '@lms-shared-patterns/enums/event.enums'
import { LiveEventsQuery } from 'apps/lms-front/src/generated/graphql'

import { useAuth } from '../../auth/hooks/use-auth'

import { TitleTagWrapper } from './EventCard.style'

const renderLocationType = (locationType: string) => {
  if (locationType === EventLocationType.Physical)
    return t({ id: 'events.card.physical', message: 'Fysiek' })
  if (locationType === EventLocationType.Online)
    return t({ id: 'events.card.online', message: 'Online' })
  return locationType
}

const renderLocationTagColor = (locationType: string) => {
  if (locationType === EventLocationType.Physical) return 'blue'
  if (locationType === EventLocationType.Online) return 'orange'
  return 'default'
}

export const EventTitle = ({
  event,
  hideLocation,
  hideCertificationType,
  hideMode,
  draftIcon,
}: {
  event: LiveEventsQuery['fetchLiveEvents']['results'][0]
  hideLocation?: boolean
  hideCertificationType?: boolean
  hideMode?: boolean
  draftIcon?: boolean
}) => {
  const auth = useAuth()

  return (
    <>
      {event.cancelled && (
        <StopOutlined className="ant-alert-icon" style={{ marginRight: 8 }} />
      )}
      {event.mode === EventMode.Closed && !hideMode && (
        <LockOutlined style={{ marginRight: 8 }} />
      )}
      {event.published ? null : draftIcon ? (
        <EditOutlined style={{ marginRight: 8 }} />
      ) : (
        `[${t({
          id: 'events.card.draft',
          message: 'Concept',
        })}] `
      )}
      {event.title}
      {!event.cancelled && (
        <TitleTagWrapper>
          {!hideLocation &&
            event.location_type
              .toSorted((a, b) => -a.localeCompare(b))
              .map((locationType) => (
                <Tag
                  key={locationType}
                  color={renderLocationTagColor(locationType)}
                >
                  {renderLocationType(locationType)}
                </Tag>
              ))}
          {!hideCertificationType && event.certificationType?.length
            ? event.certificationType
                ?.filter(
                  (c) =>
                    auth?.user?.certificationType?.find((ct) =>
                      new ObjectId(ct._id).equals(c._id)
                    )
                )
                .map((c) => (
                  <Tag key={c._id}>{c.translation?.name || c.name}</Tag>
                ))
            : null}
        </TitleTagWrapper>
      )}
    </>
  )
}

export const EventHeader = ({
  event,
}: {
  event: LiveEventsQuery['fetchLiveEvents']['results'][0]
}) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <h2 style={{ marginBottom: 0 }}>
        <EventTitle event={event} />
      </h2>
      {event.location && (
        <Space size="middle" wrap style={{ rowGap: 8 }}>
          {event.room && (
            <Space>
              <FundProjectionScreenOutlined />
              {event.room}
            </Space>
          )}
          <Space>
            <EnvironmentOutlined />
            {event.location}
          </Space>
        </Space>
      )}
      <Space size="middle" wrap style={{ rowGap: 8 }}>
        {event.lecturer && (
          <Space>
            <UserOutlined />
            {event.lecturer}
          </Space>
        )}
        {event.start && (
          <Space>
            <CalendarOutlined />
            {dayjs(event.start).format('DD MMMM YYYY')}
          </Space>
        )}
        {event.start && (
          <Space>
            <ClockCircleOutlined />
            <span>
              {dayjs(event.start).format('HH:mm')}
              {event.end && String(' - ' + dayjs(event.end).format('HH:mm'))}
            </span>
          </Space>
        )}
        {event.catering && (
          <Space>
            <CoffeeOutlined />
            <Trans id="events.card.catering">Catering voorzien</Trans>
          </Space>
        )}
      </Space>
    </Space>
  )
}
