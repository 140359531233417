/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CloseCircleFilled,
  CloseOutlined,
  DownOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons'
import { Plural, t } from '@lingui/macro'
import { Spin, Input, Select, InputRef } from 'antd'
import classNames from 'classnames'
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  ReactNode,
} from 'react'
import { FixedSizeList as VList } from 'react-window'
import { v4 as uuidv4 } from 'uuid'

import { LoadSection } from '../../../core/components/LoadScreen'
import { useLanguages } from '../../hooks/use-languages'

export interface ExtendedTreeDataType {
  id: string
  pId?: string
  value: string
  label: string
  title?: ReactNode
  disabled?: boolean
  isLeaf?: boolean
  lang?: string | null
  children?: ExtendedTreeDataType[]
  parents?: ExtendedTreeDataType[] // Now we have multiple parents
  [key: string]: any
}

export interface UltraTreeSelectProps {
  dropdownStyle?: React.CSSProperties
  onDropdownVisibleChange?: (open: boolean) => void
  notFoundContent?: React.ReactNode
  allowClear?: boolean
  multiple?: boolean
  disabled?: boolean
  defaultValue?: string[] | string
  value?: string[] | string
  onChange?: (
    value: string[] | string
    // labelList: React.ReactNode[],
    // extra: any
  ) => void
  onSelectedLeafsChange?: (value: string[]) => void
  onSelect?: (value: any, node: any, extra: any) => void
  treeIcon?: boolean
  treeLine?: boolean
  treeDefaultExpandAll?: boolean
  treeDefaultExpandedKeys?: React.Key[]
  treeCheckable?: boolean
  treeData: ExtendedTreeDataType[]
  treeTitleRender?: (node: ExtendedTreeDataType) => React.ReactNode
  loadData?: (node: ExtendedTreeDataType) => Promise<unknown>
  suffixIcon?: React.ReactNode
  clearIcon?: React.ReactNode
  removeIcon?: React.ReactNode
  switcherIcon?: React.ReactNode
  maxTagCount?: number
  maxTagPlaceholder?:
    | React.ReactNode
    | ((omittedValues: string[]) => React.ReactNode)
  placeholder?: string | React.ReactNode
  loading?: boolean
}

export const UltraPerformantTreeSelect: React.FC<UltraTreeSelectProps> = (
  props
) => {
  const {
    dropdownStyle,
    onDropdownVisibleChange,
    notFoundContent,
    allowClear = true,
    multiple = false,
    disabled = false,
    defaultValue,
    value,
    onChange,
    onSelectedLeafsChange,
    onSelect,
    treeLine,
    treeDefaultExpandAll = false,
    treeDefaultExpandedKeys,
    treeCheckable = true,
    treeData: unpreppedTreeData,
    treeTitleRender,
    loadData,
    removeIcon,
    switcherIcon,
    maxTagCount = 20,
    maxTagPlaceholder = (omitted: string[]) => (
      <Plural
        id="tree-select.max-tag-placeholder"
        one="+ # meer"
        other="+ # meer"
        value={omitted.length}
      />
    ),
    placeholder,
    loading = false,
  } = props

  const listId = useMemo(() => `tree-select-${uuidv4()}`, [])
  const containerRef = useRef<HTMLDivElement | null>(null)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const searchInputRef = useRef<InputRef>(null)
  const { languages } = useLanguages()
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filterLanguage, setFilterLanguage] = useState<string[]>([])
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(() => {
    if (treeDefaultExpandAll) {
      return unpreppedTreeData.map((node) => node.value)
    }
    return treeDefaultExpandedKeys || []
  })
  const [loadingKeys, setLoadingKeys] = useState<React.Key[]>([])
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(
    Array.isArray(defaultValue)
      ? defaultValue
      : defaultValue
      ? [defaultValue]
      : []
  )
  const [containerWidth, setContainerWidth] = useState(0)
  const containerWidthRef = useRef<HTMLDivElement>(null)
  const tagWidthsRef = useRef<Map<string, number>>(new Map())

  // Caching for search
  const searchCacheRef = useRef<Map<string, ExtendedTreeDataType[]>>(new Map())

  // Add multiple parents references
  const treeData = useMemo(() => {
    const data = [...unpreppedTreeData]
    // Initialize
    for (const node of data) {
      node.children = []
      node.parents = []
    }

    // Build references
    // If multiple items share the same id, they'll all get the same parent array
    // and each parent will push them in its own children array
    const lookup = new Map<string, ExtendedTreeDataType[]>()
    data.forEach((node) => {
      if (!lookup.has(node.id)) {
        lookup.set(node.id, [])
      }
      lookup.get(node.id)!.push(node)
    })

    data.forEach((node) => {
      if (!node.pId) return
      // The node's pId might map to multiple parents
      // so we push *all* those parents into node.parents
      const identicalNodes = lookup.get(node.id)
      const parentCandidates = lookup.get(node.pId) || []
      for (const parentCandidate of parentCandidates) {
        for (const identicalNode of identicalNodes!) {
          identicalNode.parents!.push(parentCandidate)
        }
        parentCandidate.children = parentCandidate.children || []
        parentCandidate.children.push(node)
      }
    })

    return data
  }, [unpreppedTreeData])

  /**
   * When searching, expand all nodes
   */
  const treeExpandedKeys = useMemo(() => {
    if (!searchValue.trim()) return expandedKeys
    return treeData.map((t) => t.value)
  }, [searchValue, expandedKeys, treeData])

  useEffect(() => {
    searchCacheRef.current = new Map()
  }, [treeData])

  // Sync state if 'value' changes externally
  useEffect(() => {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        setCheckedKeys(value)
      } else {
        setCheckedKeys(value ? [value] : [])
      }
    }
  }, [value])

  // Build maps for looking up nodes by value and id
  const [valueMap] = useState(() => new Map<string, ExtendedTreeDataType>())
  const [idMap] = useState(() => new Map<string, ExtendedTreeDataType[]>())

  useMemo(() => {
    valueMap.clear()
    idMap.clear()
    treeData.forEach((item) => {
      valueMap.set(item.value, item)
      if (!idMap.has(item.id)) {
        idMap.set(item.id, [])
      }
      idMap.get(item.id)!.push(item)
    })
  }, [treeData, valueMap, idMap])

  // Get root nodes
  const structuredTreeData = useMemo(() => {
    return treeData.filter((item) => !item.pId)
  }, [treeData])

  // Filter with search + language, with caching & early termination
  const filterTree = useCallback(
    (
      nodes: ExtendedTreeDataType[],
      keyword: string
    ): ExtendedTreeDataType[] => {
      const results: ExtendedTreeDataType[] = []
      for (const node of nodes) {
        const matchesSearch =
          !keyword.trim() || node.label.toLowerCase().includes(keyword)
        const matchesLang =
          filterLanguage.length === 0 ||
          (node.lang && filterLanguage.includes(node.lang))

        const filteredChildren =
          node.children && node.children.length > 0
            ? filterTree(node.children, matchesSearch ? '' : keyword)
            : []

        if ((matchesSearch && matchesLang) || filteredChildren.length > 0) {
          results.push({ ...node, children: filteredChildren })
        }
      }
      return results
    },
    [filterLanguage]
  )

  const filteredData = useMemo(() => {
    if (!searchValue.trim() && filterLanguage.length === 0)
      return structuredTreeData
    const cache_key = `${filterLanguage}_${searchValue}`
    if (searchCacheRef.current.has(cache_key)) {
      return searchCacheRef.current.get(cache_key)!
    }
    const filtered = filterTree(structuredTreeData, searchValue?.toLowerCase())
    searchCacheRef.current.set(cache_key, filtered)
    return filtered
  }, [searchValue, structuredTreeData, filterTree, filterLanguage])

  // Build dict for partial checks
  const { nodeDict, childrenMap } = useMemo<{
    nodeDict: Map<string, ExtendedTreeDataType>
    childrenMap: Map<string, string[]>
  }>(() => {
    const nodeDict = new Map<string, ExtendedTreeDataType>()
    const childrenMap = new Map<string, string[]>()
    for (const node of treeData) {
      nodeDict.set(node.id, node)
      if (node.parents && node.parents.length > 0) {
        for (const parent of node.parents) {
          if (!childrenMap.has(parent.id)) {
            childrenMap.set(parent.id, [])
          }
          childrenMap.get(parent.id)!.push(node.id)
        }
      }
    }
    return {
      nodeDict,
      childrenMap,
    }
  }, [treeData])

  // Check state caching
  const [checkedCache] = useState(() => new Map<string, boolean>())

  // Ensure parent/child checks remain in sync
  const updateParentChecks = useCallback(
    (updatedChecked: React.Key[]) => {
      const newCheckedSet = new Set(updatedChecked.map(String))
      checkedCache.clear()
      updatedChecked.forEach((v) => checkedCache.set(String(v), true))

      // Move upwards: if all siblings are checked => check each parent
      for (const val of updatedChecked) {
        const node = valueMap.get(String(val))
        if (!node?.parents?.length) continue
        for (const parent of node.parents) {
          const siblings = parent.children || []
          if (siblings.every((s) => newCheckedSet.has(s.value))) {
            newCheckedSet.add(parent.value)
            checkedCache.set(parent.value, true)
          }
        }
      }

      // Move upwards: if not all siblings are checked => uncheck the parent
      treeData.forEach((node) => {
        if (!node.parents || node.parents.length === 0) return
        for (const parent of node.parents) {
          const siblings = parent.children || []
          const allSiblingsChecked = siblings.every((s) =>
            newCheckedSet.has(s.value)
          )
          if (!allSiblingsChecked) {
            newCheckedSet.delete(parent.value)
            checkedCache.delete(parent.value)
          }
        }
      })

      return [...newCheckedSet]
    },
    [treeData, valueMap, checkedCache]
  )

  // Handle filter language change
  const handleFilterLanguageChange = useCallback((lang: string[]) => {
    setFilterLanguage(lang || [])
  }, [])

  // Gather all descendants to add/remove
  const getDescendants = useCallback((n: ExtendedTreeDataType): string[] => {
    const descendants: string[] = []
    const queue = [...(n.children || [])]
    while (queue.length > 0) {
      const current = queue.shift()!
      if (current.children && current.children.length > 0) {
        queue.push(...current.children)
      }
      if (current !== n) {
        descendants.push(current.value)
      }
    }
    return descendants
  }, [])

  // Handle checking/unchecking
  const handleCheck = useCallback(
    (node: ExtendedTreeDataType, checked: boolean) => {
      if (disabled) return
      let result = checked
        ? [...checkedKeys, node.value]
        : checkedKeys.filter((x) => x !== node.value)

      if (checked) {
        const descendants = getDescendants(node)
        result = [...new Set([node.value, ...result, ...descendants])]
      } else {
        const descendants = getDescendants(node)
        result = result.filter(
          (x) => x !== node.value && !descendants.includes(String(x))
        )
      }
      const final = updateParentChecks(result)
      setCheckedKeys(final)
      // const labels = final.map((k) => valueMap.get(k as string)?.label || k)
      onChange?.(
        multiple ? final.map(String) : String(final[0] || '')
        // labels as string[],
        // { checked, node }
      )
      if (multiple) {
        onSelectedLeafsChange?.(
          final
            .map((k) => valueMap.get(k as string))
            .filter((k) => k?.isLeaf)
            .map((k) => k!.value)
        )
      }
    },
    [
      disabled,
      checkedKeys,
      multiple,
      updateParentChecks,
      onChange,
      onSelectedLeafsChange,
      valueMap,
      getDescendants,
    ]
  )

  // Async load
  const handleLoadData = useCallback(
    async (node: ExtendedTreeDataType) => {
      if (!loadData) return
      const val = node.value
      setLoadingKeys((prev) => [...prev, val])
      try {
        await loadData(node)
      } finally {
        setLoadingKeys((prev) => prev.filter((k) => k !== val))
      }
    },
    [loadData]
  )

  // Expand/collapse
  const handleExpand = useCallback(
    (node: ExtendedTreeDataType) => {
      if (expandedKeys.includes(node.value)) {
        const newKeys = expandedKeys.filter((k) => k !== node.value)
        setExpandedKeys(newKeys)
      } else {
        const newKeys = [...expandedKeys, node.value]
        setExpandedKeys(newKeys)
        if (
          loadData &&
          !node.isLeaf &&
          (!node.children || node.children.length === 0)
        ) {
          handleLoadData(node)
        }
      }
    },
    [expandedKeys, loadData, handleLoadData]
  )

  // Flatten for virtualization
  const flattenNodes = useCallback(
    (nodes: ExtendedTreeDataType[]): ExtendedTreeDataType[] => {
      const flattenWithLevel = (
        list: ExtendedTreeDataType[],
        parentLevel = 0
      ): ExtendedTreeDataType[] => {
        let res: ExtendedTreeDataType[] = []
        for (const nd of list) {
          const nodeWithLevel = { ...nd, level: parentLevel }
          res.push(nodeWithLevel)
          if (treeExpandedKeys.includes(nd.value) && nd.children?.length) {
            res = [...res, ...flattenWithLevel(nd.children, parentLevel + 1)]
          }
        }
        return res
      }
      return flattenWithLevel(nodes)
    },
    [treeExpandedKeys]
  )

  const flatList = useMemo(
    () => flattenNodes(filteredData),
    [filteredData, flattenNodes]
  )

  // Partial map
  const { partialMap } = useMemo(() => {
    const checkedSet = new Set(checkedKeys.map(String))
    const tmpPartial = new Map<string, boolean>()
    const tmpFull = new Map<string, boolean>()

    const dfs = (nodeId: string) => {
      const node = nodeDict.get(nodeId)
      if (!node) return
      const nodeChildren = childrenMap.get(nodeId) || []
      const nodeChecked = checkedSet.has(String(node.value))
      if (nodeChildren.length === 0) {
        tmpFull.set(nodeId, nodeChecked)
        tmpPartial.set(nodeId, false)
        return
      }
      let allDescendantsFull = nodeChecked
      let anyDescendantChecked = nodeChecked
      for (const nodeChild of nodeChildren) {
        dfs(nodeChild)
        if (!tmpFull.get(nodeChild)) {
          allDescendantsFull = false
        }
        if (tmpFull.get(nodeChild) || tmpPartial.get(nodeChild)) {
          anyDescendantChecked = true
        }
      }
      if (allDescendantsFull) {
        tmpFull.set(nodeId, true)
        tmpPartial.set(nodeId, false)
      } else if (anyDescendantChecked) {
        tmpFull.set(nodeId, false)
        tmpPartial.set(nodeId, true)
      } else {
        tmpFull.set(nodeId, false)
        tmpPartial.set(nodeId, false)
      }
    }
    for (const rootNode of treeData.filter((n) => !n.pId)) {
      dfs(rootNode.id)
    }
    return { partialMap: tmpPartial, fullMap: tmpFull }
  }, [treeData, checkedKeys, nodeDict, childrenMap])

  const getAllDescendantValues = useCallback(
    (nodeId: string): string[] => {
      const values: string[] = []
      const queue = [nodeId]
      while (queue.length > 0) {
        const currentId = queue.shift()!
        const node = nodeDict.get(currentId)
        if (node) {
          values.push(node.value)
          if (node.children && node.children.length > 0) {
            for (const child of node.children) {
              queue.push(child.id)
            }
          }
        }
      }
      return values
    },
    [nodeDict]
  )

  const getAllDescendantCount = useCallback(
    (nodeId: string): number => {
      const values: string[] = []
      const queue = [nodeId]
      while (queue.length > 0) {
        const currentId = queue.shift()!
        const node = nodeDict.get(currentId)
        if (node && !values.includes(node.value)) {
          if (node.isLeaf) values.push(node.value)
          if (node.children && node.children.length > 0) {
            for (const child of node.children) {
              queue.push(child.id)
            }
          }
        }
      }
      return values.length
    },
    [nodeDict]
  )

  // Show tags
  const finalValueMemo = useMemo(() => {
    const setVal = new Set(
      treeData
        .filter((t) => checkedKeys.includes(t.value))
        .filter((t) => t.isLeaf)
        .map((t) => t.value)
    )
    const output: string[] = []
    const visited = new Set()

    const getAllAscendants = (
      n: ExtendedTreeDataType
    ): ExtendedTreeDataType[] => {
      const ancestors: ExtendedTreeDataType[] = []
      const queue: ExtendedTreeDataType[] = [n]
      while (queue.length > 0) {
        const current = queue.shift()!
        if (current.parents && current.parents.length > 0) {
          for (const p of current.parents) {
            ancestors.push(p)
            queue.push(p)
          }
        }
      }
      return ancestors
    }

    const isFullyChecked = (n: ExtendedTreeDataType): boolean => {
      if (!n.children || n.children.length === 0) {
        return setVal.has(n.value)
      }
      return n.children.every(
        (child) => setVal.has(child.value) && isFullyChecked(child)
      )
    }

    const walk = (nodes: ExtendedTreeDataType[]) => {
      for (const n of nodes) {
        if (visited.has(n.value)) continue
        visited.add(n.value)

        if (setVal.has(n.value)) {
          // If node is fully checked and not a leaf => label(count)
          if (isFullyChecked(n) && !n.isLeaf) {
            const asc = getAllAscendants(n)
            if (asc.some((a) => setVal.has(a.value))) continue
            const cnt = getAllDescendantCount(n.id)
            output.push(`${n.label} (${cnt})`)
            continue
          }
          // Otherwise walk deeper or add label
          if (n.children?.length) {
            walk(n.children)
          } else if (n.isLeaf) {
            const asc = getAllAscendants(n)
            if (!asc.some((a) => setVal.has(a.value))) {
              output.push(n.label)
            }
          }
        } else if (n.children?.length) {
          walk(n.children)
        }
      }
    }

    walk(treeData.filter((node) => !node.pId))
    return output
  }, [checkedKeys, treeData, getAllDescendantCount])

  const TagItem = useCallback(
    ({ label, style }: { label: string; style: React.CSSProperties }) => {
      const countMatch = label.match(/\((\d+)\)$/)
      const isAllTag = countMatch !== null
      const itemKey = isAllTag ? label.replace(/ \(\d+\)$/, '') : label
      const foundNode = [...valueMap.values()].find((n) => n.label === itemKey)
      const handleRemove = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (disabled) return
        e.stopPropagation()
        let newCheckedKeys: React.Key[] = []
        if (foundNode) {
          if (isAllTag) {
            const allDescendantValues = getAllDescendantValues(foundNode.id)
            newCheckedKeys = checkedKeys.filter(
              (k) => !allDescendantValues.includes(k as string)
            )
          } else {
            newCheckedKeys = checkedKeys.filter((k) => k !== foundNode.value)
          }
        } else {
          newCheckedKeys = checkedKeys.filter((k) => k !== label)
        }
        setCheckedKeys(newCheckedKeys)
        // const newLabels = newCheckedKeys.map(
        //   (k) => valueMap.get(k as string)?.label || k
        // )
        onChange?.(
          multiple
            ? newCheckedKeys.map(String)
            : String(newCheckedKeys[0] || '')
          // newLabels as string[],
          // {}
        )
        if (multiple) {
          onSelectedLeafsChange?.(
            newCheckedKeys
              .map((k) => valueMap.get(k as string))
              .filter((k) => k?.isLeaf)
              .map((k) => k!.value)
          )
        }
      }
      return (
        <span
          className={classNames('ant-select-selection-item', {
            'ant-select-selection-item-disabled': disabled,
          })}
          style={style}
          title={label}
          aria-disabled={disabled}
        >
          <span className="ant-select-selection-item-content">{label}</span>
          {!disabled && (
            <span
              className="ant-select-selection-item-remove"
              onClick={handleRemove}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRemove(e)
                }
              }}
              tabIndex={0}
              role="button"
              aria-label="Remove"
            >
              {removeIcon || <CloseOutlined />}
            </span>
          )}
        </span>
      )
    },
    [
      disabled,
      multiple,
      checkedKeys,
      onChange,
      onSelectedLeafsChange,
      removeIcon,
      valueMap,
      getAllDescendantValues,
    ]
  )

  // Calculate tag widths
  useEffect(() => {
    if (containerWidthRef.current && finalValueMemo.length > 0) {
      const tempDiv = document.createElement('div')
      tempDiv.style.position = 'absolute'
      tempDiv.style.visibility = 'hidden'
      tempDiv.style.whiteSpace = 'nowrap'
      document.body.append(tempDiv)

      let currentLeft = 4
      finalValueMemo.forEach((label) => {
        tempDiv.textContent = label
        const width = Math.min(tempDiv.offsetWidth + 40, containerWidth - 8)
        if (currentLeft + width > containerWidth - 4) {
          currentLeft = 4
        }
        tagWidthsRef.current.set(label, width)
        currentLeft += width + 8
      })
      tempDiv.remove()
    }
  }, [finalValueMemo, containerWidth])

  const handleContainerClick = useCallback(
    (e?: React.MouseEvent) => {
      if (disabled) return
      if (
        e?.target instanceof Element &&
        (e.target.closest('.ant-select-clear') ||
          e.target.closest('.ant-select-selection-item-remove'))
      ) {
        return
      }
      const nextOpen = !open
      if (nextOpen) {
        setOpen(nextOpen)
        onDropdownVisibleChange?.(nextOpen)
        // Focus search input after a small delay to ensure the dropdown is rendered
        setTimeout(() => searchInputRef.current?.focus(), 100)
      }
    },
    [open, onDropdownVisibleChange, disabled]
  )

  const handleClear = useCallback(() => {
    if (disabled) return
    setCheckedKeys([])
    onChange?.(multiple ? [] : '' /*[], {}*/)
  }, [onChange, multiple, disabled])

  // Close dropdown if clicked outside
  useEffect(() => {
    function handleOutsideClick(e: MouseEvent) {
      const target = e.target as HTMLElement
      if (
        containerRef.current?.contains(target) ||
        dropdownRef.current?.contains(target) ||
        target.closest('.ant-select-tree .ant-input')
      ) {
        return
      }
      setOpen(false)
      onDropdownVisibleChange?.(false)
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [onDropdownVisibleChange])

  useEffect(() => {
    if (containerWidthRef.current) {
      setContainerWidth(containerWidthRef.current.offsetWidth)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (containerWidthRef.current) {
        setContainerWidth(containerWidthRef.current.offsetWidth)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const dropdownCls = classNames(
    'ant-select-dropdown',
    'ant-tree-select-dropdown',
    !open && 'ant-select-dropdown-hidden',
    'ant-select-dropdown-placement-bottomLeft'
  )

  // Single row for virtualization
  const Row = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const item = flatList[index]
      if (!item) return null
      const indent = 24 * (item.level || 0)
      const checked = checkedKeys.includes(item.value)
      const isLoading = loadingKeys.includes(item.value)
      const hasChildren =
        !item.isLeaf || (item.children && item.children.length > 0)
      const isSemiChecked = (node: ExtendedTreeDataType) =>
        partialMap.get(node.id)

      return (
        <div
          key={item.id}
          className="ant-select-tree-treenode"
          style={{
            ...style,
            display: 'flex',
            alignItems: 'center',
            height: '28px',
            padding: 0,
            margin: 0,
            left: 0,
            right: 0,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <span
            className="ant-select-tree-indent"
            style={{
              paddingLeft: indent,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          />
          {hasChildren ? (
            <span
              className={classNames(
                'ant-select-tree-switcher',
                treeExpandedKeys.includes(item.value)
                  ? 'ant-select-tree-switcher_open'
                  : 'ant-select-tree-switcher_close'
              )}
              onClick={(e) => {
                e.stopPropagation()
                handleExpand(item)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault()
                  handleExpand(item)
                }
              }}
              role="button"
              tabIndex={0}
              aria-expanded={treeExpandedKeys.includes(item.value)}
              aria-label={
                treeExpandedKeys.includes(item.value) ? 'Collapse' : 'Expand'
              }
              style={{
                marginLeft: 4,
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '0 4px',
              }}
            >
              {isLoading ? (
                <Spin size="small" />
              ) : treeExpandedKeys.includes(item.value) ? (
                switcherIcon || <MinusSquareOutlined />
              ) : (
                switcherIcon || <PlusSquareOutlined />
              )}
            </span>
          ) : (
            <span style={{ width: 16, display: 'inline-block' }} />
          )}
          {treeCheckable && (
            <span
              className={classNames('ant-select-tree-checkbox', {
                'ant-select-tree-checkbox-checked': checked,
                'ant-select-tree-checkbox-indeterminate': isSemiChecked(item),
              })}
              role="checkbox"
              tabIndex={0}
              aria-checked={checked}
              onClick={(e) => {
                e.stopPropagation()
                if (!item.disabled)
                  handleCheck(item, !(checked || isSemiChecked(item)))
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault()
                  if (!item.disabled)
                    handleCheck(item, !(checked || isSemiChecked(item)))
                }
              }}
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '0 4px',
                margin: 0,
              }}
            >
              <span className="ant-select-tree-checkbox-inner" />
            </span>
          )}
          <span
            className={classNames(
              'ant-select-tree-node-content-wrapper',
              treeExpandedKeys.includes(item.value)
                ? 'ant-select-tree-node-content-wrapper-open'
                : 'ant-select-tree-node-content-wrapper-close'
            )}
            onClick={(e) => {
              e.stopPropagation()
              if (treeCheckable) {
                if (!item.disabled) handleCheck(item, !checked)
              } else {
                if (multiple) {
                  const newChecked = !checkedKeys.includes(item.value)
                  const newCheckedKeys = newChecked
                    ? [...checkedKeys, item.value]
                    : checkedKeys.filter((v) => v !== item.value)
                  setCheckedKeys(newCheckedKeys)
                  // const labels = newCheckedKeys.map(
                  //   (k) => valueMap.get(k as string)?.label || k
                  // )
                  onChange?.(
                    multiple
                      ? newCheckedKeys.map(String)
                      : String(newCheckedKeys[0] || '')
                    // labels as string[],
                    // { checked: newChecked, node: item }
                  )
                  onSelect?.(item.value, item, {})
                } else {
                  setCheckedKeys([item.value])
                  onChange?.(
                    item.value
                    // [valueMap.get(item.value)?.label || item.value],
                    // { checked: true, node: item }
                  )
                  onSelect?.(item.value, item, {})
                }
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault()
                if (treeCheckable) {
                  if (!item.disabled) handleCheck(item, !checked)
                } else {
                  if (multiple) {
                    const newChecked = !checkedKeys.includes(item.value)
                    const newCheckedKeys = newChecked
                      ? [...checkedKeys, item.value]
                      : checkedKeys.filter((v) => v !== item.value)
                    setCheckedKeys(newCheckedKeys)
                    // const labels = newCheckedKeys.map(
                    //   (k) => valueMap.get(k as string)?.label || k
                    // )
                    onChange?.(
                      multiple
                        ? newCheckedKeys.map(String)
                        : String(newCheckedKeys[0] || '')
                      // labels as string[],
                      // { checked: newChecked, node: item }
                    )
                    onSelect?.(item.value, item, {})
                  } else {
                    setCheckedKeys([item.value])
                    onChange?.(
                      item.value
                      // [valueMap.get(item.value)?.label || item.value],
                      // { checked: true, node: item }
                    )
                    onSelect?.(item.value, item, {})
                  }
                }
              }
            }}
            role="treeitem"
            tabIndex={0}
            aria-selected={checked}
            title={item.label}
            style={{
              cursor: 'pointer',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              padding: '0 4px',
              flex: 1,
            }}
          >
            <span className="ant-select-tree-title">
              {treeTitleRender ? treeTitleRender(item) : item.label}
            </span>
          </span>
        </div>
      )
    },
    [
      flatList,
      checkedKeys,
      loadingKeys,
      multiple,
      treeCheckable,
      handleExpand,
      handleCheck,
      onChange,
      onSelect,
      switcherIcon,
      treeTitleRender,
      partialMap,
      treeExpandedKeys,
    ]
  )

  return (
    <div
      ref={containerRef}
      className={classNames(
        'ant-select',
        'ant-tree-select',
        'ant-select-in-form-item',
        'ant-select-status-success',
        (treeCheckable || multiple) && 'ant-select-multiple',
        allowClear && 'ant-select-allow-clear',
        disabled && 'ant-select-disabled',
        'ultra-tree-select'
      )}
      style={{ width: '100%', position: 'relative' }}
      onClick={handleContainerClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          handleContainerClick()
        }
      }}
      role="combobox"
      tabIndex={0}
      aria-expanded={open}
      aria-haspopup="tree"
      aria-disabled={disabled}
      aria-controls={listId}
    >
      <div
        className="ant-select-selector"
        style={{ height: 'auto', minHeight: '32px' }}
      >
        {loading ? (
          <div
            className="ant-select-selection-placeholder"
            style={{ color: '#bfbfbf' }}
          >
            {t({
              id: 'action.loading',
              message: 'Aan het laden',
            })}
          </div>
        ) : finalValueMemo.length > 0 ? (
          <span className="ant-select-selection-wrap" style={{ width: '100%' }}>
            <div
              ref={containerWidthRef}
              className="ant-select-selection-overflow"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '2px',
                padding: '2px 4px',
                minHeight: '30px',
                alignItems: 'center',
              }}
            >
              <>
                {finalValueMemo.slice(0, maxTagCount).map((label) => (
                  <TagItem key={label} label={label} style={{}} />
                ))}
                {finalValueMemo.length > maxTagCount && (
                  <span
                    className="ant-select-selection-item"
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: '#f5f5f5',
                      borderRadius: '2px',
                      border: '1px solid #f0f0f0',
                      padding: '0 4px',
                      margin: '2px 4px 2px 0',
                    }}
                  >
                    {typeof maxTagPlaceholder === 'function'
                      ? maxTagPlaceholder(finalValueMemo.slice(maxTagCount))
                      : maxTagPlaceholder}
                  </span>
                )}
              </>
            </div>
          </span>
        ) : (
          <div
            className="ant-select-selection-placeholder"
            style={{ color: '#bfbfbf' }}
          >
            {placeholder}
          </div>
        )}
      </div>
      {allowClear && checkedKeys.length > 0 && (
        <span
          className="ant-select-clear"
          style={{
            position: 'absolute',
            right: 32,
            top: '50%',
            marginTop: -10,
            height: 20,
            lineHeight: '20px',
            fontSize: 12,
            cursor: 'pointer',
            zIndex: 1,
            padding: '0 4px',
          }}
          role="button"
          tabIndex={0}
          onClick={(e) => {
            e.stopPropagation()
            handleClear()
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault()
              e.stopPropagation()
              handleClear()
            }
          }}
        >
          <CloseCircleFilled />
        </span>
      )}
      <span
        className="ant-select-arrow"
        style={{
          position: 'absolute',
          right: 8,
          top: '50%',
          marginTop: -10,
          height: 20,
          lineHeight: '20px',
          padding: '0 4px',
        }}
        role="button"
        tabIndex={0}
        onClick={handleContainerClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            handleContainerClick()
          }
        }}
      >
        <DownOutlined />
      </span>
      <div
        ref={dropdownRef}
        className={dropdownCls}
        style={{
          position: 'absolute',
          left: 0,
          top: '100%',
          width: 'max(40vw, 100%)',
          boxSizing: 'border-box',
          pointerEvents: open ? 'auto' : 'none',
          ...dropdownStyle,
        }}
      >
        <div>
          <div>
            <div
              className={classNames(
                'ant-select-tree',
                treeLine && 'ant-select-tree-show-line'
              )}
            >
              <div>
                <input
                  disabled={disabled}
                  aria-label="for screen reader"
                  value=""
                  style={{
                    width: 0,
                    height: 0,
                    display: 'flex',
                    overflow: 'hidden',
                    opacity: 0,
                    border: 0,
                    padding: 0,
                    margin: 0,
                  }}
                  readOnly
                />
              </div>
              <div
                className="ant-select-tree-treenode"
                aria-hidden="true"
                style={{
                  position: 'absolute',
                  pointerEvents: 'none',
                  visibility: 'hidden',
                  height: 0,
                  overflow: 'hidden',
                  border: 0,
                  padding: 0,
                }}
              >
                <div className="ant-select-tree-indent">
                  <div className="ant-select-tree-indent-unit" />
                </div>
              </div>
              <div
                role="none"
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                style={{
                  padding: 8,
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 8,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Input
                  className="ant-select-tree-filter"
                  ref={searchInputRef}
                  placeholder={t({
                    id: 'action.search',
                    message: 'Zoeken...',
                  })}
                  disabled={disabled}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  style={{
                    flex: 2,
                  }}
                  allowClear
                />
                <Select
                  mode={'multiple'}
                  style={{ flex: 1 }}
                  placeholder={t({
                    id: 'filter.language',
                    message: 'Taal',
                  })}
                  showSearch={false}
                  className="ant-select-tree-filter"
                  value={filterLanguage}
                  onChange={handleFilterLanguageChange}
                  options={languages.map((l) => ({
                    label: l.name,
                    value: l.code,
                  }))}
                  onClick={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                />
              </div>
              <div
                className="ant-select-tree-list"
                role="tree"
                id={listId}
                style={{ position: 'relative' }}
              >
                <div
                  className="ant-select-tree-list-holder"
                  style={{
                    maxHeight: 334,
                    overflowY: 'auto',
                    overflowAnchor: 'none',
                  }}
                >
                  {loading ? (
                    <LoadSection />
                  ) : flatList.length === 0 ? (
                    notFoundContent ? (
                      <div style={{ paddingLeft: 12 }}>{notFoundContent}</div>
                    ) : (
                      <div style={{ padding: 8 }}>
                        {t({
                          id: 'tree-select.no-content',
                          message: 'Geen items gevonden',
                        })}
                      </div>
                    )
                  ) : (
                    <div
                      className="ant-select-tree-list-holder-inner"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                      }}
                    >
                      <VList
                        height={330}
                        itemCount={flatList.length}
                        itemSize={28}
                        style={{ width: 'fit-content', minWidth: '100%' }}
                        width="100%"
                      >
                        {Row}
                      </VList>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .ultra-tree-select {
            .ant-select-tree-filter.ant-input {
              &:focus,
              &-focused {
                border-color: #4096ff !important;
                box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
                outline: 0;
              }
              
              &:hover {
                border-color: #4096ff !important;
              }
              
              &.ant-input-status-error {
                border-color: #d9d9d9 !important;
                box-shadow: none !important;
                
                &:focus,
                &-focused,
                &:hover {
                  border-color: #4096ff !important;
                  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
                }
              }
            }
          }
        `}
      </style>
    </div>
  )
}
