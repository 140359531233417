import { Timeline as AntTimeline } from 'antd'
import styled, { AnyStyledComponent } from 'styled-components'

export const TimelineItemTitle = styled.h3`
  font-size: 1rem;
  line-height: 125%;
  font-weight: 500;
`

export const TimelineItemDescription = styled.p`
  font-size: 0.875rem;
  line-height: 125%;
  margin-bottom: 0;
`

export const TimelineItem = styled(
  AntTimeline.Item as unknown as AnyStyledComponent
)`
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0;
  }
`

export const InvisibleBlock = styled.div`
  padding: 0.5rem 0;
`

export const Block = styled.div`
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);

  ${({ line }: { line?: boolean }) =>
    line &&
    `
    border-left: 0.25rem solid var(--primary-color);
  `}
`

export const BlockTitle = styled.h3`
  font-size: 1rem;
  line-height: 150%;
  font-weight: 500;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 0.35rem;
  }
`

export const BlockSubtitle = styled.h4`
  font-size: 0.7rem;
  text-transform: uppercase;
  line-height: 100%;
  font-weight: 700;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
`

export const BlockDetails = styled.p`
  font-size: 0.85rem;
  line-height: 125%;
  margin-top: 0;
  margin-bottom: 0;
  color: #555;
  display: flex;
  flex-direction: row;
  gap: 0.35rem;

  span:not(:last-child)::after {
    content: '·';
    margin-left: 0.35rem;
  }
`

export const TimeDetails = styled.p`
  font-size: 0.85rem;
  line-height: 125%;
  margin-top: 1rem;
  margin-bottom: 0;
  color: #555;
  display: flex;
  flex-direction: column;
  gap: 0.35rem;

  span:not(:last-child)::after {
    content: '·';
    margin-left: 0.35rem;
  }
`
