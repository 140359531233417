import { CheckOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { ObjectId } from 'bson'
import { useMemo } from 'react'

import {
  QuizQuestion,
  UserQuizUnitActivity,
} from 'apps/lms-front/src/generated/graphql'

import { useAuth } from '../../../auth/hooks/use-auth'
import { LoadSection } from '../../../core/components/LoadScreen'
import { Table } from '../../components/Table'

export const UserCourseActivityQuizReport = ({
  questions,
  answers,
}: {
  questions: QuizQuestion[]
  answers: UserQuizUnitActivity[]
}) => {
  const { user } = useAuth()

  const data = useMemo(() => {
    return questions.map((question) => {
      const match = answers?.find((answer) =>
        new ObjectId(answer.question_id).equals(question._id)
      )

      const translatedQuestion =
        question.translations?.find((t) => t.language === user?.lang)
          ?.question || question.question

      return {
        ...question,
        question: translatedQuestion,
        user_answers: match?.user_answers?.join(', '),
        correct_answers: match?.correct_answers?.join(', '),
        is_correct: match?.correct,
      }
    })
  }, [questions, answers, user?.lang])
  if (!data?.length) return <LoadSection />
  return (
    <Table
      columns={[
        {
          title: t({
            id: 'reports.quiz_report.table.question',
            message: 'Vraag',
          }),
          dataIndex: 'question',
          key: 'question',
        },
        {
          title: t({
            id: 'reports.quiz_report.table.user_answers',
            message: 'Gegeven antwoord',
          }),
          dataIndex: 'user_answers',
          key: 'user_answers',
          align: 'center' as const,
        },
        {
          title: t({
            id: 'reports.quiz_report.table.correct_answers',
            message: 'Correct antwoord',
          }),
          dataIndex: 'correct_answers',
          key: 'correct_answers',
          align: 'center' as const,
        },
        {
          title: t({
            id: 'reports.quiz_report.table.is_correct',
            message: 'Correct',
          }),
          dataIndex: 'is_correct',
          key: 'is_correct',
          render: (val) => (val ? <CheckOutlined /> : '-'),
          align: 'center' as const,
        },
      ]}
      bordered
      size="small"
      dataSource={data}
      rowKey="_id"
      pagination={false}
    />
  )
}
