import { useQuery } from '@apollo/client'
import { Form, FormInstance } from 'antd'
import dayjs from 'dayjs'

import { EventStep, LiveEventQuery } from 'apps/lms-front/src/generated/graphql'

import {
  DetailsStep,
  PlanningStep,
} from '../../events/pages/edit-live-event/EditLiveEvent'
import { StepsWrapper } from '../../events/pages/edit-live-event/EditLiveEvent.style'
import LIVE_EVENT_QUERY from '../../events/queries/live-event.graphql'

export const StepEditEvent = (props: {
  liveEventForm: FormInstance
  form: FormInstance
  step: EventStep
}) => {
  const custom_live_event = Form.useWatch('custom_live_event', props.form)

  useQuery<LiveEventQuery>(LIVE_EVENT_QUERY, {
    variables: { id: custom_live_event },
    fetchPolicy: 'network-only',
    skip: !custom_live_event,
    onCompleted: (data) => {
      props.liveEventForm.setFieldsValue({
        ...data.fetchLiveEventById,
        start_time: dayjs(data.fetchLiveEventById.start),
        end_time: dayjs(data.fetchLiveEventById.end),
        date: dayjs(data.fetchLiveEventById.start),
        certificationType: data.fetchLiveEventById.certificationType?.map(
          (type) => type._id
        ),
        category: data.fetchLiveEventById.category?.map(
          (category) => category._id
        ),
        participants: [],
        registration_deadline: data.fetchLiveEventById.registration_deadline
          ? dayjs(data.fetchLiveEventById.registration_deadline)
          : undefined,
        location_type: data.fetchLiveEventById.location_type[0],
      })
    },
  })

  return (
    <Form form={props.liveEventForm}>
      <StepsWrapper>
        <PlanningStep
          form={props.liveEventForm}
          oneLocationTypeForced
          hideCapacity
          embedded
        />
        <DetailsStep hideCategory />
      </StepsWrapper>
    </Form>
  )
}
