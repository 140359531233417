import { t } from '@lingui/macro'
import { PageHeader, Tabs } from 'antd'
import dayjs from 'dayjs'
import quarterPlugin from 'dayjs/plugin/quarterOfYear'
import { useContext, useEffect, useMemo } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { AbilityContext } from '../../../auth/components/Can'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { useAxios } from '../../../shared/hooks/use-axios'
import { Content } from '../../../shared/layout/Layout.style'

import { CoursesTab } from './components/CoursesTab'
import { EventsTab } from './components/EventTab'
import { PathsTab } from './components/PathsTab'
dayjs.extend(quarterPlugin)

export const PersonalReport = () => {
  const [start, setStart] = useQueryParam('start', StringParam)
  const [end, setEnd] = useQueryParam('end', StringParam)

  const branch = useBranch()
  const { user } = useAuth()

  const [{ data, loading }] = useAxios({
    url: `/api/activity/users/${user?._id}/general`,
    params:
      start && end
        ? {
            start,
            end,
          }
        : undefined,
  })

  const ability = useContext(AbilityContext)

  const [hierarchyFilter, setHierarchyFilter] = useQueryParam(
    'section',
    StringParam
  )

  useEffect(() => {
    if (
      !hierarchyFilter &&
      ability.can(PermissionAction.READ, PermissionObjectType.OWN_REPORT)
    )
      setHierarchyFilter(sessionStorage.getItem('aa_report_filter'))
  }, [])

  const tabs = useMemo(
    () => [
      {
        label: t({
          id: 'reports.personal.courses',
          message: 'Opleidingen',
        }),
        children: (
          <CoursesTab
            data={data}
            loading={loading}
            userId={user?._id}
            branchId={branch?._id}
            start={start ?? undefined}
            end={end ?? undefined}
          />
        ),
      },
      {
        label: t({
          id: 'reports.personal.events',
          message: 'Evenementen',
        }),
        children: (
          <EventsTab
            data={data}
            loading={loading}
            userId={user?._id}
            branchId={branch?._id}
            start={start ?? undefined}
            end={end ?? undefined}
          />
        ),
      },
      {
        label: t({
          id: 'reports.personal.paths',
          message: 'Leerpaden',
        }),
        children: (
          <PathsTab
            data={data}
            loading={loading}
            userId={user?._id}
            branchId={branch?._id}
            hierarchyFilter={hierarchyFilter}
            start={start ?? undefined}
            end={end ?? undefined}
          />
        ),
      },
    ],
    [data, loading, user, branch, start, end, hierarchyFilter]
  )

  return (
    <Content style={{ backgroundColor: '#FFF' }}>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'reports.personal.title',
          message: 'Persoonlijk rapport',
        })}
        extra={
          <DatePicker.RangePicker
            ranges={{
              [t({
                id: 'datepicker.preset.last_7_days',
                message: 'Laatste 7 dagen',
              })]: [dayjs().add(-7, 'd'), dayjs()],
              [t({
                id: 'datepicker.preset.last_30_days',
                message: 'Laatste 30 dagen',
              })]: [dayjs().add(-30, 'd'), dayjs()],
              [t({
                id: 'datepicker.preset.this_week',
                message: 'Deze week',
              })]: [dayjs().startOf('week'), dayjs().endOf('week')],
              [t({
                id: 'datepicker.preset.this_month',
                message: 'Deze maand',
              })]: [dayjs().startOf('month'), dayjs().endOf('month')],
              [t({
                id: 'datepicker.preset.this_quarter',
                message: 'Dit kwartaal',
              })]: [dayjs().startOf('quarter'), dayjs().endOf('quarter')],
              [t({
                id: 'datepicker.preset.this_year',
                message: 'Dit jaar',
              })]: [dayjs().startOf('year'), dayjs().endOf('year')],
            }}
            allowClear={true}
            allowEmpty={[false, false]}
            defaultValue={[
              start ? dayjs(start) : null,
              end ? dayjs(end) : null,
            ]}
            disabledDate={(current) => {
              return current && current > dayjs().endOf('day')
            }}
            onChange={(dates) => {
              if (dates) {
                setStart(dates[0]?.toISOString())
                setEnd(dates[1]?.toISOString())
              } else {
                setStart(undefined)
                setEnd(undefined)
              }
            }}
            format="DD/MM/YYYY"
          />
        }
      ></PageHeader>
      <Content>
        <Tabs
          tabPosition={'left'}
          items={tabs.map((_, i) => {
            const id = String(i + 1)
            return {
              label: `${_.label}`,
              key: id,
              children: _.children,
            }
          })}
          tabBarStyle={{
            marginLeft: '-1.5rem',
          }}
        />
      </Content>
    </Content>
  )
}
