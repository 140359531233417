import styled from 'styled-components'

export const CalendarWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 0.5rem 1rem 1rem;
`

export const FullCalendarWrapper = styled.div`
  transition: opacity 200ms;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;

  a {
    text-decoration: none;
  }

  /* Reset FullCalendar's button styles completely */
  .fc {
    /* Container styles */
    --fc-button-bg-color: #ffffff;
    --fc-button-border-color: #d9d9d9;
    --fc-button-text-color: rgba(0, 0, 0, 0.85);
    --fc-button-hover-bg-color: #ffffff;
    --fc-button-hover-border-color: var(--ant-primary-color);
    --fc-button-hover-text-color: var(--ant-primary-color);
    --fc-button-active-bg-color: var(--ant-primary-color);
    --fc-button-active-border-color: var(--ant-primary-color);
    --fc-button-active-text-color: #ffffff;
  }

  /* Base button styles */
  .fc .fc-button {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 2px !important;
    border: 1px solid var(--fc-button-border-color) !important;
    background: var(--fc-button-bg-color) !important;
    padding: 4px 15px !important;
    font-size: 14px !important;
    height: 32px !important;
    line-height: 1.5715 !important;
    margin: 0 4px !important;
    color: var(--fc-button-text-color) !important;
    transition: all 0.3s !important;
    text-transform: none !important; /* Prevents uppercase transformation */
    box-shadow: none !important; /* Removes default shadows */

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    &:hover {
      color: var(--fc-button-hover-text-color) !important;
      border-color: var(--fc-button-hover-border-color) !important;
      background: var(--fc-button-hover-bg-color) !important;
      text-decoration: none !important;
    }

    &.fc-button-active {
      color: var(--fc-button-active-text-color) !important;
      border-color: var(--fc-button-active-border-color) !important;
      background: var(--fc-button-active-bg-color) !important;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.25) !important;
      border-color: #d9d9d9 !important;
      background: #f5f5f5 !important;
      cursor: not-allowed !important;
    }
  }

  /* Today button specific styles */
  .fc .fc-today-button {
    background: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
    color: #fff !important;
    border-radius: 5px !important;

    &:hover:not(:disabled) {
      background: var(--ant-primary-color) !important;
      border-color: var(--ant-primary-color) !important;
      color: #fff !important;
    }

    &:disabled {
      background: #f5f5f5 !important;
      border-color: #d9d9d9 !important;
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }

  /* Button group styles */
  .fc .fc-button-group {
    gap: 0 !important;
    border-radius: 5px !important;

    .fc-button {
      margin: 0 !important;

      &:first-child {
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
      }

      &:last-child {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
    }

    .fc-button + .fc-button {
      margin-left: -1px !important;
    }
  }

  /* Toolbar container */
  .fc .fc-toolbar {
    flex-wrap: wrap !important;
    gap: 8px !important;
  }

  /* Title styles */
  .fc .fc-toolbar-title {
    font-size: 24px !important;
    font-weight: 500 !important;
  }

  /* Target specifically the date numbers in day cells */
  .fc .fc-daygrid-day-number,
  .fc .fc-timegrid-slot-label {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      sans-serif !important;
    font-variant-numeric: tabular-nums !important;
    text-align: center !important;
  }

  .fc .fc-daygrid-event {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  /* If you also want to remove the spacing where the dot was */
  .fc .fc-list-event-graphic {
    display: none !important;
  }

  .fc .fc-more-link {
    width: 100%;
    line-height: 1.7 !important;
    padding: 0 0.5rem !important;
  }

  .fc .fc-popover {
    max-width: 300px !important;
  }

  .fc .fc-popover-body {
    padding: 1px 0 !important;
  }

  .fc .fc-popover-header {
    white-space: normal !important;
  }

  .fc .fc-event {
    cursor: pointer;
  }

  .fc .event-xs .fc-event-main div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 50% !important;
  }

  .fc .event-sm .fc-event-main div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .fc .fc-list-table tbody > tr th {
    z-index: 1;
  }

  .ant-alert-error div {
    background-color: var(--ant-error-color-deprecated-bg) !important;
    color: var(--text-color) !important;
  }

  .fc-list-table .past-event {
    color: rgba(0, 0, 0, 0.4) !important;
  }
`

export const LoadingOverlay = styled.div`
  transition: opacity 100ms;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`
