/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  PageHeader,
  Space,
  Table,
  Tooltip,
  Row,
  Col,
  Statistic,
  notification,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FilterValue } from 'antd/lib/table/interface'
import axios from 'axios'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'
import quarterPlugin from 'dayjs/plugin/quarterOfYear'
import { useContext, useEffect, useMemo, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { formatDuration } from '@lms-shared-patterns/utils'
import {
  CertificationType,
  CertificationTypesQuery,
  CompletedCoursesQuery,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { useHierarchyTree } from '../../../branch/hooks/use-hierarchy-tree'
import { LoadSection } from '../../../core/components/LoadScreen'
import { setSessionStorageItem } from '../../../core/utils/session-storage'
import CERTIFICATION_TYPES from '../../../settings/queries/certification-types.graphql'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { ExportButton } from '../../../shared/components/export-button/ExportButton'
import { TreeSelect } from '../../../shared/components/tree-select/TreeSelect'
import { useAxios } from '../../../shared/hooks/use-axios'
import { Content } from '../../../shared/layout/Layout.style'

dayjs.extend(quarterPlugin)

type CertificateDataItem = CompletedCoursesQuery['fetchCompletedCourses'][0] & {
  user: { name: string; _id: string }
}

export const CertificatesReport = () => {
  const [start, setStart] = useQueryParam('start', StringParam)
  const [end, setEnd] = useQueryParam('end', StringParam)

  const [filters, setFilters] = useState<Record<string, FilterValue | null>>()

  const [hierarchyFilter, setHierarchyFilter] = useQueryParam(
    'section',
    StringParam
  )
  const { data: treeData, loading: treeLoading } = useHierarchyTree({
    filterByPermission: {
      action: PermissionAction.READ,
      object: PermissionObjectType.BRANCH_REPORT,
    },
  })

  const ability = useContext(AbilityContext)
  const certificationTypeFilter: string[] = useMemo(() => {
    if (!filters) return []
    return (filters['certification_type'] as string[]) || ([] as string[])
  }, [filters])

  const [{ data, loading }] = useAxios<CertificateDataItem[]>({
    url: `/api/activity/certificates`,
    params:
      start && end
        ? {
            start,
            end,
            section_id:
              hierarchyFilter || sessionStorage.getItem('aa_report_filter'),
          }
        : {
            start: dayjs().startOf('year').toDate(),
            end: dayjs().endOf('year').toDate(),
            section_id:
              hierarchyFilter || sessionStorage.getItem('aa_report_filter'),
          },
  })

  const [filteredData, setFilteredData] = useState<CertificateDataItem[]>()

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  useEffect(() => {
    if (
      !hierarchyFilter &&
      (ability.can(PermissionAction.READ, PermissionObjectType.REPORT) ||
        ability.can(PermissionAction.READ, PermissionObjectType.BRANCH_REPORT))
    )
      setHierarchyFilter(sessionStorage.getItem('aa_report_filter'))
  }, [])

  const { data: certificationTypes } = useQuery<CertificationTypesQuery>(
    CERTIFICATION_TYPES,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const certification_duration_columns:
    | ColumnsType<CertificateDataItem>
    | undefined = [
    ...(certificationTypes?.fetchCertificationTypes
      ?.reduce<Map<string, ColumnsType<CertificateDataItem>[0]>>(
        (acc, certification) => {
          const key = `${certification._id}-duration`

          if (acc.has(certification.name)) {
            const existingColumn = acc.get(certification.name)
            existingColumn!.key = `${existingColumn!.key},${key}`
            const originalRender = existingColumn!.render

            existingColumn!.render = (_: number, cur: CertificateDataItem) => {
              const duration = cur?.duration
                ? cur.duration * 60
                : cur.calcDuration || 0
              if (
                new ObjectId(
                  cur.my_activity?.certification_type?._id as string
                ).equals(certification._id)
              ) {
                return formatDuration(duration)
              }
              return originalRender?.(_, cur, _)
            }
          } else {
            if (
              data?.some((x) =>
                new ObjectId(
                  x.my_activity?.certification_type?._id as string
                ).equals(certification._id)
              )
            )
              acc.set(certification.name, {
                title: certification.name,
                dataIndex: ['my_activity', 'duration'],
                key,
                align: 'center' as ColumnsType['0']['align'],
                render: (_: number, cur: CertificateDataItem) => {
                  const duration = cur?.duration
                    ? cur.duration * 60
                    : cur.calcDuration || 0
                  return new ObjectId(
                    cur.my_activity?.certification_type?._id
                  ).equals(certification._id)
                    ? formatDuration(duration)
                    : null
                },
              })
          }

          return acc
        },
        new Map()
      )
      ?.values() || []),
  ]

  const duration_reducer = (prev: number, cur: CertificateDataItem) => {
    const duration = cur?.duration ? cur?.duration * 60 : cur.calcDuration || 0
    return prev + duration
  }

  const total_training_duration = useMemo(() => {
    return filteredData?.reduce(duration_reducer, 0)
  }, [filteredData])

  const training_duration = useMemo(() => {
    return filteredData
      ?.filter(
        (course) =>
          course.my_activity && !course.my_activity?.certification_type
      )
      .reduce(duration_reducer, 0)
  }, [filteredData])

  const filteredCertificatesType = useMemo(() => {
    return certificationTypes?.fetchCertificationTypes.reduce(
      (acc: CertificationType[], current) => {
        const x = acc.find((item) => item.name === current.name)
        return x ? acc : [...acc, current]
      },
      []
    )
  }, [certificationTypes?.fetchCertificationTypes])

  return (
    <Content style={{ backgroundColor: '#FFF' }}>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'reports.certificates.title',
          message: 'Attesten',
        })}
        extra={
          <Space direction="horizontal">
            <DatePicker.RangePicker
              ranges={{
                [t({
                  id: 'datepicker.preset.last_7_days',
                  message: 'Laatste 7 dagen',
                })]: [dayjs().add(-7, 'd'), dayjs()],
                [t({
                  id: 'datepicker.preset.last_30_days',
                  message: 'Laatste 30 dagen',
                })]: [dayjs().add(-30, 'd'), dayjs()],
                [t({
                  id: 'datepicker.preset.this_week',
                  message: 'Deze week',
                })]: [dayjs().startOf('week'), dayjs().endOf('week')],
                [t({
                  id: 'datepicker.preset.this_month',
                  message: 'Deze maand',
                })]: [dayjs().startOf('month'), dayjs().endOf('month')],
                [t({
                  id: 'datepicker.preset.this_quarter',
                  message: 'Dit kwartaal',
                })]: [dayjs().startOf('quarter'), dayjs().endOf('quarter')],
                [t({
                  id: 'datepicker.preset.this_year',
                  message: 'Dit jaar',
                })]: [dayjs().startOf('year'), dayjs().endOf('year')],
              }}
              allowClear={false}
              allowEmpty={[false, false]}
              defaultValue={[
                start ? dayjs(start) : dayjs().startOf('year'),
                end ? dayjs(end) : dayjs().endOf('year'),
              ]}
              disabledDate={(current) => {
                return current && current > dayjs().endOf('day')
              }}
              onChange={(dates) => {
                if (dates) {
                  setStart(dates[0]?.toISOString())
                  setEnd(dates[1]?.toISOString())
                }
              }}
              format="DD/MM/YYYY"
            />
            {treeData.length > 0 && (
              <TreeSelect
                placeholder={t({
                  id: 'reports.filter.hierarchy',
                  message: 'Filter op afdeling',
                })}
                treeDefaultExpandAll={true}
                treeLine={true}
                showSearch
                treeDataSimpleMode
                style={{ width: 250 }}
                dropdownMatchSelectWidth={false}
                filterTreeNode={(input, option) =>
                  (option.title as string)
                    ?.toLowerCase()
                    .includes(input.toLowerCase())
                }
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: 'auto',
                }}
                treeData={treeData}
                loading={treeLoading}
                allowClear={ability.cannot(
                  PermissionAction.READ,
                  PermissionObjectType.REPORT
                )}
                treeNodeLabelProp="label"
                value={hierarchyFilter}
                onChange={(value) => {
                  setSessionStorageItem('aa_report_filter', value)
                  setHierarchyFilter(value)
                }}
              />
            )}
          </Space>
        }
      ></PageHeader>
      <Content>
        {!filteredData && <LoadSection />}
        {filteredData && (
          <>
            <Row
              gutter={16}
              style={{
                textAlign: 'center',
                marginBottom: 16,
                justifyContent: 'center',
              }}
            >
              <Col
                xs={{ span: 12 }}
                md={{ span: 6 }}
                style={{ marginBottom: 24 }}
              >
                <Statistic
                  title={t({
                    id: 'reports.certificates.total_training_duration',
                    message: 'Totale trainingstijd',
                  })}
                  value={formatDuration(total_training_duration || 0)}
                  loading={loading}
                />
              </Col>
              <Col
                xs={{ span: 12 }}
                md={{ span: 6 }}
                style={{ marginBottom: 24 }}
              >
                <Statistic
                  title={t({
                    id: 'reports.certificates.training_duration.default',
                    message: 'Trainingstijd niet-erkend',
                  })}
                  value={formatDuration(training_duration || 0)}
                  loading={loading}
                />
              </Col>
              {filteredCertificatesType
                ?.map((certification) => {
                  const duration = filteredData
                    ?.filter(
                      (course) =>
                        course.my_activity?.certification_type?.name ===
                        certification.name
                    )
                    .reduce(duration_reducer, 0)

                  return {
                    ...certification,
                    duration,
                  }
                })
                .filter(
                  (certification) =>
                    certification.duration && certification.duration > 0
                )
                .map((certification) => {
                  return (
                    <Col
                      key={certification._id}
                      xs={{ span: 12 }}
                      md={{ span: 6 }}
                      style={{ marginBottom: 24 }}
                    >
                      <Statistic
                        title={t({
                          id: 'reports.certificates.training_duration.certification',
                          message: `Trainingstijd ${certification.name}`,
                        })}
                        value={formatDuration(certification.duration || 0)}
                        loading={loading}
                      />
                    </Col>
                  )
                })}
            </Row>
            <Row>
              <Col>
                <Table
                  bordered
                  size="small"
                  loading={loading}
                  dataSource={data}
                  pagination={{
                    pageSize: 50,
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                  onChange={(_, filters, __, extra) => {
                    // @ts-ignore
                    setFilteredData(extra.currentDataSource)
                    setFilters(filters)
                  }}
                  // @ts-ignore
                  rowKey={(record) => `${record._id}-${record.user.email}`}
                  columns={[
                    {
                      title: t({
                        id: 'reports.certificates.table.completed',
                        message: 'Datum',
                      }),
                      dataIndex: ['my_activity', 'completed'],
                      key: 'completed',
                      render: (completed) =>
                        dayjs(completed).format('DD/MM/YYYY'),
                      sortDirections: ['descend', 'ascend'],
                      sorter: (a, b) =>
                        dayjs(a.my_activity?.completed).unix() -
                        dayjs(b.my_activity?.completed).unix(),
                    },
                    {
                      title: t({
                        id: 'reports.certificates.table.user',
                        message: 'Gebruiker',
                      }),
                      dataIndex: ['user', 'name'],
                      key: 'user_name',
                      defaultSortOrder: 'ascend',
                      width: '15%',
                      // @ts-ignore
                      sorter: (a, b) => a.user.name?.localeCompare(b.user.name),
                      sortDirections: ['ascend', 'descend'],
                      render: (text, record) => (
                        // @ts-ignore
                        <Tooltip title={record.user.email}>
                          <span>{text}</span>
                        </Tooltip>
                      ),
                      filters: data
                        ? [
                            ...data.reduce((acc, { user }) => {
                              acc.add(user.name)
                              return acc
                            }, new Set<string>()),
                          ]
                            .map((name) => ({ text: name, value: name }))
                            .sort((a, b) => a.text.localeCompare(b.text))
                        : [],
                      // @ts-ignore
                      onFilter: (value, record) => record.user.name === value,
                    },
                    {
                      title: t({
                        id: 'reports.certificates.table.course',
                        message: 'Opleiding',
                      }),
                      dataIndex: ['name'],
                      key: 'name',
                      sorter: (a, b) => a.name.localeCompare(b.name),
                      width: '30%',
                      ellipsis: true,
                      filters: data
                        ? [
                            ...data.reduce((acc, { name }) => {
                              acc.add(name)
                              return acc
                            }, new Set<string>()),
                          ]
                            .map((name) => ({ text: name, value: name }))
                            .sort((a, b) => a.text.localeCompare(b.text))
                        : [],
                      onFilter: (value, record) => record.name === value,
                    },
                    {
                      title: t({
                        id: 'reports.certificates.table.lecturer',
                        message: 'Docent',
                      }),
                      dataIndex: ['lecturer'],
                      key: 'lecturer',
                      sorter: (a, b) =>
                        (a.lecturer || '').localeCompare(b.lecturer || ''),
                      width: '15%',
                      ellipsis: true,
                    },
                    {
                      title: t({
                        id: 'reports.certificates.table.certification_type',
                        message: 'Type',
                      }),
                      dataIndex: ['my_activity', 'certification_type'],
                      key: 'certification_type',
                      align: 'center' as ColumnsType['0']['align'],
                      render: (certification_type?: { _id: string }) => {
                        const type =
                          certificationTypes?.fetchCertificationTypes.find(
                            (certification) =>
                              new ObjectId(certification._id).equals(
                                certification_type?._id || ''
                              )
                          )
                        return type?.translation?.name || type?.name || null
                      },
                      filters: [
                        /* TODO: replace ITAA-mention with custom translation */
                        {
                          text: t({
                            id: 'reports.certificates.table.certification_type.not_certified',
                            message: 'niet-erkend',
                          }),
                          value: false,
                        },
                        ...(certificationTypes?.fetchCertificationTypes.map(
                          (type) => {
                            return {
                              text: type.translation?.name || type.name,
                              value: type._id,
                            }
                          }
                        ) || []),
                      ],
                      onFilter: (value, record) => {
                        if (
                          value === record.my_activity?.certification_type?._id
                        )
                          return true
                        if (value === false)
                          return !record.my_activity?.certification_type?._id
                        return false
                      },
                      sortDirections: ['descend', 'ascend'],
                      sorter: (a, b) =>
                        (
                          a.my_activity?.certification_type?._id || ''
                        ).localeCompare(
                          b.my_activity?.certification_type?._id || ''
                        ),
                    },
                    {
                      title: t({
                        id: 'reports.certificates.table.total_training_duration',
                        message: 'Trainingstijd',
                      }),
                      children: [
                        /* TODO: replace ITAA-mention with custom translation */
                        {
                          title: t({
                            id: 'reports.certificates.table.total_training_duration.default',
                            message: 'niet-erkend',
                          }),
                          dataIndex: ['duration'],
                          render: (_: number, cur: CertificateDataItem) => {
                            const duration = cur?.duration
                              ? cur?.duration * 60
                              : cur.calcDuration || 0
                            return cur.my_activity?.certification_type
                              ? null
                              : formatDuration(duration)
                          },
                          align: 'center',
                        },
                        ...certification_duration_columns,
                      ],
                    },
                    {
                      title: '',
                      width: 35,
                      align: 'center',

                      render: (_, cur: CertificateDataItem) => {
                        const token = localStorage.getItem('aa_lms_at')
                        return (
                          <>
                            {cur.my_activity?.certificate_url && (
                              <a
                                href={cur.my_activity?.certificate_url}
                                target="_blank"
                                rel="noreferrer"
                                onClick={(e) => {
                                  if (e.shiftKey) {
                                    e.preventDefault()

                                    /** Regenerate certificate **/
                                    axios
                                      .get(
                                        `${
                                          import.meta.env.NX_BACKEND_URL
                                        }/api/certificate/force?user_id=${
                                          cur.user._id
                                        }&branch_id=${cur.my_activity
                                          ?.branch_id}&course_id=${cur._id}`,
                                        {
                                          headers: {
                                            authorization: token
                                              ? `Bearer ${token}`
                                              : '',
                                            'x-academy-host':
                                              window.location.hostname,
                                          },
                                        }
                                      )
                                      .then(() =>
                                        notification.info({
                                          message: t({
                                            id: 'reports.certificates.action.regenerated_certificate',
                                            message:
                                              'Attest succesvol opnieuw gegenereerd.',
                                          }),
                                          icon: <ReloadOutlined />,
                                        })
                                      )
                                  } else {
                                    notification.info({
                                      message: t({
                                        id: 'reports.certificates.action.download_certificate',
                                        message: 'Attest wordt gedownload...',
                                      }),
                                      icon: <DownloadOutlined />,
                                    })
                                  }
                                }}
                              >
                                <DownloadOutlined />
                              </a>
                            )}
                          </>
                        )
                      },
                    },
                  ]}
                  footer={() => {
                    return (
                      <div style={{ textAlign: 'right' }}>
                        <Space>
                          <span>
                            <Trans id="reports.courses_report.action.export">
                              Exporteer naar:
                            </Trans>{' '}
                          </span>
                          {['xlsx', 'csv', 'json', 'zip'].map((type, index) => (
                            <ExportButton
                              key={index}
                              url={`/api/export/certificates`}
                              params={{
                                section_id: hierarchyFilter,
                                start:
                                  start ||
                                  dayjs().startOf('year').toISOString(),
                                end: end || dayjs().endOf('year').toISOString(),
                                certification_type: certificationTypeFilter,
                                user_names: JSON.stringify(
                                  filters?.user_name ?? []
                                ),
                                course_names: JSON.stringify(
                                  filters?.name ?? []
                                ),
                              }}
                              type={type}
                              name="certificates"
                              dates={['completed']}
                            />
                          ))}
                        </Space>
                      </div>
                    )
                  }}
                />
              </Col>
            </Row>
          </>
        )}
      </Content>
    </Content>
  )
}
