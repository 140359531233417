import { t } from '@lingui/macro'
import { Form, Checkbox, Input, Space } from 'antd'
import React from 'react'

interface MailConfigProps {
  name: string
  label: string
  initialValue?: {
    email: string | null
  }
}

export const MailConfig: React.FC<MailConfigProps> = ({
  name,
  label,
  initialValue,
}) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Form.Item
        name={['notifications', name, 'enabled']}
        valuePropName="checked"
        initialValue={initialValue?.email !== null}
        noStyle
      >
        <Checkbox>{label}</Checkbox>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.notifications?.[name]?.enabled !==
          currentValues.notifications?.[name]?.enabled
        }
      >
        {({ getFieldValue }) =>
          getFieldValue(['notifications', name, 'enabled']) ? (
            <Form.Item
              name={['notifications', name, 'email']}
              initialValue={initialValue?.email || ''}
              rules={[
                {
                  required: true,
                  message: t({
                    id: 'settings.branches.form.validation.email',
                    message: 'Gelieve een e-mailadres in te vullen',
                  }),
                },
                {
                  type: 'email',
                  message: t({
                    id: 'settings.branches.form.validation.email_format',
                    message: 'Gelieve een geldig e-mailadres in te vullen',
                  }),
                },
              ]}
            >
              <Input
                placeholder={t({
                  id: 'settings.branches.form.placeholder.email',
                  message: 'E-mailadres',
                })}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name={['notifications', name, 'email']}
              initialValue=""
              hidden
            >
              <Input type="hidden" />
            </Form.Item>
          )
        }
      </Form.Item>
    </Space>
  )
}
