/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { t } from '@lingui/macro'
import { PageHeader } from 'antd'
import dayjs from 'dayjs'
import { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQueryParam, StringParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'

import { AbilityContext } from '../../../auth/components/Can'
import { useHierarchyTree } from '../../../branch/hooks/use-hierarchy-tree'
import { LoadSection } from '../../../core/components/LoadScreen'
import { setSessionStorageItem } from '../../../core/utils/session-storage'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { TreeSelect } from '../../../shared/components/tree-select/TreeSelect'
import { useAxios } from '../../../shared/hooks/use-axios'
import { Content } from '../../../shared/layout/Layout.style'
import { LiveEventUsersReport } from '../../components/EventUsersReport'
import { Table } from '../../components/Table'

export const LiveEventsReport = () => {
  const ability = useContext(AbilityContext)

  const [searchParams] = useSearchParams()
  const [query, setQuery] = useQueryParam('query', StringParam)

  const [hierarchyFilter, setHierarchyFilter] = useQueryParam(
    'section',
    StringParam
  )
  const { data: treeData, loading: treeLoading } = useHierarchyTree({
    filterByPermission: {
      action: PermissionAction.READ,
      object: PermissionObjectType.BRANCH_REPORT,
    },
  })

  const [{ data, loading }] = useAxios<{ _id: string }[]>({
    url: `/api/activity/live-events`,
    params: {
      section_id: hierarchyFilter || sessionStorage.getItem('aa_report_filter'),
      query,
    },
  })

  useEffect(() => {
    if (
      !hierarchyFilter &&
      (ability.can(PermissionAction.READ, PermissionObjectType.REPORT) ||
        ability.can(PermissionAction.READ, PermissionObjectType.BRANCH_REPORT))
    )
      setHierarchyFilter(sessionStorage.getItem('aa_report_filter'))
  }, [])

  return (
    <Content style={{ backgroundColor: '#FFF' }}>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'reports.live_events.title',
          message: 'Evenementen',
        })}
        extra={
          treeData.length > 0 && (
            <TreeSelect
              placeholder={t({
                id: 'reports.filter.hierarchy',
                message: 'Filter op afdeling',
              })}
              treeDefaultExpandAll={true}
              treeLine={true}
              showSearch
              treeDataSimpleMode
              style={{ width: 250 }}
              dropdownMatchSelectWidth={false}
              filterTreeNode={(input, option) =>
                (option.title as string)
                  ?.toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
              }}
              treeData={treeData}
              loading={treeLoading}
              allowClear={ability.cannot(
                PermissionAction.READ,
                PermissionObjectType.REPORT
              )}
              treeNodeLabelProp="label"
              value={hierarchyFilter}
              onChange={(value) => {
                setSessionStorageItem('aa_report_filter', value)
                setHierarchyFilter(value)
              }}
            />
          )
        }
      ></PageHeader>
      <Content>
        {!data && <LoadSection />}
        {data && (
          <Table
            loading={loading}
            expandable={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              expandedRowRender: (record: any) => (
                <LiveEventUsersReport
                  eventId={record._id}
                  sectionId={hierarchyFilter}
                />
              ),
              expandRowByClick: true,
              // @ts-ignore
              rowExpandable: ({ users }) => users > 0,
            }}
            defaultExpandedRowKeys={
              searchParams.get('expand')
                ? [searchParams.get('expand') || '']
                : undefined
            }
            bordered
            size="small"
            dataSource={data}
            pagination={false}
            rowKey="_id"
            columns={[
              {
                title: t({
                  id: 'reports.live_events.table.date',
                  message: 'Datum',
                }),
                sortDirections: ['ascend', 'descend'],
                dataIndex: 'start',
                key: 'start',
                render: (date: string) => dayjs(date).format('DD/MM/YYYY'),
                width: 100,
              },
              {
                title: t({
                  id: 'reports.live_events.table.events',
                  message: 'Evenementen',
                }),
                sortDirections: ['ascend', 'descend'],
                // @ts-ignore
                sorter: (a: { name: string }, b: { name: string }) =>
                  a.name.localeCompare(b.name),
                children: [
                  {
                    title: (
                      <InputSearch
                        placeholder={t({
                          id: 'reports.live_events.table.learning_path.search',
                          message: 'Zoeken op evenement',
                        })}
                        defaultValue={query || ''}
                        onSearch={(query) => {
                          setQuery(query)
                        }}
                        onChange={(e) => {
                          if (e.target.value === '') {
                            setQuery(e.target.value)
                          }
                        }}
                      />
                    ),
                    dataIndex: 'name',
                    key: 'name',
                  },
                ],
              },
              {
                title: t({
                  id: 'reports.live_events.table.users',
                  message: 'Gebruikers',
                }),
                children: [
                  {
                    title: t({
                      id: 'reports.live_events.table.users.registered',
                      message: 'Ingeschreven',
                    }),
                    dataIndex: 'registered_users',
                    key: 'registered_users',
                    align: 'center' as const,
                    sortDirections: ['descend'],
                    // @ts-ignore
                    sorter: (a, b) => a.registered_users - b.registered_users,
                  },
                  {
                    title: t({
                      id: 'reports.live_events.table.users.joined',
                      message: 'Aanwezig',
                    }),
                    dataIndex: 'joined_users',
                    key: 'joined_users',
                    align: 'center' as const,
                    sortDirections: ['descend'],
                    // @ts-ignore
                    sorter: (a, b) => a.attended_users - b.attended_users,
                  },
                  {
                    title: t({
                      id: 'reports.live_events.table.users.completed',
                      message: 'Voltooid',
                    }),
                    dataIndex: 'completed_users',
                    key: 'completed_users',
                    align: 'center' as const,
                    sortDirections: ['descend'],
                    // @ts-ignore
                    sorter: (a, b) => a.completed_users - b.completed_users,
                  },
                ],
              },
            ]}
          />
        )}
      </Content>
    </Content>
  )
}
