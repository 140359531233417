import { MutedOutlined, SoundOutlined, UserOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import {
  FocusLayout,
  LayoutContextProvider,
  ParticipantTile,
  TrackReference,
  TrackReferenceOrPlaceholder,
  VideoTrack,
  useLocalParticipant,
  useLocalParticipantPermissions,
  useParticipants,
  usePersistentUserChoices,
  useRoomContext,
  useRoomInfo,
  useTracks,
} from '@livekit/components-react'
import '@livekit/components-styles'
import { useLocalStorage } from '@uidotdev/usehooks'
import { Avatar, Button, Empty, Space, Tooltip } from 'antd'
import { ObjectId } from 'bson'
import {
  LocalVideoTrack,
  Participant,
  RemoteTrackPublication,
  Track,
} from 'livekit-client'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useMediaQuery } from 'apps/lms-front/src/modules/shared/hooks/use-media-query'

import { useStream } from '../../../../contexts/StreamContext'
import { useBackgroundProcessor } from '../../backgrounds/BackgroundProcessor'
import { AttentionCheckModal } from '../attention-modal/AttentionModal'
import { Chat } from '../chat/Chat'
import { ControlPanel } from '../control-panel/ControlPanel'
import { ParticipantList } from '../participants-list/ParticipantList'
import { SurveyModal } from '../survey-modal/SurveyModal'

import {
  ControlPanelWrapper,
  ControlsOverlay,
  DotBorder,
  LayoutContainer,
  MainScreen,
  PlaceholderContainer,
  RecordingContent,
  RecordingDot,
  RecordingIndicator,
  SideBar,
  SideBarTile,
  SideBarTiles,
} from './VideoConference.style'

export const VideoConference = () => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const { localParticipant } = useLocalParticipant()
  const participants = useParticipants()
  const permission = useLocalParticipantPermissions()
  const room = useRoomContext()
  const roomInfo = useRoomInfo()

  const { backgroundProcessor } = useBackgroundProcessor()

  const { userChoices: initialUserChoices } = usePersistentUserChoices({
    preventSave: false,
    preventLoad: false,
  })

  const [audioDeviceId] = useState<string>(initialUserChoices.audioDeviceId)
  const [videoDeviceId] = useState<string>(initialUserChoices.videoDeviceId)

  const [isChatVisible, setIsChatVisible] = useState(false)
  const [unreadChatCount, setUnreadChatCount] = useState(0)
  const [isAttentionCheckVisible, setIsAttentionCheckVisible] = useState(false)
  const [attentionCheckTimeLeft, setAttentionCheckTimeLeft] = useState(30)
  const countdownTimerRef = useRef<NodeJS.Timeout | null>(null)

  const [virtualBackground, setVirtualBackground] = useLocalStorage(
    'aa_virtual_bg',
    'none'
  )

  const [isScreenSharing, setIsScreenSharing] = useState(false)
  const tracks = useTracks([
    { source: Track.Source.Camera, withPlaceholder: true },
    { source: Track.Source.Microphone, withPlaceholder: true },
    { source: Track.Source.ScreenShare, withPlaceholder: false },
  ])

  const attentionCheckDuration = 30 // 30 seconds for attention check

  const {
    callId,
    emit,
    connected,
    setMainParticipant,
    mainParticipant,
    pendingMainParticipantId,
    setPendingMainParticipantId,
    leaveRoom,
    chatParticipants,
    setCanEnableCamera,
    setCanEnableMicrophone,
    isSurveyModalVisible,
    setLocalParticipant,
    isMeetingHost,
    duration,
  } = useStream()

  const isRecording = useMemo(
    () =>
      roomInfo?.metadata
        ? JSON.parse(roomInfo?.metadata as string).isRecording
        : false,
    [roomInfo]
  )

  const findParticipantById = useCallback(
    (participantId: string) => {
      const matched =
        participants.find((p) =>
          p.identity ? new ObjectId(p.identity).equals(participantId) : false
        ) ||
        (localParticipant.identity &&
        new ObjectId(localParticipant.identity).equals(participantId)
          ? localParticipant
          : null)
      return matched || null
    },
    [participants, localParticipant]
  )

  const handleAttentionConfirm = useCallback(() => {
    setIsAttentionCheckVisible(false)
    setAttentionCheckTimeLeft(attentionCheckDuration)
    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current)
    }
    emit('attentionCheckPassed', {})
  }, [emit, attentionCheckDuration])

  const startAttentionCheck = useCallback(() => {
    setIsAttentionCheckVisible(true)
    setAttentionCheckTimeLeft(30)
    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current)
    }
    countdownTimerRef.current = setInterval(() => {
      setAttentionCheckTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(countdownTimerRef.current as NodeJS.Timeout)
          setIsAttentionCheckVisible(false)
          leaveRoom({ attention_check_failed: true })
          return 30
        }
        return prev - 1
      })
    }, 1000)
  }, [leaveRoom])

  useEffect(() => {
    if (isMeetingHost) return
    const attentionCheckTimer = setInterval(startAttentionCheck, duration / 2.5)
    return () => {
      clearInterval(attentionCheckTimer)
      if (countdownTimerRef.current) {
        clearInterval(countdownTimerRef.current)
      }
    }
  }, [isMeetingHost, startAttentionCheck])

  useEffect(() => {
    if (room) {
      setCanEnableCamera(!!permission?.canPublish)
      setCanEnableMicrophone(!!permission?.canPublish)
    }
  }, [permission, room, setCanEnableCamera, setCanEnableMicrophone])

  const setHostAsMainParticipant = useCallback(() => {
    const hostTrack = participants.find(
      (p) => JSON.parse(p?.metadata as string)?.isHost
    )
    if (hostTrack) setMainParticipant(hostTrack)
  }, [participants, setMainParticipant])

  useEffect(() => {
    if (localParticipant) {
      setLocalParticipant(localParticipant)
    }
    if (pendingMainParticipantId) {
      const id = findParticipantById(pendingMainParticipantId)
      if (id) {
        setMainParticipant(id)
        setPendingMainParticipantId(null)
      }
    } else if (
      !mainParticipant &&
      participants.length > 0 &&
      localParticipant?.identity
    ) {
      if (
        localParticipant?.metadata &&
        (() => {
          try {
            return JSON.parse(localParticipant.metadata).isHost
          } catch (error) {
            console.error('Invalid metadata JSON:', error)
            return false
          }
        })()
      ) {
        setMainParticipant(localParticipant)
        emit('setMainParticipant', {
          call_id: callId,
          participantId: localParticipant.identity,
        })
      } else {
        setHostAsMainParticipant()
      }
    }
  }, [
    tracks,
    mainParticipant,
    localParticipant,
    participants,
    pendingMainParticipantId,
    setPendingMainParticipantId,
    setHostAsMainParticipant,
    setMainParticipant,
    findParticipantById,
    chatParticipants,
    callId,
    emit,
  ])

  const handleSetMainParticipant = useCallback(
    (participant: Participant) => {
      if (participant !== mainParticipant) {
        setMainParticipant(participant)
        if (isMeetingHost && connected) {
          emit('setMainParticipant', {
            call_id: callId,
            participantId: participant.identity,
          })
        }
      }
    },
    [
      mainParticipant,
      setMainParticipant,
      isMeetingHost,
      connected,
      callId,
      emit,
    ]
  )

  useEffect(() => {
    const camTrack = localParticipant.getTrackPublication(Track.Source.Camera)
      ?.track as LocalVideoTrack | undefined
    const currentProcessor = camTrack?.getProcessor()
    if (
      backgroundProcessor &&
      (!currentProcessor || currentProcessor.name !== backgroundProcessor.name)
    ) {
      camTrack?.setProcessor(backgroundProcessor)
    } else if (!backgroundProcessor && currentProcessor) {
      camTrack?.stopProcessor()
    }
  }, [backgroundProcessor, localParticipant])

  useEffect(() => {
    async function setupLocalParticipant() {
      if (!localParticipant) return
      if (!isMeetingHost) {
        await localParticipant.setCameraEnabled(false)
        await localParticipant.setMicrophoneEnabled(false)
      } else if (backgroundProcessor) {
        try {
          if (audioDeviceId) {
            console.log('Switching audio device to:', audioDeviceId)
            await room.switchActiveDevice('audioinput', audioDeviceId)
          }

          await localParticipant.setCameraEnabled(true, {
            deviceId: videoDeviceId,
            processor: backgroundProcessor,
          })
        } catch (error) {
          console.error('Error setting up media devices:', error)
        }
      }
    }
    setupLocalParticipant()
  }, [
    localParticipant,
    isMeetingHost,
    backgroundProcessor,
    videoDeviceId,
    audioDeviceId,
    room,
  ])

  const relevantTrackInformation = useMemo(() => {
    return tracks.map((t) => ({
      id: t.participant.identity,
      source: t.source,
      enabled:
        t.participant?.isCameraEnabled && t.participant.isMicrophoneEnabled,
      desired:
        t.publication instanceof RemoteTrackPublication
          ? t.publication.isDesired
          : isMeetingHost,
    }))
  }, [tracks, isMeetingHost])

  const sidebarTracks = useMemo(() => {
    const map = new Map<Participant, TrackReference>()
    tracks.forEach((track) => {
      const isMain =
        mainParticipant?.identity &&
        track.participant.identity &&
        new ObjectId(track.participant.identity).equals(
          mainParticipant.identity
        )

      if (
        (!isMain &&
          track.source === Track.Source.Camera &&
          !map.has(track.participant)) ||
        (isScreenSharing && track.source === Track.Source.Camera)
      ) {
        map.set(track.participant, track as TrackReference)
      }
    })
    return [...map.values()].sort((a, b) => {
      /**
       * Sorting rules:
       * - Main participant is always on top
       * - Next, the enabled participants
       * - Next, the desired participants
       * - Finally, the other participants
       */
      if (!a.publication && !b.publication) return 0
      if (!a.publication) return 1
      if (!b.publication) return -1

      const a_enabled =
        a.participant.isCameraEnabled && a.participant.isMicrophoneEnabled
      const b_enabled =
        b.participant.isCameraEnabled && b.participant.isMicrophoneEnabled

      const a_desired =
        a.publication instanceof RemoteTrackPublication
          ? a.publication.isDesired
          : isMeetingHost
      const b_desired =
        a.publication instanceof RemoteTrackPublication
          ? a.publication.isDesired
          : isMeetingHost

      if (a_desired && !b_desired) return -1
      if (!a_desired && b_desired) return 1
      if (a_enabled && !b_enabled) return -1
      if (!a_enabled && b_enabled) return 1
      return 0
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relevantTrackInformation, tracks, mainParticipant, isMeetingHost])

  const numberOfVideoSharingParticipants = useMemo(() => {
    return tracks.filter(
      (t) => t.participant?.isCameraEnabled && t.participant.isMicrophoneEnabled
    ).length
  }, [tracks])

  useEffect(() => {
    setIsScreenSharing(
      !!tracks.some((track) => track.source === Track.Source.ScreenShare)
    )
  }, [tracks])

  const renderMainContent = () => {
    const screenShareTrack = tracks.find(
      (track) => track.source === Track.Source.ScreenShare
    )
    if (screenShareTrack) {
      return (
        <>
          <FocusLayout
            trackRef={screenShareTrack as TrackReferenceOrPlaceholder}
          />
          <ParticipantTile
            key={`screenshare-${screenShareTrack.participant.name}`}
            disableSpeakingIndicator
            style={{ width: '100%', height: '100%' }}
            trackRef={screenShareTrack}
          >
            <VideoTrack trackRef={screenShareTrack as TrackReference} />
          </ParticipantTile>
        </>
      )
    }
    if (mainParticipant && !isScreenSharing) {
      const videoTrack = tracks.find(
        (track) =>
          track.source === Track.Source.Camera &&
          track.participant.identity &&
          new ObjectId(track.participant.identity).equals(
            mainParticipant.identity
          )
      )
      if (!videoTrack) return null
      return (
        <>
          <FocusLayout trackRef={videoTrack as TrackReferenceOrPlaceholder} />
          <ParticipantTile
            key={`main-${videoTrack.participant.identity}`}
            style={{ width: '100%', height: '100%' }}
            disableSpeakingIndicator={false}
            trackRef={videoTrack}
          >
            {videoTrack ? (
              <VideoTrack trackRef={videoTrack as TrackReference} />
            ) : (
              <PlaceholderContainer>
                <Avatar size={64} icon={<UserOutlined />} />
                <div>{mainParticipant.name}</div>
              </PlaceholderContainer>
            )}
          </ParticipantTile>
        </>
      )
    }
    return null
  }

  const renderVideoOrPlaceholder = (trackRef: TrackReferenceOrPlaceholder) => {
    return trackRef.publication?.isSubscribed &&
      !trackRef.publication.isMuted ? (
      <VideoTrack trackRef={trackRef} />
    ) : (
      <PlaceholderContainer>
        <Space direction="vertical">
          <Avatar size={64} icon={<UserOutlined />} />
          <div>{trackRef.participant.name}</div>
        </Space>
      </PlaceholderContainer>
    )
  }

  useEffect(() => {
    async function setupAudio() {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
      } catch (error) {
        console.error('Failed to get audio permissions:', error)
      }
    }
    setupAudio()
  }, [])

  const togglePermission = (participant: Participant) => {
    emit('toggleParticipantPermission', {
      participantId: participant.identity,
      call_id: callId,
    })
  }

  const renderParticipantControls = (participant: Participant) => {
    if (participant === localParticipant) return null
    return (
      <ControlsOverlay onClick={(e) => e.stopPropagation()}>
        <Tooltip
          title={
            participant?.permissions?.canPublish
              ? t({
                  id: 'stream.control_panel.action.retrieve_controls',
                  message: 'Video/audio uitschakelen voor deze deelnemer',
                })
              : t({
                  id: 'stream.control_panel.action.enable_controls',
                  message: 'Video/audio inschakelen voor deze deelnemer',
                })
          }
        >
          <Button
            type="link"
            shape={'circle'}
            style={{ fontSize: '1rem', color: 'white' }}
            onClick={() => togglePermission(participant)}
            icon={
              participant?.permissions?.canPublish ? (
                <SoundOutlined />
              ) : (
                <MutedOutlined />
              )
            }
          />
        </Tooltip>
      </ControlsOverlay>
    )
  }
  const getGridTemplateColumns = useCallback(() => {
    return isChatVisible ? '3fr 1fr 300px' : '3fr 1fr'
  }, [isChatVisible])

  return (
    <LayoutContextProvider>
      <LayoutContainer
        style={{
          gridTemplateColumns: getGridTemplateColumns(),
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <MainScreen>
            {isRecording && (
              <RecordingIndicator>
                <RecordingContent>
                  <DotBorder>
                    <RecordingDot />
                  </DotBorder>
                  {t({
                    id: 'video_conference.is_recording',
                    message: 'Rec',
                  })}
                </RecordingContent>
              </RecordingIndicator>
            )}
            {renderMainContent()}

            <ControlPanelWrapper>
              <ControlPanel
                onToggleChat={() => setIsChatVisible((prev) => !prev)}
                isChatVisible={isChatVisible}
                unreadChatCount={unreadChatCount}
                virtualBackground={virtualBackground}
                onVirtualBackgroundChange={setVirtualBackground}
              />
            </ControlPanelWrapper>
          </MainScreen>
        </div>
        {!isMobile && (
          <SideBar>
            <SideBarTiles>
              {sidebarTracks
                .slice(0, Math.max(4, numberOfVideoSharingParticipants))
                .map((track) => {
                  const participantIsPublishing =
                    (track.participant.isCameraEnabled &&
                      track.participant.isMicrophoneEnabled) ||
                    (track?.participant?.metadata &&
                      JSON.parse(track?.participant?.metadata as string).isHost)
                  return (
                    <SideBarTile key={track.participant.identity}>
                      <ParticipantTile
                        disableSpeakingIndicator={false}
                        onClick={
                          isMeetingHost && participantIsPublishing
                            ? () => handleSetMainParticipant(track.participant)
                            : undefined
                        }
                        trackRef={track}
                        style={{
                          cursor:
                            isMeetingHost && participantIsPublishing
                              ? 'pointer'
                              : 'default',
                        }}
                      >
                        {renderVideoOrPlaceholder(track)}
                        {isMeetingHost &&
                          renderParticipantControls(track.participant)}
                      </ParticipantTile>
                    </SideBarTile>
                  )
                })}
              {participants.length < 2 && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t({
                    id: 'video_conference.no_participants',
                    message: 'Voorlopig ben je hier helemaal alleen.',
                  })}
                />
              )}
            </SideBarTiles>
            {isMeetingHost && participants.length > 1 && (
              <ParticipantList
                participants={participants.filter(
                  (p) =>
                    !(
                      p.identity &&
                      new ObjectId(p.identity).equals(localParticipant.identity)
                    )
                )}
                togglePermission={togglePermission}
              />
            )}
          </SideBar>
        )}
        {!isMobile && (
          <Chat
            isVisible={isChatVisible}
            onUnreadCountChange={setUnreadChatCount}
          />
        )}

        {!isMeetingHost && <SurveyModal isModalOpened={isSurveyModalVisible} />}
        <AttentionCheckModal
          isVisible={isAttentionCheckVisible}
          timeLeft={attentionCheckTimeLeft}
          totalTime={30}
          onConfirm={handleAttentionConfirm}
        />
      </LayoutContainer>
    </LayoutContextProvider>
  )
}
