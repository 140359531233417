import { t, Trans } from '@lingui/macro'
import { Button, notification } from 'antd'
import { ObjectId } from 'bson'
import { LocalParticipant, Participant, Room } from 'livekit-client'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

import { FetchMeetingDetailsQuery } from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useSocket } from 'apps/lms-front/src/modules/shared/hooks/use-socket.hook'

interface StreamContextType {
  room: Room | undefined
  setRoom: React.Dispatch<React.SetStateAction<Room | undefined>>
  token: string | undefined
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>
  isRoomActive: boolean
  setIsRoomActive: React.Dispatch<React.SetStateAction<boolean>>
  isMeetingHost: boolean
  isParticipant: boolean
  participantName: string
  isRecording: boolean
  setIsRecording: React.Dispatch<React.SetStateAction<boolean>>
  emit: (event: string, data: unknown) => void
  disconnect: () => void
  connected: boolean
  getToken: () => Promise<string>
  leaveRoom: (opts?: { attention_check_failed?: boolean }) => void
  mainParticipant: Participant | null
  setMainParticipant: React.Dispatch<React.SetStateAction<Participant | null>>
  localParticipant: LocalParticipant | null
  setLocalParticipant: React.Dispatch<
    React.SetStateAction<LocalParticipant | null>
  >
  participants: Participant[]
  setParticipants: React.Dispatch<React.SetStateAction<Participant[]>>
  pendingMainParticipantId: string | null
  setPendingMainParticipantId: React.Dispatch<
    React.SetStateAction<string | null>
  >
  chatParticipants: ChatParticipant[]
  canEnableCamera: boolean
  setCanEnableCamera: React.Dispatch<React.SetStateAction<boolean>>
  canEnableMicrophone: boolean
  setCanEnableMicrophone: React.Dispatch<React.SetStateAction<boolean>>
  callId: string | null
  eventId: string | null
  isSurveyModalVisible: boolean
  setSurveyModalVisibility: React.Dispatch<React.SetStateAction<boolean>>
  surveyCompletionArr: string[]
  setUserCompletionArr: React.Dispatch<React.SetStateAction<string[]>>
  surveyActiveTimer: number
  survey: FetchMeetingDetailsQuery['fetchLiveEventByCallId']['survey']
  isAllParticipantsControlAllowed: boolean
  duration: number
  handRaisedParticipants: string[]
  notificationSound: HTMLAudioElement
}
export type ChatParticipant = {
  _id: string
  firstName: string
  lastName: string
  picture: {
    url: string
    width: number
    height: number
  }
}
const StreamContext = createContext<StreamContextType | undefined>(undefined)

export const StreamProvider: React.FC<{
  callId: string
  eventId: string
  isMeetingHost: boolean
  isParticipant: boolean
  children: React.ReactNode
  isRoomActive: boolean
  chatParticipants: ChatParticipant[]
  survey: FetchMeetingDetailsQuery['fetchLiveEventByCallId']['survey']
  duration: number
}> = ({
  children,
  callId,
  eventId,
  isMeetingHost,
  isParticipant,
  isRoomActive: initialIsRoomActive,
  chatParticipants,
  survey,
  duration,
}) => {
  const navigate = useNavigate()
  const [localParticipant, setLocalParticipant] =
    useState<LocalParticipant | null>(null)
  const [participants, setParticipants] = useState<Participant[]>([])

  const [room, setRoom] = useState<Room | undefined>(undefined)
  const [token, setToken] = useState<string | undefined>(undefined)
  const [isRoomActive, setIsRoomActive] = useState(initialIsRoomActive)
  const [isRecording, setIsRecording] = useState(false)
  const [mainParticipant, setMainParticipant] = useState<Participant | null>(
    null
  )
  const [pendingMainParticipantId, setPendingMainParticipantId] = useState<
    string | null
  >(null)

  const [isSurveyModalVisible, setSurveyModalVisibility] = useState(false)

  const [handRaisedParticipants, setHandRaisedParticipants] = useState<
    string[]
  >([])

  const [canEnableCamera, setCanEnableCamera] = useState(false)
  const [canEnableMicrophone, setCanEnableMicrophone] = useState(false)
  const { user, token: userToken } = useAuth()
  const [surveyCompletionArr, setUserCompletionArr] = useState<string[]>([])
  const [isAllParticipantsControlAllowed, setIsAllParticipantsControlAllowed] =
    useState(false)
  const surveyActiveTimer = 60000 // 1 minute

  const findParticipantById = useCallback(
    (participantId: string) => {
      if (!participantId) return null
      return (
        participants.find(
          (p) => p.identity && new ObjectId(p.identity).equals(participantId)
        ) ||
        (localParticipant?.identity &&
        new ObjectId(localParticipant.identity).equals(participantId)
          ? localParticipant
          : null)
      )
    },
    [participants, localParticipant]
  )

  const localParticipantRef = useRef<LocalParticipant | null>(null)

  useEffect(() => {
    localParticipantRef.current = localParticipant
  }, [localParticipant])
  const notificationSound = useMemo(() => new Audio('/notification.mp3'), [])

  const showHandRaisedNotification = (participantId: string) => {
    const participant = findParticipantById(participantId)
    const key = `notification-${participantId}`
    notificationSound.play()
    notification.open({
      key,
      message: t({
        id: 'stream.control_panel.notification.hand_raised',
        message: 'Hand raised',
      }),
      description: (
        <>
          <p>
            {participant ? (
              <Trans id="stream.control_panel.notification.hand_raised.description.participant">
                {participant.name} heeft zijn hand opgestoken
              </Trans>
            ) : (
              <Trans id="stream.control_panel.notification.hand_raised.description">
                Een deelnemer heeft zijn hand opgestoken
              </Trans>
            )}
          </p>
          <Button
            type="primary"
            onClick={() => {
              emit('toggleParticipantPermission', {
                participantId,
                call_id: callId,
              })
              notification.close(key)
              emit('raiseHand', {
                participantId,
                call_id: callId,
                isHandRaised: false,
              })
            }}
          >
            {t({
              id: 'stream.control_panel.notification.hand_raised.button',
              message: 'Allow control',
            })}
          </Button>
        </>
      ),
      duration: 0,
      onClose: () =>
        emit('raiseHand', {
          participantId,
          call_id: callId,
          isHandRaised: false,
        }),
    })
  }

  const { connected, emit, disconnect } = useSocket('/api/stream/ws', {
    token: userToken,
    transports: ['polling', 'websocket'],
    events: {
      roomIsActivated: (data) => {
        setIsRoomActive(data.is_active)
      },
      mainParticipantChanged: ({ participantId }) => {
        if (participantId) {
          const newMainParticipant = findParticipantById(participantId)

          if (newMainParticipant) {
            setMainParticipant(newMainParticipant)
            setPendingMainParticipantId(null)
          } else {
            setPendingMainParticipantId(participantId)
          }
        }
      },
      roomClosed: () => {
        emit('leaveStream', {
          call_id: callId,
        })
        setIsRoomActive(false)
        setRoom(undefined)
        setToken(undefined)
      },
      surveyStatusChanged: ({ isVisible }) => {
        setSurveyModalVisibility(isVisible)
      },
      participantCompletedSurvey: ({ userIdentity }) => {
        setUserCompletionArr((prev) => {
          if (prev.includes(userIdentity)) return prev
          return [...prev, userIdentity]
        })
      },
      allParticipantsPermissionsChanged: ({ isControlAllowed }) => {
        setIsAllParticipantsControlAllowed(isControlAllowed)
      },
      participantPermissionsChanged: ({ participantId, permissions }) => {
        if (
          !isMeetingHost &&
          localParticipantRef.current?.identity === participantId &&
          permissions.canPublish
        ) {
          notification.success({
            message: t({
              id: 'stream.control_panel.action.camera_mic_enabled',
              message: 'Je kan nu video en geluid delen',
            }),
            duration: 4,
          })
        }
      },
      handRaised: ({ participantId, isHandRaised }) => {
        if (!participantId) return

        setHandRaisedParticipants((prev) => {
          if (isHandRaised) {
            const updatedParticipants = [...prev, participantId]
            if (isMeetingHost) {
              showHandRaisedNotification(participantId)
            }
            return updatedParticipants
          } else {
            notification.close(`notification-${participantId}`)
            return prev.filter((p) => p !== participantId)
          }
        })
      },
    },
  })

  const getToken = async () => {
    const response = await fetch(
      `${import.meta.env.NX_BACKEND_URL}/api/meetings/generate-token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
        },
        body: JSON.stringify({
          call_id: callId,
        }),
      }
    )

    if (!response.ok) {
      const error = await response.json()
      const errorMessage = `Failed to get token: ${
        error.errorMessage || response.statusText
      }`
      console.error(errorMessage)
      throw new Error(errorMessage)
    }

    const data = await response.json()
    return data.token
  }

  const leaveRoom = useCallback(
    (options?: { attention_check_failed?: boolean }) => {
      if (isMeetingHost) emit('closeMeetingRoom', { call_id: callId })

      emit('leaveStream', {
        call_id: callId,
        attention_check_failed: options?.attention_check_failed,
      })
      room?.disconnect()
      setRoom(undefined)
      setToken(undefined)
      if (eventId) navigate('/calendar?event=' + eventId)
    },
    [isMeetingHost, emit, callId, eventId, navigate, room]
  )

  const value = {
    room,
    setRoom,
    token,
    setToken,
    isRoomActive,
    setIsRoomActive,
    isMeetingHost,
    isParticipant,
    participantName: user
      ? `${user?.firstName} ${user?.lastName}`
      : `Anonymous`,
    callId,
    eventId,
    isRecording,
    setIsRecording,
    emit,
    disconnect,
    connected,
    getToken,
    leaveRoom,
    mainParticipant,
    setMainParticipant,
    localParticipant,
    setLocalParticipant,
    participants,
    setParticipants,
    pendingMainParticipantId,
    setPendingMainParticipantId,
    chatParticipants,
    canEnableCamera,
    setCanEnableCamera,
    canEnableMicrophone,
    setCanEnableMicrophone,
    isSurveyModalVisible,
    setSurveyModalVisibility,
    surveyCompletionArr,
    setUserCompletionArr,
    surveyActiveTimer,
    survey,
    isAllParticipantsControlAllowed,
    duration,
    handRaisedParticipants,
    notificationSound,
  }

  return (
    <StreamContext.Provider value={value}>{children}</StreamContext.Provider>
  )
}

export const useStream = () => {
  const context = useContext(StreamContext)
  if (context === undefined) {
    throw new Error('useStream must be used within a StreamProvider')
  }
  return context
}
