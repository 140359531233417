import { Progress } from 'antd'
import styled from 'styled-components'

export const ControlPanelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 14px;
  max-width: 100%;
  overflow-x: auto;
  height: auto; // Ensure it only takes the height it needs
`
export const ControlButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.144s ease;
  min-width: 60px;

  &:hover {
    transform: scale(1.07);
  }

  span {
    font-size: 12px;
    margin-top: 5px;
    white-space: nowrap;
  }

  .anticon {
    font-size: 14px;
  }
`
export const LightProgress = styled(Progress)`
  .ant-progress-inner {
    color: white;
  }
  .ant-progress-bg {
    background-color: white;
  }
  .ant-statistic-content {
    color: white;
  }
  .ant-progress-text {
    color: white;
  }
`
