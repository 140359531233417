/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { RobotOutlined, EyeOutlined } from '@ant-design/icons'
import { Trans, t } from '@lingui/macro'
import {
  MediaPlayer,
  MediaPlayerInstance,
  MediaProvider,
  Menu,
  SeekButton,
  TextTrackList,
  Track,
  useMediaRemote,
  useMediaStore,
} from '@vidstack/react'
import {
  CheckIcon,
  SubtitlesIcon,
  SeekBackward10Icon,
  SeekForward30Icon,
} from '@vidstack/react/icons'
import {
  defaultLayoutIcons,
  DefaultLayoutTranslations,
  DefaultTooltip,
  DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default'
import { Alert, Button, Tooltip, notification } from 'antd'
import { debounce } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { useWakeLock } from 'react-screen-wake-lock'

import { ReportSubtitleErrorModal } from '../../../units/pages/unit-viewer/video-unit-viewer/modals/ReportSubtitleErrorModal'

import { MediaDatabaseStorage } from './media-db-storage'

interface NetworkInformation extends EventTarget {
  effectiveType: '2g' | '3g' | '4g' | 'slow-2g'
  addEventListener: (type: string, listener: EventListener) => void
  removeEventListener: (type: string, listener: EventListener) => void
}

interface NavigatorWithConnection extends Navigator {
  connection?: NetworkInformation
}

type PlayerProps = {
  meta: {
    cf_stream_id: string
  }
  playerRef?: React.RefObject<MediaPlayerInstance>
  src: { hls: string } | { url: string }
  castSrc?: string
  enablePlaybackRates?: boolean
  disableAutoPlay?: boolean
  onReady?: () => void
  onEnded?: (video: MediaPlayerInstance) => void
  onProgress?: ({ playedSeconds }: { playedSeconds: number }) => void
  onPlaybackRateChange?: (playbackRate: number) => void
  lastPosition?: number
  thumbnails?: string
  textTracks?: {
    url: string
    lang: string
    label: string
    kind: string
  }[]
}

export const Player = ({
  meta,
  playerRef,
  src,
  castSrc,
  enablePlaybackRates,
  disableAutoPlay,
  onReady,
  onProgress,
  onEnded,
  onPlaybackRateChange,
  lastPosition,
  thumbnails,
  textTracks = [],
}: PlayerProps) => {
  const { playing, textTrack, isGoogleCastConnected } = useMediaStore(playerRef)
  const [reportSubtitleErrorModalOpen, setReportSubtitleErrorModalOpen] =
    useState<boolean>(false)

  const remote = useMediaRemote(playerRef)
  const { textTracks: captions, textTrack: caption } = useMediaStore(playerRef)

  const { isSupported, request, release } = useWakeLock()

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        request()
      }
    }
    if (isSupported) {
      request()
      document.addEventListener('visibilitychange', handleVisibilityChange)
    }
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      if (isSupported) {
        release()
      }
    }
  }, [isSupported, request, release])

  const TRANSLATIONS: DefaultLayoutTranslations = {
    'Caption Styles': t({ id: `player.caption_styles` }),
    'Captions look like this': t({ id: `player.captions_look_like_this` }),
    'Closed-Captions Off': t({ id: `player.closed_captions_off` }),
    'Closed-Captions On': t({ id: `player.closed_captions_on` }),
    'Display Background': t({ id: `player.display_background` }),
    'Enter Fullscreen': t({ id: `player.enter_fullscreen` }),
    'Enter PiP': t({ id: `player.enter_pip` }),
    'Exit Fullscreen': t({ id: `player.exit_fullscreen` }),
    'Exit PiP': t({ id: `player.exit_pip` }),
    'Google Cast': t({ id: `player.google_cast` }),
    'Keyboard Animations': t({ id: `player.keyboard_animations` }),
    'Seek Backward': t({ id: `player.seek_backward` }),
    'Seek Forward': t({ id: `player.seek_forward` }),
    'Skip To Live': t({ id: `player.skip_to_live` }),
    'Text Background': t({ id: `player.text_background` }),
    Accessibility: t({ id: `player.accessibility` }),
    AirPlay: t({ id: `player.airplay` }),
    Announcements: t({ id: `player.announcements` }),
    Audio: t({ id: `player.audio` }),
    Auto: t({ id: `player.auto` }),
    Boost: t({ id: `player.boost` }),
    Captions: t({ id: `player.captions` }),
    Chapters: t({ id: `player.chapters` }),
    Color: t({ id: `player.color` }),
    Connected: t({ id: `player.connected` }),
    Connecting: t({ id: `player.connecting` }),
    Continue: t({ id: `player.continue` }),
    Default: t({ id: `player.default` }),
    Disabled: t({ id: `player.disabled` }),
    Disconnected: t({ id: `player.disconnected` }),
    Download: t({ id: `player.download` }),
    Family: t({ id: `player.family` }),
    Font: t({ id: `player.font` }),
    Fullscreen: t({ id: `player.fullscreen` }),
    LIVE: t({ id: `player.live` }),
    Loop: t({ id: `player.loop` }),
    Mute: t({ id: `player.mute` }),
    Normal: t({ id: `player.normal` }),
    Off: t({ id: `player.off` }),
    Opacity: t({ id: `player.opacity` }),
    Pause: t({ id: `player.pause` }),
    PiP: t({ id: `player.pip` }),
    Play: t({ id: `player.play` }),
    Playback: t({ id: `player.playback` }),
    Quality: t({ id: `player.quality` }),
    Replay: t({ id: `player.replay` }),
    Reset: t({ id: `player.reset` }),
    Seek: t({ id: `player.seek` }),
    Settings: t({ id: `player.settings` }),
    Shadow: t({ id: `player.shadow` }),
    Size: t({ id: `player.size` }),
    Speed: t({ id: `player.speed` }),
    Text: t({ id: `player.text` }),
    Track: t({ id: `player.track` }),
    Unmute: t({ id: `player.unmute` }),
    Volume: t({ id: `player.volume` }),
  }

  const handleTimeUpdate = useMemo(
    () =>
      debounce(
        ({ playedSeconds }: { playedSeconds: number }) => {
          onProgress && onProgress({ playedSeconds })
        },
        200,
        { trailing: true, maxWait: 200 }
      ),
    [onProgress]
  )

  const source = useMemo(() => {
    if (isGoogleCastConnected && castSrc) return castSrc
    if ('hls' in src) return src.hls
    return src.url
  }, [src, castSrc, isGoogleCastConnected])

  const storage = useMemo(
    () => new MediaDatabaseStorage(lastPosition),
    [lastPosition]
  )

  // Add network status monitoring
  useEffect(() => {
    const navigator = window.navigator as NavigatorWithConnection
    const networkInfoSupported =
      'connection' in navigator && navigator.connection

    const handleNetworkChange = () => {
      if (!navigator.onLine) {
        notification.warning({
          key: 'offline-warning',
          message: t({
            id: 'player.offline_warning',
            message:
              "Je internetverbinding lijkt offline te zijn. Het afspelen van video's kan worden onderbroken.",
          }),
          duration: 5,
        })
      }
    }

    const handleSlowConnection = () => {
      if (networkInfoSupported) {
        const connection = navigator.connection as NetworkInformation
        if (
          connection.effectiveType === 'slow-2g' ||
          connection.effectiveType === '2g'
        ) {
          notification.warning({
            key: 'slow-connection',
            message: t({
              id: 'player.slow_connection',
              message:
                'Traag netwerk gedetecteerd. De videokwaliteit kan worden verlaagd.',
            }),
            duration: 5,
          })
        }
      }
    }

    window.addEventListener('online', handleNetworkChange)
    window.addEventListener('offline', handleNetworkChange)

    if (networkInfoSupported) {
      const connection = navigator.connection as NetworkInformation
      connection.addEventListener('change', handleSlowConnection)
    }

    return () => {
      window.removeEventListener('online', handleNetworkChange)
      window.removeEventListener('offline', handleNetworkChange)

      if (networkInfoSupported) {
        const connection = navigator.connection as NetworkInformation
        connection.removeEventListener('change', handleSlowConnection)
      }
    }
  }, [])

  useEffect(() => {
    const player = playerRef?.current
    const video = player?.el?.querySelector('video')

    const handleVideoTimeUpdate = (e: Event) => {
      if (playing)
        handleTimeUpdate({
          playedSeconds: (e.target as HTMLVideoElement).currentTime,
        })
    }

    video?.addEventListener('timeupdate', handleVideoTimeUpdate, {
      passive: true,
    })

    return () => video?.removeEventListener('timeupdate', handleVideoTimeUpdate)
  }, [playerRef, playing, handleTimeUpdate])

  return (
    <>
      {isGoogleCastConnected && (
        <Alert
          icon={<EyeOutlined />}
          showIcon
          type="info"
          message={t({
            id: 'player.cast_alert',
            message:
              'Houd dit tabblad tijdens het casten zichtbaar op je scherm om te voorkomen dat opleidingsrapportage verloren gaat.',
          })}
        />
      )}
      <MediaPlayer
        storage={storage}
        ref={playerRef}
        autoPlay={!disableAutoPlay}
        src={source}
        playsInline
        onLoadedMetadata={() => {
          onReady?.()
        }}
        onError={(error) => console.error('Video playback error:', error)}
        onEnded={() => {
          const video = playerRef?.current
          if (video) onEnded?.(video)
        }}
        onTimeUpdate={(detail) => {
          if (playing) handleTimeUpdate({ playedSeconds: detail.currentTime })
        }}
        onSeeked={(detail) => {
          handleTimeUpdate({ playedSeconds: detail })
        }}
        onRateChange={(detail) => {
          onPlaybackRateChange?.(detail)
          if (!enablePlaybackRates) remote.changePlaybackRate(1)
        }}
      >
        <MediaProvider>
          {textTracks.map((track, index) => (
            <Track
              key={track.label}
              id={`${track.lang}-${index}`}
              src={track.url}
              kind={'subtitles'}
              label={`${track.label}`}
              lang={track.lang}
              type="vtt"
            />
          ))}
        </MediaProvider>
        <DefaultVideoLayout
          thumbnails={thumbnails}
          translations={TRANSLATIONS}
          icons={defaultLayoutIcons}
          slots={{
            largeLayout: {
              captionButton:
                captions.length > 0 ? (
                  <Menu.Root className="vds-menu">
                    <DefaultTooltip
                      content={t({
                        id: 'player.captions',
                      })}
                    >
                      <Menu.Button
                        className="vds-menu-button vds-button"
                        aria-label="Settings"
                      >
                        {textTrack ? (
                          <SubtitlesIcon className="vds-icon" />
                        ) : (
                          <SubtitlesIcon
                            className="vds-icon"
                            style={{ opacity: 0.5 }}
                          />
                        )}
                      </Menu.Button>
                    </DefaultTooltip>
                    <Menu.Items
                      className="vds-menu-items"
                      placement="top"
                      offset={0}
                    >
                      <Menu.RadioGroup
                        className="vds-radio-group"
                        value={
                          caption
                            ? caption.id +
                              ':' +
                              caption.kind +
                              '-' +
                              caption.label.toLowerCase()
                            : 'off'
                        }
                      >
                        <Menu.Radio
                          key="off"
                          value={'off'}
                          className="vds-radio"
                          onSelect={() => remote.toggleCaptions()}
                        >
                          <CheckIcon className="vds-icon" />
                          <Trans id="player.text_track_off">
                            Ondertiteling uitschakelen
                          </Trans>
                        </Menu.Radio>
                        {captions
                          .reduce((acc: TextTrackList['items'], cur) => {
                            if (
                              acc.some(
                                (track) => track.language === cur.language
                              )
                            )
                              return acc
                            return [...acc, cur]
                          }, [])
                          .map((track) => (
                            <Menu.Radio
                              key={track.id}
                              value={
                                track.id +
                                ':' +
                                track.kind +
                                '-' +
                                track.label.toLowerCase()
                              }
                              className="vds-radio"
                              onSelect={() => {
                                const index = captions.indexOf(track)
                                if (index >= 0)
                                  remote.changeTextTrackMode(index, 'showing')
                              }}
                            >
                              <CheckIcon className="vds-icon" />
                              <span style={{ textTransform: 'capitalize' }}>
                                {track.label}
                              </span>
                            </Menu.Radio>
                          ))}
                      </Menu.RadioGroup>
                    </Menu.Items>
                  </Menu.Root>
                ) : null,
              afterPlayButton: (
                <>
                  <DefaultTooltip
                    content={t({
                      id: 'player.rewind',
                    })}
                  >
                    <SeekButton className="vds-button" seconds={-10}>
                      <SeekBackward10Icon />
                    </SeekButton>
                  </DefaultTooltip>
                  <DefaultTooltip
                    content={t({
                      id: 'player.forward',
                    })}
                  >
                    <SeekButton className="vds-button" seconds={30}>
                      <SeekForward30Icon />
                    </SeekButton>
                  </DefaultTooltip>
                </>
              ),
            },
          }}
          playbackRates={
            enablePlaybackRates
              ? undefined
              : {
                  min: 1,
                  max: 1,
                  step: 0,
                }
          }
        />
      </MediaPlayer>
      <ReportSubtitleErrorModal
        video_id={meta.cf_stream_id}
        open={reportSubtitleErrorModalOpen}
        onClose={() => setReportSubtitleErrorModalOpen(false)}
      />
      {textTrack && (
        <Alert
          icon={<RobotOutlined />}
          showIcon
          type="info"
          message={
            <Trans id="player.text_track_alert">
              <span>
                Ondertitelingen gegenereerd met{' '}
                <Tooltip
                  title={t({
                    id: 'player.ai',
                    message: 'artificiële intelligentie',
                  })}
                >
                  AI
                </Tooltip>{' '}
                kunnen fouten bevatten.
              </span>
            </Trans>
          }
          action={
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setReportSubtitleErrorModalOpen(true)
              }}
            >
              <Trans id="player.report_subtitle_error">Fout melden</Trans>
            </Button>
          }
        />
      )}
    </>
  )
}
