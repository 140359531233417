/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Button, Form, Input, Radio, Space, message, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'

import {
  BranchScimTenantsQuery,
  CreateBranchScimTenantMutation,
} from 'apps/lms-front/src/generated/graphql'
import { authenticatedAxiosClient } from 'apps/lms-front/src/modules/shared/hooks/use-axios'
import { useCopyToClipboard } from 'apps/lms-front/src/modules/shared/hooks/use-copy-to-clipboard'

import CREATE_BRANCH_SCIM_TENANT_MUTATION from './../../../mutations/create-branch-scim-tenant.graphql'
import BRANCH_SCIM_TENANTS_QUERY from './../../../queries/branch-scim-tenants.graphql'

type SCIMProps = {
  branch_id: string
}

export const SCIM = ({ branch_id }: SCIMProps) => {
  const [token, setToken] = useState<string>()
  const [exp, setExp] = useState<Date>()
  const [, copy] = useCopyToClipboard()
  const [tenantForm] = useForm()

  const [tokenForm] = useForm()
  const selectedTenant = Form.useWatch<string>('tenant_id', tokenForm)

  const endpoint = `${import.meta.env.NX_BACKEND_URL}/api/users/microsoft/scim`

  const { data: scimTenants, loading: loadingScimTenants } =
    useQuery<BranchScimTenantsQuery>(BRANCH_SCIM_TENANTS_QUERY, {
      fetchPolicy: 'cache-and-network',
      variables: {
        branch_id,
      },
    })

  const [createTenant, { loading: creatingTenant }] =
    useMutation<CreateBranchScimTenantMutation>(
      CREATE_BRANCH_SCIM_TENANT_MUTATION,
      {
        refetchQueries: ['branchScimTenants'],
        variables: {
          branch_id,
        },
      }
    )

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Form
        disabled={creatingTenant}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={tenantForm}
        onFinish={async (values) => {
          if (!(values.tenant_id && values.tenant_name))
            return notification.error({
              message: t({
                id: 'settings.branches.scim.error',
                message: 'Vul alle velden in om een tenant toe te voegen',
              }),
            })

          return createTenant({
            variables: {
              id: values.tenant_id,
              name: values.tenant_name,
            },
          })
            .then(() => {
              notification.success({
                message: t({
                  id: 'settings.branches.scim.add_tenant.success',
                  message: 'Tenant succesvol toegevoegd',
                }),
              })
              tenantForm.resetFields()
            })
            .catch((error) => {
              notification.error({
                message: error.message,
              })
            })
        }}
      >
        <div>
          <h2>
            {t({
              id: 'settings.branches.scim.title',
              message: 'Provisionering van gebruikers via SCIM',
            })}
          </h2>
          <Form.Item
            label={t({
              id: 'settings.branches.scim.tenant.name',
              message: 'Tenant name',
            })}
            name="tenant_name"
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.branches.scim.tenant.id',
              message: 'Tenant ID',
            })}
            name="tenant_id"
            required
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button type="primary" onClick={() => tenantForm.submit()}>
              <Trans id="settings.branches.scim.tenant.save">
                Tenant opslaan
              </Trans>
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 18 }}
        form={tokenForm}
        onFinish={async (values) => {
          if (!selectedTenant)
            return notification.error({
              message: t({
                id: 'settings.branches.scim.create-token.error',
                message: 'Selecteer een tenant om een token te genereren',
              }),
            })

          // Get token
          const axios = authenticatedAxiosClient()
          const response = await axios.get(
            `${endpoint}/Token/${branch_id}/${values.tenant_id}`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )

          setToken(response.data.token)
          setExp(new Date(response.data.exp * 1000))
        }}
      >
        <h2>
          <Trans id="settings.branches.scim.token.generate">
            Genereer een token
          </Trans>
        </h2>
        <Space direction="vertical" style={{ width: '100%' }}>
          <div>
            <Form.Item
              label={<h3 style={{ margin: 0 }}>SCIM Tenant</h3>}
              name="tenant_id"
            >
              <Radio.Group disabled={loadingScimTenants}>
                <Space direction="vertical">
                  {scimTenants?.branchScimTenants.map((tenant) => (
                    <Radio key={tenant.tenant_id} value={tenant.tenant_id}>
                      {tenant.tenant_name} ({tenant.tenant_id})
                    </Radio>
                  ))}
                  {scimTenants?.branchScimTenants.length === 0 && (
                    <Radio key={'none'} value={'none'} disabled>
                      {t({
                        id: 'settings.branches.scim.no-tenants',
                        message: 'Geen tenants gevonden',
                      })}
                    </Radio>
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
          <div>
            <h3>SCIM Endpoint</h3>
            <pre
              style={{
                backgroundColor: '#F0F0F0',
                padding: '0.5rem 0.75rem',
                border: '1px solid #DDD',
                borderRadius: 4,
                fontSize: '0.8rem',
                cursor: 'copy',
              }}
              onClick={() =>
                copy(endpoint || '').then(() => {
                  message.info(
                    t({
                      id: 'settings.branches.scim.endpoint.copied',
                      message: 'SCIM endpoint gekopieerd naar klembord.',
                    })
                  )
                })
              }
            >
              {endpoint}
            </pre>
          </div>
          <div>
            <h3>SCIM Secret Token</h3>
            <pre
              style={{
                backgroundColor: '#F0F0F0',
                padding: '0.5rem 0.75rem',
                border: '1px solid #DDD',
                borderRadius: 4,
                fontSize: '0.8rem',
                cursor: 'copy',
              }}
              onClick={() =>
                token &&
                copy(token || '').then(() => {
                  message.info(
                    t({
                      id: 'settings.branches.scim.token.copied',
                      message: 'SCIM secret token gekopieerd naar klembord.',
                    })
                  )
                })
              }
            >
              {token ||
                `<${t({
                  id: 'settings.branches.scim.token.placeholder',
                  message: 'genereer een token met de knop hieronder',
                })}>`}
            </pre>
            {exp && (
              <p>
                <small>
                  De huidig gegenereerde token is geldig tot{' '}
                  {exp?.toLocaleString()}
                </small>
              </p>
            )}
          </div>
          <Space direction="horizontal">
            <Button
              type="primary"
              disabled={!!token}
              onClick={() => tokenForm.submit()}
            >
              <Trans id="settings.branches.scim.token.generate">
                Genereer een token
              </Trans>
            </Button>
            {token && (
              <Button
                disabled={!selectedTenant}
                onClick={() => {
                  setToken('')
                  setExp(undefined)
                }}
              >
                <Trans id="settings.branches.scim.token.reset">
                  Nieuwe token genereren
                </Trans>
              </Button>
            )}
          </Space>
        </Space>
      </Form>
    </Space>
  )
}
