import styled from 'styled-components'

export const ParticipantName = styled.div`
  font-weight: 500;
  margin-bottom: 0;
`

export const ParticipantRegistrationDetails = styled.div`
  color: #555;
  font-size: 0.875rem;
  margin-bottom: 0;
`

export const ParticipantListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-right: 5px;
`

// should take up the rest of the space
export const ParticipantDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex: 1;
  justify-content: space-between;
`
export const LargeTag = styled.span`
  cursor: default;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  background-color: #f0f0f0;
  display: flex;
  gap: 0.25rem;
  align-items: center;
`
