import {
  BackgroundOptions,
  BackgroundTransformer,
  ProcessorWrapper,
} from '@livekit/track-processors'
import * as vision from '@mediapipe/tasks-vision'
import { useLocalStorage } from '@uidotdev/usehooks'
import React from 'react'

export type SegmenterOptions = Partial<
  vision.ImageSegmenterOptions['baseOptions']
>

export const checkMSTProcessorSupport = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return typeof MediaStreamTrackProcessor !== 'undefined'
}

export const BackgroundProcessor = (
  options: BackgroundOptions,
  name = 'background-processor'
) => {
  const isProcessorSupported =
    ProcessorWrapper.isSupported && BackgroundTransformer.isSupported
  if (!isProcessorSupported || !checkMSTProcessorSupport()) {
    return null
  }
  return new ProcessorWrapper(new BackgroundTf(options), name)
}

class BackgroundTf extends BackgroundTransformer {}

export const useBackgroundProcessor = () => {
  const [virtualBackground] = useLocalStorage('aa_virtual_bg', 'default')
  const [blurBackgroundProcessor] = React.useState(
    BackgroundProcessor({ blurRadius: 10 }, 'blur-processor')
  )
  const [imageBackgroundProcessor] = React.useState(
    BackgroundProcessor({}, 'image-processor')
  )

  React.useEffect(() => {
    let blurRadius = 0
    let imagePath: string | undefined

    switch (virtualBackground) {
      case 'office': {
        imagePath = '/office.jpg'
        break
      }
      case 'blur-5': {
        blurRadius = 5
        break
      }
      case 'blur-10': {
        blurRadius = 10
        break
      }
      case 'blur-25': {
        blurRadius = 25
        break
      }
      case 'blur-50': {
        blurRadius = 50
        break
      }
      default: {
        if (virtualBackground) imagePath = virtualBackground
        else blurRadius = 10
        break
      }
    }
    if (virtualBackground !== 'default') {
      if (blurRadius > 0)
        blurBackgroundProcessor?.updateTransformerOptions({
          blurRadius,
        })
      if (imagePath && imagePath !== 'default')
        imageBackgroundProcessor?.updateTransformerOptions({
          imagePath,
        })
    }
  }, [virtualBackground, imageBackgroundProcessor, blurBackgroundProcessor])

  const backgroundProcessor = virtualBackground.startsWith('blur-')
    ? blurBackgroundProcessor
    : virtualBackground === 'default'
    ? null
    : imageBackgroundProcessor

  return {
    backgroundProcessor,
  }
}
