import {
  AlertOutlined,
  CheckOutlined,
  CloseOutlined,
  FundProjectionScreenOutlined,
  GlobalOutlined,
  MoreOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Avatar,
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  notification,
  Space,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import { useContext, useState } from 'react'

import { EventLocationType } from '@lms-shared-patterns/enums/event.enums'
import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  ApproveLiveEventRegistrationMutation,
  LiveEventsQuery,
  Participant,
  RegisterParticipantForLiveEventMutation,
  RejectionReasonType,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../auth/components/Can'
import { useEventStatus } from '../hooks/use-event-status.hook'
import { useUserEventRegistrationStatus } from '../hooks/use-user-event-registration-status.hook'
import APPROVE_LIVE_EVENT_REGISTRATION_MUTATION from '../mutations/approve-live-event-registration.graphql'
import CLEAR_PARTICIPANT_ATTENDANCE_MUTATION from '../mutations/clear-participant-attendance.graphql'
import REJECT_LIVE_EVENT_REGISTRATION_MUTATION from '../mutations/deny-live-event-registration.graphql'
import MARK_PARTICIPANT_ATTENDANCE_MUTATION from '../mutations/mark-participant-attendance.graphql'
import REGISTER_PARTICIPANT_FOR_LIVE_EVENT_MUTATION from '../mutations/register-participant-for-live-event.graphql'
import LIVE_EVENT_PARTICIPANTS_QUERY from '../queries/live-event-participants.graphql'

import {
  LargeTag,
  ParticipantDetails,
  ParticipantListItemWrapper,
  ParticipantName,
  ParticipantRegistrationDetails,
} from './ParticipantListItem.style'

export const ParticipantListItem = ({
  participant,
  event,
}: {
  participant: Participant
  event: LiveEventsQuery['fetchLiveEvents']['results'][0]
}) => {
  const ability = useContext(AbilityContext)
  const {
    hasReachedCapacity,
    onlyOnline,
    onlyPhysical,
    cancelled,
    started,
    ended,
  } = useEventStatus(event)
  const {
    canRegisterSubject,
    canApprove,
    canDeny,
    registrationCancelled,
    waiting,
    registered,
    selfRegistered,
    approved,
    denied,
    selfDenied,
    canAdminCancelParticipant,
  } = useUserEventRegistrationStatus(
    { ...event, my_registration: participant.registration },
    participant._id
  )

  const [denyForm] = Form.useForm()

  interface RejectionModalState {
    open: boolean
    rejectionType: RejectionReasonType | null
  }

  const [rejectionModalVisible, setRejectionModalState] =
    useState<RejectionModalState>({
      open: false,
      rejectionType: null,
    })

  const [register] = useMutation<RegisterParticipantForLiveEventMutation>(
    REGISTER_PARTICIPANT_FOR_LIVE_EVENT_MUTATION,
    {
      variables: {
        event_id: event._id,
        user_id: participant._id,
      },
      refetchQueries: [
        {
          query: LIVE_EVENT_PARTICIPANTS_QUERY,
          variables: {
            id: event._id,
          },
        },
      ],
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'events.participants.confirmation.registered',
            message: 'Deelnemer ingeschreven',
          }),
        })
      },
    }
  )

  const [approve] = useMutation<ApproveLiveEventRegistrationMutation>(
    APPROVE_LIVE_EVENT_REGISTRATION_MUTATION,
    {
      variables: {
        event_id: event._id,
        user_id: participant._id,
      },
      refetchQueries: [
        {
          query: LIVE_EVENT_PARTICIPANTS_QUERY,
          variables: {
            id: event._id,
          },
        },
      ],
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'events.participants.confirmation.approved',
            message: 'Deelnemer goedgekeurd',
          }),
        })
      },
    }
  )

  const [deny, { loading: denying }] =
    useMutation<ApproveLiveEventRegistrationMutation>(
      REJECT_LIVE_EVENT_REGISTRATION_MUTATION,
      {
        variables: {
          event_id: event._id,
          user_id: participant._id,
        },
        refetchQueries: [
          {
            query: LIVE_EVENT_PARTICIPANTS_QUERY,
            variables: {
              id: event._id,
            },
          },
        ],
        onCompleted: () => {
          setRejectionModalState({
            open: false,
            rejectionType: null,
          })
          denyForm.resetFields()
          notification.success({
            message: t({
              id: 'events.participants.confirmation.denied',
              message: 'Deelnemer afgewezen',
            }),
          })
        },
      }
    )

  const [markAttendance, { loading: marking }] = useMutation(
    MARK_PARTICIPANT_ATTENDANCE_MUTATION,
    {
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'events.participants.confirmation.attendance_marked',
            message: 'Aanwezigheid gemarkeerd',
          }),
        })
      },
    }
  )

  const [clearAttendance, { loading: clearing }] = useMutation(
    CLEAR_PARTICIPANT_ATTENDANCE_MUTATION,
    {
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'events.participants.confirmation.attendance_cleared',
            message: 'Aanwezigheid gewist',
          }),
        })
      },
    }
  )

  const handleAttendanceChange = async (checked: boolean) => {
    const mutation = checked ? markAttendance : clearAttendance

    await mutation({
      variables: {
        event_id: event._id,
        user_id: participant._id,
      },
      refetchQueries: [
        {
          query: LIVE_EVENT_PARTICIPANTS_QUERY,
          variables: {
            id: event._id,
          },
        },
      ],
    })
  }

  const dropDownMenuItems = [
    canRegisterSubject && {
      key: 'undo',
      icon: <UndoOutlined />,
      label: (
        <Trans id="events.participants.reregister">Terug inschrijven</Trans>
      ),
      onClick: () =>
        register({
          variables: {
            type: participant.registration.type,
          },
        }),
    },
    participant.registration.type === EventLocationType.Physical &&
      !onlyPhysical &&
      !registrationCancelled &&
      !ended && {
        key: 'switch-location-to-online',
        icon: <GlobalOutlined />,
        label: (
          <Trans id="events.participants.switch-location-to-online">
            Verander naar &quot;online&quot;
          </Trans>
        ),
        onClick: () =>
          register({
            variables: {
              type: EventLocationType.Online,
            },
          }),
      },
    participant.registration.type === EventLocationType.Online &&
      !onlyOnline &&
      !registrationCancelled &&
      !ended &&
      !hasReachedCapacity && {
        key: 'switch-location-to-physical',
        icon: <FundProjectionScreenOutlined />,
        label: (
          <Trans id="events.participants.switch-location-to-physical">
            Verander naar &quot;fysiek&quot;
          </Trans>
        ),
        onClick: () =>
          register({
            variables: {
              type: EventLocationType.Physical,
            },
          }),
      },
    canApprove && {
      disabled:
        hasReachedCapacity &&
        participant.registration.type === EventLocationType.Physical,
      key: 'approve',
      icon: <CheckOutlined />,
      label: <Trans id="events.participants.approve">Goedkeuren</Trans>,
      onClick: () => approve(),
    },
    canDeny && {
      key: 'deny',
      icon: <CloseOutlined />,
      label: <Trans id="events.participants.deny">Afwijzen</Trans>,
      onClick: () =>
        setRejectionModalState({
          open: true,
          rejectionType: RejectionReasonType.Denied,
        }),
    },
    canAdminCancelParticipant && {
      key: 'admin-cancel',
      icon: <CloseOutlined />,
      label: <Trans id="events.participants.cancel">Annuleren</Trans>,
      onClick: () =>
        setRejectionModalState({
          open: true,
          rejectionType: RejectionReasonType.CancelledByAdmin,
        }),
    },
  ].filter(Boolean)

  const showOnlineIcon =
    participant.registration.type === EventLocationType.Online && !onlyOnline
  const showPhysicalIcon =
    event.location_type.length > 1 &&
    participant.registration.type === EventLocationType.Physical

  return (
    <ParticipantListItemWrapper>
      <div>
        <Avatar
          src={participant.picture?.url}
          icon={participant.firstName.slice(0, 1).toUpperCase()}
        />
      </div>
      <ParticipantDetails>
        <div>
          <ParticipantName
            style={{
              textDecoration: participant.registration.denied
                ? 'line-through'
                : 'none',
            }}
          >
            <Space>
              <span>
                {participant.firstName} {participant.lastName}
              </span>
              {showOnlineIcon && (
                <Tooltip title="Online">
                  <GlobalOutlined />
                </Tooltip>
              )}
              {showPhysicalIcon && (
                <Tooltip title="Fysiek">
                  <FundProjectionScreenOutlined />
                </Tooltip>
              )}
            </Space>
          </ParticipantName>
          <ParticipantRegistrationDetails>
            {registrationCancelled && (
              <Trans id="events.participants.cancelled_details">
                Uitgeschreven op
                {' ' +
                  `${dayjs(participant.registration.cancelled).format(
                    'DD/MM/YYYY'
                  )} `}
              </Trans>
            )}
            {registered && (
              <>
                <Trans id="events.participants.registration_details">
                  Ingeschreven op
                  {' ' +
                    `${dayjs(participant.registration.created).format(
                      'DD/MM/YYYY'
                    )} `}
                </Trans>{' '}
                {selfRegistered ? null : (
                  <Trans id="events.participants.registration_details.by">
                    door
                    {' ' +
                      `${participant.registration.created_by.firstName} ${participant.registration.created_by.lastName}`}
                  </Trans>
                )}
              </>
            )}
            {denied ? (
              <>
                <Trans id="events.participants.denied_details">
                  Afgewezen op
                </Trans>
                {' ' +
                  `${dayjs(participant.registration.denied).format(
                    'DD/MM/YYYY'
                  )} `}
                {selfDenied ? null : (
                  <Trans id="events.participants.denied_details.by">
                    door
                    {' ' +
                      `${participant.registration.denied_by?.firstName} ${participant.registration.denied_by?.lastName}`}
                  </Trans>
                )}
              </>
            ) : null}
          </ParticipantRegistrationDetails>
        </div>
        <div style={{ display: 'flex' }}>
          <Space>
            {started &&
              registered &&
              participant.registration.type === EventLocationType.Physical && (
                <Checkbox
                  disabled={
                    marking ||
                    clearing ||
                    ability.cannot(
                      PermissionAction.UPDATE,
                      PermissionObjectType.BRANCH_LIVE_EVENT_REGISTRATION
                    )
                  }
                  checked={participant.activity?.attended}
                  onChange={(e) => handleAttendanceChange(e.target.checked)}
                >
                  <Trans id="events.participants.attended">Aanwezig</Trans>
                </Checkbox>
              )}
            {registrationCancelled && (
              <Tooltip title={participant.registration.cancellation_reason}>
                <LargeTag>
                  <Trans id="events.participants.unregistered">
                    <CloseOutlined />
                    Uitgeschreven
                  </Trans>
                </LargeTag>
              </Tooltip>
            )}
            {approved && (
              <LargeTag>
                <Trans id="events.participants.approved">
                  <CheckOutlined />
                  Goedgekeurd
                </Trans>
              </LargeTag>
            )}
            {denied && (
              <Tooltip title={participant.registration.denial_reason}>
                <LargeTag>
                  <Trans id="events.participants.denied">
                    <CloseOutlined />
                    Afgewezen
                  </Trans>
                </LargeTag>
              </Tooltip>
            )}
            {waiting && (
              <Space>
                <LargeTag>
                  <Trans id="events.participants.waiting">
                    <AlertOutlined />
                    Wacht op goedkeuring
                  </Trans>
                </LargeTag>
                {canApprove && (
                  <Tooltip
                    title={
                      <Trans id="events.participants.approve">Goedkeuren</Trans>
                    }
                  >
                    <Button
                      shape="circle"
                      icon={<CheckOutlined />}
                      size="small"
                      disabled={
                        hasReachedCapacity &&
                        participant.registration.type ===
                          EventLocationType.Physical
                      }
                      onClick={() => approve()}
                    />
                  </Tooltip>
                )}
                {canDeny && (
                  <Tooltip
                    title={
                      <Trans id="events.participants.deny">Afwijzen</Trans>
                    }
                  >
                    <Button
                      shape="circle"
                      icon={<CloseOutlined />}
                      size="small"
                      onClick={() =>
                        setRejectionModalState({
                          open: true,
                          rejectionType: RejectionReasonType.Denied,
                        })
                      }
                    />
                  </Tooltip>
                )}
              </Space>
            )}

            {!cancelled && dropDownMenuItems.length > 0 && (
              <Dropdown
                menu={{ items: dropDownMenuItems as MenuProps['items'] }}
              >
                <Button type={'text'} icon={<MoreOutlined />} size="small" />
              </Dropdown>
            )}
          </Space>
        </div>
      </ParticipantDetails>
      <Modal
        open={rejectionModalVisible.open}
        onCancel={() =>
          setRejectionModalState({
            open: false,
            rejectionType: null,
          })
        }
        closable={false}
        maskClosable={false}
        centered={true}
        bodyStyle={{
          borderRadius: '5px',
          overflow: 'hidden',
        }}
        onOk={() => denyForm.submit()}
        okText={
          rejectionModalVisible.rejectionType === RejectionReasonType.Denied ? (
            <Trans id="action.deny">Afwijzen</Trans>
          ) : (
            <Trans id="rejection.model.action.cancel">Annuleer deelnemer</Trans>
          )
        }
        okButtonProps={{ loading: denying }}
      >
        <Form
          form={denyForm}
          onFinish={({ reason }) =>
            deny({
              variables: {
                reason,
                rejectionType: rejectionModalVisible.rejectionType,
              },
            })
          }
        >
          <Form.Item
            label={
              rejectionModalVisible.rejectionType ===
              RejectionReasonType.Denied ? (
                <Trans id={'events.participants.deny_reason'}>
                  Reden voor afkeuring
                </Trans>
              ) : (
                <Trans id={'events.participants.cancel_reason'}>
                  Reden voor annulering
                </Trans>
              )
            }
            name="reason"
            extra={
              rejectionModalVisible.rejectionType ===
              RejectionReasonType.Denied ? (
                <Trans id={'events.participants.deny_reason.help'}>
                  De reden wordt toegevoegd in de mail naar de deelnemer
                </Trans>
              ) : (
                <Trans id={'events.participants.cancel_reason.help'}>
                  De reden wordt toegevoegd in de mail naar de deelnemer
                </Trans>
              )
            }
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </ParticipantListItemWrapper>
  )
}
