import { PathQuery } from 'apps/lms-front/src/generated/graphql'

import { useLearningPathStatus } from '../hooks/use-path-status.hook'

export const PathStatusAlerts = ({
  path,
}: {
  path: PathQuery['fetchLearningPathById']
}) => {
  const { alert } = useLearningPathStatus(path)

  return <div>{alert}</div>
}
