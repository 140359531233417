/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Area from '@ant-design/plots/es/components/area'
import { useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Dropdown, Menu, Modal } from 'antd'
import { extend } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { inflate } from '@lms-shared-patterns/utils'
import {
  CompleteUnitMutation,
  ResetUnitMutation,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { LoadSection } from '../../../core/components/LoadScreen'
import { useAxios } from '../../../shared/hooks/use-axios'
import { useTranslatedData } from '../../../shared/hooks/use-translated-data'
import { Table } from '../../components/Table'
import { dateRenderer, percentageRenderer } from '../../helpers/renderers'
import { UserCourseActivityQuizReport } from '../UserCourseActivityQuizReport'
import { UserCourseActivitySurveyReport } from '../UserCourseActivitySurveyReport'

import COMPLETE_UNIT from './../../mutations/complete-unit.graphql'
import RESET_UNIT from './../../mutations/reset-unit.graphql'

extend(duration)

export const UserCourseActivityReport = ({
  courseId,
  userId,
  branchId,
}: {
  courseId: string
  userId: string
  branchId: string | undefined
}) => {
  const ability = useContext(AbilityContext)

  const [selectedAction, setSelectedAction] = useState<string>()
  const [selectedTarget, setSelectedTarget] = useState<string>()
  const [showActionModal, setShowActionModal] = useState<boolean>(false)

  const [{ data }, refetch] = useAxios({
    url: `/api/activity/courses/${courseId}/${userId}`,
    params: {
      section_id: branchId,
    },
  })

  const transformedData = useTranslatedData(data, 'name')

  const [complete, { loading: completing }] =
    useMutation<CompleteUnitMutation>(COMPLETE_UNIT)

  const [reset, { loading: resetting }] =
    useMutation<ResetUnitMutation>(RESET_UNIT)

  if (!data) return <LoadSection />
  return (
    <>
      <Table
        expandable={{
          expandedRowRender: (record) =>
            // @ts-ignore
            record.kind === 'QuizUnit' || record.kind === 'VideoUnit' ? (
              <UserCourseActivityQuizReport
                // @ts-ignore
                questions={record.questions}
                // @ts-ignore
                answers={record.answers}
              />
            ) : (
              <UserCourseActivitySurveyReport
                // @ts-ignore
                questions={record.questions}
                // @ts-ignore
                answers={record.answers}
              />
            ),
          expandRowByClick: true,
          // @ts-ignore
          rowExpandable: (record) =>
            // @ts-ignore
            record.kind === 'QuizUnit' ||
            // @ts-ignore
            record.kind === 'SurveyUnit' ||
            // @ts-ignore
            record.answers?.length > 0,
        }}
        columns={[
          {
            title: t({
              id: 'reports.course_activity_report.table.name',
              message: 'Onderdeel',
            }),
            dataIndex: 'name',
            key: 'name',
            // @ts-ignore
            render: (value: string, { _id }: unknown) => (
              <Dropdown
                disabled={
                  !ability.can(
                    PermissionAction.UPDATE,
                    PermissionObjectType.ACTIVITY
                  )
                }
                overlay={
                  <Menu
                    onClick={(info) => {
                      info.domEvent.stopPropagation()
                      setSelectedTarget(_id)
                      setSelectedAction(info.key)
                      setShowActionModal(true)
                    }}
                    items={[
                      {
                        label: t({
                          id: 'reports.course_activity_report.action.reset',
                          message: 'Vooruitgang verwijderen',
                        }),
                        key: 'reset',
                      },
                      {
                        label: t({
                          id: 'reports.course_activity_report.action.complete',
                          message: 'Markeer als voltooid',
                        }),
                        key: 'complete',
                      },
                    ]}
                  />
                }
                trigger={['contextMenu']}
              >
                <div>{value}</div>
              </Dropdown>
            ),
          },
          {
            title: t({
              id: 'reports.course_activity_report.table.kind',
              message: 'Type',
            }),
            dataIndex: 'kind',
            key: 'kind',
            render: (val) => val.replace('Unit', ''),
          },
          {
            title: t({
              id: 'reports.course_activity_report.table.progress',
              message: 'Vooruitgang',
            }),
            dataIndex: 'progress',
            key: 'progress',
            // @ts-ignore
            render: (val: number, record: { progress_map: string }) => (
              <ProgressAreaChart
                val={val}
                map={record.progress_map}
                onRequestRefetch={refetch}
              />
            ),
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.course_activity_report.table.last_activity',
              message: 'Laatst actief op',
            }),
            dataIndex: 'last_activity',
            key: 'last_activity',
            render: dateRenderer,
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.course_activity_report.table.completed',
              message: 'Voltooid op',
            }),
            dataIndex: 'completed',
            key: 'completed',
            render: dateRenderer,
            align: 'center' as const,
          },
          {
            title: t({
              id: 'reports.course_activity_report.table.score',
              message: 'Score',
            }),
            dataIndex: 'score',
            key: 'score',
            render: percentageRenderer,
            align: 'center' as const,
          },
        ]}
        bordered
        size="small"
        dataSource={transformedData}
        rowKey="_id"
        pagination={false}
      />
      <Modal
        forceRender
        title={t({
          id: 'reports.course_activity_report.action.reset.title',
          message:
            'Ben je zeker dat je de vooruitgang van dit lesonderdeel voor deze gebruiker wil resetten?',
        })}
        open={showActionModal && selectedAction === 'reset'}
        onCancel={() => setShowActionModal(false)}
        okText={t({
          id: 'actions.continue',
          message: 'Doorgaan',
        })}
        cancelText={t({
          id: 'actions.cancel',
          message: 'Annuleren',
        })}
        okButtonProps={{ loading: resetting }}
        onOk={() => {
          reset({
            variables: {
              user_id: userId,
              branch_id: branchId,
              course_id: courseId,
              unit_id: selectedTarget,
            },
          }).then(() => {
            setShowActionModal(false)
            refetch()
          })
        }}
      >
        <Trans id="reports.course_activity_report.action.reset.description">
          Deze actie kan niet ongedaan gemaakt worden.
        </Trans>
      </Modal>
      <Modal
        forceRender
        title={t({
          id: 'reports.course_activity_report.action.complete.title',
          message:
            'Ben je zeker dat je dit lesonderdeel voor deze gebruiker wil voltooien?',
        })}
        open={showActionModal && selectedAction === 'complete'}
        onCancel={() => setShowActionModal(false)}
        okText={t({
          id: 'actions.continue',
          message: 'Doorgaan',
        })}
        cancelText={t({
          id: 'actions.cancel',
          message: 'Annuleren',
        })}
        okButtonProps={{ loading: completing }}
        onOk={() => {
          complete({
            variables: {
              user_id: userId,
              branch_id: branchId,
              course_id: courseId,
              unit_id: selectedTarget,
            },
          }).then(() => {
            setShowActionModal(false)
            refetch()
          })
        }}
      >
        <Trans id="reports.course_activity_report.action.complete.description">
          Deze actie kan niet ongedaan gemaakt worden.
        </Trans>
      </Modal>
    </>
  )
}

const ProgressAreaChart = ({
  val,
  map,
  onRequestRefetch,
}: {
  val: number
  map: string
  onRequestRefetch: () => void
}) => {
  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }

  const data = useMemo(() => {
    return map?.length
      ? [...inflate(map)]
          .map((item, i) => ({
            value: item,
            time: i,
          }))
          .reduce<{ time: number; value: number; count: number }[]>(
            (prev, cur) => {
              const curTime = Math.floor(cur.time)
              prev[curTime] = prev[curTime]
                ? {
                    time: curTime,
                    value: prev[curTime].value + cur.value,
                    count: prev[curTime].count + 1,
                  }
                : {
                    value: cur.value,
                    time: curTime,
                    count: 1,
                  }
              return prev
            },
            new Array<{ time: number; value: number; count: number }>()
          )
          .map((item) => ({
            value: Math.ceil(item.value / item.count),
            time: item.time,
          }))
      : []
  }, [map])

  return map?.length > 0 ? (
    <>
      <Link
        to={'#'}
        style={{ textDecoration: 'none' }}
        onClick={(e) => {
          e.preventDefault()
          showModal()
        }}
      >
        {percentageRenderer(val)}
      </Link>

      <Modal
        open={open}
        okText={t({
          id: 'actions.refresh',
          message: 'Vernieuwen',
        })}
        okType={'default'}
        onCancel={() => hideModal()}
        onOk={() => onRequestRefetch()}
        cancelText={t({
          id: 'actions.close',
          message: 'Sluiten',
        })}
      >
        <Area
          data={data}
          xField={'time'}
          yField={'value'}
          line={{
            size: 0,
          }}
          xAxis={{
            tickInterval: 1,
            tickMethod: 'time-cat',
            label: {
              formatter: (text) =>
                data.length > 120
                  ? `${Math.floor(Number.parseInt(text) / 60)} min`
                  : `${text} sec`,
            },
            minTickInterval: 1,
          }}
          yAxis={{
            tickInterval: 1,
            title: {
              text: t({
                id: 'reports.video_progress_chart.y_axis',
                message: 'Aantal keren bekeken',
              }),
            },
          }}
          tooltip={{
            title: (val) =>
              data.length > 120
                ? `${Math.floor(Number.parseInt(val) / 60)} min`
                : `${val} sec`,
            formatter: (datum) => {
              return {
                name: t({
                  id: 'reports.video_progress_chart.y_axis',
                  message: 'Aantal keren bekeken',
                }),
                value: datum.value,
              }
            },
          }}
          animation={{
            appear: false,
          }}
        />
      </Modal>
    </>
  ) : (
    <>{percentageRenderer(val)}</>
  )
}
