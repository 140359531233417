import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Button, Space } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'

import {
  PathQuery,
  StartLearningPathMutation,
} from 'apps/lms-front/src/generated/graphql'

import { QuestionInputModal } from '../../shared/components/question-input-modal/question-input-modal'
import { useLearningPathStatus } from '../hooks/use-path-status.hook'
import { useUserPathStatus } from '../hooks/use-user-path-status.hook'
import SUBMIT_QUESTION_LEARNING_PATH_MUTATION from '../mutations/submit-question-for-path.graphql'

import START_LEARNING_PATH_MUTATION from './../mutations/start-path.graphql'

export const LearningPathActions = ({
  path,
}: {
  path: PathQuery['fetchLearningPathById']
}) => {
  const { openForRegistration, hasRegistrationDeadline } =
    useLearningPathStatus(path)
  const { canRegister, canAskQuestion } = useUserPathStatus(
    path,
    path.my_activity,
    path.my_request || undefined
  )
  const [questionModalOpen, setQuestionModalOpen] = useState(false)

  const [start, { loading: starting }] = useMutation<StartLearningPathMutation>(
    START_LEARNING_PATH_MUTATION,
    {
      variables: {
        id: path._id,
      },
      refetchQueries: ['path'],
    }
  )

  if (!canRegister && !canAskQuestion) return null

  return (
    <Space direction="vertical">
      <Space>
        {canRegister && (
          <Button type={'primary'} loading={starting} onClick={() => start()}>
            <Trans id="paths.detail.action.register">
              Inschrijven op dit leerpad
            </Trans>
          </Button>
        )}
        {canAskQuestion && (
          <Button type={'primary'} onClick={() => setQuestionModalOpen(true)}>
            <Trans id="paths.detail.action.ask_question">Stel een vraag</Trans>
          </Button>
        )}
      </Space>
      {openForRegistration && hasRegistrationDeadline && (
        <em>
          <Trans id="paths.card.registration_deadline">
            De deadline voor het inschrijven voor dit leerpad:
          </Trans>{' '}
          {dayjs(path.registration_deadline).format('DD MMMM YYYY HH:mm')}
        </em>
      )}
      <QuestionInputModal
        open={questionModalOpen}
        onClose={() => setQuestionModalOpen(false)}
        id={path._id}
        mutation={SUBMIT_QUESTION_LEARNING_PATH_MUTATION}
        title={t({
          id: 'paths.detail.question_modal.title',
          message: 'Stel een vraag over dit leerpad',
        })}
        placeholder={t({
          id: 'paths_question_modal.placeholder',
          message: 'Jouw vraag',
        })}
        successMessage={t({
          id: 'paths_question_modal.success',
          message: 'Je vraag is succesvol verzonden!',
        })}
        errorMessage={t({
          id: 'paths_question_modal.failed',
          message: 'Er is iets misgegaan. Probeer het later opnieuw.',
        })}
      />
    </Space>
  )
}
