import styled from 'styled-components'

export const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1rem;
  width: 100%;
  height: calc(100vh - 72px);
  max-height: calc(100vh - 104px);

  overflow: visible;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

export const MainScreen = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16 / 9;
  border-radius: 0.5rem;

  & > div {
    width: 100%;
    height: auto;
  }

  & .lk-focus-toggle-button {
    display: none;
  }
`

export const SideBar = styled.div`
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  padding: 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
  }
`
export const SideBarTiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  overflow-y: auto;
  flex: 1;
  height: 100%; // Use full height of the parent

  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
  }
`

export const SideBarTile = styled.div`
  border-radius: 0.5rem;
  aspect-ratio: 16 / 9;
  width: 100%;
  cursor: pointer;
  flex-shrink: 0; // Prevent tiles from shrinking
  overflow: hidden;

  // hide the focus toggle button
  & .lk-focus-toggle-button {
    display: none !important;
  }
`
export const ControlsOverlay = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  z-index: 10;
  display: flex;
  align-items: center;
  border-radius: 0.35rem;

  background-color: rgba(0, 0, 0, 0.5);

  &:hover {
    opacity: 1;
  }
`
export const PlaceholderContainer = styled.div`
  aspect-ratio: 16 / 9;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ddd;
  color: #333;
  font-size: 14px;
  text-align: center;
  border-radius: 0.5rem;
`

export const ControlPanelWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  height: auto !important;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 10;
`

export const ChatDrawer = styled.div`
  position: relative;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;

  padding-left: 10px;
  z-index: 1000;
`

export const ChatContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const RecordingIndicator = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: auto !important;
  z-index: 1000;
  animation: fadeInOut 2s ease-in-out infinite;

  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.8;
    }
  }
`

export const RecordingContent = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--ant-error-color);
  padding: 5px;
  border-radius: 0.25rem;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
`

export const DotBorder = styled.div`
  border: 1px solid white;
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 5px;
`

export const RecordingDot = styled.div`
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
`
