import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  SnippetsOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Button, Dropdown, notification, Popconfirm, Space, Tag } from 'antd'
import dayjs from 'dayjs'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { PathQuery } from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../auth/components/Can'
import { errorNotifierFn } from '../../shared/helpers/error-notifier'
import { useCopyToClipboard } from '../../shared/hooks/use-copy-to-clipboard'
import { useClipboardCheck } from '../hooks/use-clipboard-check.hook'
import { useClipboardPaste } from '../hooks/use-clipboard-paste.hook'
import DELETE_PATH_MODULE_MUTATION from '../mutations/delete-path-module.graphql'
import {
  Block,
  BlockDetails,
  BlockTitle,
} from '../pages/LearningPathTimeline.style'

const OptionalTag = () => (
  <Tag>
    <Trans id="paths.detail.timeline.optional">Optioneel</Trans>
  </Tag>
)
export const ModuleCard = ({
  module,
  onEdit,
  sortMode,
}: {
  module: NonNullable<PathQuery['fetchLearningPathById']['modules']>[0]
  onEdit?: () => void
  sortMode?: boolean
}) => {
  const [, copy] = useCopyToClipboard()
  const { canPaste, checkClipboard } = useClipboardCheck()
  const [deleteLearningPathModule] = useMutation(DELETE_PATH_MODULE_MUTATION, {
    refetchQueries: ['path'],
  })

  const handlePaste = useClipboardPaste(module._id, ['path'])

  const handleCopy = (module) => {
    copy(
      JSON.stringify({
        id: module._id,
        type: module.__typename,
      })
    ).then(() => {
      checkClipboard()
    })
  }

  const handleDelete = async (module) => {
    if (module?._id) {
      await deleteLearningPathModule({
        variables: {
          module_id: module._id,
        },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'paths.detail.timeline.delete_module.success',
              message: 'Module succesvol verwijderd',
            }),
          })
        })
        .catch(errorNotifierFn)
    }
  }

  return (
    <Block line>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <BlockTitle>
            <Space>
              {module.name}
              {module.optional && <OptionalTag />}
              {module.my_activity?.completed && (
                <Tag color="success" style={{ verticalAlign: 'middle' }}>
                  <Trans id="path.tag.completed">Voltooid</Trans>
                </Tag>
              )}
            </Space>
          </BlockTitle>
          {(module.deadline || module.duration || module.available_from) &&
            !sortMode && (
              <BlockDetails>
                {!!module.duration && (
                  <span>
                    {module.duration}{' '}
                    {t({
                      id: 'time.minutes',
                      message: 'minuten',
                    })}
                  </span>
                )}
                {!!module.available_from && (
                  <span>
                    <Trans id="path.module.timeline.available_from">
                      Beschikbaar vanaf
                    </Trans>
                    {`: ${dayjs(module.available_from).format('DD MMM YYYY')}`}
                  </span>
                )}
                {!!module.deadline && (
                  <span>
                    <Trans id="paths.detail.timeline.deadline">Deadline</Trans>
                    {`: ${dayjs(module.deadline).format('DD MMM YYYY')}`}
                  </span>
                )}
              </BlockDetails>
            )}
        </div>
        {!sortMode && (
          <Can
            I={PermissionAction.UPDATE}
            a={PermissionObjectType.BRANCH_LEARNING_PATH}
          >
            <div>
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={() => onEdit?.()}
              />
              <Popconfirm
                key="delete"
                title={
                  <Trans id="paths.detail.module.delete.confirm">
                    Weet je zeker dat je deze module wilt verwijderen? Deze
                    actie kan niet ongedaan worden gemaakt.
                  </Trans>
                }
                okText={<Trans id="action.yes">Ja</Trans>}
                cancelText={<Trans id="action.no">Nee</Trans>}
                onConfirm={() => handleDelete(module)}
                placement="topRight"
              >
                <Button type="text" icon={<DeleteOutlined />} />
              </Popconfirm>
              <Dropdown
                onOpenChange={() => checkClipboard()}
                menu={{
                  items: [
                    {
                      key: 'copy',
                      label: (
                        <Trans id="paths.detail.timeline.copy">Kopiëren</Trans>
                      ),
                      icon: <CopyOutlined />,
                      onClick: () => handleCopy(module),
                    },
                    {
                      key: 'paste',
                      disabled: !canPaste,
                      label: (
                        <Trans id="paths.detail.timeline.paste">Plakken</Trans>
                      ),
                      icon: <SnippetsOutlined />,
                      onClick: handlePaste,
                    },
                  ],
                }}
              >
                <Button type="text" icon={<MoreOutlined />} />
              </Dropdown>
            </div>
          </Can>
        )}
      </div>
    </Block>
  )
}
