/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  PlayCircleOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  CopyOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { t, Trans } from '@lingui/macro'
import { Dropdown, Popconfirm, Space, Tag, Timeline, Tooltip } from 'antd'
import { extend } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
extend(duration)

import { CourseContentActionKind } from '@lms-shared-patterns/models'
import { formatDuration } from '@lms-shared-patterns/utils'
import {
  UnitUnion,
  DispatchCourseContentActionMutation,
  CourseBySlugQuery,
} from 'apps/lms-front/src/generated/graphql'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { SortableTimelineItem } from '../../pages/course-edit-contents/SortableSectionItem'

import DISPATCH_COURSE_CONTENT_ACTION_MUTATION from './../../mutations/dispatch-course-content-action.graphql'

interface Props {
  id?: string
  className?: string
  units: UnitUnion[]
  course?: CourseBySlugQuery['fetchCourseBySlug']
  editable?: boolean
  draggable?: boolean
  parent?: string
}

const TimelineItemUnstyled = ({
  item,
  icon,
  editable = false,
  completed,
  className,
  onClick,
}: {
  item: UnitUnion
  icon?: React.ReactNode
  completed?: unknown
  editable?: boolean
  className?: string
  onClick?: (id: string) => void
}) => (
  <Timeline.Item
    key={item._id}
    dot={completed ? <CheckOutlined /> : icon}
    color={completed ? undefined : '#AAA'}
    className={className}
  >
    <div
      onClick={() => !editable && onClick && onClick(item._id)}
      style={{
        cursor: editable ? 'auto' : 'pointer',
      }}
    >
      {'translation' in item ? item.translation?.name || item.name : item.name}
      {item.__typename === 'VideoUnit' && item.cf_stream?.duration && (
        <span style={{ display: 'none' }}>
          {' '}
          (
          {item.cf_stream?.duration >= 60
            ? formatDuration(item.cf_stream?.duration)
            : `${item.cf_stream?.duration.toFixed(0)} sec`}
          )
        </span>
      )}
      {item.optional && (
        <Tag style={{ marginLeft: 8 }}>
          <Trans id="unit.tag.optional">Optioneel</Trans>
        </Tag>
      )}
      {editable && <TimelineItemActions unitId={item._id} />}
    </div>
  </Timeline.Item>
)

const TimelineItemActionsUnstyled = ({
  unitId,
  className,
}: {
  className?: string
  unitId: string
}) => {
  const [dispatch] = useMutation<DispatchCourseContentActionMutation>(
    DISPATCH_COURSE_CONTENT_ACTION_MUTATION
  )
  return (
    <Space className={className}>
      <Tooltip
        title={t({
          id: 'action.edit',
          message: 'Bewerken',
        })}
      >
        <Link to={`/unit/edit/${unitId}`}>
          <EditOutlined />
        </Link>
      </Tooltip>
      <Tooltip
        title={t({
          id: 'action.duplicate',
          message: 'Dupliceren',
        })}
      >
        <CopyOutlined
          onClick={() =>
            dispatch({
              variables: {
                type: CourseContentActionKind.DUPLICATE_UNIT,
                id: unitId,
              },
            })
          }
        />
      </Tooltip>
      <Tooltip
        title={t({
          id: 'action.delete',
          message: 'Verwijderen',
        })}
      >
        <Popconfirm
          title={t({
            id: 'modal.remove_unit.title',
            message: 'Ben je zeker dat je dit onderdeel wil verwijderen?',
          })}
          onConfirm={(e) => {
            e?.stopPropagation()
            dispatch({
              variables: {
                type: CourseContentActionKind.REMOVE_UNIT,
                id: unitId,
              },
            })
          }}
          onCancel={() => {
            return false
          }}
          okText={t({
            id: 'action.confirm',
            message: 'Bevestigen',
          })}
          cancelText={t({
            id: 'action.cancel',
            message: 'Annuleren',
          })}
        >
          <DeleteOutlined />
        </Popconfirm>
      </Tooltip>
    </Space>
  )
}

const TimelineItem = styled(TimelineItemUnstyled)`
  &:not(:hover) .ant-space {
    display: none;
  }
  position: relative;
  z-index: 2;
`

const TimelineItemActions = styled(TimelineItemActionsUnstyled)`
  margin-left: 0.75rem;

  svg {
    color: rgba(0, 0, 0, 0.5);
    transition: all 200ms ease;

    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
`

function Droppable(props: { id: string; children?: React.ReactNode }) {
  const { setNodeRef } = useDroppable({
    id: props.id,
  })

  return (
    <div ref={setNodeRef} style={{ minHeight: 50 }}>
      {props.children}
    </div>
  )
}

export const SectionUnitTimeline: FC<Props> = ({
  id,
  units,
  course,
  editable = false,
  draggable = false,
  parent,
}) => {
  const [order, setOrder] = useState<string[]>([])
  const navigate = useNavigate()
  const branch = useBranch()

  useEffect(() => {
    if (units && units.length > 0)
      setOrder(
        [...units].sort((a, b) => a.order - b.order).map((item) => item._id)
      )
  }, [units])

  const shouldAllowCertificationChoice = !(
    (!branch?.certificationSettings.allowChoice &&
      course?.certificationType &&
      course?.certificationType.length < 2) ||
    course?.my_activity?.started
  )

  const handleClick = (id: string) => {
    if (!shouldAllowCertificationChoice)
      navigate(
        `${parent}/unit/${id}/${course?.certificationType?.[0]?._id || ''}`
      )
  }

  return (
    <SortableContext items={order} strategy={verticalListSortingStrategy}>
      <Timeline>
        {
          [...units]
            .sort((a, b) => a.order - b.order)
            .map((item) => {
              let content: ReactNode
              switch (item.__typename) {
                case 'ContentUnit': {
                  content = (
                    <TimelineItem
                      item={item}
                      completed={item.my_activity?.completed}
                      icon={<FileTextOutlined />}
                      editable={editable}
                      key={item._id}
                      onClick={handleClick}
                    ></TimelineItem>
                  )
                  break
                }
                case 'PDFUnit': {
                  content = (
                    <TimelineItem
                      item={item}
                      completed={item.my_activity?.completed}
                      icon={<FilePdfOutlined />}
                      editable={editable}
                      key={item._id}
                      onClick={handleClick}
                    ></TimelineItem>
                  )
                  break
                }
                case 'VideoUnit': {
                  content = (
                    <TimelineItem
                      item={item}
                      completed={item.my_activity?.completed}
                      icon={<PlayCircleOutlined />}
                      editable={editable}
                      key={item._id}
                      onClick={handleClick}
                    ></TimelineItem>
                  )
                  break
                }
                case 'QuizUnit': {
                  content = (
                    <TimelineItem
                      item={item}
                      completed={item.my_activity?.completed}
                      icon={<QuestionCircleOutlined />}
                      editable={editable}
                      key={item._id}
                      onClick={handleClick}
                    ></TimelineItem>
                  )
                  break
                }
                case 'SurveyUnit': {
                  content = (
                    <TimelineItem
                      item={item}
                      completed={item.my_activity?.completed}
                      icon={<ExclamationCircleOutlined />}
                      editable={editable}
                      key={item._id}
                      onClick={handleClick}
                    ></TimelineItem>
                  )
                  break
                }
                default: {
                  content = (
                    <TimelineItem
                      item={item}
                      key={item._id}
                      onClick={handleClick}
                    ></TimelineItem>
                  )
                  break
                }
              }
              return draggable ? (
                <SortableTimelineItem key={item._id} id={item._id}>
                  {content as ReactNode}
                </SortableTimelineItem>
              ) : shouldAllowCertificationChoice ? (
                // </div>
                <Dropdown
                  key={item._id}
                  overlayStyle={{
                    minWidth: 100,
                    width: 'auto',
                  }}
                  placement={'left' as 'top'}
                  menu={{
                    items: course?.certificationType?.map((type) => ({
                      key: type._id,
                      label: type.name,
                      value: type._id,
                      onClick: (info) => {
                        info.domEvent.stopPropagation()
                        navigate(`${parent}/unit/${item._id}/${info.key || ''}`)
                      },
                    })),
                  }}
                  trigger={['click']}
                >
                  {content as ReactNode}
                </Dropdown>
              ) : (
                (content as ReactNode)
              )
            }) as ReactNode
        }
        {editable && id && units.length === 0 && <Droppable id={id} />}
      </Timeline>
    </SortableContext>
  )
}
