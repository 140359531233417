import { Trans } from '@lingui/macro'
import { Col, Progress, Space, Tag } from 'antd'
import { useNavigate } from 'react-router-dom'

import { PathsQuery } from 'apps/lms-front/src/generated/graphql'

import {
  CardBody,
  Intro,
  LearningPathCardWrapper,
  ProgressLabel,
  Title,
} from './LearningPathCard.style'

export const LearningPathCard = ({
  path,
}: {
  path: PathsQuery['fetchLearningPaths']['results'][0]
}) => {
  const navigate = useNavigate()

  const calculated =
    path?.modules?.reduce(
      (acc, module) => {
        const activity = module.my_activity
        acc.completed_steps += activity?.completed_steps || 0
        acc.total_steps += activity?.total_steps || 0
        return acc
      },
      { completed_steps: 0, total_steps: 0 }
    ) || null

  let progressPct

  if (calculated) {
    progressPct =
      calculated.total_steps > 0
        ? (calculated.completed_steps / calculated.total_steps) * 100
        : 0
  }

  return (
    <>
      <Col span={24} sm={{ span: 12 }} lg={{ span: 8 }}>
        <LearningPathCardWrapper
          role="button"
          tabIndex={0}
          onClick={() => navigate(`/paths/${path._id}`)}
          aria-label={path.title}
          onKeyDown={(e) => e.key === 'Enter' && navigate(`/paths/${path._id}`)}
        >
          <CardBody>
            <Title>
              <Space align="center">
                {path.title}
                {!path.published && (
                  <Tag style={{ verticalAlign: 'middle' }}>
                    <Trans id="path.tag.draft">Concept</Trans>
                  </Tag>
                )}
                {path.my_activity?.completed && (
                  <Tag color="success" style={{ verticalAlign: 'middle' }}>
                    <Trans id="path.tag.completed">Voltooid</Trans>
                  </Tag>
                )}
              </Space>
            </Title>
            <Intro>{path.intro}</Intro>
            {calculated && progressPct > 0 && progressPct < 100 && (
              <>
                <Progress
                  percent={Math.max(progressPct, 2)}
                  showInfo={false}
                  size={'small'}
                  strokeWidth={6}
                  trailColor={'#F0F0F0'}
                  style={{ marginTop: 16 }}
                  aria-label={
                    <Trans id="course.card.progress">
                      voor {Math.ceil(progressPct)}% voltooid
                    </Trans>
                  }
                />
                {progressPct > 0 ? (
                  <ProgressLabel>
                    <Trans id="course.card.progress">
                      voor {Math.ceil(progressPct)}% voltooid
                    </Trans>
                  </ProgressLabel>
                ) : (
                  <ProgressLabel>
                    <Trans id="course.card.started">gestart</Trans>
                  </ProgressLabel>
                )}
              </>
            )}
          </CardBody>
        </LearningPathCardWrapper>
      </Col>
    </>
  )
}
