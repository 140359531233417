import { ArrowLeftOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Dropdown } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { FC, ReactNode, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { AbilityContext } from '../../../auth/components/Can'
import { StatusBar } from '../../../shared/components/status-bar/StatusBar'
import {
  Avatar,
  HeaderContainer,
  Header as AntHeader,
} from '../../../shared/layout/Layout.style'

import { HeaderTitle, HeaderTitleText } from './Header.style'

interface Props {
  title?: string | ReactNode
  onBack?: () => void
}

export const Header: FC<Props> = ({ title, onBack }) => {
  const ability = useContext(AbilityContext)
  const branch = useBranch()
  const navigate = useNavigate()
  const { user, logout } = useAuth()

  return (
    <>
      <StatusBar />
      <AntHeader style={{ backgroundColor: branch?.theme.headerColor }}>
        <HeaderContainer>
          {onBack && (
            <ArrowLeftOutlined
              onClick={onBack}
              style={{ marginRight: 20, color: '#FFF' }}
            />
          )}
          {title ? (
            <HeaderTitle>
              <HeaderTitleText>{title}</HeaderTitleText>
            </HeaderTitle>
          ) : (
            <>&nbsp;</>
          )}
          {user && (
            <Dropdown
              placement="bottomRight"
              trigger={['click']}
              menu={{
                onClick: ({ key }) =>
                  !key.startsWith('external:') && navigate(`/${key}`),
                items: [
                  {
                    key: 'external:profile',
                    label: t({
                      id: 'header.navigation.profile',
                      message: 'Mijn profiel',
                    }),
                    onClick: () =>
                      window
                        ?.open(`${window.location.origin}/profile`, '_blank')
                        ?.focus(),
                  },
                  {
                    key: 'certificates',
                    label: t({
                      id: 'header.navigation.certificates',
                      message: 'Mijn attesten',
                    }),
                  },
                  ability.can(
                    PermissionAction.CONFIGURE,
                    PermissionObjectType.PLATFORM_SETTINGS
                  ) && {
                    key: 'external:settings',
                    onClick: () =>
                      window
                        ?.open(
                          `${window.location.origin}/settings/general`,
                          '_blank'
                        )
                        ?.focus(),
                    label: t({
                      id: 'header.navigation.settings',
                      message: 'Instellingen',
                    }),
                  },
                  { type: 'divider' },
                  {
                    key: 'external:logout',
                    label: t({
                      id: 'header.navigation.logout',
                      message: 'Uitloggen',
                    }),
                    onClick: () => logout(),
                  },
                ].filter(Boolean) as ItemType[],
              }}
            >
              <Avatar
                size={40}
                style={{ minWidth: 40 }}
                src={user.picture?.url}
              >
                {user.firstName.charAt(0)}
              </Avatar>
            </Dropdown>
          )}
        </HeaderContainer>
      </AntHeader>
    </>
  )
}
