/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CheckOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { Col, Row, Space } from 'antd'
import dayjs from 'dayjs'

import { StreamActionEnum } from '@lms-shared-patterns/enums/event.enums'

import { LoadSection } from '../../../core/components/LoadScreen'
import { ExportButton } from '../../../shared/components/export-button/ExportButton'
import { useAxios } from '../../../shared/hooks/use-axios'
import { dateRenderer } from '../../helpers/renderers'
import { renderLocationType, renderLogType } from '../EventUsersReport'
import { Table } from '../Table'
import { TableHeader } from '../TableHeader/TableHeader.style'
import { UserEventSurveyReport } from '../UserEventSurveyReport'

export const UserEventsReport = ({
  userId,
  sectionId,
  branchId,
  header,
  start,
  end,
  exportable,
}: {
  userId: string
  sectionId: string | null | undefined
  branchId?: string
  header?: string
  start?: string
  end?: string
  exportable?: string
}) => {
  const [{ data }] = useAxios({
    url: `/api/activity/live-events/user/${userId}`,
    params: {
      section_id: sectionId ?? undefined,
      branch_id: branchId ?? undefined,
      ...(start && end ? { start, end } : {}),
    },
  })

  if (!data)
    return (
      <>
        <div className="ant-table-wrapper">
          <div className="ant-table ant-table-small ant-table-bordered">
            <div className="ant-table-title">
              {header && <TableHeader>{header}</TableHeader>}
            </div>
            <div className="ant-table-footer">
              <LoadSection />
            </div>
          </div>
        </div>
      </>
    )

  return (
    <Table
      {...(header && {
        title: () => <TableHeader>{header}</TableHeader>,
      })}
      expandable={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        expandedRowRender: (record: any) => (
          <Table
            size="small"
            dataSource={record.log}
            pagination={false}
            expandable={{
              expandedRowRender: () => (
                <UserEventSurveyReport
                  questions={record.questions || []}
                  answers={record.activity?.survey_answers || []}
                />
              ),
              expandRowByClick: true,
              rowExpandable: (logEntry: any) => {
                const isAnsweredSurvey =
                  logEntry.type === StreamActionEnum.ANSWERED_SURVEY
                const hasSurveyAnswers =
                  record.activity?.survey_answers?.length > 0

                return isAnsweredSurvey && hasSurveyAnswers
              },
            }}
            columns={[
              {
                title: t({
                  id: 'reports.users_report.table.log.time',
                  message: 'Tijd',
                }),
                dataIndex: 'date',
                key: 'date',
                render: (date) => dayjs(date).format('DD/MM/YYYY - HH:mm:ss'),
              },
              {
                title: t({
                  id: 'reports.users_report.table.log',
                  message: 'Log',
                }),
                dataIndex: 'type',
                key: 'type',
                render: renderLogType,
              },
            ]}
          />
        ),
        expandRowByClick: true,
        rowExpandable: (record: any) => record.log?.length > 0,
      }}
      columns={[
        {
          title: t({
            id: 'reports.live_events.table.date',
            message: 'Datum',
          }),
          sortDirections: ['ascend', 'descend'],
          dataIndex: 'start',
          key: 'start',
          render: (date: string) => dayjs(date).format('DD/MM/YYYY'),
          width: 100,
        },
        {
          title: t({
            id: 'reports.live_events.table.events',
            message: 'Evenementen',
          }),
          sortDirections: ['ascend', 'descend'],
          dataIndex: 'name',
          key: 'name',
          // @ts-ignore
          sorter: (a: { name: string }, b: { name: string }) =>
            a.name.localeCompare(b.name),
        },
        {
          title: t({
            id: 'reports.users_report.table.location_type',
            message: 'Type',
          }),
          dataIndex: 'location_type',
          key: 'location_type',
          render: renderLocationType,
        },
        {
          title: t({
            id: 'reports.users_report.table.attended',
            message: 'Aanwezig',
          }),
          dataIndex: 'joined',
          key: 'joined',
          render: (date) => (date ? <CheckOutlined /> : '-'),
          align: 'center' as const,
        },
        {
          title: t({
            id: 'reports.users_report.table.completed',
            message: 'Voltooid op',
          }),
          dataIndex: 'completed',
          key: 'completed',
          render: dateRenderer,
          align: 'center' as const,
        },
      ]}
      bordered
      size="small"
      dataSource={data}
      rowKey="_id"
      pagination={false}
      footer={() => {
        if (!exportable) {
          return (
            <Row align={'middle'}>
              <Col xs={24} sm={8}></Col>
              <Col xs={24} sm={8}>
                <div style={{ textAlign: 'right' }}>
                  <Space>
                    <span>
                      <Trans id="reports.courses_report.action.export">
                        Exporteer naar:
                      </Trans>{' '}
                    </span>
                    {['xlsx', 'csv', 'json'].map((type, index) => (
                      <ExportButton
                        key={index}
                        url={`/api/export/user/${userId}/events`}
                        params={
                          start && end
                            ? {
                                branch_id:
                                  branchId ||
                                  sessionStorage.getItem('aa_report_filter'),
                                start,
                                end,
                              }
                            : {
                                branch_id:
                                  branchId ||
                                  sessionStorage.getItem('aa_report_filter'),
                              }
                        }
                        type={type}
                        name={
                          data?.user?.name.replaceAll(/\s/g, '_') ||
                          `user_${userId}`
                        }
                        dates={['start', 'attended', 'completed']}
                      />
                    ))}
                  </Space>
                </div>
              </Col>
            </Row>
          )
        }
        return (
          <div style={{ textAlign: 'right' }}>
            <Space>
              <span>
                <Trans id="reports.courses_report.action.export">
                  Exporteer naar:
                </Trans>{' '}
              </span>
              {['xlsx', 'csv', 'json'].map((type, index) => (
                <ExportButton
                  key={index}
                  url={exportable || '/api/export/users'}
                  params={
                    start && end
                      ? {
                          start,
                          end,
                        }
                      : {}
                  }
                  type={type}
                  name="personal"
                  dates={['start', 'attended', 'completed']}
                />
              ))}
            </Space>
          </div>
        )
      }}
    />
  )
}
