import { useQuery } from '@apollo/client'
import { ObjectId } from 'bson'
import { useMemo } from 'react'

import {
  BranchMetaQuery,
  FindBranchesByMetaQuery,
  SoftwareTypesQuery,
} from 'apps/lms-front/src/generated/graphql'

import { useAuth } from '../../auth/hooks/use-auth'

import BRANCH_META from './../../branch/queries/branch-meta.graphql'
import BRANCHES from './../queries/branches.graphql'
import SOFTWARE_TYPES from './../queries/software-types.graphql'

export const SOFTWARE_TYPE_ID = '66321015cf138d1d91efa522'

export const useSoftware = (type?: (string | undefined | null)[] | null) => {
  const { user } = useAuth()

  /**
   * Check if `Software`-mode is active
   */
  const active = useMemo(() => {
    return (
      type?.some(
        (id) =>
          id &&
          ObjectId.isValid(id) &&
          new ObjectId(id).equals(SOFTWARE_TYPE_ID)
      ) &&
      user?.channels?.some((c) =>
        new ObjectId('6694dc12e2c2cb44f79ac63a').equals(c._id)
      ) // Software & More channel
    )
  }, [type, user])

  const subtypes = useMemo(() => {
    return type?.filter((id) => id && !ObjectId.isValid(id))
  }, [type])

  const { data: branches, loading } = useQuery<FindBranchesByMetaQuery>(
    BRANCHES,
    {
      skip: !active,
      variables: {
        meta: 'include_in_tools',
      },
    }
  )

  const { data: types } = useQuery<SoftwareTypesQuery>(SOFTWARE_TYPES, {
    fetchPolicy: 'cache-and-network',
    skip: !active,
  })

  const { data: branchMeta, loading: loadingMeta } = useQuery<BranchMetaQuery>(
    BRANCH_META,
    {
      skip: !active,
    }
  )

  const filteredBranches = useMemo(() => {
    function filterEnabledTools(branches, branchMeta) {
      return branches?.filter((branch) => {
        const settings = JSON.parse(
          branchMeta?.branchMeta?.meta?.disabled_tools || '[]'
        )
        return !settings.includes(branch._id)
      })
    }

    function filterBySelectedSubtypes(branches, subtypes) {
      return branches?.filter((branch) => {
        return (
          subtypes?.some((subtype) =>
            ((branch.meta.software_type as string) || '').includes(subtype)
          ) || !subtypes?.length
        )
      })
    }

    const enabledTools = filterEnabledTools(
      branches?.findBranchesByMeta,
      branchMeta
    )
    return filterBySelectedSubtypes(enabledTools, subtypes)
  }, [branches, branchMeta, subtypes])

  return {
    active,
    vendors: [...(filteredBranches || [])]?.sort((a, b) =>
      a.name.localeCompare(b.name)
    ),
    loading: loading || loadingMeta,
    types: types?.fetchSoftwareTypes.filter(Boolean) || [],
    hide_software_cta: branchMeta?.branchMeta.meta?.hide_software_cta,
  }
}
