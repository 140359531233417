/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable unicorn/no-useless-undefined */
import { EditOutlined, EyeOutlined, TagOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import {
  Button,
  Form,
  Modal,
  PageHeader,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType, CompareFn } from 'antd/lib/table/interface'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { BaseSelectRef } from 'rc-select'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
  subject,
} from '@lms-shared-patterns/models'
import {
  Tag as TagModel,
  TagsQuery,
  BranchLiveEventAttributesQuery,
  MutationUpdateBranchLiveEventAttributesArgs,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { arraySort, defaultSort } from '../../../shared/utils/sort'
import BRANCH_LIVE_EVENTS_ATTRIBUTES_QUERY from '../../queries/branch-live-event-attributes.graphql'

import TAGS_QUERY from './../../../courses/queries/tags.graphql'
import UPDATE_BRANCH_LIVE_EVENT_ATTRIBUTES_MUTATION from './../../mutations/update-branch-live-event-attributes.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface BranchLiveEventsRow {
  key: string
  slug?: string
  title: string
  lecturer?: string
  tags: Partial<TagModel>[]
}

export const BranchLiveEvents = () => {
  const [attributesModalVisible, setAttributesModalVisible] =
    useState<boolean>(false)
  const [attributesModalSubject, setAttributesModalSubject] = useState<string>()
  const [page, setPage] = useQueryParam('page', NumberParam)
  const [pageSize, setPageSize] = useQueryParam('size', NumberParam)
  const [searchTerm, setSearchTerm] = useQueryParam('q', StringParam)
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const [attributesForm] = useForm()
  const tagSelector = useRef<BaseSelectRef | null>(null)
  const [tagSelectorOpen, setTagSelectorOpen] = useState(false)

  const { data: eventTags } = useQuery<TagsQuery>(TAGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      type: 'LIVE_EVENT',
    },
  })

  const { data, loading } = useQuery<BranchLiveEventAttributesQuery>(
    BRANCH_LIVE_EVENTS_ATTRIBUTES_QUERY,
    {
      variables: {
        page,
        limit: pageSize || 10,
        q: searchTerm,
      },
    }
  )

  const [update, { loading: updating }] =
    useMutation<MutationUpdateBranchLiveEventAttributesArgs>(
      UPDATE_BRANCH_LIVE_EVENT_ATTRIBUTES_MUTATION,
      {
        variables: {
          event_id: attributesModalSubject,
        },
        refetchQueries: [
          'branchLiveEventAttributes',
          {
            query: TAGS_QUERY,
            variables: {
              type: 'LIVE_EVENT',
            },
          },
        ],
      }
    )

  const liveEvents = useMemo(() => {
    return (
      data?.fetchBranchLiveEventAttributes.results?.map<BranchLiveEventsRow>(
        (item) => ({
          key: item._id,
          title: item.title,
          lecturer: item.lecturer || undefined,
          tags: item.tags || [],
        })
      ) || []
    )
  }, [data])

  const selectedLiveEvent = useMemo(() => {
    return liveEvents?.find((c) => c.key === attributesModalSubject)
  }, [attributesModalSubject, liveEvents])

  useEffect(() => {
    if (attributesModalSubject) {
      setAttributesModalVisible(true)
    }
  }, [attributesModalSubject])

  useEffect(() => {
    if (attributesModalVisible) attributesForm.resetFields()
  }, [attributesModalVisible, attributesForm])

  const columns: ColumnsType<BranchLiveEventsRow> = [
    {
      title: t({
        id: 'settings.live_events.table.name',
        message: 'Naam',
      }),
      dataIndex: 'title',
      key: 'title',
      sorter: defaultSort('title') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.live_events.table.lecturer',
        message: 'Docent',
      }),
      dataIndex: 'lecturer',
      key: 'lecturer',
      sorter: defaultSort('lecturer') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.live_events.table.tags',
        message: 'Tags',
      }),
      dataIndex: 'tags',
      key: 'tags',
      render: (tags: TagModel[]) =>
        tags.map((tag) => <Tag key={tag._id}>{tag.name}</Tag>),
      sorter: arraySort('tags') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.roles.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      width: 125,
      render: (_: string, record: BranchLiveEventsRow) => {
        const liveEvent = data?.fetchBranchLiveEventAttributes.results.find(
          (c) => c._id === record.key
        )

        if (!liveEvent) return false

        const canUpdate =
          ability.can(
            PermissionAction.UPDATE,
            PermissionObjectType.BRANCH_LIVE_EVENT
          ) ||
          ability.can(
            PermissionAction.UPDATE,
            subject(PermissionObjectType.BRANCH_LIVE_EVENT, liveEvent)
          )

        return (
          <ActionButtonWrapper>
            <Can
              I={PermissionAction.UPDATE}
              a={PermissionObjectType.BRANCH_LIVE_EVENT_ATTRIBUTES}
            >
              <Tooltip
                title={t({
                  id: 'actions.tag',
                  message: 'Taggen',
                })}
              >
                <Button
                  onClick={() => {
                    setAttributesModalSubject(record.key)
                  }}
                  shape="circle"
                  icon={<TagOutlined />}
                />
              </Tooltip>
            </Can>
            <Can I={PermissionAction.READ} a={PermissionObjectType.LIVE_EVENT}>
              <Tooltip
                title={t({
                  id: 'actions.show',
                  message: 'Weergeven',
                })}
              >
                <Button
                  onClick={() =>
                    navigate(
                      `/calendar?event=${encodeURIComponent(record.key)}`
                    )
                  }
                  shape="circle"
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            </Can>
            {canUpdate && (
              <Tooltip
                title={t({
                  id: 'actions.edit',
                  message: 'Bewerken',
                })}
              >
                <Button
                  onClick={() => navigate(`/events/${record.key}/edit`)}
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
          </ActionButtonWrapper>
        )
      },
    },
  ]

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'branch.live_events.heading',
          message: 'Evenementen',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'settings.branches.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            defaultValue={searchTerm || undefined}
            style={{ width: 200 }}
          />,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.live_events.table.empty',
            message: 'Geen sessies gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        dataSource={liveEvents}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page || 1,
          pageSize: pageSize || 10,
          onChange: (page: number) => {
            setPage(page)
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0
          },
          onShowSizeChange(_, size) {
            setPage(1)
            setPageSize(size)
          },
          total: data?.fetchBranchLiveEventAttributes.count,
        }}
      />
      <Modal
        forceRender
        okText={t({
          id: 'action.update',
          message: 'Wijzigen',
        })}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        open={!!attributesModalVisible}
        title={selectedLiveEvent?.title || ''}
        onOk={() => attributesForm.submit()}
        onCancel={() => setAttributesModalVisible(false)}
        okButtonProps={{ loading: updating }}
        afterClose={() => {
          setAttributesModalSubject(undefined)
          attributesForm.resetFields()
        }}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          key={selectedLiveEvent?.key}
          disabled={updating}
          form={attributesForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            tags: selectedLiveEvent?.tags.map((tag) => tag.name),
          }}
          onFinish={async (values) => {
            await update({
              variables: {
                attributes: {
                  ...values,
                  tags: values.tags.map(
                    (tag: string) =>
                      eventTags?.fetchTags.find((t) => t.name === tag)?._id ||
                      tag
                  ),
                },
              },
            })
            setAttributesModalVisible(false)
          }}
        >
          <Form.Item
            name={'tags'}
            label={t({
              id: 'branches.live_events.form.label.tags',
              message: 'Tags',
            })}
          >
            <Select
              ref={tagSelector}
              mode="tags"
              allowClear
              options={eventTags?.fetchTags?.map((tag) => ({
                label: tag.name,
                value: tag.name,
              }))}
              filterOption={(input, option) =>
                option?.label.toLowerCase().includes(input.toLowerCase()) ||
                false
              }
              onDropdownVisibleChange={(flag) => setTagSelectorOpen(flag)}
              open={tagSelectorOpen}
              notFoundContent={null}
              onSelect={() => setTagSelectorOpen(false)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
