/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Plural, Trans, t } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Space,
  Switch,
} from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  AssignCourseMutationVariables,
  BranchAccessibleCoursesQuery,
} from 'apps/lms-front/src/generated/graphql'

import { CharacterLimitHelper } from '../../../shared/components/character-limit-helper/CharacterLimitHelper'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { UserTreeSelect } from '../../../shared/form-fields/user-tree-select/UserTreeSelect'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'

import BRANCH_COURSES from './../../../branch/queries/branch-accessible-courses.graphql'

const MESSAGE_MAX_LENGTH = 2500

export const CourseAssignmentModal = ({
  visible,
  onVisibilityChange,
  onAssign,
  onUpdate,
  assigning,
  subject,
  course,
  users,
}: {
  course?: string
  users?: string[]
  subject?: {
    mandatory: boolean
    message: string
    deadline?: Dayjs
    reminders?: {
      date: Dayjs
      time_amount: number
      time_unit: 'hours' | 'days' | 'weeks'
    }[]
  }
  visible: boolean
  assigning: boolean
  onVisibilityChange: (state: boolean) => void
  onAssign?: (data: AssignCourseMutationVariables) => Promise<unknown>
  onUpdate?: (data: Partial<AssignCourseMutationVariables>) => Promise<unknown>
}) => {
  const [, contextHolder] = notification.useNotification()

  const { data: courses, loading: coursesLoading } =
    useQuery<BranchAccessibleCoursesQuery>(BRANCH_COURSES, {
      skip: !visible,
      fetchPolicy: 'cache-and-network',
    })

  const [form] = Form.useForm()
  const mandatoryField = Form.useWatch('mandatory', form)
  const deadlineField = Form.useWatch('deadline', form)
  const remindersField = Form.useWatch('reminders', form)
  const message = Form.useWatch('message', form)

  /**
   * Calculate and set reminders date field
   */
  useEffect(() => {
    for (let i = 0; i < remindersField?.length; i++) {
      const date =
        !Number.isNaN(remindersField[i]?.time_amount) &&
        remindersField[i]?.time_unit
          ? dayjs(deadlineField)
              .startOf('day')
              .subtract(
                remindersField[i].time_amount,
                remindersField[i].time_unit
              )
              .toISOString()
          : undefined
      form.setFieldValue(['reminders', i, 'date'], date)
    }
  }, [remindersField, deadlineField, form])

  const editSuccessMessage = t({
    id: 'modal.assign_courses.edit.success',
    message: 'Toewijzing succesvol aangepast',
  })

  const newSuccessMessage = (length: number) => (
    <Plural
      id="modal.assign_courses.new.success"
      one="Opleiding succesvol toegewezen"
      other="Opleidingen succesvol toegewezen"
      value={length}
    />
  )

  const handleVisibilityChange = (state) => {
    onVisibilityChange(state)
    if (state) form.resetFields()
  }

  return (
    <Modal
      forceRender
      title={
        subject
          ? t({
              id: 'modal.assign_courses.edit.title',
              message: 'Toewijzing aanpassen',
            })
          : t({
              id: 'modal.assign_courses.new.title',
              message: 'Opleidingen toewijzen',
            })
      }
      open={visible}
      okText={
        subject
          ? t({
              id: 'action.update',
              message: 'Wijzigen',
            })
          : t({
              id: 'action.assign',
              message: 'Toewijzen',
            })
      }
      cancelText={t({
        id: 'action.cancel',
        message: 'Annuleren',
      })}
      onOk={() => form.submit()}
      onCancel={() => handleVisibilityChange(false)}
      okButtonProps={{ loading: assigning }}
    >
      <Form
        form={form}
        initialValues={
          subject || { courses: course ? [course] : [], users: users || [] }
        }
        autoComplete="off"
        onFinish={async () => {
          const values = await form.validateFields()

          if (subject) {
            onUpdate?.({
              mandatory: !!values.mandatory,
              reminders: values.reminders ?? [],
              deadline: values.deadline
                ? new Date(
                    new Date(values.deadline).setHours(0, 0, 0, 0)
                  ).toISOString() || null
                : null,
              message: values.message,
            })
              .then(() => {
                notification.success({
                  message: editSuccessMessage,
                })
                handleVisibilityChange(false)
                form.resetFields()
              })
              .catch(errorNotifierFn)
          } else {
            onAssign?.({
              courses: values.courses,
              assignees: values.users,
              mandatory: !!values.mandatory,
              notification: !!values.notification,
              reminders: values.reminders,
              deadline: values.deadline
                ? new Date(
                    new Date(values.deadline).setHours(0, 0, 0, 0)
                  ).toISOString() || null
                : null,
              message: values.message,
            })
              .then(() => {
                // SUCCESS
                notification.success({
                  message: newSuccessMessage(values.courses.length),
                })
                handleVisibilityChange(false)
                form.resetFields()
              })
              .catch(errorNotifierFn)
          }
        }}
      >
        <Form.Item
          name="courses"
          hidden={!!subject}
          label={t({
            id: 'modal.assign_courses.form.label.courses',
            message: 'Opleidingen',
          })}
          labelCol={{ span: 8 }}
          required={!subject}
          rules={[
            {
              required: !subject,
              message: t({
                id: 'modal.assign_courses.form.validation.courses',
                message: 'Selecteer minimaal 1 opleiding',
              }),
            },
          ]}
        >
          <Select
            mode="multiple"
            disabled={coursesLoading && !courses}
            loading={coursesLoading && !courses}
            allowClear
            style={{ width: '100%' }}
            optionFilterProp={'label'}
            placeholder={
              coursesLoading
                ? t({
                    id: 'modal.assign_courses.form.placeholder.loading',
                    message: 'Laden...',
                  })
                : t({
                    id: 'modal.assign_courses.form.placeholder.courses',
                    message: 'Selecteer opleidingen',
                  })
            }
            options={
              courses?.fetchBranchAccessibleCourses.results.map((course) => ({
                label: course.translation.name,
                value: course._id,
              })) || []
            }
          />
        </Form.Item>
        <Form.Item
          name="users"
          hidden={!!subject}
          label={t({
            id: 'modal.assign_courses.form.label.users',
            message: 'Toewijzen aan',
          })}
          labelCol={{ span: 8 }}
          required={!subject}
          rules={[
            {
              required: !subject,
              message: t({
                id: 'modal.assign_courses.form.validation.users',
                message: 'Selecteer minimaal 1 gebruiker of groep',
              }),
            },
          ]}
        >
          <UserTreeSelect
            filterByPermission={{
              action: PermissionAction.CREATE,
              object: PermissionObjectType.ASSIGNMENT,
            }}
          />
        </Form.Item>
        <Form.Item
          name="mandatory"
          label={t({
            id: 'modal.assign_courses.form.label.mandatory',
            message: 'Verplicht',
          })}
          labelCol={{ span: 8 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          hidden={!mandatoryField}
          name={'deadline'}
          label={t({
            id: 'modal.assign_courses.form.label.deadline',
            message: 'Deadline',
          })}
          labelCol={{ span: 8 }}
        >
          <DatePicker
            allowClear={true}
            format="DD/MM/YYYY"
            style={{ width: '100%' }}
            disabledDate={(current) => current.isBefore(new Date())}
            showToday={false}
          />
        </Form.Item>
        <Form.Item
          name={'message'}
          label={t({
            id: 'modal.assign_courses.form.label.message',
            message: 'Bericht',
          })}
          labelCol={{ span: 8 }}
          help={
            <CharacterLimitHelper content={message} max={MESSAGE_MAX_LENGTH} />
          }
          rules={[
            {
              max: MESSAGE_MAX_LENGTH,
              message: t({
                id: 'modal.assign_courses.form.validation.max_characters',
                message: `Gelieve onder de ${MESSAGE_MAX_LENGTH} tekens te blijven`,
              }),
            },
          ]}
        >
          <Input.TextArea
            placeholder={t({
              id: 'modal.assign_courses.form.placeholder.message',
              message: 'Dit veld is optioneel.',
            })}
          />
        </Form.Item>
        <Form.Item
          name="notification"
          hidden={!!subject}
          label={t({
            id: 'modal.assign_courses.form.label.notification',
            message: 'Stuur een melding',
          })}
          labelCol={{ span: 8 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t({
            id: 'modal.assign_courses.form.label.reminders',
            message: 'Herinneringen',
          })}
          labelCol={{ span: 8 }}
          hidden={!deadlineField}
          style={{ marginBottom: 0 }}
        >
          <Form.List name="reminders">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex' }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'time_amount']}
                      initialValue={1}
                      rules={[
                        {
                          required: true,
                          message: t({
                            id: 'modal.assign_courses.form.validation.reminder_time_amount',
                            message: 'Verplicht',
                          }),
                        },
                      ]}
                    >
                      <InputNumber style={{ maxWidth: 72 }} min={0} max={99} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'time_unit']}
                      rules={[
                        {
                          required: true,
                          message: t({
                            id: 'modal.assign_courses.form.validation.reminder_time_unit',
                            message: 'Verplicht',
                          }),
                        },
                      ]}
                      initialValue={'days'}
                    >
                      <Select
                        options={[
                          {
                            label: t({
                              id: 'modal.assign_courses.form.reminder_time_unit.hours',
                              message: 'uren',
                            }),
                            value: 'hours',
                          },
                          {
                            label: t({
                              id: 'modal.assign_courses.form.reminder_time_unit.days',
                              message: 'dagen',
                            }),
                            value: 'days',
                          },
                          {
                            label: t({
                              id: 'modal.assign_courses.form.reminder_time_unit.weeks',
                              message: 'weken',
                            }),
                            value: 'weeks',
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Trans id="modal.assign_courses.form.reminder_before">
                        voor de deadline
                      </Trans>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'date']}
                      rules={[
                        {
                          required: true,
                          message: t({
                            id: 'modal.assign_courses.form.validation.deadline',
                            message: 'Verplicht',
                          }),
                        },
                      ]}
                      hidden
                    >
                      <Input />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    <Trans id="modal.assign_courses.form.add_reminder">
                      Voeg een herinnering toe
                    </Trans>
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
      {contextHolder}
    </Modal>
  )
}
