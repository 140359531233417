import { QrcodeOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Button, Dropdown, Tooltip } from 'antd'
import download from 'js-file-download'

import { LiveEventsQuery } from 'apps/lms-front/src/generated/graphql'

import { authenticatedAxiosClient } from '../../shared/hooks/use-axios'
import { useEventStatus } from '../hooks/use-event-status.hook'

export const DownloadEventAttendanceQr = ({
  event,
}: {
  event: LiveEventsQuery['fetchLiveEvents']['results'][0]
}) => {
  const { daysUntilEvent } = useEventStatus(event)
  return (
    daysUntilEvent <= 7 && (
      <Tooltip
        title={
          <Trans id="event.attendance.download_qr">
            Download QR-code voor fysieke aanwezigheidscontrole
          </Trans>
        }
      >
        <Dropdown
          trigger={['click']}
          menu={{
            items: [
              {
                key: 'print',
                label: (
                  <Trans id="event.attendance.download_qr.print">
                    PDF (afdrukken)
                  </Trans>
                ),
              },
              {
                key: 'online',
                label: (
                  <Trans id="event.attendance.download_qr.online">
                    PNG (online)
                  </Trans>
                ),
              },
            ],
            onClick: async ({ key }) => {
              const axios = authenticatedAxiosClient()
              const file = await axios.get(
                `${import.meta.env.NX_BACKEND_URL}/api/event/attendance/${
                  event._id
                }/qr?host=${window.location.host}&type=${key}`,
                {
                  responseType: 'blob',
                }
              )
              const extension = key === 'print' ? 'pdf' : 'png'
              const mimetype = key === 'print' ? 'application/pdf' : 'image/png'
              download(file.data, `qr-${event._id}.${extension}`, mimetype)
            },
          }}
        >
          <Button icon={<QrcodeOutlined />} />
        </Dropdown>
      </Tooltip>
    )
  )
}
