import { InboxOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'

import { PathMode, PathQuery } from 'apps/lms-front/src/generated/graphql'

type PathData = PathQuery['fetchLearningPathById']

type PathStatus = {
  openForRegistration: boolean
  openForAdminRegistration: boolean
  hasRegistrationDeadline: boolean
  hasReachedCapacity: boolean
  hasExceededCapacity: boolean
  registrationStop: boolean
  closed: boolean
  archived: boolean
  draft: boolean
  remaining?: number
  alert: React.ReactNode[] | null
}

// Helper functions to improve readability and reusability
const hasReachedCapacity = (
  capacity: PathData['capacity'],
  participant_count: number
): boolean => {
  return !!capacity && participant_count >= capacity
}

const hasExceededCapacity = (
  capacity: PathData['capacity'],
  participant_count: number
): boolean => {
  return !!capacity && participant_count > capacity
}

const calculateRemaining = (
  capacity: number | null | undefined,
  participant_count: number
): number | undefined => {
  return capacity ? capacity - participant_count : undefined
}

const isRegistrationDeadlinePassed = (
  deadline: string | null | undefined
): boolean => {
  return deadline ? dayjs(deadline).isBefore(dayjs()) : false
}

const getPathStatusAlerts = (isArchived: boolean): React.ReactNode[] | null => {
  const alerts: React.ReactNode[] = []

  if (isArchived) {
    alerts.push(
      <Alert
        key="archived"
        showIcon
        icon={<InboxOutlined />}
        banner
        type="warning"
        message={
          <Trans id="paths.card.archived">Dit leerpad is gearchiveerd</Trans>
        }
      />
    )
  }

  return alerts.length > 0 ? alerts : null
}

export const useLearningPathStatus = (pathData: PathData): PathStatus => {
  return useMemo(() => {
    // Calculate core status flags
    const remaining = calculateRemaining(
      pathData.capacity,
      pathData.participant_count
    )

    const published = !!pathData.published
    const archived = !!pathData.archived

    const closed = isRegistrationDeadlinePassed(pathData.registration_deadline)

    const full = hasReachedCapacity(
      pathData.capacity,
      pathData.participant_count
    )

    return {
      openForRegistration:
        published && !closed && !full && pathData.mode === PathMode.Open,
      openForAdminRegistration: !full,
      hasRegistrationDeadline: !!pathData.registration_deadline,
      hasReachedCapacity: full,
      hasExceededCapacity: hasExceededCapacity(
        pathData.capacity,
        pathData.participant_count
      ),
      registrationStop: full,
      closed,
      draft: !published,
      remaining,
      archived,

      alert: getPathStatusAlerts(!!pathData.archived),
    }
  }, [
    pathData.published,
    pathData.registration_deadline,
    pathData.capacity,
    pathData.participant_count,
    pathData.mode,
    pathData.archived,
  ])
}

// Export types for consumers
export type { PathStatus, PathData }
