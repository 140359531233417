import { useMutation } from '@apollo/client'
import { Plural, t, Trans } from '@lingui/macro'
import { Form, Modal, notification, Radio } from 'antd'
import { Rule } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'

import { EventLocationType } from '@lms-shared-patterns/enums/event.enums'
import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  LiveEventsQuery,
  RegisterParticipantsForLiveEventMutation,
} from 'apps/lms-front/src/generated/graphql'

import { UserTreeSelect } from '../../shared/form-fields/user-tree-select/UserTreeSelect'
import { useEventStatus } from '../hooks/use-event-status.hook'
import REGISTER_PARTICIPANTS_FOR_LIVE_EVENT_MUTATION from '../mutations/register-participants-for-live-event.graphql'
import LIVE_EVENT_PARTICIPANTS_QUERY from '../queries/live-event-participants.graphql'

export const AddEventParticipantsModal = ({
  event,
  participants: registeredParticipants = [],
  open,
  onClose,
}: {
  event: LiveEventsQuery['fetchLiveEvents']['results'][0]
  participants: string[]
  open: boolean
  onClose: () => void
}) => {
  const [form] = useForm()
  const participants = Form.useWatch('participants', form)
  const location_type = Form.useWatch('type', form)

  const { hasReachedCapacity, remaining } = useEventStatus(event)

  const [addParticipants, { loading: adding }] =
    useMutation<RegisterParticipantsForLiveEventMutation>(
      REGISTER_PARTICIPANTS_FOR_LIVE_EVENT_MUTATION,
      {
        variables: {
          event_id: event._id,
          user_ids: participants,
        },
        refetchQueries: [
          {
            query: LIVE_EVENT_PARTICIPANTS_QUERY,
            variables: {
              id: event._id,
            },
          },
        ],
        onCompleted: () => {
          onClose()
          form.resetFields()
          notification.success({
            message: t({
              id: 'events.participants.add_participants.success',
              message:
                'De deelnemers worden op de achtergrond toegevoegd. Dit kan enkele minuten duren.',
            }),
          })
        },
      }
    )

  return (
    <Modal
      title={
        <Trans id="events.participants.add_participants">
          Deelnemers toevoegen
        </Trans>
      }
      open={open}
      onCancel={() => {
        onClose()
        form.resetFields()
      }}
      onOk={() => {
        form.validateFields().then(() =>
          addParticipants({
            variables: {
              type: form.getFieldValue('type'),
            },
          })
        )
      }}
      okButtonProps={{
        loading: adding,
      }}
      okText={t({
        id: 'action.add',
        message: 'Toevoegen',
      })}
    >
      <Form form={form}>
        <Form.Item
          name="participants"
          label={t({
            id: 'events.form.label.participants',
            message: 'Deelnemers',
          })}
          rules={
            [
              {
                required: true,
                message: t({
                  id: 'events.form.validation.participants_required',
                  message: 'Selecteer minimaal 1 deelnemer',
                }),
              },
              remaining === undefined
                ? false
                : ({
                    validator: (_, value) => {
                      if (
                        value.length > remaining &&
                        location_type === EventLocationType.Physical
                      ) {
                        return Promise.reject()
                      }
                      return Promise.resolve()
                    },
                    message: (
                      <Plural
                        id="events.form.validation.participants_max"
                        zero={'Er zijn geen plekken meer beschikbaar.'}
                        one={'Er is nog 1 plek beschikbaar.'}
                        other="Er zijn nog slechts # plekken beschikbaar."
                        value={remaining}
                      />
                    ),
                  } as Rule),
            ].filter(Boolean) as Rule[]
          }
        >
          <UserTreeSelect
            hiddenKeys={registeredParticipants}
            filterByPermission={{
              action: PermissionAction.READ,
              object: PermissionObjectType.BRANCH_USER,
            }}
          />
        </Form.Item>
        <Form.Item
          hidden={event.location_type.length === 1}
          name="type"
          label={t({
            id: 'events.participant-form.label.location_type',
            message: 'Locatietype',
          })}
          initialValue={event.location_type[0]}
        >
          <Radio.Group>
            {hasReachedCapacity ||
            !!(
              remaining &&
              participants &&
              remaining < participants.length
            ) ? undefined : (
              <Radio value={EventLocationType.Physical}>
                <Trans id="events.participant-form.label.physical">
                  Fysiek
                </Trans>
              </Radio>
            )}
            <Radio value={EventLocationType.Online}>
              <Trans id="events.participant-form.label.online">Online</Trans>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}
