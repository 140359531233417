import { useMutation } from '@apollo/client'
import { Plural, t, Trans } from '@lingui/macro'
import { Form, Modal, notification } from 'antd'
import { Rule } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  AddLearningPathParticipantsMutation,
  PathQuery,
} from 'apps/lms-front/src/generated/graphql'

import { UserTreeSelect } from '../../shared/form-fields/user-tree-select/UserTreeSelect'
import { useLearningPathStatus } from '../hooks/use-path-status.hook'
import ADD_LEARNING_PATH_PARTICIPANTS_MUTATION from '../mutations/add-path-participants.graphql'

export const AddLearningPathParticipantsModal = ({
  path,
  participants: registeredParticipants = [],
  open,
  onClose,
}: {
  path: PathQuery['fetchLearningPathById']
  participants?: string[]
  open: boolean
  onClose: () => void
}) => {
  const [form] = useForm()
  const participants = Form.useWatch('participants', form)

  const { remaining } = useLearningPathStatus(path)

  const [addParticipants, { loading: adding }] =
    useMutation<AddLearningPathParticipantsMutation>(
      ADD_LEARNING_PATH_PARTICIPANTS_MUTATION,
      {
        variables: {
          path_id: path._id,
          user_ids: participants,
        },
        refetchQueries: ['path'],
        onCompleted: () => {
          onClose()
          form.resetFields()
          notification.success({
            message: t({
              id: 'paths.participants.add_participants.success',
              message:
                'De deelnemers worden op de achtergrond toegevoegd. Dit kan enkele minuten duren.',
            }),
          })
        },
      }
    )

  return (
    <Modal
      title={
        <Trans id="paths.participants.add_participants">
          Deelnemers toevoegen
        </Trans>
      }
      open={open}
      onCancel={() => {
        if (form.isFieldsTouched()) {
          Modal.confirm({
            title: t({
              id: 'paths.participants.add_participants.unsaved_changes',
              message: 'Niet-opgeslagen wijzigingen',
            }),
            content: t({
              id: 'paths.participants.add_participants.unsaved_changes_warning',
              message:
                'Je hebt niet-opgeslagen wijzigingen. Weet je zeker dat je deze wilt annuleren?',
            }),
            onOk: () => {
              onClose()
              form.resetFields()
            },
            okText: t({
              id: 'action.yes',
              message: 'Ja',
            }),
            cancelText: t({
              id: 'action.no',
              message: 'Nee',
            }),
          })
          return
        }
        onClose()
        form.resetFields()
      }}
      onOk={() => form.validateFields().then(() => addParticipants())}
      okButtonProps={{
        loading: adding,
        disabled: !participants || participants.length === 0,
      }}
      okText={t({
        id: 'action.add',
        message: 'Toevoegen',
      })}
    >
      <Form form={form}>
        <Form.Item
          name="participants"
          label={t({
            id: 'paths.form.label.participants',
            message: 'Deelnemers',
          })}
          rules={
            [
              {
                required: true,
                message: t({
                  id: 'paths.form.validation.participants_required',
                  message: 'Selecteer minimaal 1 deelnemer',
                }),
              },
              remaining === undefined
                ? false
                : ({
                    validator: (_, value) => {
                      if (value.length > remaining) {
                        return Promise.reject()
                      }
                      return Promise.resolve()
                    },
                    message: (
                      <Plural
                        id="paths.form.validation.participants_max"
                        zero={'Er zijn geen plekken meer beschikbaar.'}
                        one={'Er is nog 1 plek beschikbaar.'}
                        other="Er zijn nog slechts # plekken beschikbaar."
                        value={remaining}
                      />
                    ),
                  } as Rule),
            ].filter(Boolean) as Rule[]
          }
        >
          <UserTreeSelect
            hiddenKeys={registeredParticipants}
            filterByPermission={{
              action: PermissionAction.READ,
              object: PermissionObjectType.BRANCH_USER,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
