import { UploadOutlined } from '@ant-design/icons'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Col,
  message,
  notification,
  PageHeader,
  Progress,
  Row,
  Steps,
  Table,
  Upload,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { RcFile } from 'antd/lib/upload'
import { ObjectId } from 'bson'
import { parse as papaparse } from 'papaparse'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
const { Step } = Steps

import {
  CourseTypesQuery,
  CreateCourseInput,
  CreateCourseMutation,
} from 'apps/lms-front/src/generated/graphql'

import { PageProps } from '../../../shared/interfaces/page.interface'
import { Content } from '../../../shared/layout/Layout.style'
import { arraysEqual } from '../../../shared/utils/arrays-equal'

import CREATE_COURSE_MUTATION from './../../mutations/create-course.graphql'
import COURSE_TYPES_QUERY from './../../queries/course-types.graphql'
import { StepContent } from './ImportCourses.style'

interface CourseImport {
  name: string
  slug: string
  code: string
  short_description: string
  description: string
  type: string
  duration: string
  lecturer: string
}

export const ImportCourses = ({ route }: PageProps) => {
  const navigate = useNavigate()
  const client = useApolloClient()

  const [fileList, setFileList] = useState<RcFile[]>()
  const [dataPreview, setDataPreview] = useState<CreateCourseInput[]>()
  const [current, setCurrent] = useState(0)
  const [rows, setRows] = useState(0)
  const [processingRow, setProcessingRow] = useState(0)
  const [processing, setProcessing] = useState(false)

  const { data: typesData } = useQuery<CourseTypesQuery>(COURSE_TYPES_QUERY)
  const [createCourse] = useMutation<CreateCourseMutation>(
    CREATE_COURSE_MUTATION
  )

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const parse = (file: RcFile, limit?: number) =>
    new Promise((resolve, reject) => {
      papaparse(file, {
        complete: (results) => resolve(results.data),
        error: (error) => reject(error),
        header: limit === 1 ? false : true,
        preview: limit,
        skipEmptyLines: true,
      })
    })

  useEffect(() => {
    const parseCSV = async () => {
      if (fileList && fileList.length > 0) {
        const parsedDataPreview = await parse(fileList?.[0], 5)
        setDataPreview(
          (parsedDataPreview as [CourseImport]).map((course) =>
            courseMapper(course)
          )
        )
      }
    }
    parseCSV()
  }, [fileList])

  useEffect(() => {
    if (current === 2) {
      const reader = new FileReader()
      if (fileList && fileList.length > 0) {
        reader.readAsText(fileList?.[0])
        reader.addEventListener('load', (event) => {
          const csv = (event.target as FileReader).result
          const csvArray = (csv as string).split('\n')
          setRows(csvArray.length)
        })
      }
    }
  }, [current, fileList])

  useEffect(() => {
    const parseHeader = async () => {
      if (fileList && fileList.length > 0) {
        const header = await parse(fileList?.[0], 1)
        if (
          !arraysEqual((header as string[][])[0], [
            'name',
            'slug',
            'code',
            'short_description',
            'description',
            'type',
            'duration',
            'lecturer',
          ])
        ) {
          message.error(
            t({
              id: 'error.wrong_file_structure',
              message: 'Het bestand heeft een verkeerde structuur.',
            })
          )
          setFileList([])
        }
      }
    }
    parseHeader()
  }, [fileList])

  const columns: ColumnsType<CreateCourseInput> = [
    {
      title: t({
        id: 'courses.import.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t({
        id: 'courses.import.table.slug',
        message: 'Slug',
      }),
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: t({
        id: 'courses.import.table.code',
        message: 'Code',
      }),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t({
        id: 'courses.import.table.short_description',
        message: 'Korte beschrijving',
      }),
      dataIndex: 'short_description',
      key: 'short_description',
      ellipsis: true,
    },
    {
      title: t({
        id: 'courses.import.table.description',
        message: 'Beschrijving',
      }),
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: t({
        id: 'courses.import.table.type',
        message: 'Type',
      }),
      dataIndex: 'type',
      key: 'type',
      render: (id: string) =>
        typesData?.fetchCourseTypes.find((type) =>
          new ObjectId(type._id).equals(id)
        )?.name || '',
    },
    {
      title: t({
        id: 'courses.import.table.duration',
        message: 'Duurtijd',
      }),
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: t({
        id: 'courses.import.table.lecturer',
        message: 'Docent',
      }),
      dataIndex: 'lecturer',
      key: 'lecturer',
    },
  ]

  const steps = [
    {
      title: t({
        id: 'courses.import.step.upload',
        message: 'Uploaden',
      }),
      content: (
        <StepContent>
          <h3>
            <Trans id="courses.import.step.upload.title">
              Bestand uploaden
            </Trans>
          </h3>
          <p>
            <Trans id="courses.import.step.upload.description">
              Selecteer een passend CSV-bestand met dezelfde structuur als ons{' '}
              <a href="/examples/course-import.csv" target="_blank">
                voorbeeld
              </a>
              .
            </Trans>
          </p>
          <Upload
            fileList={fileList}
            beforeUpload={(file) => setFileList([file])}
          >
            <Button icon={<UploadOutlined />}>
              <Trans id="courses.import.step.upload.button">
                Selecteer een bestand
              </Trans>
            </Button>
          </Upload>
        </StepContent>
      ),
    },
    {
      title: t({
        id: 'courses.import.step.validate',
        message: 'Valideren',
      }),
      content: (
        <StepContent>
          <h3>
            <Trans id="courses.import.step.validate.title">
              Voorbeeldtabel
            </Trans>
          </h3>
          <p>
            <Trans id="courses.import.step.validate.description">
              Kijk even na of deze structuur klopt.
            </Trans>
          </p>
          {dataPreview && (
            <Table
              dataSource={dataPreview}
              columns={columns}
              pagination={false}
              rowKey="name"
            />
          )}
        </StepContent>
      ),
    },
    {
      title: t({
        id: 'courses.import.step.import',
        message: 'Importeren',
      }),
      content: (
        <StepContent>
          <h3>
            <Trans id="courses.import.step.import.title">
              Importeren starten
            </Trans>
          </h3>
          <p>
            <Trans id="courses.import.step.import.description">
              De pagina verlaten zal het importeren stoppen.
            </Trans>
          </p>
          <Progress percent={(processingRow / rows) * 100} status="active" />
        </StepContent>
      ),
    },
  ]

  const courseMapper = (course: CourseImport): CreateCourseInput => {
    const type = typesData?.fetchCourseTypes.find(
      (type) => type.name === course.type
    )
    return {
      name: course.name,
      slug:
        course.slug ||
        slugify(course.name, { lower: true, remove: /[!"'()*+,.:?@~]/g }),
      code: course.code || undefined,
      short_description: course.short_description || undefined,
      description: course.description || undefined,
      duration: Number.parseInt(course?.duration) || undefined,
      lecturer: course.lecturer || undefined,
      type: type?._id || undefined,
    }
  }

  const handleImport = () => {
    setProcessing(true)
    let currentRow = 0
    if (fileList && fileList.length > 0) {
      papaparse(fileList[0], {
        step: (results, parser) => {
          parser.pause()
          currentRow++
          setProcessingRow(currentRow)
          createCourse({
            variables: courseMapper(results.data as CourseImport),
          })
            .catch((error) => message.error(error.message))
            .finally(() => parser.resume())
        },
        complete: () => {
          notification.success({
            message: t({
              id: 'courses.import.success',
              message: 'Importeren voltooid',
            }),
          })
          setTimeout(function () {
            client.refetchQueries({ include: ['courses'] })
          }, 100)
          navigate('/library/courses')
        },
        error: (error) => {
          message.error(error.message)
          setProcessing(false)
          setProcessingRow(0)
        },
        header: true,
        skipEmptyLines: true,
      })
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
      />

      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24} lg={18}>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current > 0 && (
                <Button
                  disabled={processing}
                  style={{ margin: '0 8px' }}
                  onClick={() => prev()}
                >
                  <Trans id="courses.import.button.previous">Terug</Trans>
                </Button>
              )}
              {fileList &&
                fileList.length > 0 &&
                current < steps.length - 1 && (
                  <Button
                    disabled={processing}
                    type="primary"
                    onClick={() => next()}
                  >
                    <Trans id="courses.import.button.next">Volgende</Trans>
                  </Button>
                )}
              {current === steps.length - 1 && (
                <Button
                  disabled={processing}
                  type="primary"
                  onClick={handleImport}
                >
                  <Trans id="courses.import.button.start">
                    Start het importeren
                  </Trans>
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Content>
    </>
  )
}
