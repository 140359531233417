export enum EventType {
  VODEvent = 'VODEvent',
  LiveEvent = 'LiveEvent',
}

export enum LiveEventType {
  LiveEvent = 'LiveEvent',
  PathEvent = 'PathEvent',
}

export enum EventLocationType {
  Physical = 'Physical',
  Online = 'Online',
}

export enum EventMode {
  Open = 'Open', // Open to all users
  Closed = 'Closed', // Only invited users can join
}

export enum EventStatus {
  Scheduled = 'Scheduled', // Event is scheduled
  Started = 'Started', // Event is started
  Finished = 'Finished', // Event is finished
  Cancelled = 'Cancelled', // Event is cancelled
}

export enum EventActionType {
  Quiz = 'Quiz',
  Survey = 'Survey',
}

export enum EventUpdateTrigger {
  START = 'start',
  END = 'end',
  LOCATION = 'location',
  ROOM = 'room',
  CATERING = 'catering',
  TITLE = 'title',
  DESCRIPTION = 'description',
  LECTURER = 'lecturer',
  LOCATION_TYPE = 'location_type',
}

export enum StreamActionEnum {
  JOIN = 'JOIN',
  LEAVE = 'LEAVE',
  PASSED_ATTENTION_CHECK = 'PASSED_ATTENTION_CHECK',
  FAILED_ATTENTION_CHECK = 'FAILED_ATTENTION_CHECK',
  ANSWERED_SURVEY = 'ANSWERED_SURVEY',
  SCANNED_ONSITE_QR = 'SCANNED_ONSITE_QR',
  ADMIN_MARKED_ATTENDANCE = 'ADMIN_MARKED_ATTENDANCE',
  ADMIN_CLEARED_ATTENDANCE = 'ADMIN_CLEARED_ATTENDANCE',
  COMPLETED = 'COMPLETED',
}
