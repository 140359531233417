import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Plural, t, Trans } from '@lingui/macro'
import {
  Alert,
  Button,
  Form,
  Grid,
  Input,
  InputNumber,
  notification,
  Radio,
  Select,
  Space,
  StepProps,
  Steps,
  Switch,
} from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { EventMode } from '@lms-shared-patterns/enums/event.enums'
import {
  CreateLearningPathMutation,
  EventReminder,
  LanguagesQuery,
  PathQuery,
  UpdateLearningPathMutation,
} from 'apps/lms-front/src/generated/graphql'

import { useAuth } from '../../auth/hooks/use-auth'
import { LoadScreen } from '../../core/components/LoadScreen'
import { CharacterLimitHelper } from '../../shared/components/character-limit-helper/CharacterLimitHelper'
import DatePicker from '../../shared/components/date-picker/DatePicker'
import { RichEditor } from '../../shared/components/rich-editor/RichEditor'
import { errorNotifierFn } from '../../shared/helpers/error-notifier'
import { PageProps } from '../../shared/interfaces/page.interface'

import LANGUAGES_QUERY from './../../settings/queries/languages.graphql'
import CREATE_LEARNING_PATH_MUTATION from './../mutations/create-path.graphql'
import UPDATE_LEARNING_PATH_MUTATION from './../mutations/update-path.graphql'
import LEARNING_PATH_QUERY from './../queries/path.graphql'
import { StepsWrapper, StepWrapper } from './EditLearningPath.style'

const DetailsStep = ({ form }: { form: FormInstance }) => {
  const intro = Form.useWatch('intro', form)
  const { user } = useAuth()
  const { data: languages } = useQuery<LanguagesQuery>(LANGUAGES_QUERY)

  const MAX_CHARACTERS = {
    TITLE: 100,
    INTRO: 500,
    DESCRIPTION: 5000,
  }
  return (
    <StepWrapper>
      <Form.Item
        key={'title'}
        label={t({
          id: 'paths.form.label.title',
          message: 'Titel',
        })}
        name={'title'}
        rules={[
          {
            required: true,
            message: t({
              id: 'paths.form.validation.title',
              message: 'Gelieve een titel in te vullen.',
            }),
          },
          {
            max: MAX_CHARACTERS.TITLE,
            message: t({
              id: 'paths.form.validation.max_characters',
              message: `Gelieve onder de ${MAX_CHARACTERS.TITLE} tekens te blijven`,
            }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'language'}
        label={t({
          id: 'paths.form.label.language',
          message: 'Taal',
        })}
        initialValue={user?.lang || 'nl-BE'}
      >
        <Select
          options={[
            {
              label: t({
                id: 'paths.form.label.languages',
                message: 'Talen',
              }),
              options:
                languages?.fetchLanguages?.map((language) => ({
                  label: language.name,
                  value: language.code,
                })) || [],
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        label={t({
          id: 'paths.form.label.intro',
          message: 'Korte beschrijving',
        })}
        name="intro"
        help={
          <CharacterLimitHelper content={intro} max={MAX_CHARACTERS.INTRO} />
        }
        rules={[
          {
            max: MAX_CHARACTERS.INTRO,
            message: t({
              id: 'paths.form.validation.intro.max_characters',
              message: `Gelieve onder de ${MAX_CHARACTERS.INTRO} tekens te blijven`,
            }),
          },
        ]}
      >
        <Input.TextArea rows={2} />
      </Form.Item>
      <Form.Item
        key={'description'}
        label={t({
          id: 'paths.form.label.description',
          message: 'Beschrijving',
        })}
        name={'description'}
        rules={[
          {
            max: MAX_CHARACTERS.DESCRIPTION,
            message: t({
              id: 'paths.form.validation.max_characters',
              message: `Gelieve onder de ${MAX_CHARACTERS.DESCRIPTION} tekens te blijven`,
            }),
          },
        ]}
      >
        <RichEditor disableTextStyles />
      </Form.Item>
      <Form.Item
        key="start"
        label={t({
          id: 'paths.form.label.date',
          message: 'Wanneer zal het leerpad starten?',
        })}
        name="start"
        extra={
          <Trans id="paths.form.extra.start">
            Laat leeg voor een dynamische startdatum.
          </Trans>
        }
      >
        <DatePicker
          showNow={false}
          style={{ width: '100%' }}
          disabledDate={(date) => {
            return date.diff(dayjs(), 'days') < 0
          }}
          format="D MMMM YYYY"
        />
      </Form.Item>
    </StepWrapper>
  )
}

const RegistrationStep = (props: {
  form: FormInstance
  values: { [key: string]: { [key: string]: unknown } }
  path?: PathQuery['fetchLearningPathById'] & { participant_count?: number }
}) => {
  const mode = Form.useWatch('mode')

  const alreadyUsers =
    props.path?.participant_count && props.path?.participant_count > 0

  const approval = Form.useWatch('approval', props.form)

  return (
    <StepWrapper>
      <Form.Item
        name="mode"
        label={t({
          id: 'paths.form.label.registration_mode',
          message: 'Inschrijving',
        })}
        initialValue={EventMode.Open}
        rules={[
          {
            required: true,
            message: t({
              id: 'paths.form.validation.registration_mode',
              message: 'Selecteer een inschrijvingsmodus.',
            }),
          },
        ]}
        extra={
          <p>
            {mode === EventMode.Open
              ? t({
                  id: 'paths.form.help.registration_mode.open',
                  message: 'Iedereen kan zich inschrijven voor het leerpad.',
                })
              : t({
                  id: 'paths.form.help.registration_mode.closed',
                  message:
                    'Alleen beheerders kunnen gebruikers inschrijven voor dit leerpad.',
                })}
          </p>
        }
      >
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={EventMode.Open}>
            <Trans id="paths.form.label.registration_mode.open">Open</Trans>
          </Radio.Button>
          <Radio.Button value={EventMode.Closed}>
            <Trans id="paths.form.label.registration_mode.closed">
              Gesloten
            </Trans>
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      {mode === EventMode.Open && (
        <>
          <Form.Item
            name={'approval'}
            label={t({
              id: 'paths.form.label.approval',
              message: 'Goedkeuring nodig',
            })}
            valuePropName="checked"
            extra={
              alreadyUsers &&
              !props.path?.approval &&
              approval && (
                <Alert
                  type="warning"
                  showIcon={true}
                  message={
                    <Plural
                      id="paths.form-edit.help.approval"
                      value={props.path?.participant_count ?? 0}
                      one={
                        'Let op: er is reeds # gebruiker ingeschreven voor het leerpad. Deze zal ook in de goedkeuringsflow terechtkomen.'
                      }
                      other="Let op: er zijn reeds # gebruikers ingeschreven voor het leerpad. Deze zullen ook in de goedkeuringsflow terechtkomen."
                    />
                  }
                />
              )
            }
          >
            <Switch
              checkedChildren={
                <Trans id="paths.form.label.approval.yes">Ja</Trans>
              }
              unCheckedChildren={
                <Trans id="paths.form.label.approval.no">Nee</Trans>
              }
            />
          </Form.Item>

          <Form.Item
            name={'registration_deadline'}
            label={t({
              id: 'paths.form.label.registration_deadline',
              message: 'Inschrijvingen sluiten op',
            })}
            extra={
              <p>
                {t({
                  id: 'paths.form.help.registration_deadline',
                  message: 'Inschrijvingen sluiten op deze datum.',
                })}
              </p>
            }
          >
            <DatePicker
              showNow={false}
              showTime={true}
              showSecond={false}
              format="D MMMM YYYY - HH:mm"
              defaultPickerValue={dayjs().add(1, 'day').startOf('day')}
              minuteStep={30}
              secondStep={60}
              style={{ width: '100%' }}
              disabledDate={(date) => {
                return date.isAfter(dayjs().endOf('hour')) &&
                  props.values['details']?.['start']
                  ? dayjs(props.values['details']?.['start'] as Date).isBefore(
                      date
                    )
                  : true
              }}
              onSelect={(value) => {
                props.form.setFieldValue('registration_deadline', value)
              }}
            />
          </Form.Item>
          <Form.Item
            key="capacity"
            label={t({
              id: 'paths.form.label.capacity',
              message: 'Maximale capaciteit',
            })}
            name="capacity"
            rules={[
              {
                type: 'number',
                min: 1,
                message: t({
                  id: 'paths.form.validation.capacity.min',
                  message: 'De capaciteit kan niet kleiner zijn dan 1.',
                }),
              },
            ]}
            extra={
              <p>
                {t({
                  id: 'paths.form.help.capacity',
                  message: 'Dit veld is optioneel, laat leeg voor onbeperkt.',
                })}
              </p>
            }
          >
            <InputNumber min={1} />
          </Form.Item>
        </>
      )}
    </StepWrapper>
  )
}

const PublishStep = ({
  form,
  values,
  path,
}: {
  form: FormInstance
  values
  path?: PathQuery['fetchLearningPathById'] & { participant_count?: number }
}) => {
  const startDate = values['details']?.['start']
  const published = Form.useWatch('published')
  const remindersField = Form.useWatch('reminders')

  /**
   * Calculate and set reminders date field
   */
  useEffect(() => {
    if (!startDate) return
    for (let i = 0; i < remindersField?.length; i++) {
      const date =
        !Number.isNaN(remindersField[i]?.time_amount) &&
        remindersField[i]?.time_unit
          ? dayjs(startDate)
              .subtract(
                remindersField[i].time_amount,
                remindersField[i].time_unit
              )
              .toISOString()
          : undefined
      form.setFieldValue(['reminders', i, 'date'], date)
    }
  }, [remindersField, startDate, form])

  return (
    <StepWrapper>
      <Form.Item
        label={t({
          id: 'paths.form.label.reminders',
          message: 'Herinneringen uitzenden',
        })}
        labelCol={{ span: 8 }}
        style={{ marginBottom: 0 }}
        hidden={!startDate}
      >
        <Form.List name="reminders">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex' }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'time_amount']}
                    initialValue={1}
                    rules={[
                      {
                        required: true,
                        message: t({
                          id: 'paths.form.validation.reminder_time_amount',
                          message: 'Verplicht',
                        }),
                      },
                    ]}
                  >
                    <InputNumber style={{ maxWidth: 72 }} min={0} max={99} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'time_unit']}
                    rules={[
                      {
                        required: true,
                        message: t({
                          id: 'paths.form.validation.reminder_time_unit',
                          message: 'Verplicht',
                        }),
                      },
                    ]}
                    initialValue={'hours'}
                  >
                    <Select
                      options={[
                        {
                          label: t({
                            id: 'paths.form.reminder_time_unit.hours',
                            message: 'uren',
                          }),
                          value: 'hours',
                        },
                        {
                          label: t({
                            id: 'paths.form.reminder_time_unit.days',
                            message: 'dagen',
                          }),
                          value: 'days',
                        },
                        {
                          label: t({
                            id: 'paths.form.reminder_time_unit.weeks',
                            message: 'weken',
                          }),
                          value: 'weeks',
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Trans id="paths.form.reminder_before">
                      voor de aanvang van het leerpad
                    </Trans>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'date']}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  <Space>
                    <PlusOutlined />
                    <Trans id="paths.form.add_reminder">
                      Voeg een herinnering toe
                    </Trans>
                  </Space>
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item
        label={
          <Trans id="articles.article_edit.form.label.status">Status</Trans>
        }
        name="published"
        valuePropName="checked"
        extra={
          published
            ? undefined
            : t({
                id: 'paths.form.help.publish',
                message:
                  'Communicatie over het leerpad wordt pas verstuurd bij het publiceren.',
              })
        }
      >
        <Switch
          disabled={
            path?.published &&
            !!(path?.participant_count && path?.participant_count > 0)
          }
          unCheckedChildren={<Trans id="article.tag.draft">Concept</Trans>}
          checkedChildren={
            <Trans id="article.tag.published">Gepubliceerd</Trans>
          }
        />
      </Form.Item>
      {!!path?.published &&
        !!(path?.participant_count && path?.participant_count > 0) && (
          <Alert
            type="warning"
            showIcon={true}
            message={
              <Trans id="paths.form.help.published">
                Er zijn reeds {path?.participant_count} inschrijvingen voor dit
                leerpad. Hierdoor kan je de publicatiestatus niet meer wijzigen.
              </Trans>
            }
          />
        )}
    </StepWrapper>
  )
}

export const EditLearningPath = (props: PageProps) => {
  const { path_id } = useParams()
  const { user } = useAuth()
  const breakpoint = Grid.useBreakpoint()
  const navigate = useNavigate()

  const formValues = useRef<{ [key: string]: { [key: string]: unknown } }>({})
  const [form] = Form.useForm()
  const title = Form.useWatch('title', form)
  const [current, setCurrent] = useState(0)

  const { data: path } = useQuery<PathQuery>(LEARNING_PATH_QUERY, {
    variables: { id: path_id },
    fetchPolicy: 'network-only',
    skip: !path_id,
    onCompleted: (data) => {
      form.setFieldsValue({
        ...data.fetchLearningPathById,
        start: data.fetchLearningPathById.start
          ? dayjs(data.fetchLearningPathById.start)
          : undefined,
        registration_deadline: data.fetchLearningPathById.registration_deadline
          ? dayjs(data.fetchLearningPathById.registration_deadline)
          : undefined,
        language: data.fetchLearningPathById.language || user?.lang,
      })
    },
  })

  const [createPath, { loading: creating }] =
    useMutation<CreateLearningPathMutation>(CREATE_LEARNING_PATH_MUTATION, {
      onCompleted: (created) => {
        notification.success({
          message: t({
            id: 'paths.create.success',
            message: 'Leerpad succesvol aangemaakt',
          }),
        })
        navigate(`/paths/${created.createLearningPath._id}`)
      },
    })

  const [updatePath, { loading: updating }] =
    useMutation<UpdateLearningPathMutation>(UPDATE_LEARNING_PATH_MUTATION, {
      variables: {
        id: path_id,
      },
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'paths.update.success',
            message: 'Leerpad succesvol bijgewerkt',
          }),
        })
        navigate(`/paths/${path_id}`)
      },
    })

  const next = async () => {
    try {
      setCurrent(current + 1)
    } catch {
      return
    }
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const steps = useMemo(
    () =>
      [
        {
          key: 'details',
          title: t({
            id: 'paths.step.details',
            message: 'Details',
          }),
          content: <DetailsStep form={form} />,
        },
        {
          key: 'registration',
          title: t({
            id: 'paths.step.registration',
            message: 'Inschrijving',
          }),
          content: (
            <RegistrationStep
              form={form}
              values={formValues.current}
              path={path?.fetchLearningPathById}
            />
          ),
        },
        path_id && {
          key: 'publish',
          title: t({
            id: 'paths.step.publish',
            message: 'Publiceren',
          }),
          content: (
            <PublishStep
              form={form}
              values={formValues.current}
              path={path?.fetchLearningPathById}
            />
          ),
        },
      ].filter(Boolean) as (StepProps & { key: string; content: ReactNode })[],
    [form, path_id, path?.fetchLearningPathById]
  )

  useEffect(() => {
    if (title) {
      props.setPageTitle?.(title)
    }
  }, [title, props])

  const items = steps.map((item) => ({ key: item.key, title: item.title }))

  if (path_id && !path?.fetchLearningPathById?._id) {
    return <LoadScreen />
  }

  return (
    <Form.Provider
      onFormFinish={(name, { values }) => {
        formValues.current[name] = values
        if (current !== steps.length - 1) return next()

        let path: { [key: string]: unknown } = {}
        for (const step of steps) {
          if (
            formValues.current[step.key] &&
            typeof formValues.current[step.key] === 'object'
          )
            path = {
              ...path,
              ...formValues.current[step.key],
            }
        }

        const input = {
          start: path.start,
          title: path.title,
          intro: path.intro,
          description: path.description,
          mode: path.mode,
          approval: path.approval ?? false,
          registration_deadline: path.registration_deadline,
          reminders: ((path.reminders as EventReminder[]) || []).map(
            (reminder) => ({
              date: reminder.date,
              time_amount: reminder.time_amount,
              time_unit: reminder.time_unit,
            })
          ),
          published: path.published ? new Date() : undefined,
          language: path.language || 'nl-BE',
        }

        if (path_id)
          return updatePath({
            variables: {
              id: path_id,
              input: {
                ...input,
                published: input.published ?? null,
                capacity: path.capacity
                  ? Number.isNaN(Number(path.capacity))
                    ? null
                    : Number(path.capacity)
                  : null,
              },
            },
          }).catch(errorNotifierFn)
        return createPath({
          variables: {
            input,
          },
        }).catch(errorNotifierFn)
      }}
    >
      <StepsWrapper>
        <Steps
          current={current}
          items={items}
          onChange={path_id ? setCurrent : undefined}
        />
        <div className="steps-content">
          <Form
            form={form}
            {...(breakpoint.lg
              ? { labelCol: { span: 8 }, wrapperCol: { span: 16 } }
              : {})}
            name={steps[current].key}
            layout={breakpoint.lg ? 'horizontal' : 'vertical'}
          >
            {steps[current].content}
          </Form>
        </div>
        <div
          className="steps-action"
          style={{
            justifyContent: current === 0 ? 'flex-end' : 'space-between',
          }}
        >
          <Button
            hidden={current === 0}
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            <Trans id="paths.step.previous">Terug</Trans>
          </Button>
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={async () => {
                try {
                  await form.validateFields()
                  form.submit()
                } catch {
                  return
                }
              }}
            >
              <Trans id="paths.step.next">Doorgaan</Trans>
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={async () => {
                await form.validateFields()
                form.submit()
              }}
              loading={creating || updating}
            >
              <Trans id="paths.step.done">Voltooien</Trans>
            </Button>
          )}
        </div>
      </StepsWrapper>
    </Form.Provider>
  )
}
