/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import { useContext, useEffect, useMemo, useState } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  BranchUsersGroupsQuery,
  BranchUsersKeyValuesQuery,
  HierarchyQuery,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import {
  prepSectionsForTreeSelect,
  prepUsersForTreeSelect,
  TreeSelectNodeProps,
} from '../../../branch/hooks/use-hierarchy-tree'
import {
  ExtendedTreeDataType,
  UltraPerformantTreeSelect,
  UltraTreeSelectProps,
} from '../../components/tree-select/UltraPerformantTreeSelect'

import HIERARCHY_QUERY from './../../../branch/queries/hierarchy.graphql'
import BRANCH_USERS_GROUPS from './../../../courses/queries/branch-users-groups.graphql'
import BRANCH_USERS_KEY_VALUES from './../../../courses/queries/branch-users-key-values.graphql'

export const UserTreeSelect = (
  props: Omit<UltraTreeSelectProps, 'treeData'> & {
    hidden?: boolean
    max?: number
    noGroups?: boolean
    onChange?: (value: string[]) => void
    hiddenKeys?: string[]
    filterByPermission?: {
      action: PermissionAction
      object: PermissionObjectType
    }
  }
) => {
  const ability = useContext(AbilityContext)
  const [checkedKeys, setCheckedKeys] = useState<string | string[]>(
    Array.isArray(props.defaultValue)
      ? props.defaultValue
      : props.defaultValue
      ? [props.defaultValue]
      : []
  )

  const { data: groupsData, loading: groupsLoading } =
    useQuery<BranchUsersGroupsQuery>(BRANCH_USERS_GROUPS, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: props.noGroups,
    })

  const { data: usersData, loading: usersLoading } =
    useQuery<BranchUsersKeyValuesQuery>(BRANCH_USERS_KEY_VALUES, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    })
  const { data: hierarchy, loading: hierarchyLoading } =
    useQuery<HierarchyQuery>(HIERARCHY_QUERY, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    })

  const dataLoading =
    (hierarchyLoading && !hierarchy) ||
    (groupsLoading && !groupsData) ||
    (usersLoading && !usersData)

  const treeData = useMemo(() => {
    let hierarchyNodes: TreeSelectNodeProps[] = hierarchy?.fetchHierarchy
      ? prepSectionsForTreeSelect(
          hierarchy?.fetchHierarchy,
          ability,
          props.filterByPermission
        )
          .map((node) => {
            return node.pId
              ? node
              : {
                  ...node,
                  disabled: false,
                  selectable: !node.disabled,
                }
          })
          .filter((node) => !node.disabled)
          .filter((node) => node.pId || node.selectable)
      : []

    // If no branch hierarchy, do not show the hierarchy folder
    if (
      hierarchyNodes.length === 1 &&
      hierarchyNodes[0].id === hierarchyNodes[0].branch_id
    ) {
      hierarchyNodes = []
    }

    const userNodes: ExtendedTreeDataType[] = usersData?.fetchBranchUsers
      ? prepUsersForTreeSelect(
          usersData.fetchBranchUsers,
          ability,
          props.filterByPermission,
          groupsData?.fetchBranchGroups || []
        ).filter((node) => !node.disabled)
      : []
    if (userNodes.length > 0)
      userNodes.unshift({
        id: 'users',
        selectable: false,
        value: 'users',
        title: t({
          id: 'user-tree-select.form.users.users',
          message: 'Gebruikers',
        }),
        label: t({
          id: 'user-tree-select.form.users.users',
          message: 'Gebruikers',
        }),
        level: 0,
      })

    const groupNodes: ExtendedTreeDataType[] =
      groupsData?.fetchBranchGroups.map((group) => ({
        id: group._id,
        pId: 'groups',
        value: group._id,
        title: group.name,
        label: group.name,
        level: 1,
        isLeaf: false,
      })) || []

    if (groupNodes.length > 0)
      groupNodes.unshift({
        id: 'groups',
        selectable: false,
        value: 'groups',
        title: t({
          id: 'user-tree-select.form.users.groups',
          message: 'Groepen',
        }),
        label: t({
          id: 'user-tree-select.form.users.groups',
          message: 'Groepen',
        }),
        level: 0,
      })

    return [...hierarchyNodes, ...userNodes, ...groupNodes].filter(
      (node) => !props.hiddenKeys?.includes(node.id)
    )
  }, [
    usersData,
    groupsData,
    hierarchy,
    ability,
    props.hiddenKeys,
    props.filterByPermission,
  ])

  useEffect(() => {
    if (props.value) {
      setCheckedKeys(props.value)
    }
  }, [props.value])

  return (
    <UltraPerformantTreeSelect
      onSelectedLeafsChange={(keys) => {
        props.onChange?.(keys)
      }}
      loading={props.loading || dataLoading}
      disabled={props.loading || props.disabled}
      multiple
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
      }}
      placeholder={
        props.noGroups
          ? t({
              id: 'user-tree-select.form.placeholder.users.no-groups',
              message: 'Selecteer gebruikers',
            })
          : t({
              id: 'user-tree-select.form.placeholder.users',
              message: 'Selecteer gebruikers of groepen',
            })
      }
      maxTagCount={20}
      maxTagPlaceholder={(omitted) => {
        return t({
          id: 'user-tree-select.form.max-tag-placeholder',
          message: `+${omitted.length} meer`,
        })
      }}
      {...props}
      treeData={treeData}
      value={checkedKeys}
      onChange={(keys) => setCheckedKeys(keys)}
    />
  )
}
