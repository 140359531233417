import { t } from '@lingui/macro'
import { usePostHog } from 'posthog-js/react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'

import { AbilityContext } from '../../auth/components/Can'
import { NavigationItem } from '../../shared/components/header/Header'
import {
  Container,
  SubNavigation,
  SubNavigationWrapper,
} from '../../shared/layout/Layout.style'

export const LibrarySubNavigation = () => {
  const location = useLocation()
  const client = usePostHog()
  const ability = useContext(AbilityContext)
  const [calendarFeature, setCalendarFeature] = useState<boolean>(false)

  const libraryNavigation = useMemo<NavigationItem[]>(
    () =>
      [
        {
          label: t({
            id: 'routes.library.courses',
            message: 'Opleidingen',
          }),
          route: '/library/courses',
          order: 1,
        },
        calendarFeature && {
          label: t({
            id: 'routes.library.events',
            message: 'Evenementen',
          }),
          route: '/library/calendar',
          params: { segment: 'all' },
          order: 2,
          permission: {
            or: [
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.BRANCH_LIVE_EVENT,
              },
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.LIVE_EVENT,
              },
            ],
          },
        },
        calendarFeature && {
          label: t({
            id: 'routes.library.learning-paths',
            message: 'Leerpaden',
          }),
          route: '/library/paths',
          order: 3,
          permission: {
            or: [
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.LEARNING_PATH,
              },
              {
                action: PermissionAction.READ,
                subject: PermissionObjectType.BRANCH_LEARNING_PATH,
              },
            ],
          },
        },
      ].filter(Boolean) as NavigationItem[],
    [calendarFeature]
  )

  useEffect(() => {
    client.onFeatureFlags(function () {
      if (client.isFeatureEnabled('Calendar')) {
        setCalendarFeature(true)
      }
    })
  }, [client])

  if (!libraryNavigation) return null
  if (libraryNavigation.filter(Boolean).length <= 1) return null

  return (
    <SubNavigationWrapper>
      <Container>
        <SubNavigation>
          {libraryNavigation
            .filter(({ permission }) => {
              if (!permission) return true
              return 'or' in permission
                ? permission.or.some((p) => {
                    return ability.can(p.action, p.subject)
                  })
                : ability.can(permission.action, permission.subject)
            })
            .map(({ label, route, params, link }, i) => {
              return route ? (
                <Link
                  key={route}
                  to={`${route}${
                    params ? `?${new URLSearchParams(params)}` : ''
                  }`}
                  style={{
                    fontWeight: location.pathname === route ? 'bold' : 'normal',
                    transition: 'none',
                  }}
                >
                  {label}
                </Link>
              ) : (
                <a
                  key={i}
                  href={link || '#'}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#FFF',
                    textDecoration: 'none',
                  }}
                >
                  {label}
                </a>
              )
            })}
        </SubNavigation>
      </Container>
    </SubNavigationWrapper>
  )
}
