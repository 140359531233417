import { useAuth } from '../../auth/hooks/use-auth'

interface Translatable {
  translations: { language: string; name: string }[]
}

export const useTranslatedData = <T extends Translatable & Record<string, any>>(
  data: T[] | undefined,
  field: Extract<keyof T, string>
) => {
  const { user } = useAuth()
  return (
    data?.map((item) => {
      if (!item.translations) return item
      const translation = item.translations.find(
        (t) => t.language === user?.lang
      )
      return {
        ...item,
        [field]: translation?.name || item[field],
      }
    }) || []
  )
}
