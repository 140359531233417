import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Col,
  notification,
  PageHeader,
  Row,
  Form,
  Input,
  InputNumber,
  Select,
  Divider,
  Card,
  TreeSelect,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Store } from 'antd/lib/form/interface'
import { cloneDeep, groupBy } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
  SelectConditionOperator,
  SimpleSelectConditionOperator,
} from '@lms-shared-patterns/models'
import {
  RuleQuery,
  UpdateRuleMutation,
  ConditionType,
  VisibilityActionType,
  VisibilityActionTarget,
  CreateRuleMutation,
  UsersKeysValuesQuery,
  CoursesKeysValuesQuery,
  TagsQuery,
  UserRolesQuery,
  BranchesKeyValuesQuery,
} from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useHierarchyTree } from 'apps/lms-front/src/modules/branch/hooks/use-hierarchy-tree'
import { errorNotifierFn } from 'apps/lms-front/src/modules/shared/helpers/error-notifier'
import { PageProps } from 'apps/lms-front/src/modules/shared/interfaces/page.interface'
import { Content } from 'apps/lms-front/src/modules/shared/layout/Layout.style'
import { purgeProperties } from 'apps/lms-front/src/modules/units/helpers/purge-properties'

import COURSE_TAGS_QUERY from './../../../../courses/queries/tags.graphql'
import USER_ROLES from './../../../../settings/queries/user-roles.graphql'
import CREATE_RULE_MUTATION from './../../../mutations/create-rule.graphql'
import UPDATE_RULE_MUTATION from './../../../mutations/update-rule.graphql'
import BRANCHES_KEY_VALUES_QUERY from './../../../queries/branches-key-values.graphql'
import COURSES_KEYS_VALUES from './../../../queries/courses-keys-values.graphql'
import RULE_QUERY from './../../../queries/rule.graphql'
import USERS_KEYS_VALUES from './../../../queries/users-keys-values.graphql'

export const RuleDetail = ({ route }: PageProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const auth = useAuth()

  const [form] = useForm()
  const [formDirty, setFormDirty] = useState<boolean>(false)

  const { data: branches } = useQuery<BranchesKeyValuesQuery>(
    BRANCHES_KEY_VALUES_QUERY
  )

  const { data: rule } = useQuery<RuleQuery>(RULE_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
    skip: !id,
  })

  const cleanedRule = useMemo(() => {
    if (rule) {
      const clone = cloneDeep(rule)
      purgeProperties(clone, ['__typename'])
      return clone as RuleQuery
    }
    return undefined
  }, [rule])

  const [createRule, { loading: creating }] =
    useMutation<CreateRuleMutation>(CREATE_RULE_MUTATION)

  const [updateRule, { loading: updating }] =
    useMutation<UpdateRuleMutation>(UPDATE_RULE_MUTATION)

  const { data: courses, loading: coursesLoading } =
    useQuery<CoursesKeysValuesQuery>(COURSES_KEYS_VALUES, {
      fetchPolicy: 'network-only',
    })

  const { data: courseTags, loading: courseTagsLoading } = useQuery<TagsQuery>(
    COURSE_TAGS_QUERY,
    {
      variables: {
        includeGlobalTags: true,
        type: 'COURSE',
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const { data: userRoles, loading: userRolesLoading } =
    useQuery<UserRolesQuery>(USER_ROLES, {
      fetchPolicy: 'network-only',
    })

  const { data: users, loading: usersLoading } = useQuery<UsersKeysValuesQuery>(
    USERS_KEYS_VALUES,
    {
      fetchPolicy: 'network-only',
    }
  )

  const { data: treeData, loading: treeLoading } = useHierarchyTree({
    filterByPermission: {
      action: PermissionAction.READ,
      object: PermissionObjectType.BRANCH_USER,
    },
    all: true,
    noBranches: false,
  })

  const handleCreation = async () => {
    try {
      const fields = await form.validateFields()
      createRule({
        variables: fields,
        refetchQueries: ['branchRules', 'rules'],
      })
        .then((value) => {
          notification.success({
            message: t({
              id: 'settings.rules.action.create.success',
              message: 'Regel succesvol aangemaakt',
            }),
          })
          setFormDirty(false)
          navigate(`/settings/rules/edit-rule/${value.data?.createRule._id}`, {
            replace: true,
          })
        })
        .catch(errorNotifierFn)
    } catch (error) {
      console.error(error)
      return
    }
  }

  const handleUpdate = async () => {
    try {
      const fields = await form.validateFields()
      updateRule({
        variables: {
          id,
          ...fields,
        },
        refetchQueries: [
          'fetchBranchRule',
          'fetchRule',
          'rules',
          'branchRules',
        ],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.rules.action.update.success',
              message: 'Regel succesvol opgeslagen',
            }),
          })
          setFormDirty(false)
        })
        .catch(errorNotifierFn)
    } catch (error) {
      console.error(error)
      return
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
        extra={[
          <Button onClick={() => navigate(-1)} key="2">
            <Trans id="actions.go_back">Ga terug</Trans>
          </Button>,
          <Button
            disabled={!formDirty || updating || creating}
            onClick={() => form.submit()}
            key="1"
            type="primary"
          >
            <Trans id="actions.save">Opslaan</Trans>
          </Button>,
        ]}
      />
      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24} lg={12}>
            {(cleanedRule || !id) && (
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={
                  cleanedRule ? (cleanedRule.fetchRule as Store) : {}
                }
                onFinish={() => {
                  if (id) handleUpdate()
                  else handleCreation()
                }}
                onFieldsChange={() => setFormDirty(true)}
                autoComplete="off"
              >
                <Form.Item
                  label={t({
                    id: 'settings.rules.form.label.name',
                    message: 'Naam',
                  })}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'settings.rules.form.validation.name',
                        message:
                          'Gelieve een naam voor deze regel in te vullen',
                      }),
                    },
                    {
                      pattern: /^[\s\w-]*$/,
                      message: t({
                        id: 'settings.rules.form.validation.name.pattern',
                        message: 'Gelieve geen speciale tekens te gebruiken',
                      }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t({
                    id: 'settings.rules.form.label.weight',
                    message: 'Gewicht',
                  })}
                  name="weight"
                  required
                >
                  <InputNumber />
                </Form.Item>
                <Divider />
                <Form.List
                  name={['conditions', 'any']}
                  rules={[
                    {
                      validator: async (_, conditionSets) => {
                        if (!conditionSets || conditionSets.length === 0) {
                          throw new Error(
                            'Minimum 1 conditieset is vereist voor een actie'
                          )
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <div
                      style={{
                        display: 'flex',
                        rowGap: 16,
                        flexDirection: 'column',
                      }}
                    >
                      {fields.map((conditionSetField) => (
                        <Card
                          size="small"
                          title={`Conditieset`}
                          key={conditionSetField.key}
                          extra={
                            <CloseOutlined
                              onClick={() => {
                                remove(conditionSetField.name)
                              }}
                            />
                          }
                        >
                          <Form.List
                            name={[conditionSetField.name, 'all']}
                            rules={[
                              {
                                validator: async (_, conditionSets) => {
                                  if (
                                    !conditionSets ||
                                    conditionSets.length === 0
                                  ) {
                                    throw new Error(
                                      'Minimum 1 conditie is vereist in een conditieset'
                                    )
                                  }
                                },
                              },
                            ]}
                          >
                            {(fields, { add, remove }, { errors }) => (
                              <div
                                style={{
                                  display: 'flex',
                                  rowGap: 16,
                                  flexDirection: 'column',
                                }}
                              >
                                {fields.map((conditionField) => {
                                  return (
                                    <Card
                                      size="small"
                                      title={`Conditie`}
                                      key={conditionField.key}
                                      extra={
                                        <CloseOutlined
                                          onClick={() => {
                                            remove(conditionField.name)
                                          }}
                                        />
                                      }
                                    >
                                      <Form.Item
                                        label="Type"
                                        name={[conditionField.name, 'subject']}
                                        required
                                      >
                                        <Select
                                          optionFilterProp={'label'}
                                          options={[
                                            {
                                              label: 'Gebruiker',
                                              value: 'User',
                                            },
                                            {
                                              label: 'Gebruikersrol',
                                              value: 'UserRole',
                                            },
                                            {
                                              label: 'Sectie',
                                              value: 'Section',
                                            },
                                          ]}
                                          onChange={(value) => {
                                            form.setFieldValue(
                                              [
                                                'conditions',
                                                'any',
                                                conditionSetField.name,
                                                'all',
                                                conditionField.name,
                                              ],
                                              {
                                                subject: value,
                                              }
                                            )
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Operator"
                                        name={[conditionField.name, 'operator']}
                                        required
                                      >
                                        <Select
                                          optionFilterProp={'label'}
                                          options={Object.keys(
                                            SelectConditionOperator
                                          ).map((key) => ({
                                            label: key,
                                            value: SelectConditionOperator[key],
                                          }))}
                                          onChange={(value) => {
                                            const subject = form.getFieldValue([
                                              'conditions',
                                              'any',
                                              conditionSetField.name,
                                              'all',
                                              conditionField.name,
                                              'subject',
                                            ])
                                            form.setFieldValue(
                                              [
                                                'conditions',
                                                'any',
                                                conditionSetField.name,
                                                'all',
                                                conditionField.name,
                                              ],
                                              {
                                                subject,
                                                operator: value,
                                              }
                                            )
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        noStyle
                                        shouldUpdate={(
                                          prevValues,
                                          currentValues
                                        ) => {
                                          const previousCondition =
                                            prevValues['conditions']?.['any']?.[
                                              conditionSetField.name
                                            ]?.['all']?.[conditionField.name]

                                          const currentCondition =
                                            currentValues['conditions'][
                                              'any'
                                            ]?.[conditionSetField.name]?.[
                                              'all'
                                            ]?.[conditionField.name]
                                          return (
                                            previousCondition !==
                                            currentCondition
                                          )
                                        }}
                                      >
                                        {({ getFieldValue }) => {
                                          const currentCondition =
                                            getFieldValue([
                                              'conditions',
                                              'any',
                                              conditionSetField.name,
                                              'all',
                                              conditionField.name,
                                            ])

                                          if (
                                            currentCondition?.operator ===
                                              SelectConditionOperator.Contains ||
                                            currentCondition?.operator ===
                                              SelectConditionOperator.DoesNotContain ||
                                            currentCondition?.operator ===
                                              SelectConditionOperator.Equals ||
                                            currentCondition?.operator ===
                                              SelectConditionOperator.DoesNotEqual
                                          ) {
                                            return (
                                              <Form.Item
                                                label="Value"
                                                name={[
                                                  conditionField.name,
                                                  'value',
                                                ]}
                                              >
                                                <Input />
                                              </Form.Item>
                                            )
                                          }

                                          if (
                                            currentCondition?.subject === 'User'
                                          )
                                            return (
                                              <Form.Item
                                                label="Value"
                                                name={[
                                                  conditionField.name,
                                                  'value',
                                                ]}
                                              >
                                                <Select
                                                  mode="multiple"
                                                  optionFilterProp="label"
                                                  loading={usersLoading}
                                                  options={users?.fetchUsers.results.map(
                                                    (user) => ({
                                                      label: `${user.firstName} ${user.lastName} (${user.email})`,
                                                      value: user._id,
                                                    })
                                                  )}
                                                />
                                              </Form.Item>
                                            )

                                          if (
                                            currentCondition?.subject ===
                                            'UserRole'
                                          )
                                            return (
                                              <Form.Item
                                                label="Value"
                                                name={[
                                                  conditionField.name,
                                                  'value',
                                                ]}
                                              >
                                                <Select
                                                  mode="multiple"
                                                  optionFilterProp="label"
                                                  loading={userRolesLoading}
                                                  options={Object.values(
                                                    groupBy(
                                                      userRoles?.fetchUserRoles,
                                                      ({ branch_name }) =>
                                                        branch_name
                                                    )
                                                  ).map((roles) => {
                                                    const branch =
                                                      roles[0].branch_name ||
                                                      t({
                                                        id: 'settings.users.form.label.default',
                                                        message: 'Standaard',
                                                      })
                                                    return {
                                                      label: branch,
                                                      options: roles.map(
                                                        (userRole) => ({
                                                          value: userRole._id,
                                                          label: userRole.name,
                                                          disabled:
                                                            userRole.level <
                                                            (auth.user
                                                              ?.role_level ??
                                                              9999),
                                                        })
                                                      ),
                                                    }
                                                  })}
                                                />
                                              </Form.Item>
                                            )

                                          if (
                                            currentCondition?.subject ===
                                            'Section'
                                          )
                                            return (
                                              <Form.Item
                                                label="Value"
                                                name={[
                                                  conditionField.name,
                                                  'value',
                                                ]}
                                              >
                                                <TreeSelect
                                                  multiple
                                                  key="2"
                                                  treeDefaultExpandAll={true}
                                                  treeLine={true}
                                                  showSearch
                                                  treeDataSimpleMode
                                                  dropdownMatchSelectWidth={
                                                    false
                                                  }
                                                  filterTreeNode={(
                                                    input,
                                                    option
                                                  ) =>
                                                    (option.title as string)
                                                      ?.toLowerCase()
                                                      .includes(
                                                        input.toLowerCase()
                                                      )
                                                  }
                                                  dropdownStyle={{
                                                    maxHeight: 400,
                                                    overflow: 'auto',
                                                  }}
                                                  treeData={treeData}
                                                  loading={treeLoading}
                                                  allowClear={true}
                                                  treeNodeLabelProp="label"
                                                />
                                              </Form.Item>
                                            )
                                          return null
                                        }}
                                      </Form.Item>
                                    </Card>
                                  )
                                })}
                                <Form.ErrorList errors={errors} />
                                <Button
                                  type="dashed"
                                  onClick={() =>
                                    add({
                                      type: ConditionType.SelectCondition,
                                      subject: 'Section',
                                      operator: SelectConditionOperator.IsIn,
                                      value: [],
                                    })
                                  }
                                  block
                                >
                                  +&nbsp;
                                  <Trans
                                    id={'settings.rules.form.add_condition'}
                                  >
                                    Conditie toevoegen
                                  </Trans>
                                </Button>
                              </div>
                            )}
                          </Form.List>
                        </Card>
                      ))}
                      <Form.ErrorList errors={errors} />
                      <Button type="dashed" onClick={() => add()} block>
                        +&nbsp;
                        <Trans id={'settings.rules.form.add_condition_set'}>
                          Conditieset toevoegen
                        </Trans>
                      </Button>
                    </div>
                  )}
                </Form.List>
                <Divider />
                <Form.List
                  name={['actions']}
                  initialValue={[]}
                  rules={[
                    {
                      validator: async (_, actions) => {
                        if (!actions || actions.length === 0) {
                          throw new Error(
                            'Minimum 1 actie is vereist voor een regel'
                          )
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 16,
                      }}
                    >
                      {fields.map((actionField) => (
                        <Card
                          size="small"
                          title={`Actie`}
                          key={actionField.key}
                          extra={
                            <CloseOutlined
                              onClick={() => {
                                remove(actionField.name)
                              }}
                            />
                          }
                        >
                          <Form.Item
                            label={t({
                              id: 'settings.rules.form.label.action_type',
                              message: 'Type',
                            })}
                            name={[actionField.name, 'type']}
                            required
                          >
                            <Select
                              optionFilterProp={'label'}
                              options={Object.keys(VisibilityActionType).map(
                                (key) => {
                                  return {
                                    label: key,
                                    value: VisibilityActionType[key],
                                  }
                                }
                              )}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[actionField.name, 'target']}
                            hidden
                            initialValue={VisibilityActionTarget.Course}
                          >
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.List
                            name={[actionField.name, 'conditions', 'any']}
                            rules={[
                              {
                                validator: async (_, conditionSets) => {
                                  if (
                                    !conditionSets ||
                                    conditionSets.length === 0
                                  ) {
                                    throw new Error(
                                      'Minimum 1 conditieset is vereist voor een actie'
                                    )
                                  }
                                },
                              },
                            ]}
                          >
                            {(fields, { add, remove }, { errors }) => (
                              <div
                                style={{
                                  display: 'flex',
                                  rowGap: 16,
                                  flexDirection: 'column',
                                }}
                              >
                                {fields.map((conditionSetField) => (
                                  <Card
                                    size="small"
                                    title={`Conditieset`}
                                    key={conditionSetField.key}
                                    extra={
                                      <CloseOutlined
                                        onClick={() => {
                                          remove(conditionSetField.name)
                                        }}
                                      />
                                    }
                                  >
                                    <Form.List
                                      name={[conditionSetField.name, 'all']}
                                      rules={[
                                        {
                                          validator: async (
                                            _,
                                            conditionSets
                                          ) => {
                                            if (
                                              !conditionSets ||
                                              conditionSets.length === 0
                                            ) {
                                              throw new Error(
                                                'Minimum 1 conditie is vereist in een conditieset'
                                              )
                                            }
                                          },
                                        },
                                      ]}
                                    >
                                      {(
                                        fields,
                                        { add, remove },
                                        { errors }
                                      ) => (
                                        <div
                                          style={{
                                            display: 'flex',
                                            rowGap: 16,
                                            flexDirection: 'column',
                                          }}
                                        >
                                          {fields.map((conditionField) => (
                                            <Card
                                              size="small"
                                              title={`Conditie`}
                                              key={conditionSetField.key}
                                              extra={
                                                <CloseOutlined
                                                  onClick={() => {
                                                    remove(conditionField.name)
                                                  }}
                                                />
                                              }
                                            >
                                              <Form.Item
                                                hidden
                                                name={[
                                                  conditionField.name,
                                                  'type',
                                                ]}
                                              >
                                                <Input type="hidden" />
                                              </Form.Item>
                                              <Form.Item
                                                label="Type"
                                                name={[
                                                  conditionField.name,
                                                  'subject',
                                                ]}
                                              >
                                                <Select
                                                  optionFilterProp={'label'}
                                                  options={[
                                                    {
                                                      label: 'Opleiding',
                                                      value: 'Course',
                                                    },
                                                    {
                                                      label: 'Opleidingstag',
                                                      value: 'CourseTag',
                                                    },
                                                    {
                                                      label:
                                                        'Opleidingsafdeling',
                                                      value:
                                                        'CourseOwnerBranch',
                                                    },
                                                  ]}
                                                  onChange={(value) => {
                                                    const operator =
                                                      form.getFieldValue([
                                                        'actions',
                                                        actionField.name,
                                                        'conditions',
                                                        'any',
                                                        conditionSetField.name,
                                                        'all',
                                                        conditionField.name,
                                                        'operator',
                                                      ])
                                                    form.setFieldValue(
                                                      [
                                                        'actions',
                                                        actionField.name,
                                                        'conditions',
                                                        'any',
                                                        conditionSetField.name,
                                                        'all',
                                                        conditionField.name,
                                                      ],
                                                      {
                                                        subject: value,
                                                        operator,
                                                      }
                                                    )
                                                  }}
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                label="Operator"
                                                name={[
                                                  conditionField.name,
                                                  'operator',
                                                ]}
                                              >
                                                <Select
                                                  optionFilterProp={'label'}
                                                  options={Object.values(
                                                    SimpleSelectConditionOperator
                                                  ).map((type) => ({
                                                    label: type,
                                                    value: type,
                                                  }))}
                                                  onChange={(value) => {
                                                    const subject =
                                                      form.getFieldValue([
                                                        'actions',
                                                        actionField.name,
                                                        'conditions',
                                                        'any',
                                                        conditionSetField.name,
                                                        'all',
                                                        conditionField.name,
                                                        'subject',
                                                      ])
                                                    form.setFieldValue(
                                                      [
                                                        'actions',
                                                        actionField.name,
                                                        'conditions',
                                                        'any',
                                                        conditionSetField.name,
                                                        'all',
                                                        conditionField.name,
                                                      ],
                                                      {
                                                        subject,
                                                        operator: value,
                                                      }
                                                    )
                                                  }}
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                noStyle
                                                shouldUpdate={(
                                                  prevValues,
                                                  currentValues
                                                ) =>
                                                  prevValues['actions']?.[
                                                    actionField.name
                                                  ]['conditions']?.['any'][
                                                    conditionSetField.name
                                                  ]['all']?.[
                                                    conditionField.name
                                                  ] !==
                                                  currentValues['actions']?.[
                                                    actionField.name
                                                  ]['conditions']?.['any'][
                                                    conditionSetField.name
                                                  ]['all']?.[
                                                    conditionField.name
                                                  ]
                                                }
                                              >
                                                {({ getFieldValue }) => {
                                                  const value = getFieldValue([
                                                    'actions',
                                                    actionField.name,
                                                    'conditions',
                                                    'any',
                                                    conditionSetField.name,
                                                    'all',
                                                    conditionField.name,
                                                  ])

                                                  if (
                                                    value?.operator ===
                                                      SelectConditionOperator.Contains ||
                                                    value?.operator ===
                                                      SelectConditionOperator.DoesNotContain ||
                                                    value?.operator ===
                                                      SelectConditionOperator.Equals ||
                                                    value?.operator ===
                                                      SelectConditionOperator.DoesNotEqual
                                                  ) {
                                                    return (
                                                      <Form.Item
                                                        label="Value"
                                                        name={[
                                                          conditionField.name,
                                                          'value',
                                                        ]}
                                                      >
                                                        <Input />
                                                      </Form.Item>
                                                    )
                                                  }

                                                  let options: {
                                                    label: string
                                                    value: string
                                                  }[] = []

                                                  switch (value?.subject) {
                                                    case 'Course': {
                                                      options =
                                                        courses?.fetchCourses?.results.map(
                                                          (i) => ({
                                                            label: i.name,
                                                            value:
                                                              i._id as string,
                                                          })
                                                        ) || []
                                                      break
                                                    }
                                                    case 'CourseTag': {
                                                      options =
                                                        courseTags?.fetchTags?.map(
                                                          (i) => ({
                                                            label: i.name,
                                                            value:
                                                              i._id as string,
                                                          })
                                                        ) || []
                                                      break
                                                    }
                                                    case 'CourseOwnerBranch': {
                                                      options =
                                                        branches?.fetchBranches?.map(
                                                          (i) => ({
                                                            label: i.name,
                                                            value:
                                                              i._id as string,
                                                          })
                                                        ) || []
                                                      break
                                                    }
                                                    default: {
                                                      options = []
                                                    }
                                                  }

                                                  return (
                                                    <Form.Item
                                                      label="Value"
                                                      name={[
                                                        conditionField.name,
                                                        'value',
                                                      ]}
                                                    >
                                                      <Select
                                                        optionFilterProp={
                                                          'label'
                                                        }
                                                        mode="multiple"
                                                        allowClear
                                                        loading={
                                                          coursesLoading ||
                                                          courseTagsLoading
                                                        }
                                                        options={options}
                                                      />
                                                    </Form.Item>
                                                  )
                                                }}
                                              </Form.Item>
                                            </Card>
                                          ))}
                                          <Form.ErrorList errors={errors} />
                                          <Button
                                            type="dashed"
                                            onClick={() =>
                                              add({
                                                type: ConditionType.SelectCondition,
                                                subject: 'Course',
                                                operator:
                                                  SelectConditionOperator.IsIn,
                                                value: [],
                                              })
                                            }
                                            block
                                          >
                                            +&nbsp;
                                            <Trans
                                              id={
                                                'settings.rules.form.add_condition'
                                              }
                                            >
                                              Conditie toevoegen
                                            </Trans>
                                          </Button>
                                        </div>
                                      )}
                                    </Form.List>
                                  </Card>
                                ))}

                                <Form.ErrorList errors={errors} />

                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                >
                                  +&nbsp;
                                  <Trans
                                    id={'settings.rules.form.add_condition_set'}
                                  >
                                    Conditieset toevoegen
                                  </Trans>
                                </Button>
                              </div>
                            )}
                          </Form.List>
                        </Card>
                      ))}
                      <Form.ErrorList errors={errors} />
                      <Button type="dashed" onClick={() => add()} block>
                        +&nbsp;
                        <Trans id={'settings.rules.form.add_action'}>
                          Actie toevoegen
                        </Trans>
                      </Button>
                    </div>
                  )}
                </Form.List>
              </Form>
            )}
          </Col>
        </Row>
      </Content>
    </>
  )
}
