import {
  PlusOutlined,
  DragOutlined,
  SaveOutlined,
  BookOutlined,
  FormOutlined,
  CalendarOutlined,
  ImportOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  rectIntersection,
  DragStartEvent,
  DragOverEvent,
  Active,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { t, Trans } from '@lingui/macro'
import { Button, notification, Space, Timeline, Tooltip, Dropdown } from 'antd'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { unstable_batchedUpdates } from 'react-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  AssignmentStep,
  EventStep,
  PathQuery,
  SelfStudyStep,
  StepCategory,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../auth/components/Can'
import { errorNotifierFn } from '../../shared/helpers/error-notifier'
import { purgeProperties } from '../../units/helpers/purge-properties'
import { ImportModuleModal } from '../components/ImportModuleModal'
import { ModuleCard } from '../components/ModuleCard'
import { StepCard } from '../components/StepCard'
import { usePath } from '../contexts/PathContext'
import { useUserPathStatus } from '../hooks/use-user-path-status.hook'
import { EditAssignmentStepModal } from '../modals/EditAssignmentStepModal'
import { EditEventStepModal } from '../modals/EditEventStepModal'
import { EditModuleModal } from '../modals/EditModuleModal'
import { EditSelfStudyStepModal } from '../modals/EditSelfStudyStepModal'
import CREATE_PATH_ASSIGNMENT_STEP from '../mutations/create-path-assignment-step.graphql'
import CREATE_PATH_EVENT_STEP from '../mutations/create-path-event-step.graphql'
import CREATE_PATH_MODULE_MUTATION from '../mutations/create-path-module.graphql'
import CREATE_PATH_SELF_STUDY_STEP from '../mutations/create-path-self-study-step.graphql'
import UPDATE_PATH_STEP_ORDER_MUTATION from '../mutations/update-path-modules-order.graphql'

import {
  InvisibleBlock,
  TimelineItem,
  TimelineItemDescription,
  TimelineItemTitle,
} from './LearningPathTimeline.style'

type Modules = NonNullable<PathQuery['fetchLearningPathById']['modules']>
type Module = Modules[0]
type ModuleStep = Module['steps'][0]

interface EditStepModalProps {
  step: ModuleStep
  open: boolean
  onClose: () => void
  path_id: string
}

const EditStepModal = ({
  step,
  open,
  onClose,
  path_id,
}: EditStepModalProps) => {
  const cleanedStep = useMemo(() => {
    if (step) {
      const clone = cloneDeep(step)
      purgeProperties(clone, ['__typename'])
      return clone as ModuleStep
    }
    return undefined
  }, [step])

  switch (step.category) {
    case StepCategory.SelfStudyStep: {
      return (
        <EditSelfStudyStepModal
          step={cleanedStep as SelfStudyStep}
          open={open}
          onClose={onClose}
        />
      )
    }
    case StepCategory.AssignmentStep: {
      return (
        <EditAssignmentStepModal
          step={cleanedStep as AssignmentStep}
          open={open}
          onClose={onClose}
        />
      )
    }
    case StepCategory.EventStep: {
      return (
        <EditEventStepModal
          step={cleanedStep as EventStep}
          open={open}
          onClose={onClose}
          path_id={path_id}
        />
      )
    }
    default: {
      return null
    }
  }
}

const SortableModuleItems = ({
  steps,
  moduleId,
  sortMode,
  activeId,
  active,
  overModuleId,
  modules,
}: {
  steps: ModuleStep[]
  moduleId: string
  sortMode: boolean
  activeId: string | null
  active: Active | null
  overModuleId: string | null
  modules: Module[]
}) => {
  if (!sortMode) {
    return (
      <>
        {steps.map((step) => (
          <Timeline.Item key={step._id}>
            <StepCard step={step} />
          </Timeline.Item>
        ))}
      </>
    )
  }

  const stepBeingDragged = modules
    .flatMap((m) => m.steps)
    .find((i) => i._id === activeId)

  return (
    <SortableContext
      items={steps.map((step) => step._id)}
      strategy={verticalListSortingStrategy}
    >
      {steps.map((step) => (
        <SortableModuleItem key={step._id} step={step} moduleId={moduleId} />
      ))}
      {overModuleId === moduleId &&
        stepBeingDragged &&
        !steps.some((i) => i._id === activeId) &&
        active?.data.current?.type === 'item' && (
          <Timeline.Item>
            <StepCard sortMode step={stepBeingDragged} ghost />
          </Timeline.Item>
        )}
    </SortableContext>
  )
}

const SortableModuleItem = ({
  step,
  moduleId,
}: {
  step: ModuleStep
  moduleId: string
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: step._id,
      data: {
        type: 'item',
        moduleId,
        step,
      },
    })

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined, // Only translate, no scale
    transition,
  }

  return (
    <Timeline.Item>
      <div ref={setNodeRef} style={style}>
        <div {...attributes} {...listeners} style={{ cursor: 'grab' }}>
          <StepCard sortMode step={step} />
        </div>
      </div>
    </Timeline.Item>
  )
}

const SortableModuleBlock = ({
  module,
  sortMode,
  activeId,
  active,
  overModuleId,
  modules,
}: {
  module: Module
  sortMode: boolean
  setModules: React.Dispatch<React.SetStateAction<Module[]>>
  activeId: string | null
  active: Active | null
  overModuleId: string | null
  modules: Module[]
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: module._id,
    data: {
      type: 'module',
      module,
    },
  })

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined, // Only translate, no scale
    transition,
    opacity: isDragging ? 0.8 : 1,
    position: 'relative' as const,
    zIndex: isDragging ? 1 : 0,
    touchAction: 'none',
    width: '100%',
    height: 'auto',
    transformOrigin: '50% 50%',
  }

  return (
    <Timeline.Item>
      <div ref={setNodeRef} style={style}>
        <div {...attributes} {...listeners} style={{ cursor: 'grab' }}>
          <ModuleCard module={module} sortMode />
        </div>
        <div style={{ marginLeft: 20, marginTop: 20 }}>
          <Timeline mode="left">
            <SortableModuleItems
              steps={module.steps}
              moduleId={module._id}
              sortMode={sortMode}
              activeId={activeId}
              active={active}
              overModuleId={overModuleId}
              modules={modules}
            />
            {!sortMode && (
              <AddModuleItemButton moduleId={module._id} module={module} />
            )}
          </Timeline>
        </div>
      </div>
    </Timeline.Item>
  )
}

export const LearningPathTimeline = ({
  path,
}: {
  path: PathQuery['fetchLearningPathById']
}) => {
  const { isTodayBeforeAvailabilityDate } = usePath()
  const { registered } = useUserPathStatus(
    path,
    path.my_activity,
    path.my_request
  )
  const [modules, setModules] = useState<Module[]>(path.modules || [])
  const [sortMode, setSortMode] = useState(false)
  const [activeId, setActiveId] = useState<string | null>(null)
  const [active, setActive] = useState<Active | null>(null)
  const [overModuleId, setOverModuleId] = useState<string | null>(null)
  const [editModalStates, setEditModalStates] = useState<
    Record<string, boolean>
  >({})
  const [updateLearningPathStepOrder] = useMutation(
    UPDATE_PATH_STEP_ORDER_MUTATION,
    {
      refetchQueries: ['path'],
    }
  )

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3, // Reduced to 3px
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id)
    setActive(event.active)
  }

  const handleDragOver = (event: DragOverEvent) => {
    const { over } = event
    if (!over) return

    const newOverModuleId =
      over.data.current?.moduleId ||
      (over.data.current?.type === 'module' ? over.id : null)

    setOverModuleId(newOverModuleId)
  }

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      // Batch all state resets
      unstable_batchedUpdates(() => {
        setActiveId(null)
        setActive(null)
        setOverModuleId(null)
      })

      if (!over || active.id === over.id) return

      const activeType = active.data.current?.type
      const overType = over.data.current?.type

      // Handle module reordering
      if (activeType === 'module' && overType === 'module') {
        const oldIndex = modules.findIndex((m) => m._id === active.id)
        const newIndex = modules.findIndex((m) => m._id === over.id)
        setModules(arrayMove(modules, oldIndex, newIndex))
        return
      }

      // Handle item reordering or moving between modules
      if (activeType === 'item') {
        const activeModuleId = active.data.current?.moduleId
        const overModuleId =
          over.data.current?.moduleId ||
          (overType === 'module' ? over.id : active.data.current?.moduleId)

        setModules((prevModules) => {
          const newModules = prevModules.map((m) => ({
            ...m,
            steps: [...m.steps],
          }))
          const sourceModule = newModules.find((m) => m._id === activeModuleId)
          const targetModule = newModules.find((m) => m._id === overModuleId)

          if (!sourceModule || !targetModule) return prevModules

          if (activeModuleId === overModuleId) {
            const oldIndex = sourceModule.steps.findIndex(
              (item) => item._id === active.id
            )
            const newIndex = sourceModule.steps.findIndex(
              (item) => item._id === over.id
            )
            sourceModule.steps = arrayMove(
              [...sourceModule.steps],
              oldIndex,
              newIndex
            )
            return newModules
          }

          const itemToMove = sourceModule.steps.find(
            (item) => item._id === active.id
          )
          if (!itemToMove) return prevModules

          sourceModule.steps = sourceModule.steps.filter(
            (item) => item._id !== active.id
          )
          targetModule.steps = [...targetModule.steps, itemToMove]

          return newModules
        })
      }
    },
    [modules]
  )

  const handleSave = useCallback(async () => {
    // Prepare the input for both modules and steps order
    const modulesInput = modules.map((module, index) => ({
      _id: module._id,
      order: index,
    }))

    const stepsInput = modules.flatMap((module) =>
      module.steps.map((step, index) => ({
        _id: step._id,
        module_id: module._id,
        order: index,
      }))
    )

    // Single mutation to update everything at once
    await updateLearningPathStepOrder({
      variables: {
        modules: modulesInput,
        steps: stepsInput,
      },
      refetchQueries: ['path'],
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'paths.detail.timeline.save_order',
            message: 'Volgorde opgeslagen',
          }),
        })
        setSortMode(false)
      },
    }).catch(errorNotifierFn)
  }, [modules, updateLearningPathStepOrder])

  useEffect(() => {
    setModules(path.modules || [])
  }, [path.modules])

  return (
    <div style={{ position: 'relative' }}>
      <Can
        I={PermissionAction.UPDATE}
        a={PermissionObjectType.BRANCH_LEARNING_PATH}
      >
        <Tooltip
          title={
            sortMode ? (
              <Trans id="action.save">Opslaan</Trans>
            ) : (
              <Trans id="paths.detail.timeline.sort_mode">
                Volgorde aanpassen
              </Trans>
            )
          }
          placement="left"
        >
          <Button
            type={sortMode ? 'primary' : 'default'}
            size="large"
            icon={sortMode ? <SaveOutlined /> : <DragOutlined />}
            onClick={() => (sortMode ? handleSave() : setSortMode(true))}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1000,
              borderRadius: '50%',
              width: 48,
              height: 48,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
            }}
          />
        </Tooltip>
      </Can>
      <Timeline style={{ marginTop: 24 }} mode="left" className="path-timeline">
        {path.registration_deadline && (
          <EventBlock
            title={
              <Trans id="paths.detail.timeline.registration.label">
                Inschrijvingen sluiten
              </Trans>
            }
            description={
              <>
                {dayjs(path.registration_deadline).format('DD MMMM YYYY')} -{' '}
                {dayjs(path.registration_deadline).format('HH:mm')}
              </>
            }
          />
        )}
        <EventBlock
          title={
            <Trans id="paths.detail.timeline.start.label">
              Start van het leerpad
            </Trans>
          }
          description={
            <>
              {path.start ? (
                <>{dayjs(path.start).format('DD MMMM YYYY')}</>
              ) : (
                <Trans id="paths.detail.timeline.start.no_date">
                  Bij inschrijving
                </Trans>
              )}
            </>
          }
        />
        {sortMode ? (
          <DndContext
            sensors={sensors}
            collisionDetection={rectIntersection}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={modules.map((module) => module._id)}
              strategy={verticalListSortingStrategy}
            >
              {modules.map((module) => (
                <SortableModuleBlock
                  key={module._id}
                  module={module}
                  sortMode={sortMode}
                  setModules={setModules}
                  activeId={activeId}
                  active={active}
                  overModuleId={overModuleId}
                  modules={modules}
                />
              ))}
            </SortableContext>
          </DndContext>
        ) : (
          modules.map((module) => {
            return (
              <Timeline.Item
                className={`${
                  module.my_activity?.completed ? 'completed' : ''
                }`}
                dot={
                  module.my_activity?.completed ? (
                    <CheckCircleOutlined />
                  ) : undefined
                }
                key={module._id}
              >
                <ModuleCard
                  module={module}
                  onEdit={() =>
                    setEditModalStates((prev) => ({
                      ...prev,
                      [module._id]: true,
                    }))
                  }
                />
                {!isTodayBeforeAvailabilityDate(module?.available_from) && (
                  <div style={{ marginLeft: 20, marginTop: 20 }}>
                    <Timeline mode="left">
                      {module.steps?.map((step) => (
                        <Timeline.Item key={step._id}>
                          <StepCard
                            clickable={registered}
                            step={step}
                            onEdit={() =>
                              setEditModalStates((prev) => ({
                                ...prev,
                                [step._id]: true,
                              }))
                            }
                          />
                          <EditStepModal
                            step={step}
                            open={editModalStates[step._id] || false}
                            onClose={() =>
                              setEditModalStates((prev) => ({
                                ...prev,
                                [step._id]: false,
                              }))
                            }
                            path_id={module.path_id}
                          />
                        </Timeline.Item>
                      ))}
                      <AddModuleItemButton
                        moduleId={module._id}
                        module={module}
                      />
                    </Timeline>
                  </div>
                )}

                <EditModuleModal
                  module={module}
                  open={editModalStates[module._id] || false}
                  onClose={() =>
                    setEditModalStates((prev) => ({
                      ...prev,
                      [module._id]: false,
                    }))
                  }
                />
              </Timeline.Item>
            )
          })
        )}
        {!sortMode && (
          <AddModuleButton
            pathId={path._id}
            setModules={setModules}
            modules={modules}
          />
        )}
      </Timeline>
    </div>
  )
}

const EventBlock = ({
  title,
  description,
}: {
  title: React.ReactNode
  description: React.ReactNode
}) => {
  return (
    <TimelineItem>
      <InvisibleBlock>
        <TimelineItemTitle>{title}</TimelineItemTitle>
        <TimelineItemDescription>{description}</TimelineItemDescription>
      </InvisibleBlock>
    </TimelineItem>
  )
}

const AddModuleButton = ({
  pathId,
  setModules,
  modules,
}: {
  pathId: string
  setModules: React.Dispatch<React.SetStateAction<Module[]>>
  modules: Module[]
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)

  const [createLearningPathModule] = useMutation(CREATE_PATH_MODULE_MUTATION, {
    refetchQueries: ['path'],
  })

  const handleSubmit = async (values) => {
    const result = await createLearningPathModule({
      variables: {
        path_id: pathId,
        input: {
          name: values.name,
          description: values.description,
          optional: values.optional || false,
          duration: values.duration,
          deadline: values.deadline,
          order: modules.length, // Add at the end
        },
      },
    })
      .then((res) => {
        notification.success({
          message: t({
            id: 'paths.detail.timeline.add_module.success',
            message: 'Module succesvol toegevoegd',
          }),
        })
        return res
      })
      .catch(errorNotifierFn)

    if (result?.data?.createLearningPathModule) {
      setModules((prev) => [...prev, result.data.createLearningPathModule])
    }
    setIsModalOpen(false)
  }

  const items = [
    {
      key: 'new',
      label: (
        <Trans id="paths.detail.timeline.add_new_module">
          Nieuwe module samenstellen
        </Trans>
      ),
      icon: <PlusOutlined />,
    },
    {
      key: 'import',
      label: (
        <Trans id="paths.detail.timeline.import_course_module">
          Opleiding als module importeren
        </Trans>
      ),
      icon: <ImportOutlined />,
    },
  ]

  return (
    <>
      <Can
        I={PermissionAction.UPDATE}
        a={PermissionObjectType.BRANCH_LEARNING_PATH}
      >
        <Timeline.Item>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) =>
                key === 'new'
                  ? setIsModalOpen(true)
                  : setIsImportModalOpen(true),
            }}
            trigger={['click']}
          >
            <Button
              type="dashed"
              style={{ width: '100%' }}
              onClick={() => {
                localStorage.setItem('aa_lms_paths_tour', 'true')
              }}
            >
              <Space>
                <PlusOutlined />
                <Trans id="paths.detail.timeline.add_module">
                  Module toevoegen
                </Trans>
              </Space>
            </Button>
          </Dropdown>
        </Timeline.Item>
      </Can>
      <EditModuleModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
      />
      <ImportModuleModal
        path={pathId}
        open={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
      />
    </>
  )
}

const AddModuleItemButton = ({
  moduleId,
  module,
}: {
  moduleId: string
  module: Module
}) => {
  const [modalState, setModalState] = useState<{
    type: StepCategory | null
    isOpen: boolean
  }>({
    type: null,
    isOpen: false,
  })

  const [createAssignmentStep] = useMutation(CREATE_PATH_ASSIGNMENT_STEP, {
    refetchQueries: ['path'],
  })
  const [createEventStep] = useMutation(CREATE_PATH_EVENT_STEP, {
    refetchQueries: ['path'],
  })
  const [createSelfStudyStep] = useMutation(CREATE_PATH_SELF_STUDY_STEP, {
    refetchQueries: ['path'],
  })

  const handleSubmit = async (values) => {
    const input = {
      ...values,
      order: module.steps?.length ?? 0,
    }

    switch (modalState.type) {
      case StepCategory.SelfStudyStep: {
        await createSelfStudyStep({
          variables: { module_id: moduleId, input },
        })
          .then(() => {
            setModalState({ type: null, isOpen: false })
            notification.success({
              message: t({
                id: 'paths.detail.timeline.add_self_study.success',
                message: 'Zelfstudie succesvol toegevoegd',
              }),
            })
          })
          .catch(errorNotifierFn)
        break
      }
      case StepCategory.AssignmentStep: {
        await createAssignmentStep({
          variables: { module_id: moduleId, input },
        })
          .then(() => {
            setModalState({ type: null, isOpen: false })
            notification.success({
              message: t({
                id: 'paths.detail.timeline.add_assignment.success',
                message: 'Opdracht succesvol toegevoegd',
              }),
            })
          })
          .catch(errorNotifierFn)
        break
      }
      case StepCategory.EventStep: {
        await createEventStep({
          variables: { module_id: moduleId, input },
        })
          .then(() => {
            setModalState({ type: null, isOpen: false })
            notification.success({
              message: t({
                id: 'paths.detail.timeline.add_event.success',
                message: 'Groepssessie succesvol toegevoegd',
              }),
            })
          })
          .catch(errorNotifierFn)
        break
      }
    }
  }

  const items = [
    {
      key: StepCategory.SelfStudyStep,
      label: (
        <Trans id="paths.detail.timeline.add_self_study">Zelfstudie</Trans>
      ),
      icon: <BookOutlined />,
    },
    {
      key: StepCategory.AssignmentStep,
      label: <Trans id="paths.detail.timeline.add_assignment">Opdracht</Trans>,
      icon: <FormOutlined />,
    },
    {
      key: StepCategory.EventStep,
      label: <Trans id="paths.detail.timeline.add_event">Groepssessie</Trans>,
      icon: <CalendarOutlined />,
    },
  ]

  return (
    <>
      <Can
        I={PermissionAction.UPDATE}
        a={PermissionObjectType.BRANCH_LEARNING_PATH}
      >
        <Timeline.Item>
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) =>
                setModalState({ type: key as StepCategory, isOpen: true }),
            }}
            trigger={['click']}
          >
            <Button type="text" style={{ width: '100%' }}>
              <Space>
                <PlusOutlined />
                <Trans id="paths.detail.timeline.add_module_item">
                  Onderdeel toevoegen
                </Trans>
              </Space>
            </Button>
          </Dropdown>
        </Timeline.Item>
      </Can>

      <EditSelfStudyStepModal
        open={
          modalState.isOpen && modalState.type === StepCategory.SelfStudyStep
        }
        onClose={() => setModalState({ type: null, isOpen: false })}
        onSubmit={handleSubmit}
        moduleId={moduleId}
      />

      <EditAssignmentStepModal
        open={
          modalState.isOpen && modalState.type === StepCategory.AssignmentStep
        }
        onClose={() => setModalState({ type: null, isOpen: false })}
        onSubmit={handleSubmit}
        moduleId={moduleId}
      />

      <EditEventStepModal
        open={modalState.isOpen && modalState.type === StepCategory.EventStep}
        onClose={() => setModalState({ type: null, isOpen: false })}
        onSubmit={handleSubmit}
        moduleId={moduleId}
        path_id={module.path_id}
      />
    </>
  )
}
