import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Plural, t, Trans } from '@lingui/macro'
import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  Switch,
} from 'antd'
import { CheckboxOptionType } from 'antd/es/checkbox'
import { FormInstance } from 'antd/es/form/Form'
import { ObjectId } from 'bson'
import dayjs, { Dayjs } from 'dayjs'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  EventLocationType,
  EventMode,
} from '@lms-shared-patterns/enums/event.enums'
import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  CreateLiveEventMutation,
  EventExtrasQuery,
  EventReminder,
  LanguagesQuery,
  LiveEventQuery,
  LocationSearchQuery,
  UpdateLiveEventMutation,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { useAuth } from '../../../auth/hooks/use-auth'
import { useBranch } from '../../../auth/hooks/use-branch'
import { LoadScreen } from '../../../core/components/LoadScreen'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { RichEditor } from '../../../shared/components/rich-editor/RichEditor'
import { UserTreeSelect } from '../../../shared/form-fields/user-tree-select/UserTreeSelect'
import useThrottledFunction from '../../../shared/hooks/use-throttled-function'
import { PageProps } from '../../../shared/interfaces/page.interface'
import CREATE_LIVE_EVENT_MUTATION from '../../mutations/create-live-event.graphql'
import UPDATE_LIVE_EVENT_MUTATION from '../../mutations/update-live-event.graphql'
import EVENT_EXTRAS_QUERY from '../../queries/event-extras.graphql'
import LIVE_EVENT_QUERY from '../../queries/live-event.graphql'
import LOCATION_SUGGESTIONS from '../../queries/location-search.graphql'

import LANGUAGES_QUERY from './../../../settings/queries/languages.graphql'
import { StepsWrapper, StepWrapper } from './EditLiveEvent.style'
import SurveyStep from './SurveyStep'

export const PlanningStep = ({
  form,
  hideCapacity = false,
  embedded = false,
  initialLocationType,
  participantCount,
  oneLocationTypeForced,
}: {
  form: FormInstance
  hideCapacity?: boolean
  embedded?: boolean
  initialLocationType?: Array<string>
  participantCount?: number
  oneLocationTypeForced?: boolean
}) => {
  const type = Form.useWatch('location_type')
  const date = Form.useWatch<Dayjs>('date')
  const startTime = Form.useWatch<Dayjs>('start_time')
  const endTime = Form.useWatch<Dayjs>('end_time')
  const [showWarning, setShowWarning] = useState(false)

  const [suggestions, setSuggestions] = useState<
    LocationSearchQuery['fetchLocationSuggestions']
  >([])
  const [getLocationSuggestions] =
    useLazyQuery<LocationSearchQuery>(LOCATION_SUGGESTIONS)

  const fetchSuggestions = async (input) => {
    if (input.length > 2) {
      const response = await getLocationSuggestions({
        variables: {
          query: input,
        },
      })

      setSuggestions(response.data?.fetchLocationSuggestions || [])
    } else {
      setSuggestions([])
    }
  }

  useEffect(() => {
    if (type) {
      if (
        !initialLocationType ||
        type.length === 0 ||
        type.length > 1 ||
        participantCount === 0
      )
        return

      // Check if the type has changed and if only one type is selected
      const hasChanged =
        !type.every((t) => initialLocationType.includes(t)) ||
        !initialLocationType.every((t) => type.includes(t))
      const onlyOneSelected = type.length === 1

      if (hasChanged && onlyOneSelected) {
        setShowWarning(true)
      } else {
        setShowWarning(false)
      }
    }
  }, [type, form, initialLocationType, participantCount])

  useEffect(() => {
    let modal: { destroy: () => void } | null = null

    if (showWarning) {
      modal = Modal.warning({
        title: t({
          id: 'events.form.warning.location_type_change.title',
          message: 'Opgelet',
        }),
        content: t({
          id: 'events.form.warning.location_type_change.content',
          message:
            'Bij het wijzigen van het locatie-type wordt de locatie automatisch aangepast voor alle geregistreerde gebruikers',
        }),
        onOk: () => setShowWarning(false),
      })
    }

    return () => {
      if (modal) {
        modal.destroy()
      }
    }
  }, [showWarning])

  const {
    throttledFn: throttledSuggestionFetch,
    cancel: cancelThrottledSuggestionFetch,
  } = useThrottledFunction({
    callbackFn: fetchSuggestions,
    throttleMs: 500,
  })

  /**
   * Calculate and set start_date and end_date fields
   */
  useEffect(() => {
    if (date && startTime && endTime) {
      form.setFieldValue(
        'start',
        dayjs(date)
          .startOf('day')
          .add(startTime.hour(), 'hour')
          .add(startTime.minute(), 'minute')
          .toDate()
      )
      form.setFieldValue(
        'end',
        dayjs(date)
          .startOf('day')
          .add(endTime.hour(), 'hour')
          .add(endTime.minute(), 'minute')
          .toDate()
      )
    }
  }, [date, startTime, endTime, form])

  return (
    <StepWrapper>
      <Form.Item key="start" name="start" rules={[{ required: true }]} hidden>
        <Input />
      </Form.Item>
      <Form.Item key="end" name="end" rules={[{ required: true }]} hidden>
        <Input />
      </Form.Item>
      <Form.Item
        key="date"
        label={t({
          id: 'events.form.label.date',
          message: 'Wanneer zal de sessie plaatsvinden?',
        })}
        name="date"
        rules={[
          {
            required: true,
            message: t({
              id: 'events.form.validation.date',
              message: 'Selecteer een datum.',
            }),
          },
        ]}
      >
        <DatePicker
          showNow={false}
          style={{ width: '100%' }}
          disabledDate={(date) => {
            return date.diff(dayjs(), 'days') < 0
          }}
          format="D MMMM YYYY"
        />
      </Form.Item>
      <Row>
        <Col
          xs={{ span: 24 }}
          lg={embedded ? { span: 11 } : { offset: 6, span: 6 }}
        >
          <Form.Item
            key="start_time"
            label={t({
              id: 'events.form.label.start',
              message: 'Starttijd',
            })}
            name="start_time"
            trigger="onSelect"
            validateTrigger={['onSelect', 'onChange', 'onBlur']}
            rules={[
              {
                required: true,
                message: t({
                  id: 'events.form.validation.start',
                  message: 'Selecteer een starttijd.',
                }),
              },
            ]}
          >
            <DatePicker.TimePicker
              allowClear={false}
              minuteStep={5}
              secondStep={60}
              showNow={false}
              style={{ width: '100%' }}
              hideDisabledOptions={true}
              format="HH:mm"
              onSelect={(value) => {
                form.setFieldValue('start_time', value)
              }}
              disabledTime={() => {
                const now = dayjs() // current date and time
                const isToday = date?.isSame(now, 'day') // check if the selected date is today

                return {
                  disabledHours: () => {
                    // If there's an endTime, calculate hours from endTime, otherwise handle past hours for today
                    const endHours = endTime
                      ? Array.from({ length: 24 - endTime.hour() })
                          .fill(0)
                          .map((_, i) => 24 - i)
                      : []

                    if (isToday) {
                      // Disable hours in the past if today
                      const pastHours = Array.from({ length: now.hour() }).map(
                        (_, i) => i
                      )
                      return [...new Set([...endHours, ...pastHours])] // Merge endHours and pastHours
                    }

                    return endHours
                  },
                  disabledMinutes: (hour) => {
                    // If there's an endTime, calculate minutes based on endTime for the given hour
                    const endMinutes =
                      endTime && hour === endTime.hour()
                        ? Array.from({ length: 65 - endTime.minute() })
                            .fill(0)
                            .map((_, i) => 60 - i)
                        : []

                    if (isToday && hour === now.hour()) {
                      // Disable minutes in the past if today and hour matches current hour
                      const pastMinutes = Array.from({
                        length: now.minute() + 1,
                      }).map((_, i) => i)
                      return [...new Set([...endMinutes, ...pastMinutes])] // Merge endMinutes and pastMinutes
                    }

                    return endMinutes
                  },
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={embedded ? { offset: 2, span: 11 } : { offset: 0, span: 6 }}
        >
          <Form.Item
            key="end_time"
            label={t({
              id: 'events.form.label.end',
              message: 'Eindtijd',
            })}
            name="end_time"
            validateTrigger={['onSelect', 'onChange', 'onBlur']}
            rules={[
              {
                required: true,
                message: t({
                  id: 'events.form.validation.end',
                  message: 'Selecteer een eindtijd.',
                }),
              },
            ]}
          >
            <DatePicker.TimePicker
              onSelect={(value) => {
                form.setFieldValue('end_time', value)
              }}
              allowClear={false}
              minuteStep={5}
              secondStep={60}
              showSecond={false}
              showNow={false}
              style={{ width: '100%' }}
              hideDisabledOptions={true}
              format="HH:mm"
              disabledTime={() => {
                return {
                  disabledHours: () => {
                    return startTime
                      ? Array.from({ length: startTime.hour() })
                          .fill(0)
                          .map((_, i) => i)
                      : []
                  },
                  disabledMinutes(hour) {
                    return startTime && hour === startTime.hour()
                      ? Array.from({ length: startTime.minute() + 5 })
                          .fill(0)
                          .map((_, i) => i)
                      : []
                  },
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        key={'title'}
        label={t({
          id: 'events.form.label.location_type',
          message: 'Waar zal de sessie plaatsvinden?',
        })}
        name={'location_type'}
        rules={[
          {
            required: true,
            message: t({
              id: 'events.form.validation.location_type',
              message: 'Selecteer een locatie.',
            }),
          },
        ]}
      >
        {oneLocationTypeForced ? (
          <Radio.Group>
            <Radio value={EventLocationType.Physical}>
              <Trans id={'events.form.label.physical'}>Fysieke locatie</Trans>
            </Radio>
            <Radio value={EventLocationType.Online}>
              <Trans id={'events.form.label.online'}>Online webinar</Trans>
            </Radio>
          </Radio.Group>
        ) : (
          <Checkbox.Group>
            <Checkbox value={EventLocationType.Physical}>
              <Trans id={'events.form.label.physical'}>Fysieke locatie</Trans>
            </Checkbox>
            <Checkbox value={EventLocationType.Online}>
              <Trans id={'events.form.label.online'}>Online webinar</Trans>
            </Checkbox>
          </Checkbox.Group>
        )}
      </Form.Item>
      {type?.includes(EventLocationType.Physical) && (
        <>
          <Form.Item
            key={'location'}
            label={t({
              id: 'events.form.label.location',
              message: 'Locatie',
            })}
            name={'location'}
            help={t({
              id: 'events.form.help.location',
              message: 'Het adres waar de fysieke sessie zal plaatsvinden.',
            })}
            rules={[
              {
                required: true,
                message: t({
                  id: 'events.form.validation.location',
                  message: 'Gelieve een locatie in te vullen.',
                }),
              },
            ]}
          >
            <AutoComplete
              maxTagCount={1}
              options={suggestions.map((suggestion) => ({
                label: suggestion,
                value: suggestion,
              }))}
              onSelect={() => {
                setSuggestions([])
              }}
              onSearch={(value) => {
                if (value === '') {
                  setSuggestions([])
                } else {
                  cancelThrottledSuggestionFetch()
                  throttledSuggestionFetch(value)
                }
              }}
            />
          </Form.Item>
          <Form.Item
            key="room"
            label={t({
              id: 'events.form.label.room',
              message: 'Zaal',
            })}
            name="room"
          >
            <Input />
          </Form.Item>
          <Form.Item
            hidden={hideCapacity}
            key="capacity"
            label={t({
              id: 'events.form.label.capacity',
              message: 'Maximale capaciteit',
            })}
            name="capacity"
            rules={[
              {
                validator: async (_, value) => {
                  if (
                    !Number.isNaN(value) &&
                    value !== undefined &&
                    value < 0
                  ) {
                    throw new Error(
                      'De capaciteit kan niet kleiner zijn dan 1.'
                    )
                  }
                },
                message: t({
                  id: 'events.form.validation.capacity.min',
                  message: 'De capaciteit kan niet kleiner zijn dan 1.',
                }),
              },
            ]}
          >
            <Input type="number" min={0} />
          </Form.Item>
          <Form.Item
            key="catering"
            name="catering"
            valuePropName="checked"
            label={t({
              id: 'events.form.label.catering',
              message: 'Catering voorzien',
            })}
          >
            <Switch />
          </Form.Item>
        </>
      )}
      {type?.includes(EventLocationType.Online) && (
        <>
          <Form.Item
            key="stream_details.host_ids"
            name={['stream_details', 'host_ids']}
            label={t({
              id: 'events.form.label.stream_hosts',
              message: 'Stream hosts',
            })}
          >
            <UserTreeSelect noGroups />
          </Form.Item>

          <Form.Item
            name={'is_survey_available'}
            label={t({
              id: 'events.form.label.survey.approval',
              message: 'Enable survey',
            })}
            valuePropName="checked"
          >
            <Switch
              checkedChildren={
                <Trans id="events.form.label.approval.yes">Ja</Trans>
              }
              unCheckedChildren={
                <Trans id="events.form.label.approval.no">Nee</Trans>
              }
            />
          </Form.Item>
        </>
      )}
    </StepWrapper>
  )
}

export const DetailsStep = ({ hideCategory }: { hideCategory?: boolean }) => {
  const { data: eventExtras } = useQuery<EventExtrasQuery>(EVENT_EXTRAS_QUERY)

  const { user } = useAuth()
  const { data: languages } = useQuery<LanguagesQuery>(LANGUAGES_QUERY)

  const branch = useBranch()
  const ability = useContext(AbilityContext)
  const MAX_CHARACTERS = {
    TITLE: 100,
    SLUG: 100,
    LECTURER: 50,
    DESCRIPTION: 5000,
  }
  return (
    <StepWrapper>
      <Form.Item
        key={'title'}
        label={t({
          id: 'events.form.label.title',
          message: 'Titel',
        })}
        name={'title'}
        rules={[
          {
            required: true,
            message: t({
              id: 'events.form.validation.title',
              message: 'Gelieve een titel in te vullen.',
            }),
          },
          {
            max: MAX_CHARACTERS.TITLE,
            message: t({
              id: 'events.form.validation.max_characters',
              message: `Gelieve onder de ${MAX_CHARACTERS.TITLE} tekens te blijven`,
            }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'language'}
        label={t({
          id: 'events.form.label.language',
          message: 'Taal',
        })}
        initialValue={user?.lang || 'nl-BE'}
      >
        <Select
          options={[
            {
              label: t({
                id: 'events.form.label.languages',
                message: 'Talen',
              }),
              options:
                languages?.fetchLanguages?.map((language) => ({
                  label: language.name,
                  value: language.code,
                })) || [],
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        key={'description'}
        label={t({
          id: 'events.form.label.description',
          message: 'Beschrijving',
        })}
        name={'description'}
        rules={[
          {
            max: MAX_CHARACTERS.DESCRIPTION,
            message: t({
              id: 'events.form.validation.max_characters',
              message: `Gelieve onder de ${MAX_CHARACTERS.DESCRIPTION} tekens te blijven`,
            }),
          },
        ]}
      >
        <RichEditor disableTextStyles />
      </Form.Item>
      <Form.Item
        label={t({
          id: 'events.form.label.lecturer',
          message: 'Docent',
        })}
        name="lecturer"
        rules={[
          {
            max: MAX_CHARACTERS.LECTURER,
            message: t({
              id: 'events.form.validation.max_characters',
              message: `Gelieve onder de ${MAX_CHARACTERS.LECTURER} tekens te blijven`,
            }),
          },
        ]}
      >
        <Input
          placeholder={t({
            id: 'events.form.placeholder.lecturer',
            message: 'John Doe',
          })}
        />
      </Form.Item>
      {eventExtras?.fetchCertificationTypes?.length && (
        <Can
          I={PermissionAction.ASSIGN}
          a={PermissionObjectType.CERTIFICATION_TYPE}
        >
          <Form.Item
            label={t({
              id: 'courses.course_edit.form.label.certification_types',
              message: 'Certificeringstypes',
            })}
            name="certificationType"
            help={
              eventExtras.fetchCertificationTypes.some(
                (type) =>
                  branch &&
                  new ObjectId(branch?._id).equals(type.branch_id || '')
              ) ? (
                <p>
                  {t({
                    id: 'settings.users.form.help.certification_types',
                    message: '* op afdelingsniveau',
                  })}
                </p>
              ) : null
            }
          >
            <Checkbox.Group
              options={eventExtras.fetchCertificationTypes
                .filter((type) =>
                  ability.can(
                    PermissionAction.ASSIGN,
                    PermissionObjectType.BRANCH_CERTIFICATION_TYPE
                  )
                    ? (type.branch_id &&
                        new ObjectId(type.branch_id).equals(branch?._id)) ||
                      !type.branch_id
                    : !type.branch_id
                )
                .map((type) => {
                  const translation = type.translation?.name || type.name
                  return {
                    label: `${translation}${type.branch_id ? ' *' : ''}`,
                    value: type._id as string,
                  } as CheckboxOptionType
                })}
            />
          </Form.Item>
        </Can>
      )}
      {eventExtras?.fetchBranchCertificationTypes &&
      eventExtras?.fetchBranchCertificationTypes?.length > 0 ? (
        <Can
          not
          I={PermissionAction.ASSIGN}
          a={PermissionObjectType.CERTIFICATION_TYPE}
        >
          <Can
            I={PermissionAction.ASSIGN}
            a={PermissionObjectType.BRANCH_CERTIFICATION_TYPE}
          >
            <Form.Item
              label={t({
                id: 'courses.course_edit.form.label.certification_types',
                message: 'Certificeringstypes',
              })}
              name="certificationType"
            >
              <Checkbox.Group
                options={eventExtras.fetchBranchCertificationTypes.map(
                  (type) =>
                    ({
                      label: type.name,
                      value: type._id as string,
                    }) as CheckboxOptionType
                )}
              />
            </Form.Item>
          </Can>
        </Can>
      ) : null}
      <Form.Item
        name={'category'}
        label={t({
          id: 'courses.course_edit.form.label.category',
          message: 'Categorie',
        })}
        hidden={
          !(
            eventExtras?.fetchBranchCategories.length ||
            eventExtras?.fetchCategories.length
          ) || hideCategory
        }
      >
        <Select
          mode="multiple"
          allowClear
          options={[
            {
              label: branch?.name || '',
              options:
                eventExtras?.fetchBranchCategories?.map((category) => ({
                  label: category.name,
                  value: category._id,
                })) || [],
            },
            {
              label: t({
                id: 'courses.course_edit.form.default_categories',
                message: 'Standaardcategorieën',
              }),
              options:
                eventExtras?.fetchCategories?.map((category) => ({
                  label: category.name,
                  value: category._id,
                })) || [],
            },
          ]}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </Form.Item>
    </StepWrapper>
  )
}

const RegistrationStep = (props: {
  form: FormInstance
  values: { [key: string]: { [key: string]: unknown } }
  event?: LiveEventQuery['fetchLiveEventById']
}) => {
  const mode = Form.useWatch('mode')
  const startDate = props.values['planning']?.['start']

  const alreadyUsers =
    props.event?.participant_count && props.event?.participant_count > 0

  const approval = Form.useWatch('approval', props.form)

  return (
    <StepWrapper>
      <Form.Item
        name="mode"
        label={t({
          id: 'events.form.label.registration_mode',
          message: 'Inschrijving',
        })}
        initialValue={EventMode.Open}
        rules={[
          {
            required: true,
            message: t({
              id: 'events.form.validation.registration_mode',
              message: 'Selecteer een inschrijvingsmodus.',
            }),
          },
        ]}
        extra={
          <p>
            {mode === EventMode.Open
              ? t({
                  id: 'events.form.help.registration_mode.open',
                  message: 'Iedereen kan zich inschrijven voor de sessie.',
                })
              : t({
                  id: 'events.form.help.registration_mode.closed',
                  message:
                    'Alleen beheerders kunnen gebruikers inschrijven voor deze sessie.',
                })}
          </p>
        }
      >
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={EventMode.Open}>
            <Trans id="events.form.label.registration_mode.open">Open</Trans>
          </Radio.Button>
          <Radio.Button value={EventMode.Closed}>
            <Trans id="events.form.label.registration_mode.closed">
              Gesloten
            </Trans>
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      {mode === EventMode.Open && (
        <>
          <Form.Item
            name={'approval'}
            label={t({
              id: 'events.form.label.approval',
              message: 'Goedkeuring nodig',
            })}
            valuePropName="checked"
            extra={
              alreadyUsers &&
              !props.event?.approval &&
              approval && (
                <Alert
                  type="warning"
                  showIcon={true}
                  message={
                    <Plural
                      id="events.form-edit.help.approval"
                      value={props.event?.participant_count ?? 0}
                      one={
                        'Let op: er is reeds # gebruiker ingeschreven voor de sessie. Deze zal ook in de goedkeuringsflow terechtkomen.'
                      }
                      other="Let op: er zijn reeds # gebruikers ingeschreven voor de sessie. Deze zullen ook in de goedkeuringsflow terechtkomen."
                    />
                  }
                />
              )
            }
          >
            <Switch
              checkedChildren={
                <Trans id="events.form.label.approval.yes">Ja</Trans>
              }
              unCheckedChildren={
                <Trans id="events.form.label.approval.no">Nee</Trans>
              }
            />
          </Form.Item>

          <Form.Item
            name={'registration_deadline'}
            label={t({
              id: 'events.form.label.registration_deadline',
              message: 'Inschrijvingen sluiten op',
            })}
            extra={
              <p>
                {t({
                  id: 'events.form.help.registration_deadline',
                  message: 'Inschrijvingen sluiten op deze datum.',
                })}
              </p>
            }
          >
            <DatePicker
              showNow={false}
              showTime={true}
              showSecond={false}
              format="D MMMM YYYY - HH:mm"
              defaultPickerValue={dayjs().add(1, 'day').startOf('day')}
              minuteStep={30}
              secondStep={60}
              style={{ width: '100%' }}
              disabledDate={(date) => {
                return date.isAfter(dayjs().endOf('hour')) && startDate
                  ? dayjs(startDate as Date).isBefore(date)
                  : true
              }}
              onSelect={(value) => {
                props.form.setFieldValue('registration_deadline', dayjs(value))
              }}
            />
          </Form.Item>
        </>
      )}
    </StepWrapper>
  )
}

const PublishStep = ({
  form,
  values,
  event,
}: {
  form: FormInstance
  values
  event?: LiveEventQuery['fetchLiveEventById']
}) => {
  const startDate = values['planning']?.['start']
  const published = Form.useWatch('published')
  const remindersField = Form.useWatch('reminders')

  /**
   * Calculate and set reminders date field
   */
  useEffect(() => {
    for (let i = 0; i < remindersField?.length; i++) {
      const date =
        !Number.isNaN(remindersField[i]?.time_amount) &&
        remindersField[i]?.time_unit
          ? dayjs(startDate)
              .subtract(
                remindersField[i].time_amount,
                remindersField[i].time_unit
              )
              .toISOString()
          : undefined
      form.setFieldValue(['reminders', i, 'date'], date)
    }
  }, [remindersField, startDate, form])

  return (
    <StepWrapper>
      {event?.path && (
        <Alert
          banner
          type="info"
          message={
            <Trans id="events.alert.linked_to_path">
              Deze groepsessie is gelinkt aan het leerpad: {event?.path?.title}
            </Trans>
          }
        />
      )}
      <Form.Item
        hidden={!!event?.path}
        label={t({
          id: 'events.form.label.reminders',
          message: 'Herinneringen uitzenden',
        })}
        labelCol={{ span: 8 }}
        style={{ marginBottom: 0 }}
      >
        <Form.List name="reminders">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex' }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'time_amount']}
                    initialValue={1}
                    rules={[
                      {
                        required: true,
                        message: t({
                          id: 'events.form.validation.reminder_time_amount',
                          message: 'Verplicht',
                        }),
                      },
                    ]}
                  >
                    <InputNumber style={{ maxWidth: 72 }} min={0} max={99} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'time_unit']}
                    rules={[
                      {
                        required: true,
                        message: t({
                          id: 'events.form.validation.reminder_time_unit',
                          message: 'Verplicht',
                        }),
                      },
                    ]}
                    initialValue={'hours'}
                  >
                    <Select
                      options={[
                        {
                          label: t({
                            id: 'events.form.reminder_time_unit.hours',
                            message: 'uren',
                          }),
                          value: 'hours',
                        },
                        {
                          label: t({
                            id: 'events.form.reminder_time_unit.days',
                            message: 'dagen',
                          }),
                          value: 'days',
                        },
                        {
                          label: t({
                            id: 'events.form.reminder_time_unit.weeks',
                            message: 'weken',
                          }),
                          value: 'weeks',
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Trans id="events.form.reminder_before">
                      voor de aanvang van de sessie
                    </Trans>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'date']}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  <Space>
                    <PlusOutlined />
                    <Trans id="events.form.add_reminder">
                      Voeg een herinnering toe
                    </Trans>
                  </Space>
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item
        hidden={!!event?.path}
        label={
          <Trans id="articles.article_edit.form.label.status">Status</Trans>
        }
        name="published"
        valuePropName="checked"
        extra={
          published
            ? undefined
            : t({
                id: 'events.form.help.publish',
                message:
                  'Communicatie over de sessie wordt pas verstuurd bij het publiceren.',
              })
        }
      >
        <Switch
          disabled={
            event?.published &&
            !!(event?.participant_count && event?.participant_count > 0)
          }
          unCheckedChildren={<Trans id="article.tag.draft">Concept</Trans>}
          checkedChildren={
            <Trans id="article.tag.published">Gepubliceerd</Trans>
          }
        />
      </Form.Item>
      {!!event?.published &&
        !!(event?.participant_count && event?.participant_count > 0) && (
          <Alert
            type="warning"
            showIcon={true}
            message={
              <Trans id="events.form.help.published">
                Er zijn reeds {event?.participant_count} inschrijvingen voor
                deze sessie. Hierdoor kan je de publicatiestatus niet meer
                wijzigen.
              </Trans>
            }
          />
        )}
    </StepWrapper>
  )
}

export const EditLiveEvent = (props: PageProps) => {
  const location = useLocation()
  const [initialLocationType, setInitialLocationType] =
    useState<Array<string>>()
  const [participantCount, setParticipantCount] = useState<number>()
  const duplicatedEvent = location.state?.duplicatedEvent

  const { event_id } = useParams()
  const { state } = useLocation()
  const breakpoint = Grid.useBreakpoint()
  const navigate = useNavigate()

  const formValues = useRef<{ [key: string]: { [key: string]: unknown } }>({})
  const [form] = Form.useForm()

  const title = Form.useWatch('title', form)
  const isSurveySectionAvailable = Form.useWatch('is_survey_available', form)
  const [current, setCurrent] = useState(0)
  const [isSurveyAvailable, setIsSurveyAvailable] = useState<
    boolean | undefined
  >(form.getFieldValue('is_survey_available'))

  useEffect(() => {
    if (isSurveySectionAvailable !== undefined)
      setIsSurveyAvailable(isSurveySectionAvailable)
  }, [isSurveySectionAvailable])

  const CALENDAR_ROUTE = '/library/calendar' as const

  useEffect(() => {
    if (state?.start && state?.end && state?.date) {
      form.setFieldsValue({
        date: dayjs(state.start),
        start_time: dayjs(state.start),
        end_time: dayjs(state.end),
      })
    } else if (state?.date) {
      form.setFieldsValue({
        date: dayjs(state.date),
      })
    }
  }, [state, form])

  useEffect(() => {
    return () => {
      // Reset form fields on unmount
      form.resetFields()
    }
  }, [form])

  const handleFormValuesChange = (changedValues: any) => {
    if ('is_survey_available' in changedValues) {
      setIsSurveyAvailable(changedValues.is_survey_available)
    }
  }

  const { data: event } = useQuery<LiveEventQuery>(LIVE_EVENT_QUERY, {
    variables: { id: event_id },
    fetchPolicy: 'network-only',
    skip: !event_id,
    onCompleted: (data) => {
      form.setFieldsValue({
        ...data.fetchLiveEventById,
        start_time: dayjs(data.fetchLiveEventById.start),
        end_time: dayjs(data.fetchLiveEventById.end),
        date: dayjs(data.fetchLiveEventById.start),
        certificationType: data.fetchLiveEventById.certificationType?.map(
          (type) => type._id
        ),
        category: data.fetchLiveEventById.category?.map(
          (category) => category._id
        ),
        registration_deadline: data.fetchLiveEventById.registration_deadline
          ? dayjs(data.fetchLiveEventById.registration_deadline)
          : undefined,
        hosts: data.fetchLiveEventById?.stream_details?.host_ids || [],
      })
      setParticipantCount(data.fetchLiveEventById.participant_count)
      setInitialLocationType(data.fetchLiveEventById.location_type)
    },
  })

  useEffect(() => {
    if (duplicatedEvent) {
      form.setFieldsValue({
        ...duplicatedEvent.data,
        start_time: dayjs(duplicatedEvent.start_time),
        end_time: dayjs(duplicatedEvent.end_time),
        date: dayjs(duplicatedEvent.date),
        certificationType: duplicatedEvent.certificationType,
        category: duplicatedEvent.category,
        participants: duplicatedEvent.participants,
        registration_deadline: duplicatedEvent.registration_deadline
          ? dayjs(duplicatedEvent.registration_deadline)
          : undefined,
      })
    }
  }, [duplicatedEvent, form])

  const [createLiveEvent, { loading: creating }] =
    useMutation<CreateLiveEventMutation>(CREATE_LIVE_EVENT_MUTATION, {
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'events.create.success',
            message: 'Sessie succesvol aangemaakt',
          }),
        })
        navigate(CALENDAR_ROUTE)
      },
    })

  const [updateLiveEvent, { loading: updating }] =
    useMutation<UpdateLiveEventMutation>(UPDATE_LIVE_EVENT_MUTATION, {
      variables: {
        id: event_id,
      },
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'events.update.success',
            message: 'Sessie succesvol bijgewerkt',
          }),
        })
        navigate(CALENDAR_ROUTE)
      },
    })

  const next = async () => {
    try {
      setCurrent(current + 1)
    } catch {
      return
    }
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  //  executed when a step is completed or tabs are changed
  const handleStepSubmit = async () => {
    try {
      await form.validateFields()
      formValues.current[steps[current].key] = await form.getFieldsValue(true)
      form.submit()

      let event: { [key: string]: unknown } = {}
      for (const step of steps) {
        if (
          formValues.current[step.key] &&
          typeof formValues.current[step.key] === 'object'
        )
          event = {
            ...event,
            ...formValues.current[step.key],
          }
      }

      return true
    } catch (error) {
      console.error('Form validation failed:', error)
      return false
    }
  }

  // executed at the end of the form when clicking `complete`
  const handleFormSubmit = async () => {
    const event = await form.getFieldsValue(true)

    try {
      const input = {
        start: event.start,
        end: event.end,
        location_type: event.location_type,
        location: event.location,
        room: event.room,
        catering: event.catering,
        capacity: event.capacity
          ? Number.parseFloat(event.capacity as string)
          : undefined,
        title: event.title,
        description: event.description,
        lecturer: event.lecturer,
        certificationType: event.certificationType,
        is_survey_available: event.is_survey_available,
        survey: (event.survey || []).map(({ __typename, ...question }) => ({
          ...question,
          answers: question.answers?.map(({ __typename, ...answer }) => answer),
        })),
        category: event.category,
        mode: event.mode,
        approval:
          event.mode === EventMode.Open ? event.approval ?? false : false,
        registration_deadline: event.registration_deadline,
        reminders: ((event.reminders as EventReminder[]) || []).map(
          (reminder) => ({
            date: reminder.date,
            time_amount: reminder.time_amount,
            time_unit: reminder.time_unit,
          })
        ),
        stream_details: {
          is_active: false,
          host_ids: event.stream_details?.host_ids || [],
        },
        published: event.published ? new Date() : undefined,
        language: event.language,
      }

      if (event_id) {
        updateLiveEvent({
          variables: {
            id: event_id,
            input: {
              ...input,
              stream_details: {
                is_active: false,
                host_ids: event.stream_details?.host_ids || [],
              },
              published: input.published ?? null,
            },
          },
        })
      } else {
        createLiveEvent({
          variables: {
            input: {
              ...input,
            },
          },
        })
      }
    } catch (error) {
      console.error('Form validation failed:', error)
    }
  }

  const steps = useMemo(
    () =>
      [
        {
          key: 'planning',
          title: t({
            id: 'events.step.planning',
            message: 'Planning',
          }),

          content: (
            <PlanningStep
              initialLocationType={initialLocationType}
              participantCount={participantCount}
              form={form}
            />
          ),
        },
        {
          key: 'survey',
          title: t({
            id: 'events.step.survey',
            message: 'Survey',
          }),
          content: <SurveyStep form={form} />,
        },
        {
          key: 'details',
          title: t({
            id: 'events.step.details',
            message: 'Details',
          }),
          content: <DetailsStep />,
        },
        {
          key: 'registration',
          title: t({
            id: 'events.step.registration',
            message: 'Inschrijving',
          }),
          content: (
            <RegistrationStep
              form={form}
              values={formValues.current}
              event={event?.fetchLiveEventById}
            />
          ),
        },
        // {
        //   key: 'attachments',
        //   title: t({
        //     id: 'events.step.attachments',
        //     message: 'Bijlagen',
        //   }),
        //   content: <AttachmentStep form={form} />,
        // },
        {
          key: 'publish',
          title: t({
            id: 'events.step.publish',
            message: 'Publiceren',
          }),
          content: (
            <PublishStep
              form={form}
              values={formValues.current}
              event={event?.fetchLiveEventById}
            />
          ),
        },
      ].filter((step) => step.key !== 'survey' || isSurveyAvailable === true),
    [
      initialLocationType,
      participantCount,
      form,
      event?.fetchLiveEventById,
      isSurveyAvailable,
    ]
  )

  useEffect(() => {
    if (title) {
      props.setPageTitle?.(title)
    }
  }, [title, props])

  const items = steps.map((item) => ({ key: item.key, title: item.title }))

  if (event_id && !event?.fetchLiveEventById?._id) {
    return <LoadScreen />
  }

  return (
    <Form.Provider>
      <StepsWrapper>
        <Steps
          current={current}
          items={items}
          onChange={async (newCurrent) => {
            try {
              if (!!event_id && (await handleStepSubmit())) {
                setCurrent(newCurrent)
              }
            } catch (error) {
              // If validation fails, don't change the step
              console.error('Form validation failed:', error)
            }
          }}
        />
        <div className="steps-content">
          <Form
            form={form}
            {...(breakpoint.lg
              ? { labelCol: { span: 8 }, wrapperCol: { span: 16 } }
              : {})}
            onValuesChange={handleFormValuesChange}
            name={steps[current].key}
            layout={breakpoint.lg ? 'horizontal' : 'vertical'}
          >
            {steps[current].content}
          </Form>
        </div>
        <div
          className="steps-action"
          style={{
            justifyContent: current === 0 ? 'flex-end' : 'space-between',
          }}
        >
          <Button onClick={() => navigate(-1)}>
            <Trans id="events.step.cancel">Terug zonder opslaan</Trans>
          </Button>
          <Space>
            <Button
              hidden={current === 0}
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
            >
              <Trans id="events.step.previous">Terug</Trans>
            </Button>
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={async () => {
                  if (await handleStepSubmit()) {
                    next()
                  }
                }}
              >
                <Trans id="events.step.next">Doorgaan</Trans>
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                onClick={async () => {
                  if (await handleStepSubmit()) {
                    handleFormSubmit()
                  }
                }}
                loading={creating || updating}
              >
                <Trans id="events.step.done">Voltooien</Trans>
              </Button>
            )}
          </Space>
        </div>
      </StepsWrapper>
    </Form.Provider>
  )
}
